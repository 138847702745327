import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';

import classes from './NavItem.module.css';

interface Props {
  img: string;
  link: string;
  exact?: boolean;
}

const NavItem: FC<Props> = ({
  img,
  link,
  children,
  exact = false,
}) => (
  <li className={classes.navItem}>
    <NavLink to={link} exact={exact} activeClassName={classes.active}>
      <img
        src={img}
        alt="nav item icon"
        className={classes.navIcon}
      />
      <span className={classes.linkLabel}>{children}</span>
    </NavLink>
  </li>
);
export default NavItem;
