import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

// import Spinner from 'components/UI/Spinner/Spinner';
import { LOGIN } from './routes';
import { setToken as SetTokenAction } from 'store/actions/auth';
import { auth, getToken } from './firebase';

type TokenType = string | null;
// type Status = 'LOADING' | 'SIGNED_IN';

const WithAuth = (Component: any) =>
  function HOC(props: any) {
    const dispatch = useDispatch();
    const [token, setToken] = useState<TokenType>(null);
    // const [status, setStatus] = useState<Status>('LOADING');

    useEffect(() => {
      auth.onAuthStateChanged(async (user) => {
        if (user) {
          const token = await getToken();
          if (token) {
            await dispatch(SetTokenAction(token));
            setToken(token);
            // setStatus('SIGNED_IN');
          }
        } else {
          props.history.push(LOGIN.URL);
        }
      });
    });

    // if (status === 'LOADING') {
    //   return <Spinner />;
    // } else {
    return <Component {...props} token={token} />;
    // }
  };

export default WithAuth;
