import React, { FC, useState, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { RootState } from 'store';
import Modal from '../Modal/Modal';
import { getInitials } from 'utils/helpers';
import { CoachProfile } from 'interfaces/db';
import { useLocalStorage } from 'utils/hooks';
import { LS_PRICING_INFO } from 'utils/constants';
import CoachCodeForm from '../../forms/CoachCode';
import { FirebaseObject, LocalStoragePricing } from 'interfaces/utils';
import { fetchCoachProfile, logout, saveCoachCode, saveCoachProfile } from 'store/actions/auth';
import OutsideAlerter from '../../OutsideAlerter/OutsideAlerter';
import { COACH_TRAINING, ACCOUNT, BILLING, LOGOUT, ANALYTICS, COMMUNITY } from 'utils/routes';
import classes from './Header.module.css';

const partialProfile: Pick<
  CoachProfile,
  'isPaidSubscriptionActive' | 'planId' | 'clientCount' | 'code' | 'fullname' | 'profile_url'
> = {
  isPaidSubscriptionActive: false,
  planId: 1,
  clientCount: 0,
  code: 'test',
  fullname: '',
  profile_url: '',
};

const mapStateToProps = (state: RootState) => {
  return {
    token: state.auth.token,
    userId: state.auth.userId,
    isRegistered: state.auth.isRegistered,
    coachProfile: state.auth.coachProfile,
    dbPlans: state.plans.plans,
    clients: state.clients.clients,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onLogout: () => dispatch(logout()),
    onSaveCoachCode: (token: string, userId: string, coachKey: CoachProfile & { key: string }, code: string) =>
      dispatch(saveCoachCode(token, userId, coachKey, code)),
    onSaveCoachProfile: (coachProfile: CoachProfile, coachProfileKey: string, token: string) =>
      dispatch(saveCoachProfile(coachProfile, coachProfileKey, token)),
    onFetchCoachProfile: (token: string, userId: string, isRegistered: boolean) =>
      dispatch(fetchCoachProfile(token, userId, isRegistered)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  loading: boolean;
  heading: string;
};

const BackArrow: FC = () => {
  return (
    <svg style={{ height: 20, width: 20 }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.01 35">
      <path
        fill="#707070"
        d="M6.032 17.495L19.275 4.262A2.501 2.501 0 0015.732.73l-15 14.993a2.5 2.5 0 00-.073 3.449l15.066 15.1a2.501 2.501 0 003.543-3.532z"
      />
    </svg>
  );
};

const Header: FC<Props> = ({
  token,
  userId,
  loading,
  heading,
  dbPlans,
  onLogout,
  coachProfile,
  isRegistered,
  onSaveCoachCode,
  onSaveCoachProfile,
  onFetchCoachProfile,
  clients,
}) => {
  const history = useHistory();
  const [selectedPricing] = useLocalStorage<LocalStoragePricing | null>(LS_PRICING_INFO, null);

  const [showDropDown, setShowDropDown] = useState(false);
  const [isSubscriptionActive, setIsSubscriptionActive] = useState(true);
  const [profileObj, setProfileObj] = useState<FirebaseObject<CoachProfile> | CoachProfile>({});
  const [coachKey, setCoachKey] = useState<string>('');

  useEffect(() => {
    const key = Object.keys(coachProfile || {})[0];
    setCoachKey(key);
    const profile: any = coachProfile?.[key] ?? partialProfile;
    setProfileObj(profile);
  }, [coachProfile]);

  useEffect(() => {
    const setCodeAndClientCount = async () => {
      if (clients && token && userId) {
        const plansArr = Object.values(dbPlans || {}) || [];
        if (!plansArr.length) {
          return;
        }

        await onFetchCoachProfile(token, userId, false)
          .then(async (response: FirebaseObject<CoachProfile>) => {
            const key = Object.keys(response)[0];
            const profile = response?.[key];

            const currentClientCount = profile?.clientCount || 0;
            const fetchedClientCount = Object.keys(clients).length || 0;
            if (currentClientCount !== fetchedClientCount) {
              await onSaveCoachProfile(
                {
                  ...profile,
                  clientCount: fetchedClientCount,
                } as CoachProfile,
                key,
                token,
              );
            }

            setProfileObj(profile);

            const { isPaidSubscriptionActive, planId = 1, clientCount } = profile;

            let maxClients = 1;
            const plan = plansArr.find((el) => el.id === planId);

            if (plan) {
              maxClients = plan.maxClients;
            }

            if ((!isPaidSubscriptionActive && planId !== 1) || clientCount > maxClients) {
              history.push(BILLING.URL);
              setIsSubscriptionActive(false);
            } else {
              setIsSubscriptionActive(true);
            }
          })
          .catch((err: any) => {
            console.log(err, 'error');
          });
      }
    };

    setCodeAndClientCount();
  }, [dbPlans, history, onFetchCoachProfile, onSaveCoachProfile]);

  const logout = () => {
    onLogout();
  };

  const setCoachCode = (code: string) => {
    if (token && userId && coachProfile) {
      onSaveCoachCode(token, userId, { ...coachProfile[coachKey], key: coachKey }, code);
    }

    if (selectedPricing) {
      history.push(BILLING.URL);
    }
  };

  const canGoBack = history.length && history.location.pathname.split('/').length > 2;

  const handleBackClick = () => {
    const { location } = history;
    if (location.pathname.includes('/programs/') && !location.pathname.includes('workout-builder')) {
      history.replace('/programs');
    } else {
      history.goBack();
    }
  };

  const { code: coachCode = '', fullname, profile_url } = profileObj;
  const initials = getInitials(fullname + '');

  return (
    <>
      {!isSubscriptionActive && (
        <div className={classes.subNotification}>You have exceeded the limits of your current plan.</div>
      )}

      <div className={classes.Header}>
        <div className={classes.PageTitle}>
          {loading && <h3 className={classes.heading}>Loading...</h3>}
          {!loading && (
            <>
              {canGoBack && (
                <button type="button" className={classes.backBtn} onClick={handleBackClick}>
                  <BackArrow />
                </button>
              )}

              <h3 className={classes.heading}>{heading}</h3>
            </>
          )}
        </div>

        <div className={classes.rhsWrapper}>
          <NavLink to={COACH_TRAINING.URL} className={classes.workoutLink}>
            {COACH_TRAINING.TITLE}
          </NavLink>
          <OutsideAlerter close={() => setShowDropDown(false)} show={showDropDown}>
            {showDropDown ? (
              <ul className={classes.DropDownContainer}>
                <NavLink to={ACCOUNT.URL} activeClassName={classes.active} exact>
                  <li className={classes.DropDownButton} onClick={() => setShowDropDown(false)}>
                    {ACCOUNT.TITLE}
                  </li>
                </NavLink>
                <li className={classes.LineRuleAnalytics}></li>
                <NavLink to={COMMUNITY.URL} activeClassName={classes.active} exact>
                  <li className={classes.DropDownButtonAnalytics} onClick={() => setShowDropDown(false)}>
                    Community
                  </li>
                </NavLink>
                <li className={classes.LineRuleAnalytics}></li>
                <NavLink to={ANALYTICS.URL} activeClassName={classes.active} exact>
                  <li className={classes.DropDownButtonAnalytics} onClick={() => setShowDropDown(false)}>
                    Analytics
                  </li>
                </NavLink>
                <li className={classes.LineRule}></li>
                <NavLink to={BILLING.URL} activeClassName={classes.active} exact>
                  <li className={classes.DropDownButton} onClick={() => setShowDropDown(false)}>
                    {BILLING.TITLE}
                  </li>
                </NavLink>
                <li className={classes.LineRule}></li>
                <li onClick={logout} className={classes.DropDownButton}>
                  {LOGOUT.TITLE}
                </li>
              </ul>
            ) : null}

            <div className={classes.profileWrapper}>
              {profile_url ? (
                <div className={classes.ImageDiv} onClick={() => setShowDropDown(!showDropDown)}>
                  {initials}
                </div>
              ) : (
                <div className={classes.Account} onClick={() => setShowDropDown(!showDropDown)}>
                  {initials}
                </div>
              )}

              <div className={classes.CoachName}>
                <img
                  onClick={() => setShowDropDown(!showDropDown)}
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAABmJLR0QA/wD/AP+gvaeTAAAAfklEQVRIie3PSwqAIBRGYZeQRAtqDY7achBtplEEp4mBRA+1X5p4RuKF+3GNqdV+C+iK7wR6YAGcEHF+Zx9+NsAIbMAgQlZgBtrzUII9IiosCvmKJSG5WBaSin1CYjEJ8oZJkTusCBJgFpg8sPq3lSIBdlymv+QCs8UuqdWk7dKfQxIWXBY7AAAAAElFTkSuQmCC"
                  alt="dropdown arrow"
                />
              </div>
            </div>
          </OutsideAlerter>
        </div>
      </div>

      <Modal isModalOpen={isRegistered || !coachCode} openModal={() => null}>
        <div className={classes.modalContainer}>
          <CoachCodeForm handleSubmit={setCoachCode} />
        </div>
      </Modal>
    </>
  );
};

export default connector(Header);
