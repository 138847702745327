import React, { FC } from 'react';
import classes from './ChannelsSidebar.module.css';
import { Channel } from 'interfaces/db';
interface Props {
  channels: Channel[];
  setSelectedChannel: (channel: string) => void;
  selectedChannel: string;
}

const ChannelsSidebar: FC<Props> = ({ channels, setSelectedChannel, selectedChannel }) => {
  return (
    <div className={classes.Container}>
      <ul className={classes.List}>
        {channels?.map((channel, key) => {
          return (
            <li key={key}>
              <button
                className={
                  selectedChannel === channel.id
                    ? [classes.ChannelButton, classes.Selected].join(' ')
                    : classes.ChannelButton
                }
                onClick={() => setSelectedChannel(channel.id)}
              >
                {channel?.data?.channelTitle || channel.id}
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ChannelsSidebar;
