import React, { FC } from 'react';
import { Formik, FormikHelpers, Form, Field, ErrorMessage } from 'formik';
import { object, string } from 'yup';

import { FormProps } from 'interfaces/utils';
import classes from './AddCategory.module.css';

type InitialValues = {
  category: string;
};

const initialValues = { category: '' };

const AddCategory: FC<
  FormProps<string> & {
    existingCategories: string[];
    loading: boolean;
  }
> = ({ existingCategories, handleSubmit, loading }) => {
  const validationSchema = object().shape({
    category: string()
      .min(4)
      .test('unique-category-validation', 'Sorry this category is already available', (value) => {
        return !existingCategories.includes(value?.toLowerCase() || '');
      })
      .required(),
  });

  const onSubmit = async (payload: InitialValues, actions: FormikHelpers<InitialValues>) => {
    handleSubmit(payload.category?.trim());
    actions.setSubmitting(false);
  };

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
      {({ submitForm }) => (
        <Form>
          <div className={classes.CategoryModal}>
            <p>Add an extra category for you to organise your documents!</p>

            <Field
              id="category"
              name="category"
              placeholder="Category"
              className={classes.Category}
            />

            <ErrorMessage name="category">
              {(errMsg) => <p className={classes.CategoryWarning}>{errMsg}</p>}
            </ErrorMessage>

            <div className={classes.ModalButtons}>
              <button type="button" onClick={submitForm} className={classes.ModalSave}>
                {loading ? <i className="fa fa-circle-o-notch fa-spin" /> : 'Save Category'}
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddCategory;
