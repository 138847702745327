import { combineReducers, AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import clientsReducer from './reducers/clients';
import programsReducer from './reducers/programs';
import authReducer from './reducers/auth';
import plansReducer from './reducers/plans';
import exerciseLibraryReducer from './reducers/exerciseLibrary';
import documentsReducer from './reducers/documents';

export const rootReducer = combineReducers({
  clients: clientsReducer,
  programs: programsReducer,
  auth: authReducer,
  plans: plansReducer,
  exerciseLibrary: exerciseLibraryReducer,
  documents: documentsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk = ThunkAction<
  void,
  RootState,
  unknown,
  AnyAction
>;

const appReducer = (state: any, action: any) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === 'AUTH_LOGOUT') {
    // we keep a reference of the keys we want to maintain
    // other keys will be passed as undefined and this will call
    // reducers with an initial state
    storage.removeItem('persist:root');
    state = undefined;
  }

  return rootReducer(state, action);
};

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'clients',
    'programs',
    'auth',
    'coachWorkout',
    'plans',
    'documents',
  ], // this is wrong - you shouldn't persist the auth [i did it to persist the coach profile]
};

export const persistedReducer = persistReducer(
  persistConfig,
  appReducer,
);
