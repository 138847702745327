import axios from 'axios';
import { AddToast } from 'react-toast-notifications';

import { fetchPlans } from './plans';
import { AppThunk } from '../index';
import { auth, getToken, config, storage, database } from 'utils/firebase';
import {
  AUTH_START,
  AUTH_SUCCESS,
  AUTH_FAIL,
  AUTH_LOGOUT,
  CREATE_COACH_PROFILE_START,
  CREATE_COACH_PROFILE_SUCCESS,
  CREATE_COACH_PROFILE_FAIL,
  FETCH_COACH_PROFILE_START,
  FETCH_COACH_PROFILE_SUCCESS,
  FETCH_COACH_PROFILE_FAIL,
  SAVE_COACH_PROFILE_START,
  SAVE_COACH_PROFILE_SUCCESS,
  SAVE_COACH_PROFILE_FAIL,
  SAVE_COACH_CODE_START,
  SAVE_COACH_CODE_SUCCESS,
  SAVE_COACH_CODE_FAIL,
  FETCH_COACH_CODES_START,
  FETCH_COACH_CODES_SUCCESS,
  FETCH_COACH_CODES_FAIL,
  SET_TOKEN,
} from './actionTypes';
import { BASE_URL } from 'utils/helpers';
import { AuthActionTypes } from 'interfaces/actions/auth';
import { CoachProfile, Codes } from 'interfaces/db';
import { FirebaseObject } from 'interfaces/utils';

const authStart = (): AuthActionTypes => {
  return {
    type: AUTH_START,
  };
};

const authSuccess = (token: string, userId: string): AuthActionTypes => {
  return {
    token,
    userId,
    type: AUTH_SUCCESS,
  };
};

const authFail = (error: string): AuthActionTypes => {
  return {
    type: AUTH_FAIL,
    error: error,
  };
};

export const setToken = (token: string): AuthActionTypes => {
  return {
    token,
    type: SET_TOKEN,
  };
};

export const logout = (): AuthActionTypes => {
  auth.signOut();
  return {
    type: AUTH_LOGOUT,
  };
};

const createCoachProfileStart = (): AuthActionTypes => {
  return {
    type: CREATE_COACH_PROFILE_START,
  };
};

const createCoachProfileSuccess = (registered: true): AuthActionTypes => {
  return {
    type: CREATE_COACH_PROFILE_SUCCESS,
    registered: registered,
  };
};

const createCoachProfileFail = (): AuthActionTypes => {
  return {
    type: CREATE_COACH_PROFILE_FAIL,
  };
};

const fetchCoachProfileStart = (): AuthActionTypes => {
  return {
    type: FETCH_COACH_PROFILE_START,
  };
};

export const fetchCoachProfileSuccess = (
  profile: FirebaseObject<CoachProfile>,
  registered: boolean,
): AuthActionTypes => {
  return {
    type: FETCH_COACH_PROFILE_SUCCESS,
    coachProfile: profile,
    registered: registered,
  };
};

const fetchCoachProfileFail = (): AuthActionTypes => {
  return {
    type: FETCH_COACH_PROFILE_FAIL,
  };
};

const saveCoachProfileStart = (): AuthActionTypes => {
  return {
    type: SAVE_COACH_PROFILE_START,
  };
};

const saveCoachProfileSuccess = (coachProfile: FirebaseObject<CoachProfile>): AuthActionTypes => {
  return {
    type: SAVE_COACH_PROFILE_SUCCESS,
    coachProfile: coachProfile,
  };
};

const saveCoachCodeStart = (): AuthActionTypes => {
  return {
    type: SAVE_COACH_CODE_START,
  };
};

const saveCoachCodeSuccess = (): AuthActionTypes => {
  return {
    type: SAVE_COACH_CODE_SUCCESS,
  };
};

const saveCoachCodeFail = (): AuthActionTypes => {
  return {
    type: SAVE_COACH_CODE_FAIL,
  };
};

const saveCoachProfileFail = (error: string): AuthActionTypes => {
  return {
    error,
    type: SAVE_COACH_PROFILE_FAIL,
  };
};

const fetchCoachCodesStart = (): AuthActionTypes => {
  return {
    type: FETCH_COACH_CODES_START,
  };
};

const fetchCoachCodesSuccess = (codes: FirebaseObject<Codes>): AuthActionTypes => {
  return {
    type: FETCH_COACH_CODES_SUCCESS,
    codes: codes,
  };
};

const fetchCoachCodesFail = (): AuthActionTypes => {
  return {
    type: FETCH_COACH_CODES_FAIL,
  };
};

// @ts-ignore: Unused variable
export const fetchCoachProfile = (token: string, userId: string, isRegistered: boolean): AppThunk => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchCoachProfileStart());
      try {
        database.ref('coachprofiles').orderByChild('userId').equalTo(userId).once('value', (snapshot) => {
          const coachProfile = snapshot.val();
          resolve(coachProfile);
          dispatch(fetchCoachProfileSuccess(coachProfile, isRegistered));
        });
      } catch (e) {
        reject(e);
        dispatch(fetchCoachProfileFail());
      }
    });
  };
};

export const createCoachProfile = (token: string, userId: string, name: string, email: string): AppThunk => {
  const coachProfile: Pick<
    CoachProfile,
    'birthday' | 'code' | 'email' | 'fullname' | 'profile_url' | 'userId' | 'referred_by'
  > = {
    birthday: '12/12/1900',
    code: '',
    email: email,
    fullname: name,
    profile_url: '',
    userId: userId,
  };

  return (dispatch) => {
    dispatch(createCoachProfileStart());

    database.ref('coachprofiles').push(coachProfile, (error) => {
      if (error) {
        dispatch(createCoachProfileFail());
      } else {
        dispatch(createCoachProfileSuccess(true));
        dispatch(fetchCoachProfile(token, userId, true));
      }
    });
  };
};

export const login = (email: string, password: string, addToast: AddToast): AppThunk => {
  return (dispatch) => {
    dispatch(authStart());

    auth.signInWithEmailAndPassword(email, password)
      .then(async (res) => {
        if (res.user) {
          const { uid } = res.user;

          const token = await getToken();
          if (!token) {
            throw new Error('invalid token');
          }

          dispatch(authSuccess(token, uid));
          dispatch(fetchCoachProfile(token, uid, false));
          dispatch(fetchPlans(token));
        }
      })
      .catch((err) => {
        // show toast notification
        addToast(err.message, {
          appearance: 'error',
        });

        dispatch(authFail(err.message));
      });
  };
};

export const register = (name: string, email: string, password: string): AppThunk => {
  return (dispatch) => {
    dispatch(authStart());

    auth
      .createUserWithEmailAndPassword(email, password)
      .then(async (res) => {
        if (res.user) {
          const { uid } = res.user;

          const token = await getToken();
          if (!token) {
            throw new Error('invalid token');
          }

          dispatch(authSuccess(token, uid));
          dispatch(createCoachProfile(token, uid, name, email));
          dispatch(fetchPlans(token));
        }
      })
      .catch((err) => {
        dispatch(authFail(err));
      });
  };
};

// @ts-ignore: Unused variable
export const saveCoachProfile = (coachProfile: CoachProfile, coachProfileKey: string, token: string): AppThunk => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(saveCoachProfileStart());

      database.ref(`coachprofiles/${coachProfileKey}`).update(coachProfile)
        .then(() => {
          resolve(null);
          dispatch(
            saveCoachProfileSuccess({
              [coachProfileKey]: coachProfile,
            }),
          );
        })
        .catch((error) => {
          reject(error);
          dispatch(saveCoachProfileFail(error));
        });
    });
  };
};

export const saveCodeInCoachProfile = (
  // @ts-ignore: Unused variable
  token: string,
  coachProfile: CoachProfile & { key: string },
  code: string,
): AppThunk => {
  const { key, ...profileWithoutKeyProp } = coachProfile;
  const newProfile = { ...profileWithoutKeyProp, code };

  return (dispatch) => {
    database.ref(`coachprofiles/${key}`).update({ code }, (error) => {
      if (error) {
        dispatch(saveCoachCodeFail());
      } else {
        dispatch(saveCoachCodeSuccess());
        dispatch(fetchCoachProfileSuccess({ [key]: newProfile }, false));
      }
    })
  };
};

export const saveCoachCode = (
  token: string,
  userId: string,
  coachKey: CoachProfile & { key: string },
  code: string,
): AppThunk => {
  const temp: Codes = {
    coachcode: code.toLowerCase(),
    userId: userId,
  };

  return (dispatch) => {
    dispatch(saveCoachCodeStart());
    database.ref('codes').push(temp, (error) => {
      if (error) {
        dispatch(saveCoachCodeFail());
      } else {
        dispatch(saveCodeInCoachProfile(token, coachKey, code));
      }
    });
  };
};

// @ts-ignore: Unused variable
export const fetchCoachCodes = (token: string): AppThunk => {
  return (dispatch) => {
    dispatch(fetchCoachCodesStart());
    database.ref('codes').once('value').then((snapshot) => {
      const codes = snapshot.val();
      dispatch(fetchCoachCodesSuccess(codes));
    }).catch(() => {
      dispatch(fetchCoachCodesFail());
    });
  };
};

export const deleteDocuments = (url: string): AppThunk => {
  return (dispatch, state) => {
    const { auth } = state();
    const { token, userId, coachProfile } = auth;
    const coachProfileKey = Object.keys(coachProfile || {})[0];

    if (!token || !userId || !coachProfile) {
      return;
    }

    dispatch(fetchCoachProfileStart());
    const { mealPlans = [] } = coachProfile[coachProfileKey];

    const newMealPlans = mealPlans.filter(({ downloadURL }) => downloadURL !== url);
    const newCoachProfile = {
      ...coachProfile[coachProfileKey],
      mealPlans: newMealPlans,
    };

    // delete
    const unassignPlan = axios({
      method: 'POST',
      url: `${BASE_URL}/v1-https-unassignMealPlan`,
      data: {
        url,
      },
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    const storagePromise = storage.refFromURL(url).delete();
    const updateCoachProfile = axios.patch(
      `${config.databaseURL}/coachprofiles/${coachProfileKey}.json?auth=${token}`,
      newCoachProfile,
    );

    Promise.all([unassignPlan, storagePromise, updateCoachProfile])
      .then(async () => {
        dispatch(fetchCoachProfile(token, userId, false));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
