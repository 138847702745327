import React, { FC, useState } from 'react';
import { SetKeys, Day as DayType } from 'interfaces/db';
import Modal from '../Modal/Modal';
import QuickEdit from '../QuickEdit/QuickEdit';
import classes from './QuickEditModal.module.css';
import { IndexedExercise } from 'interfaces/utils';

interface Props {
  showModal: boolean;
  closeModal: () => void;
  day: DayType;
  dayNumber: number;
  weekNumber: number;
  saveProgram: (workoutId?: string) => void;
  setRestDay: (weekIndex: number, dayIndex: number) => void;
  removeRestDay: (weekIndex: number, dayIndex: number) => void;
  addWorkout: () => Promise<void>;
  copyWorkoutHandler: () => void;
  pasteWorkoutHandler: () => void;
  goToWorkoutBuilder: () => Promise<void>;
  addExerciseNotes: (exerciseNotes: string, group: number, index: number) => void;
  addWorkoutNotes: (workoutNotes: string, dayIndex: number, weekIndex: number) => void;
  addSetHandler: (group: number, index: number) => void;
  removeSetHandler: (group: number, index: number) => void;
  setsChangeHandler: (key: SetKeys, val: string, setIndex: number, group: number, index: number) => void;
  autoFillSetsHandler: (key: SetKeys, val: string, setIndex: number, group: number, index: number) => void;
  setShowAutoFill: (setIdentifier: string) => void;
  showAutoFill: string;
  previousDay?: DayType;
  clientId?: string;
  coachId?: string | null;
}

interface QuickEditProps {
  setIsQuickEdit: (val: boolean) => void;
}

const QuickEditModal: FC<
  Pick<
    Props,
    | 'showModal'
    | 'closeModal'
    | 'day'
    | 'dayNumber'
    | 'weekNumber'
    | 'saveProgram'
    | 'addExerciseNotes'
    | 'addWorkoutNotes'
    | 'addSetHandler'
    | 'removeSetHandler'
    | 'setsChangeHandler'
    | 'autoFillSetsHandler'
    | 'setShowAutoFill'
    | 'showAutoFill'
    | 'previousDay'
    | 'clientId'
    | 'coachId'
  > &
    QuickEditProps
> = ({
  showModal,
  closeModal,
  day,
  dayNumber,
  weekNumber,
  saveProgram,
  setIsQuickEdit,
  addExerciseNotes,
  addWorkoutNotes,
  addSetHandler,
  removeSetHandler,
  setsChangeHandler,
  autoFillSetsHandler,
  setShowAutoFill,
  showAutoFill,
  previousDay,
  clientId,
  coachId,
}) => {
  const [analyticsExerciseList, setAnalyticsExerciseList] = useState<IndexedExercise[]>([]);

  const openAnalytics = (indexedExercise: IndexedExercise) => {
    const exerciseList = [...analyticsExerciseList];
    exerciseList.push(indexedExercise);
    setAnalyticsExerciseList(exerciseList);
  };

  const closeAnalytics = (indexedExercise: IndexedExercise) => {
    const exerciseList = [...analyticsExerciseList];
    const newList = exerciseList.filter(
      (ex) => !(ex.group === indexedExercise.group && ex.index === indexedExercise.index),
    );
    setAnalyticsExerciseList(newList);
  };

  return (
    <Modal isModalOpen={showModal} openModal={closeModal} isDynamicWidth={true}>
      <div className={classes.modalContainer}>
        <div className={classes.ModalClose}>
          <img
            onClick={closeModal}
            src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGlkPSJDYXBhXzEiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDM4Ni42NjcgMzg2LjY2NyIgaGVpZ2h0PSI1MTIiIHZpZXdCb3g9IjAgMCAzODYuNjY3IDM4Ni42NjciIHdpZHRoPSI1MTIiIGNsYXNzPSJob3ZlcmVkLXBhdGhzIj48Zz48cGF0aCBkPSJtMzg2LjY2NyA0NS41NjQtNDUuNTY0LTQ1LjU2NC0xNDcuNzcgMTQ3Ljc2OS0xNDcuNzY5LTE0Ny43NjktNDUuNTY0IDQ1LjU2NCAxNDcuNzY5IDE0Ny43NjktMTQ3Ljc2OSAxNDcuNzcgNDUuNTY0IDQ1LjU2NCAxNDcuNzY5LTE0Ny43NjkgMTQ3Ljc2OSAxNDcuNzY5IDQ1LjU2NC00NS41NjQtMTQ3Ljc2OC0xNDcuNzd6IiBkYXRhLW9yaWdpbmFsPSIjMDAwMDAwIiBjbGFzcz0iaG92ZXJlZC1wYXRoIGFjdGl2ZS1wYXRoIiBzdHlsZT0iZmlsbDojRkZGRkZGIiBkYXRhLW9sZF9jb2xvcj0iIzAwMDAwMCI+PC9wYXRoPjwvZz4gPC9zdmc+"
            alt="close"
          />
        </div>
        <div className={classes.ModalHeader}>
          <h3>Quick Edit</h3>
          <span>
            Week {weekNumber + 1} Day {dayNumber + 1}
          </span>
        </div>
        <div className={classes.ModalInnerContainer}>
          <QuickEdit
            day={day}
            dayNumber={dayNumber}
            weekNumber={weekNumber}
            saveProgram={saveProgram}
            addSetHandler={addSetHandler}
            setIsQuickEdit={setIsQuickEdit}
            removeSetHandler={removeSetHandler}
            addExerciseNotes={addExerciseNotes}
            addWorkoutNotes={addWorkoutNotes}
            setsChangeHandler={setsChangeHandler}
            autoFillSetsHandler={autoFillSetsHandler}
            setShowAutoFill={setShowAutoFill}
            showAutoFill={showAutoFill}
            previousDay={previousDay}
            openAnalytics={openAnalytics}
            closeAnalytics={closeAnalytics}
            userId={clientId || coachId}
            analyticsExerciseList={analyticsExerciseList}
          />
        </div>
      </div>
    </Modal>
  );
};

export default QuickEditModal;
