import {
  FETCH_PLAN_START,
  FETCH_PLAN_SUCCESS,
  FETCH_PLAN_FAIL,
} from '../actions/actionTypes';
import { Plans } from 'interfaces/db';
import { updateObject } from '../utility';
import { PlansState } from 'interfaces/state';
import { FirebaseObject } from 'interfaces/utils';
import { PlansActionTypes } from 'interfaces/actions/plans';

const initialState: PlansState = {
  plans: null,
  loading: false,
};

const fetchPlanStart = (state: PlansState): PlansState => {
  return updateObject(state, { loading: true });
};

const fetchPlanSuccess = (
  state: PlansState,
  action: { plans: FirebaseObject<Plans> },
): PlansState => {
  return updateObject(state, {
    plans: action.plans,
    loading: false,
  });
};

const fetchPlanFail = (state: PlansState): PlansState => {
  return updateObject(state, { loading: false });
};

const reducer = (
  state = initialState,
  action: PlansActionTypes,
): PlansState => {
  switch (action.type) {
    case FETCH_PLAN_START:
      return fetchPlanStart(state);

    case FETCH_PLAN_SUCCESS:
      return fetchPlanSuccess(state, action);

    case FETCH_PLAN_FAIL:
      return fetchPlanFail(state);

    default:
      return state;
  }
};
export default reducer;
