import React, { FC } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { object, string } from 'yup';

import classes from './AddProgram.module.css';

const initialValues = { title: '' };
const validationSchema = object().shape({
  title: string().required(),
});

interface AddProgramProps {
  closeModal: () => void;
  handleSubmit: (value: any) => void;
}

const AddProgram: FC<AddProgramProps> = ({ closeModal, handleSubmit }) => {
  const onSubmit = async (value: any, actions: any) => {
    await handleSubmit(value);
    actions.setSubmitting(false);
  };

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
      {({ submitForm }) => (
        <>
          <p className={classes.ModalLargeText}>Create Program</p>
          <p className={classes.ModalSmallText}>Program title</p>
          <div className={classes.ProgramTitleInput}>
            <Form>
              <Field id="title" name="title" placeholder="Program title" />

              <ErrorMessage name="title">
                {(errMsg) => <p className={classes.error}>{errMsg}</p>}
              </ErrorMessage>
            </Form>
          </div>

          <div className={classes.ModalButtons}>
            <button onClick={closeModal} className={classes.ModalCancel}>
              Cancel
            </button>

            <button type="submit" className={classes.ModalSave} onClick={submitForm}>
              Create Program
            </button>
          </div>
        </>
      )}
    </Formik>
  );
};

export default AddProgram;
