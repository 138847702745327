import React, { FC, useState, useCallback } from 'react';
import { Formik, Form, FormikHelpers, ErrorMessage } from 'formik';
import { object, mixed } from 'yup';
import cls from 'classnames';
import { useDropzone } from 'react-dropzone';

import { FormProps } from 'interfaces/utils';
import classes from './ProgramImageUpload.module.css';

const MAX_FILE_SIZE = 100000000; // 100 MB
const ACCEPTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

type FormValues = {
  title: string;
  file: any;
};

const initialValues = { title: '', file: undefined };
const validationSchema = object().shape({
  file: mixed()
    .required('Image is required')
    .test(
      'fileSize',
      'File too large',
      (value) => value && value[0] && value[0].size <= MAX_FILE_SIZE,
    )
    .test(
      'fileFormat',
      'Unsupported Format',
      (value) => value && value[0] && ACCEPTED_FORMATS.includes(value[0].type),
    ),
});

const DragAndDrop: FC<{
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}> = ({ setFieldValue }) => {
  const [fileName, setFileName] = useState('');
  const onDrop = useCallback(
    (acceptedFiles) => {
      setFieldValue('file', acceptedFiles);
      setFileName(acceptedFiles[0].name);
    },
    [setFieldValue],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    // maxSize: MAX_FILE_SIZE,
    // accept: ACCEPTED_FORMATS,
  });

  return (
    <div
      className={cls(classes.dndWrapper, {
        [classes.activeWrapper]: isDragActive,
      })}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {fileName && <p className={classes.fileName}>{fileName}</p>}
      {isDragActive ? (
        <p className={classes.activeDrag}>Drop the files here ...</p>
      ) : (
        <p className={classes.inactiveDrag}>
          Drag &lsquo;n&rsquo; drop a image file, or click to select an image. Max file size: 100MB
        </p>
      )}
    </div>
  );
};

const ProgramImageUpload: FC<
  FormProps<FormValues> & {
    progress: number;
  }
> = ({ handleSubmit, progress }) => {
  const onSubmit = async (values: FormValues, actions: FormikHelpers<FormValues>) => {
    if (values.file) {
      await handleSubmit(values);
      actions.setSubmitting(false);
    }
  };

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
      {({ setFieldValue, submitForm }) => (
        <Form className={classes.formWrapper}>
          <div className={classes.progressWrapper}>
            <div className={classes.progressBar} style={{ width: `${progress}%` }} />
          </div>

          <DragAndDrop setFieldValue={setFieldValue} />
          <ErrorMessage name="file">
            {(errMsg) => <p className={classes.error}>{errMsg}</p>}
          </ErrorMessage>

          <div className={classes.ModalButtons}>
            <button type="button" onClick={submitForm} className={classes.ModalSave}>
              Upload Program Image
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ProgramImageUpload;
