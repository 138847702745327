import {
  FETCH_CLIENTS_START,
  FETCH_CLIENTS_SUCCESS,
  FETCH_CLIENTS_FAIL,
  REMOVE_CLIENT_SUCCESS,
  REMOVE_CLIENT_FAIL,
  REMOVE_CLIENT_START,
  SAVE_CLIENT_PROFILE_START,
  SAVE_CLIENT_PROFILE_SUCCESS,
  SAVE_CLIENT_PROFILE_FAIL,
  UPDATE_CLIENT_STATUS,
} from '../actions/actionTypes';
import { AppThunk } from '../index';
import { database } from 'utils/firebase';
import { Clients, Documents, Profile } from 'interfaces/db';
import { FirebaseObject } from 'interfaces/utils';
import { ClientsActionTypes } from 'interfaces/actions/clients';

const fetchClientsStart = (): ClientsActionTypes => {
  return {
    type: FETCH_CLIENTS_START,
  };
};

const fetchClientsSuccess = (clients: FirebaseObject<Clients>): ClientsActionTypes => {
  return {
    type: FETCH_CLIENTS_SUCCESS,
    clients: clients,
  };
};

const fetchClientsFail = (): ClientsActionTypes => {
  return {
    type: FETCH_CLIENTS_FAIL,
  };
};

const removeClientSuccess = (clientKey: string): ClientsActionTypes => {
  return {
    type: REMOVE_CLIENT_SUCCESS,
    clientKey: clientKey,
  };
};

const removeClientFail = (): ClientsActionTypes => {
  return {
    type: REMOVE_CLIENT_FAIL,
  };
};

const removeClientStart = (): ClientsActionTypes => {
  return {
    type: REMOVE_CLIENT_START,
  };
};

export const updateClientStatus = (clientKey: string, status: number) => {
  return {
    type: UPDATE_CLIENT_STATUS,
    clientKey: clientKey,
    status: status,
  };
};

const saveClientProfileStart = (): ClientsActionTypes => {
  return {
    type: SAVE_CLIENT_PROFILE_START,
  };
};

const saveClientProfileSuccess = (clientProfile: Profile, clientKey: string): ClientsActionTypes => {
  return {
    clientKey,
    clientProfile,
    type: SAVE_CLIENT_PROFILE_SUCCESS,
  };
};

const saveClientProfileFail = (): ClientsActionTypes => {
  return {
    type: SAVE_CLIENT_PROFILE_FAIL,
  };
};

// @ts-ignore: Unused variable
export const fetchClients = (token: string, userId: string): AppThunk => {
  return (dispatch) => {
    dispatch(fetchClientsStart());

    database.ref('clients').orderByChild('userId').equalTo(userId).once('value')
      .then((snapshot) => {
        const clientsData = snapshot.val();
        dispatch(fetchClientsSuccess(clientsData));
      })
      .catch(() => {
        dispatch(fetchClientsFail());
      });
  };
};

// @ts-ignore: Unused variable
export const removeClient = (clientKey: string, token: string): AppThunk => {
  return (dispatch) => {
    dispatch(removeClientStart());
    database.ref(`clients/${clientKey}`).remove((error) => {
      if (error) {
        dispatch(removeClientFail());
      } else {
        dispatch(removeClientSuccess(clientKey));
      }
    });
  };
};

// @ts-ignore: Unused variable
export const saveClientProfile = (clientProfile: Profile, clientKey: string, token: string): AppThunk => {
  return (dispatch) => {
    dispatch(saveClientProfileStart());
    database.ref(`clients/${clientKey}/profile`).update(clientProfile, (error) => {
      if (error) {
        dispatch(saveClientProfileFail());
      } else {
        dispatch(saveClientProfileSuccess(clientProfile, clientKey));
      }
    });
  };
};

export const deleteDocumentFromClient = (document: Documents, token: string, documentKey: string): AppThunk => {
  return (dispatch, state) => {
    const { clients } = state();
    if (!document) {
      return null;
    }
    return document?.clientList?.map((clientkey) => {
      const profile = clients?.clients?.[clientkey]?.profile;
      let newProfile = Object.assign({}, profile);
      if (profile) {
        const clientDocuments = profile?.documents?.filter((docKey) => docKey !== documentKey);

        newProfile = { ...profile, documents: clientDocuments };
      }
      dispatch(saveClientProfile(newProfile, clientkey, token));
      return null;
    });
  };
};
