import React, { FC } from 'react';
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from 'formik';
import { object, string } from 'yup';

import { CoachProfile } from 'interfaces/db';
import { FormProps } from 'interfaces/utils';
import classes from './UpdateProfile.module.css';

type FormValues = Pick<CoachProfile, 'fullname' | 'birthday' | 'email'>;
type InitialValues = FormValues & Pick<CoachProfile, 'code' | 'email'>;

const UpdateProfile: FC<FormProps<FormValues> & { initialValues: InitialValues }> = ({
  handleSubmit,
  initialValues,
}) => {
  const validationSchema = object().shape({
    fullname: string()
      .test('fullname-test', 'Fill first and last name', (value = '') => {
        const [firstName, lastName] = value ? value.trim().split(' ') : [];
        return !!(firstName && lastName);
      })
      .required('Full name is a required field'),
    birthday: string()
      .matches(
        /(^(((0[1-9]|1[0-9]|2[0-8])[/](0[1-9]|1[012]))|((29|30|31)[/](0[13578]|1[02]))|((29|30)[/](0[4,6,9]|11)))[/](19|[2-9][0-9])\d\d$)|(^29[/]02[/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)/,
        {
          message: 'Must be in DD/MM/YYYY format',
          excludeEmptyString: false,
        },
      )
      .required(),
    email: string().email().required(),
    code: string().required(),
  });

  const onSubmit = async (
    { fullname, birthday, email }: InitialValues,
    actions: FormikHelpers<InitialValues>,
  ) => {
    await handleSubmit({ fullname, birthday, email });
    actions.setSubmitting(false);
  };

  return (
    <Formik
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ submitForm }) => (
        <Form>
          <div className={classes.topInformation}>
            <div className={classes.row}>
              <div className={classes.inputBlock}>
                <label className={classes.label}>Full name</label>
                <Field
                  id="fullname"
                  name="fullname"
                  className={classes.input}
                  placeholder="Enter full name"
                />

                <ErrorMessage name="fullname">
                  {(errMsg) => <p className={classes.error}>{errMsg}</p>}
                </ErrorMessage>
              </div>

              <div className={classes.inputBlock}>
                <label className={classes.label}>Email address</label>
                <Field
                  id="email"
                  name="email"
                  className={classes.input}
                  placeholder="Enter email address"
                />

                <ErrorMessage name="email">
                  {(errMsg) => <p className={classes.error}>{errMsg}</p>}
                </ErrorMessage>
              </div>
            </div>

            <div className={classes.row}>
              {/* <div className={classes.inputBlock}>
                <label className={classes.label}>Birthday</label>
                <Field
                  id="birthday"
                  name="birthday"
                  className={classes.input}
                  placeholder="DD/MM/YYYY"
                />

                <ErrorMessage name="birthday">
                  {(errMsg) => (
                    <p className={classes.error}>{errMsg}</p>
                  )}
                </ErrorMessage>
              </div> */}

              <div className={classes.inputBlock}>
                <label className={classes.label}>Coach code</label>
                <Field
                  disabled
                  id="code"
                  name="code"
                  className={classes.input}
                  placeholder="Enter coach code"
                />

                <ErrorMessage name="code">
                  {(errMsg) => <p className={classes.error}>{errMsg}</p>}
                </ErrorMessage>
              </div>
            </div>
          </div>
          <div className={classes.coachProfileButtons}>
            <button type="button" onClick={submitForm} className={classes.saveCoachButton}>
              SAVE
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default UpdateProfile;
