import React, { FC, useState, useEffect, ChangeEvent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import {
  removeClient,
  saveClientProfile,
} from 'store/actions/clients';
import { RootState } from 'store';
import Modal from 'components/UI/Modal/Modal';
import withAuth from 'utils/withAuth';
import Layout from '../../Layout';
import { CLIENTS } from 'utils/routes';
import { Profile } from 'interfaces/db';
import { ClientProfileParams } from 'interfaces/routes';
import classes from './ClientProfile.module.css';

const mapStateToProps = ({ auth, clients }: RootState) => {
  return {
    token: auth.token,
    clients: clients.clients,
    loading: clients.loading,
    removed: clients.removed,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onSaveClientProfile: (
      clientProfile: Profile,
      clientKey: string,
      token: string,
    ) => dispatch(saveClientProfile(clientProfile, clientKey, token)),
    onRemoveClient: (clientKey: string, token: string) => {
      dispatch(removeClient(clientKey, token));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux &
  RouteComponentProps<ClientProfileParams>;

const ClientProfile: FC<Props> = ({
  token,
  match,
  clients,
  loading,
  history,
  onRemoveClient,
  onSaveClientProfile,
}) => {
  const { clientKey } = match.params;

  const [showSave, setShowSave] = useState(false);
  const [showRemove, setShowRemove] = useState(false);
  const [clientProfile, setClientProfile] = useState({
    email: '',
    birthday: '',
    fullname: '',
  });

  useEffect(() => {
    if (clients && clientKey) {
      const { email, birthday, fullname } = clients[
        clientKey
      ].profile;
      setClientProfile({ email, birthday, fullname });
    }
  }, [clients, clientKey]);

  // fetch profile
  const fullnameOnChangeHandler = ({
    target,
  }: ChangeEvent<HTMLInputElement>) => {
    setClientProfile({ ...clientProfile, fullname: target.value });
  };

  const emailOnChangeHandler = ({
    target,
  }: ChangeEvent<HTMLInputElement>) => {
    setClientProfile({ ...clientProfile, email: target.value });
  };

  const birthdayOnChangeHandler = ({
    target,
  }: ChangeEvent<HTMLInputElement>) => {
    setClientProfile({ ...clientProfile, birthday: target.value });
  };

  const saveProfile = () => {
    if (clientKey && token) {
      onSaveClientProfile(clientProfile, clientKey, token);
      setShowSave(false);
    }
  };

  const removeProfile = () => {
    if (clientKey && token) {
      onRemoveClient(clientKey, token);
      setShowRemove(false);
      history.push(CLIENTS.URL);
    }
  };

  const initials = `${clientProfile.fullname[0] || ''} ${
    clientProfile.fullname[clientProfile.fullname.indexOf(' ') + 1] ||
    ''
  }`;

  return (
    <Layout loading={loading} heading={clientProfile.fullname || ''}>
      <div>
        <Modal
          isModalOpen={showSave}
          openModal={() => setShowSave(false)}
        >
          <div className={classes.modalContainer}>
            <div className={classes.ModalClose}>
              <img
                onClick={() => setShowSave(false)}
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGlkPSJDYXBhXzEiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDM4Ni42NjcgMzg2LjY2NyIgaGVpZ2h0PSI1MTIiIHZpZXdCb3g9IjAgMCAzODYuNjY3IDM4Ni42NjciIHdpZHRoPSI1MTIiIGNsYXNzPSJob3ZlcmVkLXBhdGhzIj48Zz48cGF0aCBkPSJtMzg2LjY2NyA0NS41NjQtNDUuNTY0LTQ1LjU2NC0xNDcuNzcgMTQ3Ljc2OS0xNDcuNzY5LTE0Ny43NjktNDUuNTY0IDQ1LjU2NCAxNDcuNzY5IDE0Ny43NjktMTQ3Ljc2OSAxNDcuNzcgNDUuNTY0IDQ1LjU2NCAxNDcuNzY5LTE0Ny43NjkgMTQ3Ljc2OSAxNDcuNzY5IDQ1LjU2NC00NS41NjQtMTQ3Ljc2OC0xNDcuNzd6IiBkYXRhLW9yaWdpbmFsPSIjMDAwMDAwIiBjbGFzcz0iaG92ZXJlZC1wYXRoIGFjdGl2ZS1wYXRoIiBzdHlsZT0iZmlsbDojRkZGRkZGIiBkYXRhLW9sZF9jb2xvcj0iIzAwMDAwMCI+PC9wYXRoPjwvZz4gPC9zdmc+"
                alt="close"
              />
            </div>
            <div className={classes.ModalSaveIcon}>
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAABjklEQVRoge2ZMU7DMBSG/8eQBWYkJLJzi0pcihtAuUJHuABigI0LMDLAjNpwjJ+hprJM7NiOE8eVv9GJ33ufrTynDVCpTA/JhuQ9yY7z8U7yPLXIekYBnY+xMmKIdAAuRq1GPF8ArkXkJ2ayKcIkJcUzSuaAY+ufSK5Inqapd2IsEje56wqmbyfUeFndrCfYSo2X1c16Ap2pcedORK2afx2fJMM6qa3AoWWbWMRL5iR1ESEELMYVgDeXTFaRQJwyJYkADpnSRACLTIkiwF7mWR9wvmuJiPSNm/zdF4oeV4/h2wn1OaXuyD+qyNJIIkLyMmaeaGix2phYqXZkEyujoyQ2MXOTdK1c1K61ZKrI0qgiBi8AWkkEgBbAa0gBqdpvKyLbkMRDqDPl23WP3n6zvv0OEZK3PiNLo4osjaMV6bJUkQBT5DFLFanh/vPBmuQu53+/MXm9DrKjPBDN39hzMZT3aLuWjUM3m/q5sNGTd6df9xV58EmSmjE12QptSN5RdbPMbEnekmyCRSqVcH4BoyWacwifNfYAAAAASUVORK5CYII="
                alt="save"
              />
            </div>
            <p className={classes.ModalLargeText}>Are you sure?</p>
            <p className={classes.ModalSmallText}>
              Do you want to save {clientProfile.fullname}&apos;s
              profile?
              <br />
              This will save any changes that were made.
            </p>
            <div className={classes.ModalButtons}>
              <button
                onClick={() => setShowSave(false)}
                className={classes.ModalCancel}
              >
                Cancel
              </button>
              <button
                className={classes.ModalSave}
                onClick={saveProfile}
              >
                Save
              </button>
            </div>
          </div>
        </Modal>

        <Modal
          isModalOpen={showRemove}
          openModal={() => setShowRemove(false)}
        >
          <div className={classes.modalContainer}>
            <div className={classes.ModalClose}>
              <img
                onClick={() => setShowRemove(false)}
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGlkPSJDYXBhXzEiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDM4Ni42NjcgMzg2LjY2NyIgaGVpZ2h0PSI1MTIiIHZpZXdCb3g9IjAgMCAzODYuNjY3IDM4Ni42NjciIHdpZHRoPSI1MTIiIGNsYXNzPSJob3ZlcmVkLXBhdGhzIj48Zz48cGF0aCBkPSJtMzg2LjY2NyA0NS41NjQtNDUuNTY0LTQ1LjU2NC0xNDcuNzcgMTQ3Ljc2OS0xNDcuNzY5LTE0Ny43NjktNDUuNTY0IDQ1LjU2NCAxNDcuNzY5IDE0Ny43NjktMTQ3Ljc2OSAxNDcuNzcgNDUuNTY0IDQ1LjU2NCAxNDcuNzY5LTE0Ny43NjkgMTQ3Ljc2OSAxNDcuNzY5IDQ1LjU2NC00NS41NjQtMTQ3Ljc2OC0xNDcuNzd6IiBkYXRhLW9yaWdpbmFsPSIjMDAwMDAwIiBjbGFzcz0iaG92ZXJlZC1wYXRoIGFjdGl2ZS1wYXRoIiBzdHlsZT0iZmlsbDojRkZGRkZGIiBkYXRhLW9sZF9jb2xvcj0iIzAwMDAwMCI+PC9wYXRoPjwvZz4gPC9zdmc+"
                alt="close"
              />
            </div>
            <div className={classes.ModalSaveIcon}>
              <img
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDUxMiA1MTIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMiA1MTI7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgY2xhc3M9IiI+PGc+PGNpcmNsZSBzdHlsZT0iZmlsbDojRDczQTI3IiBjeD0iMjU2IiBjeT0iMjU2IiByPSIyNTYiIGRhdGEtb3JpZ2luYWw9IiNFMjFCMUIiIGNsYXNzPSIiIGRhdGEtb2xkX2NvbG9yPSIjRTIxQjFCIj48L2NpcmNsZT48cGF0aCBzdHlsZT0iZmlsbDojQkYzMzIyIiBkPSJNNTEwLjI4LDI4NS4zMDRMMzY3LjkxMiwxNDIuOTM2TDE1MC4yNDgsMzY4LjYwOGwxNDAuOTI4LDE0MC45MjggIEM0MDYuMzUyLDQ5My42OTYsNDk3LjA1Niw0MDEuMjg4LDUxMC4yOCwyODUuMzA0eiIgZGF0YS1vcmlnaW5hbD0iI0M0MDYwNiIgY2xhc3M9ImFjdGl2ZS1wYXRoIiBkYXRhLW9sZF9jb2xvcj0iI0M0MDYwNiI+PC9wYXRoPjxnPgoJPHBhdGggc3R5bGU9ImZpbGw6I0ZGRkZGRjsiIGQ9Ik0zNTQuMzc2LDM3MS41MzZjLTUuMTIsMC0xMC4yMzItMS45NTItMTQuMTQ0LTUuODU2TDE0Ni40MDgsMTcxLjg0OCAgIGMtNy44MTYtNy44MTYtNy44MTYtMjAuNDcyLDAtMjguMjhzMjAuNDcyLTcuODE2LDI4LjI4LDBMMzY4LjUyLDMzNy40YzcuODE2LDcuODE2LDcuODE2LDIwLjQ3MiwwLDI4LjI4ICAgQzM2NC42MDgsMzY5LjU4NCwzNTkuNDk2LDM3MS41MzYsMzU0LjM3NiwzNzEuNTM2eiIgZGF0YS1vcmlnaW5hbD0iI0ZGRkZGRiIgY2xhc3M9IiI+PC9wYXRoPgoJPHBhdGggc3R5bGU9ImZpbGw6I0ZGRkZGRjsiIGQ9Ik0xNjAuNTQ0LDM3MS41MzZjLTUuMTIsMC0xMC4yMzItMS45NTItMTQuMTQ0LTUuODU2Yy03LjgxNi03LjgxNi03LjgxNi0yMC40NzIsMC0yOC4yOCAgIGwxOTMuODMyLTE5My44MzJjNy44MTYtNy44MTYsMjAuNDcyLTcuODE2LDI4LjI4LDBzNy44MTYsMjAuNDcyLDAsMjguMjhMMTc0LjY4OCwzNjUuNjggICBDMTcwLjc4NCwzNjkuNTg0LDE2NS42NjQsMzcxLjUzNiwxNjAuNTQ0LDM3MS41MzZ6IiBkYXRhLW9yaWdpbmFsPSIjRkZGRkZGIiBjbGFzcz0iIj48L3BhdGg+CjwvZz48L2c+IDwvc3ZnPg=="
                alt="delete"
              />
            </div>
            <p className={classes.ModalLargeText}>Are you sure?</p>
            <p className={classes.ModalSmallText}>
              Do you want to remove this client?
              <br />
              This cannot be undone.
            </p>
            <div className={classes.ModalButtons}>
              <button
                onClick={() => setShowRemove(false)}
                className={classes.ModalCancel}
              >
                Cancel
              </button>
              <button
                className={classes.ModalSave}
                onClick={removeProfile}
              >
                Remove
              </button>
            </div>
          </div>
        </Modal>

        <div className={classes.ClientProfile}>
          <div className={classes.TopSection}>
            <div className={classes.ImageDiv}>
              {initials.toUpperCase()}
            </div>
          </div>
          <div className={classes.ClientName}>
            <h3>{clientProfile.fullname}</h3>
          </div>
          <form style={{ display: 'block' }}>
            <div className={classes.TopInformation}>
              <div className={classes.Row}>
                <div className={classes.InputBlock}>
                  <label className={classes.Label}>Full name</label>
                  <input
                    type="text"
                    required={true}
                    className={classes.Input}
                    placeholder="Enter full name"
                    onChange={fullnameOnChangeHandler}
                    defaultValue={clientProfile.fullname}
                  />
                </div>
              </div>
              <div className={classes.Row}>
                <div className={classes.InputBlock}>
                  <label className={classes.Label}>
                    Email address
                  </label>
                  <input
                    type="text"
                    required={true}
                    className={classes.Input}
                    onChange={emailOnChangeHandler}
                    placeholder="Enter email address"
                    defaultValue={clientProfile.email}
                  />
                </div>
                <div className={classes.InputBlock}>
                  <label className={classes.Label}>Birthday</label>
                  <input
                    type="text"
                    autoComplete="off"
                    placeholder="DD/MM/YYYY"
                    className={classes.Input}
                    onChange={birthdayOnChangeHandler}
                    defaultValue={clientProfile.birthday}
                  />
                </div>
              </div>
            </div>

            <hr
              style={{
                borderColor: '#D60509',
                marginLeft: '78px',
                marginRight: '78px',
              }}
            />
            <div className={classes.ClientProfileButtons}>
              <button
                className={classes.SaveClientButton}
                onClick={() => setShowSave(true)}
              >
                SAVE
              </button>
              <button
                className={classes.RemoveClientButton}
                onClick={() => setShowRemove(true)}
              >
                REMOVE
              </button>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default connector(withAuth(ClientProfile));
