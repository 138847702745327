import React, { FC, ChangeEvent } from 'react';

import { SetKeys, Exercise, Day, SetObject } from 'interfaces/db';
import { setKeysMap } from 'utils/helpers';
import classes from './QuickEditTable.module.css';
import OutsideAlerter from '../../OutsideAlerter/OutsideAlerter';
import Separator from './Separator';
interface Props {
  exercise: Exercise;
  addSetHandler: () => void;
  removeSetHandler: () => void;
  setsChangeHandler: (setType: SetKeys, value: string, setIndex: number) => void;
  autoFillSetsHandler: (setType: SetKeys, value: string, setIndex: number) => void;
  setShowAutoFill: (setIdentifier: string) => void;
  showAutoFill: string;
  previousDay?: Day;
  day?: Day;
}

const QuickEditTable: FC<Props> = ({
  exercise,
  addSetHandler,
  removeSetHandler,
  setsChangeHandler,
  autoFillSetsHandler,
  setShowAutoFill,
  showAutoFill,
  previousDay,
  // day,
}) => {
  const { sets = [] } = exercise;
  const numSets = sets.length;

  const { completed, ...otherSets } = sets[0] || {};
  const keys = Object.keys(otherSets);
  const finished = previousDay?.workout?.finished || false;

  const handleKeyDown = (e: any) => {
    if (e.keyCode === 13 && showAutoFill === e.currentTarget.id) {
      const button = document.getElementById(e.currentTarget.id + 'button');
      return button?.click();
    }
  };

  return (
    <div className={classes.container}>
      <ul className={classes.innerWrapper}>
        <li className={finished ? classes.tableRowFeedback : classes.tableRow}>
          <div className={finished ? classes.columnHeaderSetFinished : classes.columnHeaderSet}>Sets</div>
          {keys.map((el) => {
            const title = setKeysMap.find(({ value }) => value === el);

            return (
              <div key={el} className={finished ? classes.columnHeaderFinished : classes.columnHeader}>
                {title?.label}
              </div>
            );
          })}
        </li>

        {sets.map((el, setIndex) => {
          const { completed, ...subSets } = el;
          const subSetsEntries = Object.entries(subSets);
          return (
            <li key={setIndex} className={!previousDay ? classes.tableRow : classes.tableRowFeedback}>
              <div className={finished ? classes.tableCellSetFinished : classes.tableCellSet}>{setIndex + 1}</div>

              {subSetsEntries.map((el, i) => {
                const [key, value] = el;
                const isValString = typeof value === 'string';
                return (
                  <>
                    <div className={!previousDay ? classes.tableCell : classes.tableCellFeedback} key={i}>
                      <OutsideAlerter
                        show={showAutoFill === setIndex + (key as SetKeys) + exercise.type}
                        close={() => setShowAutoFill('')}
                      >
                        {!previousDay && (
                          <button
                            id={setIndex + (key as SetKeys) + exercise.type + 'button'}
                            className={
                              showAutoFill === setIndex + (key as SetKeys) + exercise.type
                                ? classes.AutofillButton
                                : classes.AutofillButtonHidden
                            }
                            onClick={() => {
                              setShowAutoFill('');
                              autoFillSetsHandler(
                                key as SetKeys,
                                (
                                  document.getElementById(
                                    setIndex + (key as SetKeys) + exercise.type,
                                  ) as HTMLInputElement
                                ).value + '' || '0',
                                setIndex,
                              );
                            }}
                          >
                            Autofill
                          </button>
                        )}
                        <input
                          type="text"
                          id={
                            !previousDay
                              ? setIndex + (key as SetKeys) + exercise.type
                              : setIndex + (key as SetKeys) + exercise.type + 'feedback'
                          }
                          onKeyDown={(e) => handleKeyDown(e)}
                          onChange={({ target }: ChangeEvent<HTMLInputElement>) => {
                            setsChangeHandler(key as SetKeys, target.value, setIndex);
                            setShowAutoFill(setIndex + (key as SetKeys) + exercise.type);
                          }}
                          readOnly={finished}
                          value={isValString ? value : value?.programmed || ''}
                          className={finished ? classes.innerTableCellCompleted : classes.innerTableCell}
                        />
                      </OutsideAlerter>
                    </div>
                    {finished && (
                      <>
                        <Separator />
                        <input
                          style={{
                            color: (value as SetObject).achieved ? '#e94811' : '#f1f1f1',
                          }}
                          type="text"
                          readOnly
                          value={isValString ? value : value?.achieved || value?.programmed} // short circuting because input changes to controlled input when you swap
                          className={classes.TableInputAchieved}
                        />
                      </>
                    )}
                  </>
                );
              })}
              {finished ? (
                completed ? (
                  <div
                    style={{
                      width: 27,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <svg
                      // style={{ marginLeft: 8 }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="18.1"
                      height="14.1"
                      viewBox="0 0 26.4 20.1"
                    >
                      <path
                        id="Icon_material-done"
                        data-name="Icon material-done"
                        d="M13.5,24.3,7.2,18,5.1,20.1l8.4,8.4,18-18L29.4,8.4Z"
                        transform="translate(-5.1 -8.4)"
                        fill="#58cb2c"
                      />
                    </svg>
                  </div>
                ) : (
                  <div
                    style={{
                      width: 27,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <svg
                      // style={{ marginLeft: 8 }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="18.1"
                      height="18.1"
                      viewBox="0 0 21 21"
                    >
                      <path
                        id="Icon_material-close"
                        data-name="Icon material-close"
                        d="M28.5,9.615,26.385,7.5,18,15.885,9.615,7.5,7.5,9.615,15.885,18,7.5,26.385,9.615,28.5,18,20.115,26.385,28.5,28.5,26.385,20.115,18Z"
                        transform="translate(-7.5 -7.5)"
                        fill="#ee1818"
                      />
                    </svg>
                  </div>
                )
              ) : null}
            </li>
          );
        })}
      </ul>

      {!previousDay && (
        <div className={classes.setBtnWrapper}>
          <button type="button" onClick={addSetHandler} className={classes.addSet}>
            <span className={classes.addSetText}>+</span>
            ADD SET
          </button>

          {!numSets ? null : (
            <button type="button" onClick={removeSetHandler} className={classes.removeSet}>
              <span className={classes.removeSetText}>-</span>
              DELETE SET
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default QuickEditTable;
