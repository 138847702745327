import SectionHeader from 'components/SectionHeader';
import React, { FC, useState } from 'react';
import classes from './FAQ.module.css';

interface Props {
  isTrialEnabled: boolean;
  price: number;
  currency: string;
  currencySymbol: string;
  organisation: boolean;
  reactivateSub: boolean;
}

const FAQ: FC<Props> = ({ isTrialEnabled, price, currency, currencySymbol, organisation, reactivateSub }) => {
  const [activeKey, setActiveKey] = useState<number | null>(null);
  const handleOpen = (key: number) => {
    if (key === activeKey) setActiveKey(null);
    else setActiveKey(key);
  };

  const faqs = [
    ...(isTrialEnabled && !reactivateSub
      ? [
          {
            question: `Does the app really only cost ${currencySymbol}1?`,
            answer: `Only your first month costs ${currencySymbol}1. After that you'll be charged ${price} (${currency}) per month for access to ${
              organisation ? 'our' : 'my'
            } exclusive community group chat as well as new programs added monthly.`,
          },
        ]
      : []),
    {
      question: "What's different about your app?",
      answer:
        'I have partnered with CoachElite to provide the best app to track your workouts and monitor your exercise progress overtime, ensuring that you are constantly reaching your goals.',
    },
    {
      question: 'Can I Cancel At Anytime?',
      answer:
        'Yes! Once you have logged into the CoachElite mobile app (with the details you created during purchase), you will be able to cancel your subscription at anytime in the account screen and you will no longer be charged. You will have full access to the programs for the remainder of your subscription period.',
    },
    {
      question: 'How Do I Get Access To Your Programs?',
      answer:
        'Firstly you need to purchase a subscription plan on this website. During purchase we will ask you for account details so that we can create your account. Once this is complete, all you need to do is download the CoachElite mobile app and login with the details you created during purchase.',
    },
    {
      question: 'What If I Have Started A Program But Now Want To Switch To A New One?',
      answer:
        'No problem! All you need to do is navigate to the programs tab in the mobile app and start a new program. All your workout history will still be saved from your previous program. Your subscription includes unlimited access to all programs and the app is entirely self-service.',
    },
    {
      question: 'Where Can I Download The Mobile App?',
      answer:
        'We use the CoachElite mobile app to deliver our programs. You can find the CoachElite mobile app on the Apple App Store, Google Play Store and Huawei App Gallery.',
    },
    {
      question: "I've Downloaded The Mobile App But I Can't Access My Account?",
      answer:
        'If you have purchased a subsription on this website, you would have already created an account on our system. Please use the details you provided during the purchase process to log in to your account. If you have forgotten your password you can reset it on the CoachElite mobile app and then log in with your new details.',
    },
  ];

  return (
    <section id="faqs" className={classes.section}>
      <SectionHeader>
        <span>FAQs</span>
      </SectionHeader>
      <div className={classes.accordionWrapper}>
        {faqs.map((data, key) => (
          <div key={key} className={classes.accordion}>
            <div className={classes.accordionHeader} onClick={() => handleOpen(key)}>
              <h3>{data.question}</h3>
              <div className={classes.sign}>{activeKey === key ? '-' : '+'}</div>
            </div>

            <div className={classes.accordionBody} aria-expanded={activeKey !== key}>
              <div className={classes.answer}>{data.answer}</div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default FAQ;
