import React, { FunctionComponent, useRef } from 'react';

import Separator from './Separator';
import classes from './Set.module.css';
import { SetObject, SetKeys } from 'interfaces/db';
import OutsideAlerter from '../../components/OutsideAlerter/OutsideAlerter';
interface Props {
  setKey: SetKeys;
  set: string | SetObject;
  finished: boolean;
  setsChangeHandler: (setType: SetKeys, value: string) => void;
  autoFillSetsHandler: (setType: SetKeys, value: string) => void;
  setNum: number;
  setShowAutoFill: (setIdentifier: string) => void;
  showAutoFill: string;
}

const SetsColum: FunctionComponent<Props> = ({
  set,
  setKey,
  finished,
  setsChangeHandler,
  autoFillSetsHandler,
  setNum,
  setShowAutoFill,
  showAutoFill,
}) => {
  const achievedValue = typeof set === 'string' ? set : set.achieved;
  const programmedValue =
    typeof set === 'string' ? set : set.programmed;
  const inputRef = useRef<HTMLInputElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleKeyDown = (e: any) => {
    if (e.keyCode === 13 && showAutoFill === setNum + setKey) {
      autoFillSetsHandler(
        setKey,
        inputRef?.current?.value + '' || '0',
      );
      setShowAutoFill('');
    }
  };

  return (
    <>
      <div className={classes.InputContainer}>
        <OutsideAlerter
          show={showAutoFill === setNum + setKey}
          close={() => setShowAutoFill('')}
        >
          <button
            ref={buttonRef}
            className={
              showAutoFill === setNum + setKey
                ? classes.AutofillButton
                : classes.AutofillButtonHidden
            }
            onClick={() => {
              setShowAutoFill('');
              autoFillSetsHandler(
                setKey,
                inputRef?.current?.value + '' || '0',
              );
            }}
          >
            Autofill
          </button>
          <input
            min="1"
            max="40"
            type="text"
            ref={inputRef}
            readOnly={finished}
            onKeyDown={(e) => handleKeyDown(e)}
            onChange={({ target }) => {
              setsChangeHandler(setKey, target.value);
              setShowAutoFill(setNum + setKey);
            }}
            value={programmedValue || ''} // short circuting because input changes to controlled input when you swap
            className={
              finished
                ? classes.TableInputOriginal
                : classes.TableInput
            }
          />
        </OutsideAlerter>
      </div>

      {finished && (
        <>
          <Separator />
          <input
            style={{ color: achievedValue ? '#e94811' : '#f1f1f1' }}
            type="text"
            readOnly
            value={achievedValue || programmedValue} // short circuting because input changes to controlled input when you swap
            className={classes.TableInputAchieved}
          />
        </>
      )}
    </>
  );
};

export default SetsColum;
