import React, { FC } from 'react';

import classes from './Support.module.css';

const Support: FC = () => {
  return (
    <div className={classes.background}>
      <p className={classes.text}>
        Please forward any support queries to support@coachelite.co.za
      </p>
    </div>
  );
};

export default Support;
