import React, { FC } from 'react';
import { Formik, FormikHelpers, Form, Field, ErrorMessage } from 'formik';
import { object, string } from 'yup';
import { emoji_strip } from 'utils/helpers';
import { FormProps } from 'interfaces/utils';
import classes from './CoachCode.module.css';
import { functions } from 'utils/firebase';

type InitialValues = {
  code: string;
};

const initialValues = { code: '' };

const CoachCode: FC<FormProps<string>> = ({ handleSubmit }) => {
  const checkCode = functions.httpsCallable('v1-https-checkUniqueCoachCode');

  const validationSchema = object().shape({
    code: string().min(4).required(),
  });

  const codeTransform = (code: string) => {
    return emoji_strip(code.toLowerCase().replace(/\s/g, ''));
  };

  const onSubmit = async (payload: InitialValues, actions: FormikHelpers<InitialValues>) => {
    if (payload.code) {
      try {
        const result = await checkCode({ code: payload.code });
        if (result.data) {
          handleSubmit(codeTransform(payload.code));
          actions.setSubmitting(false);
        } else {
          actions.setErrors({ code: 'This code is already in use' });
        }
      } catch (e) {
        actions.setErrors({ code: 'Something went wrong, please try again' });
      }
    }
  };

  const onChange = (
    value: string,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
  ) => {
    const manipulatedValue = codeTransform(value);
    setFieldValue('code', manipulatedValue);
  };

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
      {({ submitForm, setFieldValue }) => (
        <Form>
          <div className={classes.CoachCodeModal}>
            <h3>Please Enter Your Coach Code</h3>
            <p>This code will be used by your clients to join your crew!</p>

            <Field
              id="code"
              name="code"
              placeholder="Coach Code"
              className={classes.CoachCode}
              onChange={(event: any) => {
                onChange(event.target.value, setFieldValue);
              }}
            />

            <ErrorMessage name="code">{(errMsg) => <p className={classes.UsedCodeWarning}>{errMsg}</p>}</ErrorMessage>

            <div className={classes.ModalButtons}>
              <button type="button" onClick={submitForm} className={classes.ModalSave}>
                Save Code
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CoachCode;
