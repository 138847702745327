import React, { FC } from 'react';

import { getSetsLabel, getSetCountLabel } from 'utils/helpers';
import { Exercise as ExerciseI, SetKeys } from 'interfaces/db';
import classes from './Exercise.module.css';

interface Props {
  exerciseBlock: string;
  exercise: ExerciseI[];
}

const Exercise: FC<Props> = ({ exercise, exerciseBlock }) => {
  const block = exercise.map((exerciseType, index) => {
    const { sets = [] } = exerciseType;

    const { completed, ...types } = sets[0] || {};
    const typesArray = Object.keys(types || {});

    return (
      <div key={index}>
        <div className={classes.BlockAndTitle} key={index}>
          <div className={classes.ExerciseBlock}>
            {`${exerciseBlock}${index + 1}`}
          </div>

          <div>
            <div className={classes.Title}>{exerciseType.type}</div>

            <div className={classes.miniDesc}>
              {getSetCountLabel(sets.length)}
              {typesArray.map((el, index) => {
                const set = types[el as SetKeys];
                if (!set) {
                  return null;
                }

                const programmedValue =
                  typeof set === 'string' ? set : set.programmed;

                return `${getSetsLabel(
                  el as SetKeys,
                  programmedValue,
                  index,
                )}`;
              })}
            </div>
          </div>
        </div>

        {/* if there's more of the superset then render this svg */}
        {exercise.length && index !== exercise.length - 1 ? (
          <svg
            width="50"
            height="20px"
            style={{ overflow: 'inherit' }}
          >
            <line
              x1="23"
              y1="-48px"
              x2="23"
              y2="64px"
              stroke="#e94811"
              strokeWidth="2px"
            />
          </svg>
        ) : null}
      </div>
    );
  });

  return <div className={classes.Exercise}>{block}</div>;
};

export default Exercise;
