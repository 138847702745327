import { SetParam } from 'interfaces/utils';
import { ProgressPhoto, Set } from '../interfaces/db';

export const LS_PRICING_INFO = 'slected_price';

export const COACH_WHITE_LIST = [
  'jVyDIZecHFX9xn2YuS6HpyZbFmk2',
  'YPWterp5c7bNuEvKfoQBfeO8pPH3',
  'DYaDA3vSQ5bWaatwYHEjUjOPD7I3',
  'pRWsbI706cPneZMMizOfuLbhMmB3',
  'zfL2CrfQVEThnKFHloCSQ1mP8gm2',
  'OU3D3a9ZmPSVJE0c2oCDopGgF5q1',
  '3OOHvItEdReZaiVmyKV2YgH91Sh1',
  'ngRXcTnYvlS5ZMBQf07d7ZKuc4z1',
  'JULdev2v3dSEmbmsotIvqT1K5I92',
  'fJCTGED9ztYBxJxUyTgKraOHodz1',
  'wTqSRBM5jqRPwC0UpwPLIzIovAv1',
  'YW9mT9yOaZUPyyeqiOemga8tmBO2',
  'Y2lYAYoWP7OBvBbU600L1ZG0cUc2',
  'pP6rKaBn1Eb8GJJF5GvewQdlBYv2',
  'WaQVsnw9KNbRQHZ1AsG0s3C04Yf2',
  '3O9oBhrqnfW35Sb4H2uAffSwLyz1',
  'FQ71ExgbbDPF23K8MLOnuuo1PO93',
  'Hel5DUkGlTVr9QlCOnQsvXzQXMs1',
];

export const ANALYTICS_WHITE_LIST = [
  '3OOHvItEdReZaiVmyKV2YgH91Sh1',
  'jVyDIZecHFX9xn2YuS6HpyZbFmk2',
  'pRWsbI706cPneZMMizOfuLbhMmB3',
  'ngRXcTnYvlS5ZMBQf07d7ZKuc4z1',
  'fJCTGED9ztYBxJxUyTgKraOHodz1',
  'Y2lYAYoWP7OBvBbU600L1ZG0cUc2',
  'pP6rKaBn1Eb8GJJF5GvewQdlBYv2',
  'OU3D3a9ZmPSVJE0c2oCDopGgF5q1',
  'WaQVsnw9KNbRQHZ1AsG0s3C04Yf2',
];

export const PHOTOS_TEMPLATE: { [key: string]: ProgressPhoto } = {
  front: {
    categoryName: 'Front',
    clientNotes: '',
    coachNotes: '',
    image: '',
    description: 'Progress photo of you standing facing the camera',
  },
  left_side: {
    categoryName: 'Left Side',
    clientNotes: '',
    coachNotes: '',
    image: '',
    description: 'Progress photo of you standing with your left sholder facing the camera',
  },
  right_side: {
    categoryName: 'Right Side',
    clientNotes: '',
    coachNotes: '',
    image: '',
    description: 'Progress photo of you standing with your right sholder facing the camera',
  },
  back: {
    categoryName: 'Back',
    clientNotes: '',
    coachNotes: '',
    image: '',
    description: 'Progress photo of you standing with your back to the camera',
  },
};

export const DEFAULT_SET_PARAMS: SetParam[] = ['reps', 'weightLBS'];

export const DEFAULT_SET: Set = {
  completed: false,
  reps: { achieved: '', programmed: '', index: 1 },
  weightLBS: { achieved: '', programmed: '', index: 2 },
};

export const ALL_CLIENTS = 'All'; // value = 0
export const ACTIVE_CLIENTS = 'Active'; // value = 1
export const PAUSED_CLIENTS = 'Paused'; // value = 2
export const CLIENT_STATUS_TABS = [ALL_CLIENTS, ACTIVE_CLIENTS, PAUSED_CLIENTS];
