import React, { FC, useState } from 'react';
import { NavLink } from 'react-router-dom';
import OutsideAlerter from '../OutsideAlerter/OutsideAlerter';
import { getInitials } from 'utils/helpers';
import { CLIENT_PROFILE } from 'utils/routes';
import ClientButtons from './ClientButtons/ClientButtons';
import classes from './Client.module.css';
interface Props {
  name: string;
  clientKey: string;
  hasPrograms: boolean;
  showProgramTitle: () => void;
  removeClient: () => void;
  showPauseClientAccessModal: () => void;
  status: number;
}

const Client: FC<Props> = ({
  name = '',
  clientKey,
  hasPrograms,
  removeClient,
  showProgramTitle,
  showPauseClientAccessModal,
  status,
}) => {
  const [showDropDown, setShowDropDown] = useState(false);

  const removeHandler = () => {
    removeClient();
    setShowDropDown(false);
  };

  const showPauseModal = () => {
    showPauseClientAccessModal();
    setShowDropDown(false);
  };

  const dropdown = (
    <ul className={classes.DropDownContainer}>
      <li className={classes.DropDownButton} onClick={showProgramTitle}>
        <img
          alt="edit"
          src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iMjAiIGhlaWdodD0iMjAiCnZpZXdCb3g9IjAgMCAyMjYgMjI2IgpzdHlsZT0iIGZpbGw6IzAwMDAwMDsiPjxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0ibm9uemVybyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIHN0cm9rZS1saW5lY2FwPSJidXR0IiBzdHJva2UtbGluZWpvaW49Im1pdGVyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1kYXNoYXJyYXk9IiIgc3Ryb2tlLWRhc2hvZmZzZXQ9IjAiIGZvbnQtZmFtaWx5PSJub25lIiBmb250LXdlaWdodD0ibm9uZSIgZm9udC1zaXplPSJub25lIiB0ZXh0LWFuY2hvcj0ibm9uZSIgc3R5bGU9Im1peC1ibGVuZC1tb2RlOiBub3JtYWwiPjxwYXRoIGQ9Ik0wLDIyNnYtMjI2aDIyNnYyMjZ6IiBmaWxsPSJub25lIj48L3BhdGg+PGcgZmlsbD0iIzdlN2U3ZSI+PHBhdGggZD0iTTE3My4zOTkwOCwxOC44MzMzM2MtMi40MDk0OCwwIC00LjgyMTYzLDAuOTE3ODMgLTYuNjU3ODgsMi43NTg3OWwtMTYuMDc0NTQsMTYuMDc0NTRsMzcuNjY2NjcsMzcuNjY2NjdsMTYuMDc0NTQsLTE2LjA3NDU0YzMuNjgxOTIsLTMuNjgxOTIgMy42ODE5MiwtOS42NDMyNiAwLC0xMy4zMTU3NmwtMjQuMzUwOTEsLTI0LjM1MDkxYy0xLjg0MDk2LC0xLjg0MDk2IC00LjI0ODQsLTIuNzU4NzkgLTYuNjU3ODgsLTIuNzU4Nzl6TTEzNi41NDE2Nyw1MS43OTE2N2wtMTA4LjI5MTY3LDEwOC4yOTE2N3YzNy42NjY2N2gzNy42NjY2N2wxMDguMjkxNjcsLTEwOC4yOTE2N3oiPjwvcGF0aD48L2c+PC9nPjwvc3ZnPg=="
        />
        Create Program
      </li>
      <li className={classes.DropDownButton} onClick={removeHandler}>
        <img
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAC2ElEQVRoQ+1ZS2gTURQ9902s1mKw4AexIIigoq4UsS4EQaW7rBpcuApJN5GQmVlUd4GiVNrO1KKLNKmiK2l37oquCuKySJW6URA/oC5qpT9K865MsTBJa16cSYjgm+39nrm/d98j1OHL5XIiGu24KiCTAHUysEOhdg2EVwA/mp/fnc/l4qth3aCwCgYH83sigsYZdDGILgKmS2zEbDvxMYj8hkwoIPl8fufSkpgC43QYJwDMGpHWc5nMtZ9B9YQC4rqjt8F0M6jxMjkixzSTdlBdgYEMDDxuixgrXwCK+owvEnMfmF8QxPJWTnFERpjpLJhuAdjl41lo2b56IJ1OLwQBExiI44xdIchJv1EGEpaVeliLI65bTIB5zM9Lgruy2Z4ynbXo8ng2AXGcQpGAQzUoOAjgeAXfDAi1dSBGC4BTFfKzAD6rbDPwwbJSybKfUCnkOoXXAE6olDWZ/sa0Uic1kCZHwW9eHZHhoWI3C25XOU3Sm97cquL7Ozots8CKSoYkzWXt5ETV1FIp+VfpyvbrOIWYYD7WXADiR9ZO5qv5oATiuoVxMLqbCoTwzjRTRzQQ7w/oiNQzF3Vq+f5mldT6BOAtgP0VZ6ZFAC/hnaUIF8oCw5j6fRbrBNDmo80A+ArA644dm4LZ0Igw7pt26vr6ACUe9xlfn7ojIw/2ltZK3/xOGRFjXyaT+F55niOmuDfg3KHCPRDSGki1uvxjaumI6NRq0GTXqaVTS6fWelfW7VdPdn1Eqb446BrRNaJrRNeI3hD1qlulCoJfmep9RO8jeh/5T/YRwoQo4Q4TX2Kifl9DeS8k4tLgdjA9K2s0xJdFieakgHczeXiDRsw3iOm5NNC75VtMQ69M63nbrtJVHyDFfjD3qmw1lM6YNO1UV6gXq+HhsaMs5TSAOr/g1g6dmGNZu+dpKCCe8N2h0fOSaBDAGQDbanchFKdksPds0WdZPU9Umn4BuIU/b/ejCBUAAAAASUVORK5CYII="
          alt="delete program"
        />
        Delete
      </li>
      <li className={classes.DropDownButton} onClick={showPauseModal}>
        {status === 1 ? (
          <>
            <svg xmlns="http://www.w3.org/2000/svg" fill="#82828E" viewBox="0 0 320 512">
              <path d="M48 64C21.5 64 0 85.5 0 112V400c0 26.5 21.5 48 48 48H80c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48H48zm192 0c-26.5 0-48 21.5-48 48V400c0 26.5 21.5 48 48 48h32c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48H240z" />
            </svg>
            Pause Access
          </>
        ) : (
          <>
            <svg xmlns="http://www.w3.org/2000/svg" fill="#82828E" viewBox="0 0 384 512">
              <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z" />
            </svg>
            Unpause Access
          </>
        )}
      </li>
    </ul>
  );
  const initials = getInitials(name);

  return (
    <div className={classes.cardContainer}>
      <OutsideAlerter close={() => setShowDropDown(false)} show={showDropDown}>
        <div className={classes.Client}>
          <NavLink
            to={{
              pathname: `${CLIENT_PROFILE.URL}/${clientKey}`,
            }}
            exact
          >
            <div className={classes.ImageDiv}>{initials}</div>
          </NavLink>
          {showDropDown ? dropdown : null}
          <div className={classes.DotsContainer} onClick={() => setShowDropDown(!showDropDown)}>
            <div className={classes.dots}>
              <div className={classes.dot}></div>
            </div>
          </div>
          <div className={classes.RightContainer}>
            <h3 className={classes.ClientName}>{name}</h3>

            {!hasPrograms && (
              <div className={classes.noClientProgram}>
                <div className={classes.PulsingDot} />
                Program not assigned
              </div>
            )}

            <ClientButtons
              clientKey={clientKey}
              hasPrograms={hasPrograms}
              showProgramTitle={() => showProgramTitle()}
            />
          </div>
        </div>
      </OutsideAlerter>
    </div>
  );
};

export default Client;
