import React, { FC, useCallback, useState, useEffect } from 'react';
import {
  Formik,
  Form,
  Field,
  FormikHelpers,
  ErrorMessage,
  FieldInputProps,
  FormikProps,
  FieldMetaProps,
} from 'formik';
import { object, string, mixed } from 'yup';
import cls from 'classnames';
import { useDropzone } from 'react-dropzone';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import { FormPropsPromise } from 'interfaces/utils';
import OutsideClickHandler from 'react-outside-click-handler';
import Textarea from 'react-textarea-autosize';
import classes from './Community.module.css';

// const MAX_FILE_SIZE = 100000000; // 100 MB
// const ACCEPTED_FORMATS = ['application/pdf'];

type FormValues = {
  text: string;
  file: any;
  channel: string;
};

export interface FieldProps<V = any> {
  field: FieldInputProps<V>;
  form: FormikProps<V>; // if ppl want to restrict this for a given form, let them.
  meta: FieldMetaProps<V>;
}

const initialValues = { text: '', file: undefined, channel: 'Chat-room' };
const validationSchema = object().shape({
  text: string(),
  file: mixed(),
  // .test(
  //   'fileSize',
  //   'File too large',
  //   (value) => value && value[0] && value[0].size <= MAX_FILE_SIZE,
  // ),
  // .test(
  //   'fileFormat',
  //   'Unsupported Format',
  //   (value) =>
  //     value && value[0] && ACCEPTED_FORMATS.includes(value[0].type),
  // ),
});

const DragAndDrop: FC<{
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  setFileObj: (val: any) => void;
}> = ({ setFieldValue, setFileObj }) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      setFieldValue('file', acceptedFiles);
      setFileObj(acceptedFiles[0]);
    },
    [setFieldValue, setFileObj],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
  });

  return (
    <div
      className={cls(classes.dndWrapper, {
        [classes.activeWrapper]: isDragActive,
      })}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {/* {fileName && <p className={classes.fileName}>{fileName}</p>} */}
      {isDragActive ? (
        <p className={classes.activeDrag}>Drop the files here ...</p>
      ) : (
        <div className={classes.AttachButton}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-paperclip"
            viewBox="0 0 16 16"
          >
            <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z" />
          </svg>
        </div>
      )}
    </div>
  );
};

const EmojiButton: FC<{
  setEmojiPicker: (value: boolean) => void;
  emojiPickerState: boolean;
}> = ({ setEmojiPicker, emojiPickerState }) => {
  return (
    <div className={classes.EmojiButton}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="#ffffff"
        className="bi bi-emoji-sunglasses-fill"
        viewBox="0 0 16 16"
        onClick={() => setEmojiPicker(!emojiPickerState)}
      >
        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zM2.31 5.243A1 1 0 0 1 3.28 4H6a1 1 0 0 1 1 1v.116A4.22 4.22 0 0 1 8 5c.35 0 .69.04 1 .116V5a1 1 0 0 1 1-1h2.72a1 1 0 0 1 .97 1.243l-.311 1.242A2 2 0 0 1 11.439 8H11a2 2 0 0 1-1.994-1.839A2.99 2.99 0 0 0 8 6c-.393 0-.74.064-1.006.161A2 2 0 0 1 5 8h-.438a2 2 0 0 1-1.94-1.515L2.31 5.243zM4.969 9.75A3.498 3.498 0 0 0 8 11.5a3.498 3.498 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.498 4.498 0 0 1 8 12.5a4.498 4.498 0 0 1-3.898-2.25.5.5 0 0 1 .866-.5z" />
      </svg>
    </div>
  );
};

const SendButton: FC<{
  submitForm: () => void;
}> = ({ submitForm }) => {
  return (
    <div onClick={submitForm} className={classes.SendButton}>
      <svg
        className="MuiSvgIcon-root jss179"
        focusable="false"
        viewBox="0 0 24 24"
        aria-hidden="true"
        fill="#ffffff"
      >
        <path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z"></path>
      </svg>
    </div>
  );
};

const Community: FC<
  FormPropsPromise<FormValues> & {
    progress: number;
    // setMarginBottom: (value: number) => void;
    handleSubmit: (payload: FormValues) => Promise<any>;
    setProgress: (value: number) => void;
    selectedChannel: string;
  }
> = ({ handleSubmit, progress, setProgress, selectedChannel }) => {
  let submitting = false;
  const onSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) => {
    if (!submitting) {
      submitting = true;
      handleSubmit({ ...values, channel: selectedChannel }).then(() => {
        actions.setSubmitting(false);
        actions.resetForm({});
        setFileObj(null);
        setProgress(0);
        submitting = false;
      });
    }
  };
  const [fileObj, setFileObj] = useState<File | null>(null);
  const [emojiPickerState, setEmojiPicker] = useState<boolean>(false);

  const previewURL = fileObj !== null ? window.URL.createObjectURL(fileObj) : '';

  const MessageInputClass = fileObj !== null ? classes.MessageInputUpload : classes.MessageInput;

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      URL.revokeObjectURL(previewURL);
    },
    [fileObj],
  );

  const handleKeyPress = (e: any, submitForm: () => void) => {
    if (e.key === 'Enter' && e.shiftKey) {
      submitForm();
    }
  };

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
      {({ submitForm, setFieldValue, values, isSubmitting }) => (
        <Form className={classes.formWrapper}>
          <Field name="text">
            {({ meta, field }: FieldProps) => (
              <div
                className={MessageInputClass}
                onKeyPress={(e) => (!isSubmitting ? handleKeyPress(e, submitForm) : {})}
              >
                <Textarea
                  id="text"
                  placeholder="Send a message..."
                  // onHeightChange={(rowHeight) => {
                    // rowHeight <= 260 && setMarginBottom(rowHeight);
                  // }}
                  readOnly={isSubmitting}
                  {...field}
                />
                {meta.touched && meta.error && <div className={classes.error}>{meta.error}</div>}
                {previewURL && (
                  <>
                    <div className={classes.PreviewUpload}>
                      <div className={classes.progressWrapper}>
                        <div className={classes.progressBar} style={{ width: `${progress}%` }} />
                      </div>
                      {fileObj?.type?.includes('video') ? (
                        <video height="80" controls>
                          <source src={previewURL} />
                        </video>
                      ) : (
                        <img src={previewURL} alt="preview" />
                      )}
                    </div>
                  </>
                )}
              </div>
            )}
          </Field>

          <ErrorMessage name="file">
            {(errMsg) => <p className={classes.error}>{errMsg}</p>}
          </ErrorMessage>

          {emojiPickerState && (
            <OutsideClickHandler
              disabled={!emojiPickerState}
              onOutsideClick={() => setEmojiPicker(false)}
            >
              <Picker
                style={{
                  position: 'absolute',
                  top: '-420px',
                  right: '40px',
                  zIndex: 2,
                  boxShadow: '0 5px 2px hsla(0, 0%, 0%, 0.1)',
                }}
                theme="dark"
                title="Pick your emoji..."
                emoji="point_up"
                onSelect={(emoji: any) => setFieldValue('text', values.text + emoji.native)}
              />
            </OutsideClickHandler>
          )}

          <div className={classes.ButtonsContainer}>
            <DragAndDrop setFieldValue={setFieldValue} setFileObj={setFileObj} />
            <EmojiButton setEmojiPicker={setEmojiPicker} emojiPickerState={emojiPickerState} />
            <div className={classes.Divider}></div>
            <SendButton submitForm={submitForm} />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Community;
