import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/firestore';
import 'firebase/functions';

const productionConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// exporting config so we can use config.databaseURL for API calls
export const config = productionConfig;

if (!firebase.apps.length) {
  firebase.initializeApp(productionConfig);
}

export const auth = firebase.auth();
export const storage = firebase.storage();
export const database = firebase.database();
export const firestore = firebase.firestore();
export const functions = firebase.functions()
// functions.useFunctionsEmulator('http://127.0.0.1:5001'); for testing locally

export const getUser = (): firebase.User | null => {
  return auth.currentUser;
};

export const getToken = (): Promise<string> | null => {
  const currentUser = getUser();

  if (currentUser) {
    return currentUser.getIdToken();
  }

  return null;
};
