import { SetParam } from './utils';

export interface CoachProfile {
  birthday: string;
  clientCount: number;
  code?: string;
  email: string;
  fullname: string;
  hasPrograms?: boolean;
  isPaidSubscriptionActive: boolean;
  lastBillingDate: number;
  subscriptionId?: string | null; // Stripe subscription id
  paymentLink?: string | null; // Stripe payment link
  stripeCustomerId?: string | null; // Stripe customer id
  currency?: 'ZAR' | 'USD';
  mealPlans?: {
    downloadURL: string;
    fileName: string;
    title: string;
  }[];
  totalDocumentsSize?: number;
  documentCount?: number;
  recentDocuments?: string[];
  categories: string[];
  nextBillingDate: number;
  planId: number;
  profile_url: string;
  userId: string;
  referral_code?: string;
  referred_by?: string;
  authorization?: {
    authorization_code: string;
    card_type: string;
    last4: string;
    exp_month: string;
    exp_year: string;
    bin: string;
    bank: string;
    channel: string;
    signature: string;
    reusable: boolean;
    country_code: string;
    email: string;
  };
}

export interface Codes {
  coachcode: string;
  userId: string;
}

export type Currency = 'ZAR' | 'USD';

export type Price = {
  currency: Currency;
  amount: number;
};

export interface Plans {
  id: number;
  maxClients: number;
  name: 'Free' | 'Basic' | 'Pro' | 'Elite' | 'Enterprise' | 'Enterprise Level 2';
  price: Price[];
  annualPrice: Price[];
}

export type Profile = {
  birthday: string;
  email: string;
  fullname: string;
  mealPlanURL?: string;
  documents?: string[];
};

export interface Clients {
  clientUserId: string;
  hasPrograms: boolean;
  needsTraining?: boolean;
  profile: Profile;
  status?: number;
  pushToken?: string;
  userId: string;
  documents?: string[];
}

export interface Documents {
  documentId: string;
  clientList?: string[];
  downloadURL: string;
  fileName: string;
  title: string;
  userId: string;
  fileSize: number;
  uploadedAt: string;
  category: string;
}

export type SetObject = {
  index?: number;
  achieved: string;
  programmed: string;
};

export const instanceOfSetObject = (object: any): object is SetObject => {
  return 'achieved' in object;
};

export type Set = {
  completed: boolean;
  reps?: string | SetObject;
  rpe?: string | SetObject;
  weight?: string | SetObject;
  weightLBS?: string | SetObject;
  weightRM?: string | SetObject;
  rir?: string | SetObject;
  time?: string | SetObject;
  resistance?: string | SetObject;
  distance?: string | SetObject;
  timeMinutes?: string | SetObject;
};

export type SetKeys = keyof Omit<Set, 'completed'>;

export type Exercise = {
  notes?: string;
  videoURL?: string;
  exerciseNotes?: {
    athlete: string;
    coach: string;
  };
  sets?: Set[];
  type: string;
};

export type Workout = {
  exercises?: Exercise[][];
  finished: boolean;
  notes?: string;
  workoutDuration?: string;
  workoutNotes?: {
    athlete: string;
    coach: string;
  };
  workoutTitle?: string;
  workoutId?: string;
  weekIndex?: number;
  dayIndex?: number;
};

export type Day = {
  rest: boolean;
  workout: Workout;
  workoutId?: string;
  isDuplicated?: boolean;
};

export type Week = {
  Days: Day[];
};

export interface ProgramSettings {
  defaultSetParams?: SetParam[];
}
export interface Programs {
  programId?: string;
  image?: string;
  name?: string;
  userId: string;
  coachId?: string;
  weeks?: Week[];
  status?: string;
  settings?: ProgramSettings;
  isWorkoutDataPopulated?: boolean;
  onMarketplace?: boolean;
}

export interface NewAssignedPrograms {
  image?: string;
  name?: string;
  userId: string;
  weeks?: Week[];
  clientKey?: string;
  createdAt?: Date;
}

export interface DiscountCodes {
  code: string;
  duration: number;
  planIds?: number[];
  quantity: number;
  times_used: number;
  type: 'percentage' | 'price';
  value: number;
  emails?: string[];
}

export interface Messages {
  createdAt: string;
  text: string;
  displayName: string;
  downloadURL: string;
  userId: string;
}

export type FunnelIdentifier = {
  coachUserId: string;
  planCode: string;
};

export type FunnelProgram = {
  coachName: string;
  createdAt: Date;
  description: string;
  duration: number;
  equipment: string;
  experience: string;
  focusArea: string;
  programKey: string;
  programName: string;
  sessionsPerWeek: number;
  image: string;
};

export interface PlanImage {
  downloadURL: string;
  fileName: string;
  fileSize: number;
  userId: string;
}

export interface TestimonialImage extends PlanImage {
  testimonialId: string;
}

export type Testimonial = {
  location: string;
  name: string;
  content: string;
  beforeImage?: TestimonialImage;
  afterImage?: TestimonialImage;
};

export type FunnelPlan = {
  coachOverview: {
    title: string;
    coachName: string;
    descriptionTitle: string;
    description: string;
  };
  planOverview: {
    description: string;
    price: number;
    displayPrice: number;
    identifier: string;
    currency: string;
    organisation: boolean;
    isTrialEnabled: boolean;
  };
  paystackInfo: {
    plan_code: string;
  };
  faqs: {
    question: string;
    answer: string;
  }[];
  otherImages: {
    getToKnowImage: PlanImage;
    heroImageDesktop: PlanImage;
    heroImageMobile: PlanImage;
  };

  testimonials: Testimonial[];
};

export type Channel = {
  id: string;
  data?: {
    channelTitle?: string;
    createdAt?: any;
  };
};

export type ProgressPhoto = {
  categoryName: string;
  clientNotes: string;
  coachNotes: string;
  image: string;
  description: string;
  docId?: string;
};

export interface ProgressPhotosTemplate {
  [key: string]: ProgressPhoto;
}
export interface Progress {
  bodyWeight: number | string;
  date: string;
  overallCoachNotes: string;
  overallClientNotes: string;
  photos: ProgressPhotosTemplate;
}
