import React, { useState, FunctionComponent, ChangeEvent, ReactNode } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { RootState } from 'store';
import Table from '../UI/Table/Table';
import Modal from 'components/UI/Modal/Modal';
import { ExerciseLibForm, Autocomplete } from 'components';
import ExerciseHistoryBox from '../ExerciseHistoryBox/ExerciseHistoryBox';

import { getThumbnailURL, formValues } from 'utils/helpers';
import { Set, SetKeys, Exercise } from 'interfaces/db';
import { saveExercise, fetchExercises } from 'store/actions/exerciseLibrary';
import classes from './ExerciseBlock.module.css';
import { IndexedExercise } from 'interfaces/utils';

type Suggestions = Exercise & {
  author: 'coach' | 'admin';
};

interface Props {
  fullname: string;
  finished: boolean;
  group: number;
  index: number;
  exercise: Exercise;
  clientKey?: string;
  programKey?: string;
  addSetsHandler: (sets: Set[], group: number, index: number) => void;
  addExerciseNotes: (exerciseNotes: string, group: number, index: number) => void;
  addExerciseType: (exerciseType: string, group: number, index: number) => void;
  openAnalytics: (indexedExercise: IndexedExercise) => void;
  closeAnalytics: (indexedExercise: IndexedExercise) => void;
  // isAnalyticsVisible: boolean;
  analyticsExerciseList?: IndexedExercise[];
  bottom: boolean;
  supersetLength: number;
  swapSets: (groupIndexOne: number, groupIndexTwo: number) => void;
  swapInSupersets: (groupIndex: number, indexOne: number, indexTwo: number) => void;
  addSetHandler: () => void;
  removeSetHandler: () => void;
  setsChangeHandler: (setType: SetKeys, value: string, setIndex: number) => void;
  autoFillSetsHandler: (setType: SetKeys, value: string, setIndex: number) => void;
  setTypeChangeHandler: (setType: SetKeys, setIndex: number) => void;
  suggestions: Suggestions[];
  suggestionsSource: Suggestions[];
  setSuggestions: (val: Suggestions[]) => void;
  setExerciseFromLib: (exercise: Suggestions) => void;
  removeTypeChangeHandler: (setKey: SetKeys) => void;
  addTypeChangehandler: (setType: SetKeys) => void;
}

const mapStateToProps = ({ auth, clients }: RootState) => {
  const { token, coachProfile, userId } = auth;

  return {
    token,
    coachKey: Object.keys(coachProfile || {})[0],
    clients: clients.clients,
    userId,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onFetchExercise: (token: string, coachKey: string) => dispatch(fetchExercises(token, coachKey)),

    onSaveExercise: (token: string, coachKey: string, exercise: Exercise) =>
      dispatch(saveExercise(token, coachKey, exercise)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = Partial<ConnectedProps<typeof connector>>;

const ExerciseBlock: FunctionComponent<Props & PropsFromRedux & { children: ReactNode }> = ({
  group,
  index,
  token,
  bottom,
  exercise,
  coachKey,
  finished,
  swapSets,
  children,
  fullname,
  clientKey,
  clients,
  userId,
  programKey,
  openAnalytics,
  closeAnalytics,
  // isAnalyticsVisible,
  analyticsExerciseList,
  // suggestions,
  addSetHandler,
  supersetLength,
  // setSuggestions,
  onSaveExercise,
  onFetchExercise,
  addExerciseNotes,
  swapInSupersets,
  addExerciseType,
  removeSetHandler,
  suggestionsSource,
  setsChangeHandler,
  autoFillSetsHandler,
  setExerciseFromLib,
  setTypeChangeHandler,
  addTypeChangehandler,
  removeTypeChangeHandler,
}) => {
  const [initialValues, setInitialValues] = useState(formValues);
  const [exerciseModal, showExerciseModal] = useState(false);
  const [showAutoFill, _setShowAutoFill] = useState<string>('');

  const setShowAutoFill = (setIdentifier: string) => {
    _setShowAutoFill(setIdentifier);
  };

  const handleSubmit = (val: Exercise) => {
    if (token && coachKey && onSaveExercise && onFetchExercise) {
      onSaveExercise(token, coachKey, val);
      showExerciseModal(false);
    }
  };

  const handleAddToLibrary = () => {
    const { type = '' } = exercise;

    setInitialValues({ ...formValues, type });
    showExerciseModal(true);
  };

  const exerciseTypeOnChangeHandler = (value: string) => {
    addExerciseType(value, group, index - 1);
  };

  const exerciseNotesOnChangeHandler = ({ target }: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = target;
    addExerciseNotes(value, group, index - 1);
  };

  const isAnalyticsVisible = (index: number, group: number) => {
    return analyticsExerciseList?.some((indexedExercise) => {
      return indexedExercise.index === index && indexedExercise.group === group;
    });
  };

  const exerciseHistoryButton = (exercise: Exercise, index: number, group: number) => {
    return (
      <button
        className={classes.AnalyticsButton}
        onClick={() => openAnalytics({ exercise, index, group })}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
          fill="#707070"
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14zM7 10h2v7H7zm4-3h2v10h-2zm4 6h2v4h-2z" />
        </svg>
        <p>Open Exercise History</p>
      </button>
    );
  };

  const coachNotes = exercise.exerciseNotes?.coach;
  const athleteNotes = exercise.exerciseNotes?.athlete;

  const block =
    exercise === undefined ? null : (
      <div style={{ display: 'flex', flexFlow: 'row' }}>
        <div className={classes.ExerciseBlock}>
          <div className={classes.BlockWrapper}>
            <div className={finished ? classes.ExerciseWrapperReview : classes.ExerciseWrapper}>
              <div className={classes.Exercise}>
                <Autocomplete
                  footer={
                    <div onClick={handleAddToLibrary} className={classes.footerText}>
                      <div className={classes.footerLink}>
                        <div className={classes.footerTextIcon}>+</div>
                        Add to exercise library
                      </div>
                    </div>
                  }
                  fullname={fullname}
                  exerciseType={exercise.type}
                  suggestions={suggestionsSource}
                  setExerciseFromLib={setExerciseFromLib}
                  exerciseTypeOnChangeHandler={exerciseTypeOnChangeHandler}
                />
              </div>

              {exercise.videoURL && (
                <a
                  target="_blank"
                  href={exercise.videoURL}
                  rel="noreferrer noopener"
                  className={classes.videoURL}
                >
                  <img
                    alt="video thumbnail"
                    className={classes.videoThumb}
                    src={getThumbnailURL(exercise.videoURL)}
                  />
                </a>
              )}

              <div className={classes.ExerciseNotes}>
                {finished ? (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                    }}
                  >
                    <p className={classes.ExerciseNotesHeading}>
                      {coachNotes ? `Your exercise notes:` : ''}
                    </p>
                    <p className={classes.ExerciseNotesCoachParagraph}>{coachNotes || ''}</p>
                    <p className={classes.ExerciseNotesHeading}>
                      {athleteNotes ? `Client feedback:` : ''}
                    </p>
                    <p className={classes.ExerciseNotesClientParagraph}>{athleteNotes || ''}</p>
                  </div>
                ) : (
                  <textarea
                    value={coachNotes || ''}
                    placeholder="Exercise Notes"
                    onChange={exerciseNotesOnChangeHandler}
                    className={classes.ExerciseNotesTextarea}
                  />
                )}
              </div>
            </div>

            <div className={classes.SetWrapper}>
              <div className={classes.SetTable}>
                <Table
                  finished={finished}
                  exercise={exercise}
                  addSetHandler={addSetHandler}
                  removeSetHandler={removeSetHandler}
                  setsChangeHandler={setsChangeHandler}
                  autoFillSetsHandler={autoFillSetsHandler}
                  setTypeChangeHandler={setTypeChangeHandler}
                  addTypeChangehandler={addTypeChangehandler}
                  removeTypeChangeHandler={removeTypeChangeHandler}
                  setShowAutoFill={(setIdentifier: string) => setShowAutoFill(setIdentifier)}
                  showAutoFill={showAutoFill}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );

  const moveUp = () => {
    if (!group && !(index - 1)) {
      return;
    }
    if (supersetLength) {
      if (index - 1 === 0) {
        swapSets(group, group - 1);
        return;
      } else {
        swapInSupersets(group, index - 1, index - 2);
        return;
      }
    }
    swapSets(group, group - 1);
  };

  const moveDown = () => {
    if (bottom) {
      return;
    }

    if (supersetLength) {
      if (supersetLength === index - 1) {
        swapSets(group, group + 1);
        return;
      } else {
        swapInSupersets(group, index - 1, index);
        return;
      }
    }
    swapSets(group, group + 1);
  };

  return (
    <>
      <div className={classes.ExerciseContainerStyle}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div className={classes.ExerciseBlock}>{block}</div>
        </div>
        {children}
      </div>

      {finished || isAnalyticsVisible(index, group) ? (
        isAnalyticsVisible(index, group) ? null : (
          exerciseHistoryButton(exercise, index, group)
        )
      ) : (
        <div className={classes.ExerciseMoveContainer}>
          <button
            className={!group && !(index - 1) ? classes.MoveButtonDisabled : classes.MoveButton}
            onClick={moveUp}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="11.115"
              viewBox="0 0 18 11.115"
            >
              <path
                d="M11.115,22.875,18,16.005l6.885,6.87L27,20.76l-9-9-9,9Z"
                transform="translate(-9 -11.76)"
                fill="#3c4148"
              />
            </svg>
            <p>
              {!supersetLength
                ? `Move Exercise Up`
                : index === 1
                ? `Move Superset Up`
                : `Move Exercise Up`}
              {/* Move Exercise Up In Superset */}
            </p>
          </button>
          <button
            className={bottom ? classes.MoveButtonDisabled : classes.MoveButton}
            onClick={moveDown}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="11.115"
              viewBox="0 0 18 11.115"
            >
              <path
                d="M11.115,11.76,18,18.63l6.885-6.87L27,13.875l-9,9-9-9Z"
                transform="translate(-9 -11.76)"
                fill="#707070"
              />
            </svg>
            <p>
              {!supersetLength
                ? `Move Exercise Down`
                : index === supersetLength + 1
                ? `Move Superset Down`
                : `Move Exercise Down`}
              {/* Move Exercise Up In Superset */}
            </p>
          </button>
          {programKey === undefined ? exerciseHistoryButton(exercise, index, group) : null}
        </div>
      )}

      {isAnalyticsVisible(index, group) && !programKey && (
        <div className={classes.ExerciseHistoryWrapper}>
          <ExerciseHistoryBox
            closeAnalytics={closeAnalytics}
            indexedExercise={{ exercise, index, group }}
            userId={clientKey ? clients?.[clientKey].clientUserId : userId}
          />
        </div>
      )}
      <Modal isModalOpen={exerciseModal} openModal={() => showExerciseModal(false)}>
        <div className={classes.modalContainer}>
          <div className={classes.ModalClose}>
            <img
              onClick={() => showExerciseModal(false)}
              src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGlkPSJDYXBhXzEiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDM4Ni42NjcgMzg2LjY2NyIgaGVpZ2h0PSI1MTIiIHZpZXdCb3g9IjAgMCAzODYuNjY3IDM4Ni42NjciIHdpZHRoPSI1MTIiIGNsYXNzPSJob3ZlcmVkLXBhdGhzIj48Zz48cGF0aCBkPSJtMzg2LjY2NyA0NS41NjQtNDUuNTY0LTQ1LjU2NC0xNDcuNzcgMTQ3Ljc2OS0xNDcuNzY5LTE0Ny43NjktNDUuNTY0IDQ1LjU2NCAxNDcuNzY5IDE0Ny43NjktMTQ3Ljc2OSAxNDcuNzcgNDUuNTY0IDQ1LjU2NCAxNDcuNzY5LTE0Ny43NjkgMTQ3Ljc2OSAxNDcuNzY5IDQ1LjU2NC00NS41NjQtMTQ3Ljc2OC0xNDcuNzd6IiBkYXRhLW9yaWdpbmFsPSIjMDAwMDAwIiBjbGFzcz0iaG92ZXJlZC1wYXRoIGFjdGl2ZS1wYXRoIiBzdHlsZT0iZmlsbDojRkZGRkZGIiBkYXRhLW9sZF9jb2xvcj0iIzAwMDAwMCI+PC9wYXRoPjwvZz4gPC9zdmc+"
              alt="close"
            />
          </div>

          <p className={classes.ModalLargeText}>Create Exercise</p>

          <ExerciseLibForm handleSubmit={handleSubmit} initialValues={initialValues} />
        </div>
      </Modal>
    </>
  );
};
export default connector(ExerciseBlock);
