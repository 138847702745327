import React, { FC } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { RootState } from 'store';
import { RegisterForm } from 'components';
import { LOGIN } from 'utils/routes';
import { register } from 'store/actions/auth';
import Logo from 'components/Logo/Logo';
import withoutAuth from 'utils/withoutAuth';
import classes from './Auth.module.css';

const mapStateToProps = ({ auth }: RootState) => {
  return {
    loading: auth.loading,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onRegister: (name: string, email: string, password: string) =>
      dispatch(register(name, email, password)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type FormValues = {
  fullName: string;
  email: string;
  password: string;
};

const Auth: FC<PropsFromRedux> = ({ onRegister, loading }) => {
  const handleRegister = ({ fullName, email, password }: FormValues) => {
    onRegister(fullName, email, password);
  };

  return (
    <main>
      <div className={classes.Header}>
        <div className={classes.Logo}>
          <Logo link="https://www.coachelite.co/" auth={true} dashboard={false} />
        </div>

        <div className={classes.AuthButtons}>
          <p className={classes.signPreText}>Already a member? </p>
          <NavLink to={{ pathname: LOGIN.URL }} className={classes.SignupButton}>
            Sign In
          </NavLink>
        </div>
      </div>

      <div className={classes.Auth}>
        <div className={classes.AuthWrapper}>
          <div className={classes.AuthContainer}>
            <h2 className={classes.AuthHeading}>Let&apos;s get started</h2>
            <p className={classes.AuthSubHeading}>
              This web platform is for <strong>coaches only</strong>
              <br /> If you are a client of a coach please download our mobile app <br />
              or reach out to <a href="mailto:support@coachelite.co">support@coachelite.co</a>
            </p>
            <div className={classes.UserForm}>
              <RegisterForm loading={loading} handleSubmit={handleRegister} />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default connector(withoutAuth(Auth));
