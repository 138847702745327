import React, { FC } from 'react';
import { useToasts } from 'react-toast-notifications';
import { RouteComponentProps } from 'react-router-dom';

import { ForgotPasswordForm } from 'components';
import { LOGIN } from 'utils/routes';
import Logo from 'components/Logo/Logo';
import { auth } from 'utils/firebase';
import withoutAuth from 'utils/withoutAuth';
import classes from './Auth.module.css';

const ForgotPassword: FC<RouteComponentProps> = ({ history }) => {
  const { addToast } = useToasts();

  const sendResetEmail = async ({ email }: { email: string }) => {
    try {
      //  send password reset email
      await auth.sendPasswordResetEmail(email);

      // show toast notification
      addToast(`Password reset link has been sent to ${email}`, {
        appearance: 'success',
      });

      // redirect back to login screen
      history.push({ pathname: LOGIN.URL });
    } catch (err: any) {
      // possible firebase error codes
      const errCodes = ['auth/invalid-email', 'auth/user-not-found'];

      if (errCodes.includes(err.code)) {
        // invalid user email
        addToast(`${email} is not a registered email`, {
          appearance: 'error',
        });
        return;
      }

      // unhandled errors
      addToast('An error has occured, please try later.', {
        appearance: 'error',
      });
    }
  };

  return (
    <main>
      <div className={classes.Header}>
        <div className={classes.Logo}>
          <Logo link="/" dashboard={false} />
        </div>
      </div>

      <div className={classes.Auth}>
        <div className={classes.AuthWrapper}>
          <div className={classes.AuthContainer}>
            <h2 className={classes.AuthHeading}>Reset Password</h2>
            <div className={classes.UserForm}>
              <ForgotPasswordForm handleSubmit={sendResetEmail} />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default withoutAuth(ForgotPassword);
