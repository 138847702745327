import React from 'react';
import classes from './Button.module.css';

interface Props {
  onClick?: () => void;
  border?: string;
  color?: string;
  children?: React.ReactNode;
  height?: string;
  radius?: string;
  width?: string;
  loading?: boolean;
  type?: 'button' | 'submit';
  disabled?: boolean;
}

const Button: React.FC<Props> = ({
  onClick,
  border,
  color,
  children,
  height,
  radius,
  width,
  loading,
  disabled,
  type,
}) => {
  return (
    <button
      onClick={onClick}
      style={{
        backgroundColor: color,
        border,
        borderRadius: radius,
        height,
        width,
      }}
      disabled={disabled}
      type={type}
      className={classes.button}
    >
      {loading ? (
        <i className="fa fa-circle-o-notch fa-spin" />
      ) : (
        children
      )}
    </button>
  );
};

export default Button;
