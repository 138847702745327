import React, { FC, useEffect, useState } from 'react';
import { connect, ConnectedProps, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { ClientProfileParams } from 'interfaces/routes';
import withAuth from 'utils/withAuth';
import { RootState } from 'store';
import Layout from '../Layout';
import { CLIENTS_PROGRESS } from 'utils/routes';
import { removeClient, saveClientProfile } from 'store/actions/clients';
import { Profile, Progress, ProgressPhotosTemplate } from 'interfaces/db';
import classes from './Progress.module.css';
import { Line } from 'react-chartjs-2';
import { database, firestore } from 'utils/firebase';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import Spinner from 'components/UI/Spinner/Spinner';
import { getDateStringWithNoSeparators, groupByMonth, groupByYear, flatDeep } from '../../utils/helpers';
import { PHOTOS_TEMPLATE } from '../../utils/constants';
import { QuerySnapshot, DocumentData } from '@firebase/firestore-types';
import firebase from 'firebase';
import Modal from 'components/UI/Modal/Modal';

dayjs.extend(customParseFormat); // allows us to set our own format for dates

const mapStateToProps = ({ auth, clients }: RootState) => {
  return {
    token: auth.token,
    clients: clients.clients,
    loading: clients.loading,
    removed: clients.removed,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onSaveClientProfile: (clientProfile: Profile, clientKey: string, token: string) =>
      dispatch(saveClientProfile(clientProfile, clientKey, token)),
    onRemoveClient: (clientKey: string, token: string) => {
      dispatch(removeClient(clientKey, token));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & RouteComponentProps<ClientProfileParams>;
type EntriesProps = {
  entriesData: any;
  setSelectedEntry: (value: Progress) => void;
  selectedEntry: Progress | undefined;
};

type TimeFilterTabsProps = {
  timeFilterOptions: {
    value: string;
    label: string;
  }[];
  timeFilter: string;
  setSelectedTimeFilter: (value: string) => void;
};
interface IProgress {
  [key: string]: Progress;
}
interface ProgressDateKey {
  [date: string]: Progress;
}

const EntriesList: FC<EntriesProps> = ({ entriesData, setSelectedEntry, selectedEntry }) => {
  return (
    <div className={classes.EntriesWrapper}>
      <ul className={classes.WeightsList}>
        <div className={classes.EntriesTitle}>
          <span>Entries:</span>
          <hr className={classes.Divider} />
        </div>
        {entriesData?.labels.map((label: any, i: number) => {
          const currentEntry = entriesData.datasets[0]?.data[i];
          return (
            <React.Fragment key={label}>
              <li
                onClick={() => setSelectedEntry(currentEntry)}
                className={
                  selectedEntry !== currentEntry
                    ? classes.EntryListItem
                    : [classes.EntryListItem, classes.EntrySelected].join(' ')
                }
              >
                <div>
                  <span>{currentEntry.bodyWeight}kg</span>
                  <p>{dayjs(label, 'DD MMM YYYY').format('ddd, D MMM YYYY')}</p>
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#ffffff"
                  className="bi bi-chevron-right"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                  />
                </svg>
              </li>
              <hr className={classes.Divider} />
            </React.Fragment>
          );
        })}
      </ul>
    </div>
  );
};

const TimeFilterTabs: FC<TimeFilterTabsProps> = ({ timeFilterOptions, timeFilter, setSelectedTimeFilter }) => {
  return (
    <div className={classes.GraphHeader}>
      <ul className={classes.TabList}>
        {timeFilterOptions.map((option) => (
          <li
            key={option.value}
            className={timeFilter !== option.value ? classes.Tab : [classes.Tab, classes.TabSelected].join(' ')}
            onClick={() => {
              if (option.value !== timeFilter) {
                setSelectedTimeFilter(option.value);
              }
            }}
          >
            {option.label}
          </li>
        ))}
      </ul>
    </div>
  );
};

const ProgressPage: FC<Props> = ({ match }) => {
  const [timeFilter, setTimeFilter] = useState('all');
  const [isFullData, setIsFullData] = useState(true);
  const [data, setData] = useState<any>();
  const [fullGraphData, setFullGraphData] = useState<any>();
  const [filteredData, setFilteredData] = useState<any>();
  const [entriesData, setEntriesData] = useState<any>();
  const [isGraphMounted, setIsGraphMounted] = useState(false);
  const [, setLoading] = useState<boolean>(false);
  const { clients } = useSelector((state: RootState) => state.clients);
  const { clientKey } = match.params;
  const clientUserId: string = clients?.[clientKey || '']?.clientUserId || '';
  const [clientProgress, setClientProgress] = useState<IProgress[]>();
  const [, setClientProgressPhotosTemplate] = useState<ProgressPhotosTemplate>();
  const [selectedEntry, setSelectedEntry] = useState<Progress>();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const [leftSelectedEntry, setLeftSelectedEntry] = useState<Progress>();
  const [rightSelectedEntry, setRightSelectedEntry] = useState<Progress>();
  const [leftDate, setLeftDate] = useState<string>();
  const [rightDate, setRightDate] = useState<string>();
  const [selectedLeftImageCategory, setSelectedLeftImageCategory] = useState<string>('');
  const [selectedRightImageCategory, setSelectedRightImageCategory] = useState<string>('');

  const today = dayjs();

  const timeFilterOptions = [
    { value: 'all', label: 'All' },
    { value: 'oneYearAgo', label: 'YTD' },
    { value: 'nineMonthsAgo', label: '9M' },
    { value: 'sixMonthsAgo', label: '6M' },
    { value: 'threeMonthsAgo', label: '3M' },
    { value: 'twoMonthsAgo', label: '2M' },
    { value: 'oneMonthAgo', label: '1M' },
  ];

  const [filters] = useState<any>({
    oneYearAgo: today.subtract(1, 'year'),
    nineMonthsAgo: today.subtract(9, 'month'),
    sixMonthsAgo: today.subtract(6, 'month'),
    threeMonthsAgo: today.subtract(3, 'month'),
    twoMonthsAgo: today.subtract(2, 'month'),
    oneMonthAgo: today.subtract(1, 'month'),
  });

  const fetchPhotoTemplates = async (isFocused = true) => {
    const photoCategories: ProgressPhotosTemplate = {};
    await firestore
      .collection(`clients`)
      .doc(clientUserId)
      .collection('progressPhotosTemplate')
      .get()
      .then((snapshot: QuerySnapshot) => {
        snapshot.forEach((doc: DocumentData) => {
          photoCategories[doc.data().categoryName.toLowerCase().replace(' ', '_')] = doc.data();
        });
        if (isFocused || isFocused === undefined) {
          setClientProgressPhotosTemplate(photoCategories);
        }
      })
      .catch((err: any) => {
        console.log(err, 'Failed to fetch progress photo categories.');
      });
  };

  const fetchClientProgress = async () => {
    const dataPoints: IProgress[] = [];
    const keyDataPoints: ProgressDateKey = {};
    const fetchProgressDocsPromise = firestore
      .collection(`clients`)
      .doc(clientUserId)
      .collection('progress')
      .orderBy(firebase.firestore.FieldPath.documentId())
      .get()
      .then((snapshot: QuerySnapshot) => {
        snapshot.forEach((doc: DocumentData) => {
          const data = doc.data();
          dataPoints.push(data);
          keyDataPoints[doc.id] = data;
        });
      })
      .catch((err: any) => {
        console.log(err, 'Failed to fetch progress documents.');
      });

    Promise.all([fetchPhotoTemplates, fetchProgressDocsPromise])
      .then(() => {
        setClientProgress(dataPoints);
        setData(keyDataPoints);
        return dataPoints;
      })
      .catch((err) => {
        console.log(err, 'Catch all error for fetchClientProgress');
      });
  };

  useEffect(() => {
    const transferBodyWeightDataToFirestore = async () =>
      new Promise((resolve, reject) => {
        const progressData: Progress[] = [];

        database
          .ref(`bodyWeights/${clientKey}`)
          .once('value')
          .then((snapshot) => {
            snapshot.forEach((el: any) => {
              const key = el.key;

              if (!key) {
                return;
              }

              Object.entries(el.val()).map((el: any) => {
                // @ts-ignore: Unused variable
                const [_, elVal] = el;
                const dateParts = elVal.date.split('/');
                const dateObject = dayjs(new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]));

                progressData.push({
                  bodyWeight: +elVal.weightValue,
                  date: dateObject.toString(),
                  overallClientNotes: '',
                  overallCoachNotes: '',
                  photos: PHOTOS_TEMPLATE,
                });
              });
            });

            const batch = firestore.batch();
            const clientRef = firestore.collection(`clients`).doc(clientUserId);

            progressData.map((progressDoc: any) => {
              const clientsProgressNewDoc = clientRef
                .collection('progress')
                .doc(getDateStringWithNoSeparators(progressDoc?.date));
              batch.set(clientsProgressNewDoc, progressDoc);
            });

            Object.entries(PHOTOS_TEMPLATE).map(([_, value]) => {
              console.log(_);
              const photosTemplateRef = clientRef.collection('progressPhotosTemplate').doc();
              batch.set(photosTemplateRef, { ...value, docId: photosTemplateRef.id });
            });

            batch
              .commit()
              .then(() => {
                clientRef
                  .set({
                    bodyWeightsTransferred: true,
                  })
                  .then(() => {
                    resolve('Successful transfer of bodyweight node to firestore progress document.');
                  });
              })
              .catch((err: any) => {
                reject(err);
                console.log(
                  err,
                  'Failed to batch commit in body weight transfer [progress data and/or photo categories].',
                );
              });
          })
          .catch((err: any) => {
            reject(err);
            console.log(err, 'Failed to fetch body weight data from real time database node.');
          });
      });

    const runProgressDataProcessing = async () => {
      const clientRef = firestore.collection(`clients`).doc(clientUserId);
      setLoading(true);
      await clientRef.get().then(async (clientDoc: any) => {
        if (clientDoc.exists) {
          const clientData = clientDoc.data();
          if (clientData?.bodyWeightsTransferred) {
            // fetch progress data as body weights have been transferred to firstore
            fetchClientProgress();

            setLoading(false);
          } else {
            transferBodyWeightDataToFirestore().then(async () => {
              await fetchClientProgress();
              setLoading(false);
            });
          }
        } else {
          transferBodyWeightDataToFirestore().then(async () => {
            await fetchClientProgress();
            setLoading(false);
          });
        }
      });
    };

    runProgressDataProcessing();
  }, []);

  const setSelectedTimeFilter = (timeValue: string) => {
    setTimeFilter(timeValue);
    if (!isFullData && timeValue === 'all') {
      setIsFullData(true);
    } else if (isFullData && timeValue !== 'all') {
      setIsFullData(false);
    }
  };

  useEffect(() => {
    if (clientProgress) {
      const values = clientProgress?.map((value: any) => {
        if (value) {
          const date = dayjs(value?.date).format('DD MMM YYYY');
          return {
            ...value,
            date: date,
            bodyWeight: +value?.bodyWeight,
          };
        }
      });
      const entriesByYear = groupByYear(({ date }: any) => date, values);

      const entriesByMonth = Object.keys(entriesByYear).map((year) => {
        const value = groupByMonth(({ date }: any) => date, entriesByYear[year]);
        return Object.values(value);
      });
      const flattenedData = flatDeep(entriesByMonth, 3);
      const labelsTemp: any[] = flattenedData.map((item: any) => item.date);

      const graphData: any = {
        labels: labelsTemp,
        datasets: [
          {
            label: 'Body Weight (Kg)',
            data: flattenedData || [],
            borderColor: '#e94811',
            backgroundColor: '#EC3724',
          },
        ],
      };

      const graphDataCopy = JSON.parse(JSON.stringify(graphData));
      setEntriesData({
        ...graphDataCopy,
        labels: graphDataCopy.labels.reverse(),
        datasets: [
          {
            ...graphDataCopy?.datasets,
            data: graphDataCopy?.datasets[0]?.data?.reverse(),
          },
        ],
      });

      fetchPhotoTemplates(true);
      setSelectedTimeFilter('all');
      setFullGraphData(graphData);
      setFilteredData(graphData);
      setIsGraphMounted(true);
    }
  }, [clientProgress]);

  useEffect(() => {
    if (fullGraphData && timeFilter) {
      if (timeFilter !== 'all') {
        const filterLabels = fullGraphData.labels?.filter((label: string) => {
          return !dayjs(label, 'DD MMM YYYY').isBefore(filters[timeFilter]);
        });

        const filteredData = fullGraphData.datasets[0].data?.filter((entry: any) => {
          return !dayjs(entry?.date, 'DD MMM YYYY').isBefore(filters[timeFilter]);
        });

        if (fullGraphData.labels !== filterLabels) {
          setFilteredData({
            ...fullGraphData,
            datasets: [
              {
                ...fullGraphData?.datasets,
                data: filteredData,
                label: 'Body Weight (Kg)',
                borderColor: '#e94811',
                backgroundColor: '#EC3724',
              },
            ],
            labels: filterLabels,
          });
        }
      } else {
        setFilteredData(fullGraphData);
      }
    }
  }, [timeFilter]);

  useEffect(() => {
    if (data) {
      const keysArray: string[] = Object.keys(data);
      const progressLength: number = keysArray.length;
      const leftRightDate = data[keysArray[progressLength - 1]];
      setLeftDate(dayjs(leftRightDate?.date).format('DD MMMM YYYY') || new Date().toString());
      setRightDate(dayjs(leftRightDate?.date).format('DD MMMM YYYY') || new Date().toString());
    }
  }, [data]);

  const selectEntryByDate = async (
    date: Date | string,
    setProgress: (progress: Progress) => void,
    setSelectedImageCategory: (categoryKey: string) => void,
  ) => {
    const dateKey = getDateStringWithNoSeparators(new Date(date));
    if (data && dateKey) {
      const progressDataObject: Progress = data?.[dateKey];

      setProgress(progressDataObject);
      setSelectedImageCategory(progressDataObject ? Object.keys(progressDataObject.photos)[0] : '');
    }
  };

  useEffect(() => {
    if (leftDate) {
      selectEntryByDate(leftDate, setLeftSelectedEntry, setSelectedLeftImageCategory);
    }
  }, [leftDate]);

  useEffect(() => {
    if (rightDate) {
      selectEntryByDate(rightDate, setRightSelectedEntry, setSelectedRightImageCategory);
    }
  }, [rightDate]);

  const getProgressImages = () => {
    const imageCards = Object.values(selectedEntry?.photos || {})
      .filter((photo) => photo?.image)
      ?.map((photo) => {
        return (
          <div className={classes.ProgressPhotoCard} key={photo.image}>
            <div className={classes.ImageContainer}>
              <img src={photo?.image} />
            </div>
            <div className={classes.CategoryInfo}>
              <h5>{photo.categoryName}</h5>
              <p>{photo.clientNotes}</p>
            </div>
          </div>
        );
      });
    return imageCards.length > 0 ? imageCards : <div className={classes.NoImages}>No Images</div>;
  };

  return (
    <>
      <Layout loading={false} heading={CLIENTS_PROGRESS.TITLE}>
        <div className={classes.Container}>
          <table className={classes.BodyWeightGraphContainer}>
            <tbody>
              <tr>
                <td className={classes.Graph}>
                  {isGraphMounted && data && (
                    <TimeFilterTabs
                      timeFilterOptions={timeFilterOptions}
                      timeFilter={timeFilter}
                      setSelectedTimeFilter={setSelectedTimeFilter}
                    />
                  )}
                  {filteredData?.labels?.length === 0 && <h1 className={classes.NoDataTitle}>No Data</h1>}
                  {isGraphMounted && !data && <h1 className={classes.NoEntries}>No Entries</h1>}
                  {isGraphMounted ? (
                    <div className={classes.LineGraphWrapper}>
                      <Line
                        data={filteredData || {}}
                        options={{
                          parsing: {
                            yAxisKey: 'bodyWeight',
                            xAxisKey: 'date',
                          },
                        }}
                      />
                    </div>
                  ) : (
                    <Spinner />
                  )}
                </td>
              </tr>
            </tbody>
          </table>
          <div className={classes.BottomContainer}>
            {isGraphMounted && data && (
              <>
                <EntriesList
                  entriesData={entriesData}
                  setSelectedEntry={setSelectedEntry}
                  selectedEntry={selectedEntry}
                />
                <div className={classes.ProgressPhotosContainer}>
                  <div className={classes.ProgressPhotosHeader}>
                    <div>
                      {selectedEntry?.overallClientNotes && (
                        <>
                          <h5>Client Overall Notes</h5>
                          <p>{selectedEntry?.overallClientNotes}</p>
                        </>
                      )}
                    </div>

                    <button onClick={() => setIsModalVisible(true)}>Compare</button>
                  </div>
                  <div className={classes.ProgressPhotoGrid}>
                    {selectedEntry ? (
                      getProgressImages()
                    ) : (
                      <div className={classes.NoImages}>Please Select An Entry</div>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </Layout>
      <Modal isModalOpen={isModalVisible} openModal={() => setIsModalVisible(false)} isDynamicWidth>
        <div className={classes.modalContainer}>
          <div className={classes.ModalClose}>
            <img
              onClick={() => setIsModalVisible(false)}
              src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGlkPSJDYXBhXzEiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDM4Ni42NjcgMzg2LjY2NyIgaGVpZ2h0PSI1MTIiIHZpZXdCb3g9IjAgMCAzODYuNjY3IDM4Ni42NjciIHdpZHRoPSI1MTIiIGNsYXNzPSJob3ZlcmVkLXBhdGhzIj48Zz48cGF0aCBkPSJtMzg2LjY2NyA0NS41NjQtNDUuNTY0LTQ1LjU2NC0xNDcuNzcgMTQ3Ljc2OS0xNDcuNzY5LTE0Ny43NjktNDUuNTY0IDQ1LjU2NCAxNDcuNzY5IDE0Ny43NjktMTQ3Ljc2OSAxNDcuNzcgNDUuNTY0IDQ1LjU2NCAxNDcuNzY5LTE0Ny43NjkgMTQ3Ljc2OSAxNDcuNzY5IDQ1LjU2NC00NS41NjQtMTQ3Ljc2OC0xNDcuNzd6IiBkYXRhLW9yaWdpbmFsPSIjMDAwMDAwIiBjbGFzcz0iaG92ZXJlZC1wYXRoIGFjdGl2ZS1wYXRoIiBzdHlsZT0iZmlsbDojRkZGRkZGIiBkYXRhLW9sZF9jb2xvcj0iIzAwMDAwMCI+PC9wYXRoPjwvZz4gPC9zdmc+"
              alt="close"
            />
          </div>
          <p className={classes.ModalLargeText}>Compare Progress Photos</p>
          <div className={classes.CompareContainer}>
            <div className={classes.LeftProgressContainer}>
              <div
                className={
                  leftSelectedEntry?.photos?.[selectedLeftImageCategory]?.image
                    ? classes.ImageContainerModal
                    : classes.NoImageContainerModal
                }
              >
                {leftSelectedEntry?.photos?.[selectedLeftImageCategory]?.image ? (
                  <img alt="left progress photo" src={leftSelectedEntry?.photos?.[selectedLeftImageCategory]?.image} />
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="36"
                    fill="#ffffff"
                    className="bi bi-image-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M.002 3a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-12a2 2 0 0 1-2-2V3zm1 9v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V9.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12zm5-6.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0z" />
                  </svg>
                )}
              </div>
              <h4 className={classes.whiteHeading}>Date</h4>
              {leftDate && (
                <select value={leftDate} onChange={(e) => setLeftDate(e.target.value)}>
                  {Object.keys(data || {}).map((key) => {
                    return (
                      <option key={key} value={dayjs(data[key]?.date).format('DD MMMM YYYY')}>
                        {dayjs(data[key]?.date).format('DD MMMM YYYY')}
                      </option>
                    );
                  })}
                </select>
              )}
              <h4 className={classes.whiteHeading}>Type Image/Pose</h4>
              {leftSelectedEntry && (
                <select
                  value={selectedLeftImageCategory}
                  onChange={(e) => setSelectedLeftImageCategory(e.target.value)}
                >
                  {Object.keys(leftSelectedEntry?.photos || {}).map((key) => {
                    const photoCategory = leftSelectedEntry?.photos[key];
                    return (
                      <option key={key} value={key}>
                        {photoCategory?.categoryName}
                      </option>
                    );
                  })}
                </select>
              )}
            </div>
            <div className={classes.RightProgressContainer}>
              <div
                className={
                  rightSelectedEntry?.photos?.[selectedRightImageCategory]?.image
                    ? classes.ImageContainerModal
                    : classes.NoImageContainerModal
                }
              >
                {rightSelectedEntry?.photos?.[selectedRightImageCategory]?.image ? (
                  <img
                    alt="right progress photo"
                    src={rightSelectedEntry?.photos?.[selectedRightImageCategory]?.image}
                  />
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="36"
                    fill="#ffffff"
                    className="bi bi-image-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M.002 3a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-12a2 2 0 0 1-2-2V3zm1 9v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V9.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12zm5-6.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0z" />
                  </svg>
                )}
              </div>
              <h4 className={classes.whiteHeading}>Date</h4>
              {rightDate && (
                <select value={rightDate} onChange={(e) => setRightDate(e.target.value)}>
                  {Object.keys(data || {}).map((key) => {
                    return (
                      <option key={key} value={dayjs(data[key]?.date).format('DD MMMM YYYY')}>
                        {dayjs(data[key]?.date).format('DD MMMM YYYY')}
                      </option>
                    );
                  })}
                </select>
              )}
              <h4 className={classes.whiteHeading}>Type Image/Pose</h4>
              {rightSelectedEntry && (
                <select
                  value={selectedRightImageCategory}
                  onChange={(e) => setSelectedRightImageCategory(e.target.value)}
                >
                  {Object.keys(rightSelectedEntry?.photos || {}).map((key) => {
                    const photoCategory = rightSelectedEntry?.photos[key];
                    return (
                      <option key={key} value={key}>
                        {photoCategory?.categoryName}
                      </option>
                    );
                  })}
                </select>
              )}
            </div>
          </div>
          <div className={classes.ModalButtons}>
            <button className={classes.ModalSave} type="button" onClick={() => setIsModalVisible(false)}>
              Close
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default connector(withAuth(ProgressPage));
