import React, { FC } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import { object, string, ref } from 'yup';
import Button from '../../inputs/Button';
import TextField from '../../inputs/TextField';
import { FormProps } from 'interfaces/utils';
import classes from './Register.module.css';

const initialValues = {
  fullName: '',
  email: '',
  password: '',
  confirmPassword: '',
  // referralCode: '',
};

type FormValues = {
  fullName: string;
  email: string;
  password: string;
  // referralCode: string;
};

type FormValuesExtended = FormValues & {
  confirmPassword: string;
};

const validationSchema = object().shape({
  fullName: string()
    .test(
      'fullname-test',
      'Fill first and last name',
      (value = '') => {
        const [firstName, lastName] = value
          ? value.trim().split(' ')
          : [''];
        return !!(firstName && lastName);
      },
    )
    .required('Full name is a required field'),
  email: string().email().required(),
  // referralCode: string().test(
  //   'referralCode-test',
  //   'Referral code is invalid',
  //   async (value: string | null | undefined) => {
  //     if (!value) {
  //       return true;
  //     }

  //     try {
  //       const coachProfile = await database
  //         .ref('coachprofiles')
  //         .orderByChild('referral_code')
  //         .equalTo(value)
  //         .limitToFirst(1)
  //         .once('value');

  //       if (!coachProfile.exists()) {
  //         throw new Error('coach profile does not exist');
  //       }

  //       return true;
  //     } catch (err) {
  //       return false;
  //     }
  //   },
  // ),
  password: string()
    .matches(/.{6,}$/, {
      message: 'Min 6 characters required',
      excludeEmptyString: false,
    }) // match 6 or more chars except newline
    .required(),
  confirmPassword: string()
    .when('password', {
      is: (val: string | any[]) => !!(val && val.length > 0),
      then: string().oneOf(
        [ref('password')],
        'Password does not match',
      ),
    })
    .required('confirm password'),
});

const FullNameIcon: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.333"
      height="18.333"
      viewBox="0 0 18.333 18.333"
    >
      <path
        id="Icon_awesome-user-alt"
        data-name="Icon awesome-user-alt"
        d="M9.167,10.312A5.156,5.156,0,1,0,4.01,5.156,5.157,5.157,0,0,0,9.167,10.312Zm4.583,1.146H11.777a6.233,6.233,0,0,1-5.221,0H4.583A4.583,4.583,0,0,0,0,16.041v.573a1.719,1.719,0,0,0,1.719,1.719h14.9a1.719,1.719,0,0,0,1.719-1.719v-.573A4.583,4.583,0,0,0,13.75,11.458Z"
        fill="#83868f"
      />
    </svg>
  );
};

const EmailIcon: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="16"
      viewBox="0 0 20 16"
    >
      <path
        id="Icon_material-email"
        data-name="Icon material-email"
        d="M21,6H5A2,2,0,0,0,3.01,8L3,20a2.006,2.006,0,0,0,2,2H21a2.006,2.006,0,0,0,2-2V8A2.006,2.006,0,0,0,21,6Zm0,4-8,5L5,10V8l8,5,8-5Z"
        transform="translate(-3 -6)"
        fill="#cacbd5"
        opacity="0.5"
      />
    </svg>
  );
};

const PasswordIcon: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.047"
      height="22.375"
      viewBox="0 0 17.047 22.375"
    >
      <path
        id="Icon_ionic-md-lock"
        data-name="Icon ionic-md-lock"
        d="M21.666,10.693H20.6V8.562a5.327,5.327,0,1,0-10.655,0v2.131H8.881A2.137,2.137,0,0,0,6.75,12.824V23.478a2.137,2.137,0,0,0,2.131,2.131H21.666A2.137,2.137,0,0,0,23.8,23.478V12.824A2.137,2.137,0,0,0,21.666,10.693Zm-6.393,9.7A2.131,2.131,0,1,1,17.4,18.257,2.137,2.137,0,0,1,15.274,20.388Zm3.3-9.7H11.971V8.562a3.3,3.3,0,1,1,6.606,0Z"
        transform="translate(-6.75 -3.234)"
        fill="#cacbd5"
        opacity="0.5"
      />
    </svg>
  );
};

// const ReferralIcon: FC = () => {
//   return (
//     <svg
//       fill="none"
//       strokeWidth="3"
//       viewBox="0 0 24 24"
//       stroke="currentColor"
//       strokeLinecap="round"
//       strokeLinejoin="round"
//       className={classes.referralIcon}
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <circle cx="18" cy="5" r="3" />
//       <circle cx="6" cy="12" r="3" />
//       <circle cx="18" cy="19" r="3" />
//       <path d="M8.59 13.51l6.83 3.98M15.41 6.51l-6.82 3.98" />
//     </svg>
//   );
// };

const Register: FC<FormProps<FormValues> & { loading: boolean }> = ({
  loading,
  handleSubmit,
}) => {
  const onSubmit = async (
    values: FormValuesExtended,
    actions: FormikHelpers<FormValuesExtended>,
  ) => {
    await handleSubmit(values);
    actions.setSubmitting(false);
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ errors, touched, isSubmitting }) => (
        <Form className={classes.wrapper}>
          <div className={classes.inputWrapper}>
            <TextField
              name="fullName"
              errors={errors}
              touched={touched}
              icon={<FullNameIcon />}
              placeholder="Full Name"
            />
          </div>

          <div className={classes.inputWrapper}>
            <TextField
              name="email"
              errors={errors}
              touched={touched}
              placeholder="Email"
              icon={<EmailIcon />}
            />
          </div>

          {/* <div className={classes.inputWrapper}>
            <TextField
              name="referralCode"
              errors={errors}
              touched={touched}
              placeholder="Referral Code"
              icon={<ReferralIcon />}
            />
          </div> */}

          <div className={classes.inputWrapper}>
            <TextField
              type="password"
              errors={errors}
              name="password"
              touched={touched}
              placeholder="Password"
              icon={<PasswordIcon />}
            />
          </div>

          <TextField
            type="password"
            errors={errors}
            touched={touched}
            name="confirmPassword"
            icon={<PasswordIcon />}
            placeholder="Confirm Password"
          />

          <div className={classes.btnWrapper}>
            <Button
              type="submit"
              loading={loading || isSubmitting}
              disabled={loading || isSubmitting}
            >
              SIGN UP
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Register;
