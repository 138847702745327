import React, { FC, useRef, useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import classes from './Community.module.css';
import { COMMUNITY } from 'utils/routes';
import Layout from '../Layout';
import withAuth from 'utils/withAuth';
import Message from '../../components/Message/Message';
import { CommunityForm, ChannelsSidebar } from 'components';
import { eligibleCoach } from 'utils/helpers';
import { Channel } from 'interfaces/db';
// REDUX
import { RootState } from 'store';
import { connect, ConnectedProps } from 'react-redux';

// FIREBASE
import firebase from 'firebase/app';
import { firestore, storage } from 'utils/firebase';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { UploadTaskSnapshot, UploadTask, FullMetadata } from '@firebase/storage-types';

const mapStateToProps = ({ clients, auth }: RootState) => {
  const { userId, coachProfile, token, loading } = auth;
  return {
    token: token,
    clients: clients.clients,
    userId: userId,
    coachProfile: coachProfile,
    coachKey: Object.keys(coachProfile || {})[0],
    loading: loading,
  };
};

type FormValues = {
  text: string;
  file: any;
  channel: string;
};

const connector = connect(mapStateToProps, null);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Community: FC<PropsFromRedux> = ({
  //   token,
  userId,
  coachProfile,
  coachKey,
  //   clients,
}) => {
  const [updatedChannels, setUpdatedChannels] = useState(false);
  const isMountedRef = useRef(false);
  const [selectedChannel, setSelectedChannel] = useState<string>('Chat-room');
  const [channels, setChannels] = useState<any[]>([]);
  const dummy = useRef<null | HTMLDivElement>(null);
  const coachesRef = firestore?.collection('coaches');
  const coachDocument = coachesRef?.doc(userId || 'test');
  const channelsRef = coachDocument?.collection('channels');

  useEffect(() => {
    isMountedRef.current = true;
    channelsRef.get().then((snapshot) => {
      if (isMountedRef.current) {
        const docs = snapshot?.docs?.map((doc) => {
          return {
            id: doc.id,
            data: doc.data(),
          } as Channel;
        });
        setChannels(docs);
      }
    });

    return () => {
      isMountedRef.current = false;
    };
  }, [updatedChannels]);

  const channelDocument = channelsRef?.doc(selectedChannel || 'Chat-room');
  const chatRoomRef = channelDocument?.collection('messages');
  const query = chatRoomRef?.orderBy('createdAt');

  const [messages] = useCollectionData(query, {
    idField: 'id',
  });

  const [progress, setProgress] = useState(0);
  // const [marginBottom, setMarginBottom] = useState<number>(80);

  useEffect(() => {
    if (dummy && dummy?.current) {
      // eslint-disable-next-line no-unused-expressions
      const x = dummy?.current?.scrollIntoView({
        behavior: 'smooth',
      });

      console.log(x, 'dummy logging');
    }
  }, [messages]);

  useEffect(() => {
    isMountedRef.current = true;
    if (eligibleCoach(userId)) {
      const customersChannelDocument = channelsRef?.doc('customer-channel');
      customersChannelDocument.get().then((snapshot) => {
        if (!snapshot.exists && isMountedRef.current) {
          setUpdatedChannels(true);
          customersChannelDocument.set({
            channelTitle: 'All Subscribers',
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          });
        }
      });
    }
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const sendMessage = async (
    payload: FormValues,
    downloadURL?: string,
    metadata?: FullMetadata,
  ) => {
    const profile: any = coachProfile?.[coachKey];
    const trimmedMessage = payload.text.trim();
    // if (trimmedMessage) {
    // Add new message in Firestore
    await chatRoomRef.add({
      text: trimmedMessage,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      userId,
      displayName: profile.fullname,
      media: downloadURL
        ? {
            url: downloadURL || null,
            metadata: metadata
              ? {
                  contentType: metadata?.contentType,
                  timeCreated: metadata?.timeCreated,
                  updated: metadata?.updated,
                  size: metadata?.size,
                }
              : null,
          }
        : null,
    });
    // }
  };

  const uploadProgress: (a: UploadTaskSnapshot) => any = (snapshot) => {
    setProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
  };

  const uploadError: (a: Error) => any = (error) => {
    console.log(error);
  };

  const uploadSuccess = async ({
    uploadTask,
    payload,
  }: {
    uploadTask: UploadTask;
    payload: FormValues;
  }) => {
    await uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
      uploadTask.snapshot.ref.getMetadata().then((metadata) => {
        sendMessage(payload, downloadURL, metadata);
      });
    });
  };

  const handleSubmit = (payload: FormValues): Promise<any> => {
    return new Promise((resolve) => {
      if (payload.file) {
        const storageRef = storage.ref(`coaches/${userId}/channels/${payload.channel}/${uuidv4()}`);
        const uploadTask: any = storageRef.put(payload.file[0]);

        uploadTask.on('state_changed', uploadProgress, uploadError, async () => {
          await uploadSuccess({
            uploadTask,
            payload,
          });
          resolve(null);
        });
      } else {
        sendMessage(payload).then(() => {
          resolve(null);
        });
      }
    });
  };

  const chatRoom: Channel = {
    id: 'Chat-room',
    data: { channelTitle: 'All Clients' },
  };

  return (
    <Layout loading={false} heading={COMMUNITY.TITLE}>
      <div className={classes.CommunityContainer}>
        <div className={classes.Sidebar}>
          <ChannelsSidebar
            channels={[chatRoom, ...channels]}
            setSelectedChannel={setSelectedChannel}
            selectedChannel={selectedChannel}
          />
        </div>
        <div className={classes.ChatContainer}>
          <ul className={classes.MessageUl}>
            {messages &&
              messages?.map((message) => (
                <li key={message.id} className={classes.MessageLi}>
                  <Message message={message} currentUserId={userId} />
                </li>
              ))}
            <span ref={dummy}></span>
          </ul>
          <div className={classes.Form}>
            <CommunityForm
              handleSubmit={handleSubmit}
              progress={progress}
              // setMarginBottom={setMarginBottom}
              setProgress={setProgress}
              selectedChannel={selectedChannel}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default connector(withAuth(Community));
