import axios from 'axios';

import {
  FETCH_PLAN_START,
  FETCH_PLAN_SUCCESS,
  FETCH_PLAN_FAIL,
} from './actionTypes';
import { AppThunk } from '../index';
import { Plans } from 'interfaces/db';
import { config } from 'utils/firebase';
import { FirebaseObject } from 'interfaces/utils';
import { PlansActionTypes } from 'interfaces/actions/plans';

const fetchPlanStart = (): PlansActionTypes => {
  return {
    type: FETCH_PLAN_START,
  };
};

const fetchPlanSuccess = (
  plans: FirebaseObject<Plans>,
): PlansActionTypes => {
  return {
    type: FETCH_PLAN_SUCCESS,
    plans,
  };
};

const fetchPlanFail = (): PlansActionTypes => {
  return {
    type: FETCH_PLAN_FAIL,
  };
};

export const fetchPlans = (token: string): AppThunk => {
  return (dispatch) => {
    dispatch(fetchPlanStart());
    const queryParams = '?auth=' + token;

    axios
      .get(`${config.databaseURL}/plans.json${queryParams}`)
      .then((response) => {
        dispatch(fetchPlanSuccess(response.data));
      })
      .catch(() => {
        dispatch(fetchPlanFail());
      });
  };
};
