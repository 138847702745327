import React, { FC, useEffect, useState, useMemo } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Prompt, useHistory, useLocation } from 'react-router-dom';
import { Location } from 'history';
import cx from 'classnames';
import axios, { AxiosResponse } from 'axios';
import queryString from 'query-string';
import dayjs from 'dayjs';

import Layout from '../Layout';
import { RootState } from 'store';
import withAuth from 'utils/withAuth';
import classes from './Billing.module.css';
import Modal from 'components/UI/Modal/Modal';
import { BILLING, LOGIN } from 'utils/routes';
import { useLocalStorage } from 'utils/hooks';
import ChangePlanModal from './ChangePlanModal';
import ConfirmPlanModal from './ConfirmPlanModal';
import { LS_PRICING_INFO } from 'utils/constants';
import { CoachProfile, Plans } from 'interfaces/db';
import { removeClient } from 'store/actions/clients';
import { BASE_URL } from 'utils/helpers';
import { fetchCoachProfileSuccess, saveCoachProfile } from 'store/actions/auth';
import { FirebaseObject, LocalStoragePricing } from 'interfaces/utils';
import { VerifyResponse } from 'interfaces/api';
import SubscriptionCancelModal from './SubscriptionCancelModal';

const mapStateToProps = ({ clients, plans, auth }: RootState) => {
  const { userId, token, coachProfile } = auth;
  return {
    userId,
    token,
    clients: clients,
    dbPlans: plans.plans,
    loading: auth.loading,
    coachProfile,
    coachKey: Object.keys(coachProfile || {})[0],
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    deleteClient: (clientKey: string, token: string) => dispatch(removeClient(clientKey, token)),
    onSaveCoachProfile: (coachProfile: CoachProfile, coachProfileKey: string, token: string) =>
      dispatch(saveCoachProfile(coachProfile, coachProfileKey, token)),
    updateProfile: (profile: FirebaseObject<CoachProfile>) => dispatch(fetchCoachProfileSuccess(profile, false)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type PlansWActive = Plans & { isActive: boolean };

const Settings: FC<PropsFromRedux> = ({ token, dbPlans, loading, coachKey, coachProfile, onSaveCoachProfile }) => {
  const [selectedPricing, setSelectedPricing] = useLocalStorage<LocalStoragePricing | null>(LS_PRICING_INFO, null);
  const [plans, setPlans] = useState<PlansWActive[]>([]);
  const [isChangePlanModalShowing, setIsChangePlanModalShowing] = useState(false);
  const [isPlanConfirmModalShowing, setIsPlanConfirmModalShowing] = useState(false);
  const [isSubscriptionCancelModalShowing, setIsSubscriptionCancelModalShowing] = useState(false);
  const [selectedPlanIndex, setSelectedPlanIndex] = useState<number>(0);
  const [isSubscriptionActive, setIsSubscriptionActive] = useState(true);
  const [coachPlan, setCoachPlan] = useState<
    Partial<CoachProfile> &
      Partial<VerifyResponse> & {
        checkoutSessionId?: string;
      }
  >({
    email: '',
    planId: 1,
    userId: '',
    fullname: '',
    clientCount: 0,
    nextBillingDate: 0,
  });
  const [paymentStatus, setPaymentStatus] = useState<string>('');
  const [isPlanLoading, setIsPlanLoading] = useState<boolean>(false);
  const [isCancelLoading, setIsCancelLoading] = useState<boolean>(false);

  const { search } = useLocation();
  const history = useHistory();
  const { email = '', planId = 1, clientCount = 0, nextBillingDate = 0, subscriptionId = '' } = coachPlan;

  const currentPlan = plans.filter((el) => el.isActive)[0] || {};
  const { maxClients = 0, name: planName = '' } = currentPlan;
  const progress = ((clientCount / maxClients) * 100).toFixed(2);

  useEffect(() => {
    setIsPlanLoading(true);
    try {
      const parsed = queryString.parse(search);
      const fetchSubscription = async () => {
        if (parsed.c_id && parsed.p_id) {
          await axios({
            method: 'POST',
            url: `${BASE_URL}/v1-https-funnels-verifySession`,
            data: {
              sessionId: parsed.c_id,
            },
          })
            .then((response: AxiosResponse) => {
              const data: VerifyResponse = response.data?.data || {};

              if (data.status === 'complete' && data.paymentStatus === 'paid' && token) {
                setPaymentStatus('SUCCESS');
                const today = dayjs();
                const nextBillingDate = today.add(1, 'M').unix();

                setCoachPlan({
                  ...data,
                  currency: data.currency === 'usd' ? 'USD' : 'ZAR',
                  checkoutSessionId: parsed.c_id + '',
                  planId: +(parsed.p_id + ''),
                  nextBillingDate: nextBillingDate,
                });
                updateCoachProfile({
                  planId: parsed.p_id ? +parsed.p_id : 0,
                  isPaidSubscriptionActive: true,
                  nextBillingDate: nextBillingDate,
                  subscriptionId: data?.subscriptionId,
                  paymentLink: data?.paymentLink,
                  stripeCustomerId: data?.stripeCustomerId,
                });
              }

              setIsPlanLoading(false);
            })
            .catch((error) => {
              setIsPlanLoading(false);
              console.warn(error);
              setPaymentStatus('FAILED');
            })
            .finally(() => {
              // clear params from url
              history.replace('billing');
            });
        }
      };
      if (parsed?.c_id && parsed?.p_id) {
        setIsPlanConfirmModalShowing(true);
        fetchSubscription().catch((err) => {
          setIsPlanLoading(false);
          console.warn(err);
        });
      }
    } catch (e) {
      setIsPlanLoading(false);
      setPaymentStatus('FAILED');
      console.warn(e);
    }
  }, [search, token]);

  useEffect(() => {
    if (coachProfile && coachKey) {
      const {
        email = '',
        planId = 1,
        userId = '',
        fullname = '',
        clientCount = 0,
        nextBillingDate = 0,
        isPaidSubscriptionActive = false,
        subscriptionId = '',
      } = coachProfile[coachKey];

      setCoachPlan({
        email,
        planId,
        userId,
        fullname,
        clientCount,
        nextBillingDate,
        isPaidSubscriptionActive,
        subscriptionId,
      });

      if ((!isPaidSubscriptionActive && planId !== 1) || clientCount > maxClients) {
        setIsSubscriptionActive(false);
      } else {
        setIsSubscriptionActive(true);
      }
    }
  }, [coachProfile, plans, maxClients]);

  useEffect(() => {
    if (dbPlans) {
      const plan = Object.values(dbPlans).map((el) => ({
        ...el,
        isActive: el.id === planId,
      }));

      setPlans(plan);
    }
  }, [planId, dbPlans]);

  const availablePlans = useMemo(() => {
    return plans.filter((el) => !el.isActive);
  }, [plans]);

  useEffect(() => {
    if (!selectedPricing) return;
    const { planId } = selectedPricing;
    const index = availablePlans.findIndex((el) => el.id === planId);

    setSelectedPlanIndex(index === -1 ? 0 : index);
    setIsChangePlanModalShowing(true);
  }, [selectedPricing, availablePlans]);

  const updateCoachProfile = async (updateObj: Partial<CoachProfile>) => {
    if (coachProfile && coachKey && token && updateObj) {
      const updatedCoachProfile = {
        ...coachProfile[coachKey],
        ...updateObj,
      } as CoachProfile;

      await onSaveCoachProfile(updatedCoachProfile, coachKey, token);
    }
  };

  const hideChangePlanModal: () => void = () => {
    setIsChangePlanModalShowing(false);
    setSelectedPricing(null);
  };

  const hidePlanConfirmModal: () => void = () => {
    setIsPlanConfirmModalShowing(false);
  };

  const handleNavPrompt = ({ pathname }: Location<unknown>) => {
    // allow nav if user is trying to logout
    if (pathname === LOGIN.URL) {
      return true;
    }

    // block nav on all routes
    return false;
  };

  const formatNextBillingDate = () => {
    return nextBillingDate
      ? dayjs(nextBillingDate * 1000).format('dddd, MMMM D YYYY')
      : 'Issue with calculating next billing date. One month from date of payment.';
  };

  const handleSubscriptionCancel = async () => {
    setIsCancelLoading(true);
    axios({
      method: 'POST',
      url: `${BASE_URL}/v1-https-funnels-cancelSubscription`,
      data: {
        subscriptionId: subscriptionId,
      },
    })
      .then(() => {
        updateCoachProfile({
          isPaidSubscriptionActive: true,
          planId: 1,
          subscriptionId: null,
          paymentLink: null,
        });
        setIsSubscriptionCancelModalShowing(false);
        setIsCancelLoading(false);
      })
      .catch((e) => {
        setIsCancelLoading(false);
        console.warn(e);
      });
  };

  return (
    <Layout loading={loading} heading={BILLING.TITLE}>
      <Prompt when={!isSubscriptionActive} message={handleNavPrompt} />

      <div className={classes.container}>
        <h5 className={classes.planTitle}>
          Current Plan - <span className={classes.highlight}>{planName}</span>
        </h5>

        <div className={classes.innerContainer}>
          <div className={classes.contentSection}>
            <div className={classes.clientCountWrapper}>
              <p className={classes.clientCount}>{maxClients} Clients - Monthly</p>
              <p className={classes.clientCount}>
                {clientCount} of {maxClients} Clients
              </p>
            </div>

            <div className={classes.progressWrapper}>
              <div className={classes.progressBar} style={{ width: `${progress}%` }} />
            </div>
          </div>

          {currentPlan.id !== 1 && (
            <div className={classes.contentSection}>
              <p className={classes.title}>Next billing date</p>
              <h6 className={classes.value}>{formatNextBillingDate()}</h6>
            </div>
          )}
        </div>

        <div className={classes.footer}>
          <button
            type="button"
            onClick={() => setIsChangePlanModalShowing(true)}
            className={cx(classes.button, classes.changeBtn)}
          >
            Change plan
          </button>
          {currentPlan.id !== 1 && (
            <button
              className={cx(classes.button, classes.secondaryButton)}
              onClick={() => setIsSubscriptionCancelModalShowing(true)}
            >
              Downgrade to Free Plan
            </button>
          )}
        </div>
      </div>

      <Modal isModalOpen={isChangePlanModalShowing} openModal={hideChangePlanModal}>
        <ChangePlanModal
          plans={plans.filter((plan) => plan.id !== currentPlan.id && plan.id !== 1)}
          email={email}
          isYearly={false}
          selectedPlanIndex={selectedPlanIndex}
          hideChangePlanModal={hideChangePlanModal}
          clientCount={clientCount}
        />
      </Modal>
      <Modal
        isModalOpen={isPlanConfirmModalShowing}
        openModal={hidePlanConfirmModal}
        disableOutsideClicker={isPlanLoading}
      >
        <ConfirmPlanModal
          paymentStatus={paymentStatus}
          loading={isPlanLoading}
          hidePlanConfirmModal={hidePlanConfirmModal}
          nextBillingDate={
            nextBillingDate
              ? dayjs(nextBillingDate * 1000).format('dddd, MMMM D YYYY')
              : 'Issue with calculating next billing date. One month from date of payment.'
          }
        />
      </Modal>
      <Modal
        isModalOpen={isSubscriptionCancelModalShowing}
        openModal={() => setIsSubscriptionCancelModalShowing(false)}
      >
        <SubscriptionCancelModal
          loading={isCancelLoading}
          clientCount={clientCount}
          hideSubscriptionCancelModal={() => setIsSubscriptionCancelModalShowing(false)}
          handleSubscriptionCancel={handleSubscriptionCancel}
        />
      </Modal>
    </Layout>
  );
};

export default connector(withAuth(Settings));
