import axios from 'axios';

import {
  FETCH_EXERCISES_START,
  FETCH_EXERCISES_SUCCESS,
  FETCH_EXERCISES_FAIL,
} from './actionTypes';
import { AppThunk } from '../index';
import { Exercise } from 'interfaces/db';
import { config } from 'utils/firebase';
import { FirebaseObject } from 'interfaces/utils';
import { ExerciseLibraryActionTypes } from 'interfaces/actions/exerciseLibrary';

const fetchExercisesStart = (): ExerciseLibraryActionTypes => {
  return {
    type: FETCH_EXERCISES_START,
  };
};

const fetchExercisesSuccess = (
  exercises: FirebaseObject<Exercise>,
): ExerciseLibraryActionTypes => {
  return {
    type: FETCH_EXERCISES_SUCCESS,
    exercises,
  };
};

const fetchExercisesFail = (): ExerciseLibraryActionTypes => {
  return {
    type: FETCH_EXERCISES_FAIL,
  };
};

export const fetchExercises = (
  token: string,
  coachKey: string,
): AppThunk => {
  return (dispatch) => {
    dispatch(fetchExercisesStart());
    const queryParams = '?auth=' + token;

    axios
      .get(
        `${config.databaseURL}/exerciseLibrary/${coachKey}.json${queryParams}`,
      )
      .then((response) => {
        dispatch(fetchExercisesSuccess(response.data));
      })
      .catch(() => {
        dispatch(fetchExercisesFail());
      });
  };
};

export const saveExercise = (
  token: string,
  coachKey: string,
  exercise: Exercise,
): AppThunk => {
  return (dispatch) => {
    const queryParams = '?auth=' + token;

    axios
      .post(
        `${config.databaseURL}/exerciseLibrary/${coachKey}.json${queryParams}`,
        exercise,
      )
      .then(() => {
        dispatch(fetchExercises(token, coachKey));
      })
      .catch((e) => console.log(e.toString()));
  };
};

export const updateExercise = (
  token: string,
  coachKey: string,
  exerciseKey: string,
  exercise: Exercise,
): AppThunk => {
  return (dispatch) => {
    const queryParams = '?auth=' + token;

    axios
      .patch(
        `${config.databaseURL}/exerciseLibrary/${coachKey}/${exerciseKey}.json${queryParams}`,
        exercise,
      )
      .then(() => {
        dispatch(fetchExercises(token, coachKey));
      })
      .catch((e) => console.log(e.toString()));
  };
};

export const deleteExercise = (
  token: string,
  coachKey: string,
  exerciseKey: string,
): AppThunk => {
  return (dispatch) => {
    const queryParams = '?auth=' + token;

    axios
      .delete(
        `${config.databaseURL}/exerciseLibrary/${coachKey}/${exerciseKey}.json${queryParams}`,
      )
      .then(() => {
        dispatch(fetchExercises(token, coachKey));
      })
      .catch((e) => console.log(e.toString()));
  };
};
