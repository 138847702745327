import { DocumentsActionTypes } from 'interfaces/actions/documents';
import { FirebaseObject } from 'interfaces/utils';
import { Documents } from 'interfaces/db';
import { DocumentsState } from 'interfaces/state';
import { updateObject } from '../utility';
import {
  FETCH_DOCUMENTS_START,
  FETCH_DOCUMENTS_SUCCESS,
  FETCH_DOCUMENTS_FAIL,
  ADD_DOCUMENT_START,
  ADD_DOCUMENT_SUCCESS,
  ADD_DOCUMENT_FAIL,
  FETCH_DOCUMENT_START,
  FETCH_DOCUMENT_SUCCESS,
  FETCH_DOCUMENT_FAIL,
  UPDATE_DOCUMENT_START,
  UPDATE_DOCUMENT_SUCCESS,
  UPDATE_DOCUMENT_FAIL,
} from '../actions/actionTypes';

const initialState: DocumentsState = {
  documents: null,
  loading: false,
  removed: false,
};

const fetchDocumentsStart = (
  state: DocumentsState,
): DocumentsState => {
  return updateObject(state, { loading: true });
};

const fetchDocumentsSuccess = (
  state: DocumentsState,
  action: { documents: FirebaseObject<Documents> },
): DocumentsState => {
  return updateObject(state, {
    documents: action.documents,
    loading: false,
  });
};

const fetchDocumentsFail = (
  state: DocumentsState,
): DocumentsState => {
  return updateObject(state, { loading: false });
};

const addDocumentStart = (state: DocumentsState): DocumentsState => {
  return updateObject(state, { loading: true });
};

const addDocumentSuccess = (
  state: DocumentsState,
  action: { document: Documents; documentId: string },
) => {
  const documentsCopy = Object.assign({}, state.documents);
  documentsCopy[action.documentId] = action.document;
  return updateObject(state, {
    loading: false,
    documents: documentsCopy,
  });
};

const addDocumentFail = (state: DocumentsState): DocumentsState => {
  return updateObject(state, { loading: false });
};

const fetchDocumentStart = (
  state: DocumentsState,
): DocumentsState => {
  return updateObject(state, { loading: true });
};

const fetchDocumentSuccess = (
  state: DocumentsState,
  action: {
    document: FirebaseObject<Documents>;
    documentKey: string;
  },
): DocumentsState => {
  const documentsCopy: any = { ...state.documents };
  documentsCopy[action.documentKey] = action.document;

  return updateObject(state, {
    documents: documentsCopy,
    loading: false,
  });
};

const fetchDocumentFail = (state: DocumentsState): DocumentsState => {
  return updateObject(state, { loading: false });
};

const updateDocumentStart = (
  state: DocumentsState,
): DocumentsState => {
  return updateObject(state, { loading: true });
};

const updateDocumentSuccess = (
  state: DocumentsState,
  action: {
    document: Documents;
    documentId: string;
  },
): DocumentsState => {
  const documentsCopy: any = { ...state.documents };
  documentsCopy[action.documentId] = action.document;

  return updateObject(state, {
    documents: documentsCopy,
    loading: false,
  });
};

const updateDocumentFail = (
  state: DocumentsState,
): DocumentsState => {
  return updateObject(state, { loading: false });
};

const reducer = (
  state = initialState,
  action: DocumentsActionTypes,
): DocumentsState => {
  switch (action.type) {
    case FETCH_DOCUMENTS_START:
      return fetchDocumentsStart(state);
    case FETCH_DOCUMENTS_SUCCESS:
      return fetchDocumentsSuccess(state, action);
    case FETCH_DOCUMENTS_FAIL:
      return fetchDocumentsFail(state);
    case ADD_DOCUMENT_START:
      return addDocumentStart(state);
    case ADD_DOCUMENT_SUCCESS:
      return addDocumentSuccess(state, action);
    case ADD_DOCUMENT_FAIL:
      return addDocumentFail(state);
    case FETCH_DOCUMENT_START:
      return fetchDocumentStart(state);
    case FETCH_DOCUMENT_SUCCESS:
      return fetchDocumentSuccess(state, action);
    case FETCH_DOCUMENT_FAIL:
      return fetchDocumentFail(state);
    case UPDATE_DOCUMENT_START:
      return updateDocumentStart(state);
    case UPDATE_DOCUMENT_SUCCESS:
      return updateDocumentSuccess(state, action);
    case UPDATE_DOCUMENT_FAIL:
      return updateDocumentFail(state);
    default:
      return state;
  }
};
export default reducer;
