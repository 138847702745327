import React, { FC, ChangeEvent } from 'react';

import QuickEditTable from 'components/UI/QuickEditTable';
import { SetKeys, Day as DayType, Exercise as ExerciseType } from 'interfaces/db';
import classes from '../../../containers/ClientTraining/Week/Day/Day.module.css';
import analyticsClasses from '../../ExerciseBlock/ExerciseBlock.module.css';
import ExerciseHistoryBox from 'components/ExerciseHistoryBox/ExerciseHistoryBox';
import { IndexedExercise } from 'interfaces/utils';

interface Props {
  day: DayType;
  dayNumber: number;
  weekNumber: number;
  saveProgram: (workoutId?: string) => void;
  setRestDay: (weekIndex: number, dayIndex: number) => void;
  removeRestDay: (weekIndex: number, dayIndex: number) => void;
  addWorkout: () => Promise<void>;
  copyWorkoutHandler: () => void;
  pasteWorkoutHandler: () => void;
  goToWorkoutBuilder: () => Promise<void>;
  addExerciseNotes: (exerciseNotes: string, group: number, index: number) => void;
  addWorkoutNotes: (workoutNotes: string, dayIndex: number, weekIndex: number) => void;
  addSetHandler: (group: number, index: number) => void;
  removeSetHandler: (group: number, index: number) => void;
  setsChangeHandler: (key: SetKeys, val: string, setIndex: number, group: number, index: number) => void;
  autoFillSetsHandler: (key: SetKeys, val: string, setIndex: number, group: number, index: number) => void;
  setShowAutoFill: (setIdentifier: string) => void;
  showAutoFill: string;
  previousDay?: DayType; // ? Previous week day
  openAnalytics?: (analyticsData: IndexedExercise) => void;
  closeAnalytics?: (analyticsData: IndexedExercise) => void;
  userId?: string | null;
  analyticsExerciseList?: IndexedExercise[];
}

interface QuickEditProps {
  setIsQuickEdit: (val: boolean) => void;
}

const QuickEdit: FC<
  Pick<
    Props,
    | 'day'
    | 'dayNumber'
    | 'weekNumber'
    | 'saveProgram'
    | 'addExerciseNotes'
    | 'addWorkoutNotes'
    | 'addSetHandler'
    | 'removeSetHandler'
    | 'setsChangeHandler'
    | 'autoFillSetsHandler'
    | 'setShowAutoFill'
    | 'showAutoFill'
    | 'previousDay'
    | 'openAnalytics'
    | 'closeAnalytics'
    | 'analyticsExerciseList'
    | 'userId'
  > &
    QuickEditProps
> = ({
  day,
  dayNumber,
  weekNumber,
  saveProgram,
  setIsQuickEdit,
  addExerciseNotes,
  addWorkoutNotes,
  addSetHandler,
  removeSetHandler,
  setsChangeHandler,
  autoFillSetsHandler,
  setShowAutoFill,
  showAutoFill,
  previousDay,
  openAnalytics,
  closeAnalytics,
  userId,
  analyticsExerciseList,
}) => {
  const { exercises = [] } = day?.workout;
  const finished = previousDay?.workout?.finished || false;

  const exerciseNotesOnChangeHandler = (value: string, group: number, index: number) => {
    addExerciseNotes(value, group, index);
  };

  const isExerciseHistoryVisible = (index: number, group: number) => {
    return analyticsExerciseList?.some(
      (indexedExercise) => indexedExercise.index === index && indexedExercise.group === group,
    );
  };

  const analyticsButton = (exercise: ExerciseType, index: number, group: number) => {
    if (!isExerciseHistoryVisible(index, group) && userId) {
      return (
        <button
          className={analyticsClasses.AnalyticsButton}
          onClick={() =>
            openAnalytics?.({
              exercise,
              group,
              index,
            })
          }
        >
          <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#707070">
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14zM7 10h2v7H7zm4-3h2v10h-2zm4 6h2v4h-2z" />
          </svg>
          <p className={classes.ExerciseHistoryText}>Open Exercise History</p>
        </button>
      );
    } else return null;
  };

  const getGroup = (group: any, i: number, char: string) => {
    return group.map((exercise: ExerciseType, j: number) => {
      const { exerciseNotes } = exercise;
      const typeDay = exercise?.type;
      let equivalentExerciseBlock = null;
      if (previousDay) {
        const exercisesOfPreviousDay = previousDay?.workout?.exercises || [];

        exercisesOfPreviousDay.every((groupPrev: any, i: number) => {
          groupPrev.every((exercisePrev: ExerciseType, j: number) => {
            const typePrev = exercisePrev?.type;
            const exerciseNotesPrev = exercisePrev?.exerciseNotes;
            if (typePrev !== typeDay) {
              return true; // continue to next iteration
            }
            if (typePrev === typeDay && finished && weekNumber > 0) {
              equivalentExerciseBlock = (
                <div key={`${i}${j}`} id={`${i}${j}prev`} className={classes.exercise}>
                  {!previousDay && (
                    <div className={classes.exerciseInner}>
                      <div className={classes.exerciseGroup}>{char + (j + 1)}</div>

                      {groupPrev.length > 1 && j !== groupPrev.length - 1 ? (
                        <svg
                          width="50"
                          height="100%"
                          style={{
                            overflow: 'inherit',
                            transition: `height 0.6s cubic-bezier(0.85, 0, 0.15, 1)`,
                          }}
                          // id={`line${i}${j}`}
                        >
                          <line x1="25" y1="0" x2="25" y2="110%" stroke="#e94811" strokeWidth="3px" />
                        </svg>
                      ) : null}
                    </div>
                  )}

                  <div id={!previousDay ? typePrev : ''} style={{ flex: 1 }}>
                    {!previousDay ? (
                      <>
                        <div className={classes.type}>{typePrev}</div>
                        <div className={classes.notesWrapper}>
                          <textarea
                            placeholder="Exercise Notes"
                            className={classes.notesInnerWrapper}
                            value={exerciseNotesPrev?.coach || ''}
                            onChange={({ target }: ChangeEvent<HTMLTextAreaElement>) =>
                              exerciseNotesOnChangeHandler(target.value, i, j)
                            }
                          />
                        </div>
                      </>
                    ) : (
                      <div className={classes.AthleteFeedbackContainer}>
                        <div className={classes.AthleteFeedbackWrapper}>
                          <div className={classes.type}>{typePrev}</div>
                          <div className={classes.ExerciseFeedbackText}>Exercise feedback:</div>
                          <p>{exerciseNotesPrev?.athlete || 'No feedback notes.'}</p>
                        </div>

                        {analyticsButton(exercise, i, j)}
                      </div>
                    )}

                    <div className={classes.tableWrapper}>
                      <QuickEditTable
                        exercise={exercisePrev}
                        setsChangeHandler={(key: SetKeys, val: string, setIndex: number) =>
                          setsChangeHandler(key, val, setIndex, i, j)
                        }
                        autoFillSetsHandler={(key: SetKeys, val: string, setIndex: number) =>
                          autoFillSetsHandler(key, val, setIndex, i, j)
                        }
                        addSetHandler={() => addSetHandler(i, j)}
                        removeSetHandler={() => removeSetHandler(i, j)}
                        setShowAutoFill={(setIdentifier: string) => setShowAutoFill(setIdentifier)}
                        showAutoFill={showAutoFill}
                        previousDay={previousDay}
                        day={day}
                      />
                    </div>
                  </div>
                </div>
              );
            }
            return true;
          });
          return true;
        });
      }

      const exerciseBlock = (
        <div key={`${i}${j}day`} className={classes.exercise}>
          <div className={classes.exerciseInner}>
            <div className={classes.exerciseGroup}>{char + (j + 1)}</div>

            {group.length > 1 && j !== group.length - 1 ? (
              <svg
                width="50"
                height="100%"
                style={{
                  overflow: 'inherit',
                  transition: `height 0.6s cubic-bezier(0.85, 0, 0.15, 1)`,
                }}
                // id={`line${i}${j}`}
              >
                <line x1="25" y1="0" x2="25" y2="110%" stroke="#e94811" strokeWidth="3px" />
              </svg>
            ) : null}
          </div>

          <div style={{ flex: 1 }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div className={classes.type}>{typeDay}</div>
              {!previousDay?.workout?.finished || previousDay?.workout?.exercises?.[i]?.[j]?.type !== typeDay
                ? analyticsButton(exercise, i, j)
                : null}
            </div>
            <div className={classes.notesWrapper}>
              <textarea
                placeholder="Exercise Notes"
                className={classes.notesInnerWrapper}
                value={exerciseNotes?.coach || ''}
                onChange={({ target }: ChangeEvent<HTMLTextAreaElement>) =>
                  exerciseNotesOnChangeHandler(target.value, i, j)
                }
              />
            </div>

            <div className={classes.tableWrapper}>
              <QuickEditTable
                exercise={exercise}
                setsChangeHandler={(key: SetKeys, val: string, setIndex: number) =>
                  setsChangeHandler(key, val, setIndex, i, j)
                }
                autoFillSetsHandler={(key: SetKeys, val: string, setIndex: number) =>
                  autoFillSetsHandler(key, val, setIndex, i, j)
                }
                addSetHandler={() => addSetHandler(i, j)}
                removeSetHandler={() => removeSetHandler(i, j)}
                setShowAutoFill={(setIdentifier: string) => setShowAutoFill(setIdentifier)}
                showAutoFill={showAutoFill}
                day={day}
              />
            </div>
          </div>
        </div>
      );

      return (
        <div style={{ display: 'flex' }} key={`${i}${j}`}>
          {exerciseBlock}
          {finished && weekNumber > 0 && (
            <>
              <div className={classes.Divider}>
                <div></div>
              </div>
              {equivalentExerciseBlock}
            </>
          )}

          {userId &&
            isExerciseHistoryVisible(i, j) &&
            (previousDay?.workout?.exercises?.[i]?.[j]?.type === typeDay || !previousDay?.workout?.finished) && (
              <div className={classes.Divider}>
                <div></div>
              </div>
            )}

          {typeof analyticsExerciseList !== 'undefined' &&
            analyticsExerciseList.length > 0 &&
            isExerciseHistoryVisible(i, j) &&
            userId &&
            closeAnalytics && (
              <div className={classes.ExerciseHistoryWrapper}>
                <ExerciseHistoryBox
                  closeAnalytics={closeAnalytics}
                  userId={userId}
                  indexedExercise={{ exercise, index: i, group: j }}
                />
              </div>
            )}
        </div>
      );
    });
  };

  return (
    <div className={classes.quickEditWrapper}>
      <div className={classes.quickEditInnerWrapper} id={'day.' + dayNumber + 'week.' + weekNumber}>
        <div className={classes.NotesContainer}>
          <div>
            <div className={classes.type}>Workout notes:</div>
            <div className={classes.WorkoutNotesWrapper}>
              <textarea
                placeholder="Exercise Notes"
                className={classes.notesInnerWrapper}
                value={day?.workout?.workoutNotes?.coach || ''}
                onChange={({ target }: ChangeEvent<HTMLTextAreaElement>) =>
                  addWorkoutNotes(target.value, dayNumber, weekNumber)
                }
              />
            </div>
          </div>
          {previousDay && finished && weekNumber > 0 && (
            <>
              <div className={classes.DividerNotes}>
                <div></div>
              </div>

              <div>
                <div className={classes.type}>Workout feedback:</div>
                <p className={classes.WorkoutNotesFeedback}>
                  {previousDay?.workout?.workoutNotes?.athlete || 'No feedback notes.'}
                </p>
              </div>
            </>
          )}
        </div>

        {exercises?.map((group, i) => {
          const char = String.fromCharCode(65 + i);
          return (
            <>
              <div key={i} className={classes.groupWrapper}>
                {getGroup(group, i, char)}
              </div>
            </>
          );
        })}
      </div>

      <div className={classes.QuickEditButtonGroup}>
        <div
          onClick={() => {
            saveProgram(day?.workout?.workoutId);
            setIsQuickEdit(false);
          }}
          className={classes.quickEditBtn}
        >
          <svg className={classes.EditIcon} viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">
            <path
              fill="#58cb2c"
              d="M19.278 4.5H6.611A2.111 2.111 0 004.5 6.611v14.778A2.111 2.111 0 006.611 23.5h14.778a2.117 2.117 0 002.111-2.111V8.722zM14 21.389a3.167 3.167 0 113.167-3.167A3.162 3.162 0 0114 21.389zm3.167-10.556H6.611V6.611h10.556z"
              data-name="Icon material-save"
              transform="translate(-4.5 -4.5)"
            ></path>
          </svg>
          Save
        </div>

        <div className={classes.CloseButton} onClick={() => setIsQuickEdit(false)}>
          Close
        </div>
      </div>
    </div>
  );
};

export default QuickEdit;
