import React, { FC, useState, useEffect, ChangeEvent, createRef } from 'react';
import { Prompt, Redirect, RouteComponentProps } from 'react-router-dom';
import { Location } from 'history';
import { useToasts } from 'react-toast-notifications';
import { database } from 'utils/firebase';
import Modal from 'components/UI/Modal/Modal';
import Spinner from 'components/UI/Spinner/Spinner';
import { createDefaultSet, getNestedValue } from 'utils/helpers';
import { CLIENTS, CLIENTS_WORKOUT_BUILDER } from 'utils/routes';
import withAuth from 'utils/withAuth';
import classes from './ClientTraining.module.css';
import Layout from '../Layout';
import Week from './Week/Week';
import { Set, SetKeys, Exercise, Day, Programs } from 'interfaces/db';
import { DEFAULT_SET_PARAMS } from 'utils/constants';
import { SetParam } from 'interfaces/utils';
import ProgramSettings from 'components/ProgramSettings/ProgramSettings';
import { RootState } from 'store';
import { connect, ConnectedProps } from 'react-redux';

interface ProgramsType {
  [key: string]: Programs;
}

const mapStateToProps = ({ clients }: RootState) => {
  return {
    clients: clients.clients,
  };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux &
  RouteComponentProps<{
    clientKey: string;
  }>;

const ClientTraining: FC<Props> = ({ match, history, clients }) => {
  // get client key from url
  const { params } = match;
  const { clientKey = '' } = params;
  const { addToast } = useToasts();

  const elRefs = React.useRef([]);
  const [showAutoFill, _setShowAutoFill] = useState<string>('');
  const setShowAutoFill = (setIdentifier: string) => {
    _setShowAutoFill(setIdentifier);
  };
  const [program, setProgram] = useState<Programs | null>(null);
  const [programs, setPrograms] = useState<ProgramsType>({});
  const [selectedProgramKey, setSelectedProgramKey] = useState<string>('');
  const [trackProgramKey, setTrackProgramKey] = useState<string>('');
  const [loading, setLoading] = useState(true);
  const [changed, setChanged] = useState(false);
  const [navModal, showNavModal] = useState(false);
  const [completeModal, setCompleteModal] = useState(false);
  const [nextLocation, setNextLocation] = useState('');
  const [shouldNavOut, setShouldNavOut] = useState(false);
  const [activeWeek, setActiveWeek] = useState<number>(0);
  const [copiedWorkout, setCopiedWorkout] = useState<Day | null>(null);
  const [initialProgram, setInitialProgram] = useState<Programs | null>(null);
  const [showSaveProgram, setShowSaveProgram] = useState(false);
  const [showRemoveProgram, setShowRemoveProgram] = useState(false);
  const [defaultSetParams, setDefaultSetParams] = useState<SetParam[]>(DEFAULT_SET_PARAMS);
  const [clientName] = useState<string>(clients?.[clientKey]?.profile?.fullname ?? '');

  if (!elRefs.current.length) {
    elRefs.current = Array.from({ length: 7 }).map((_, i) => elRefs.current[i] || createRef());
  }

  const checkIfChanges = (program1: Programs, program2: Programs) => {
    if (!program1 || !program2) return;

    const program1ObjString = JSON.stringify(program1);
    const program2ObjString = JSON.stringify(program2);

    if (program2ObjString !== program1ObjString) {
      setChanged(true);
    } else {
      setChanged(false);
    }
  };

  // fetch program from database
  const getData = async () => {
    // showing loading spinner pending db call
    setLoading(true);
    try {
      // make the db query
      const snapshot = await database
        .ref(`newAssignedPrograms`)
        .orderByChild('clientKey')
        .equalTo(clientKey)
        .once('value');
      // check if query is valid
      if (snapshot.exists()) {
        // get client program
        let programs = snapshot.val();
        programs = Object.fromEntries(
          Object.entries(programs || {})?.filter(([_, val]) => {
            const value: any = val;
            const status = value?.status;
            return status === undefined && status !== 'completed';
          }),
        );

        if (Object.keys(programs || {}).length === 0) {
          return;
        }

        const program: any = Object.entries(programs)[0];

        const previouslySelectedProgramKey = window.localStorage.getItem('selectedProgramKey');

        const validKey = previouslySelectedProgramKey
          ? Object.keys(programs).includes(previouslySelectedProgramKey)
          : false;

        setSelectedProgramKey(validKey ? previouslySelectedProgramKey : program?.[0]);

        const { weeks = [], ...values } =
          validKey && previouslySelectedProgramKey ? programs[previouslySelectedProgramKey] : program?.[1];

        // set client program
        setPrograms(programs);
        const selectedProgram: Programs = { weeks, ...values };
        setProgram(selectedProgram);

        setDefaultSetParams(selectedProgram?.settings?.defaultSetParams || DEFAULT_SET_PARAMS);

        setInitialProgram({ weeks, ...values });
      }
    } catch (err) {
      // log error for now, we'll handle errors later
      console.log(err);
    } finally {
      // hide loading spinner
      setLoading(false);
    }
  };

  useEffect(() => {
    if (program && !initialProgram) {
      setInitialProgram(program);
    }

    if (!program || !initialProgram) return;

    checkIfChanges(program, initialProgram);
  }, [program, initialProgram]);

  useEffect(() => {
    if (shouldNavOut && nextLocation) {
      history.push(nextLocation);
    }
  }, [shouldNavOut, history, nextLocation]);

  useEffect(() => {
    getData();
  }, [clientKey]);

  useEffect(() => {
    const weekStatus = program?.weeks?.map((el) => {
      return el.Days.reduce((acc, cur) => {
        if (acc) {
          return acc;
        }

        return cur.workout.finished;
      }, false);
    });

    const currentWeekIndex = weekStatus?.lastIndexOf(true);

    if (!currentWeekIndex || currentWeekIndex === -1) {
      return;
    }

    setActiveWeek(currentWeekIndex);
  }, [program]);

  useEffect(() => {
    const activeNode: any = elRefs.current[activeWeek];

    if (activeNode?.current) {
      activeNode.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'end',
      });
    }
  }, [activeWeek]);

  const saveProgram = async () => {
    if (!program) {
      return;
    }
    // save program
    await database.ref(`newAssignedPrograms/${selectedProgramKey}`).update(program);

    setProgram(program);
    const programsCopy = Object.assign({}, programs);
    programsCopy[selectedProgramKey] = program;
    setPrograms(programsCopy);
    // hide save program modal
    setShowSaveProgram(false);
    setChanged(false);
    setInitialProgram(null);

    // show toast
    addToast('Program Saved', { appearance: 'success' });
  };

  const updateClientHasProgram = (clientKey: string): Promise<any> => {
    const clientRef = database.ref(`clients/${clientKey}`);

    return clientRef.once('value', (snapshot) => {
      if (snapshot.exists()) {
        clientRef.update({
          hasPrograms: false,
        });
      }
    });
  };

  const removeProgram = async () => {
    // delete program
    await database.ref(`newAssignedPrograms/${selectedProgramKey}`).remove();

    const remaingPrograms = Object.fromEntries(
      Object.entries(programs || {})?.filter(([key, _]) => key !== selectedProgramKey),
    );

    // close program removal modal
    setShowRemoveProgram(false);

    // navigate back to clients page
    if (Object.keys(remaingPrograms)?.length === 0) {
      await updateClientHasProgram(clientKey);
      history.push(CLIENTS.URL);
    } else {
      const key = Object.keys(remaingPrograms)[0];
      const program = remaingPrograms?.[key];
      setSelectedProgramKey(key);
      setProgram(program);
      setInitialProgram(program);
      setPrograms(remaingPrograms);
    }
  };

  const completeProgram = async () => {
    const assignedProgramRef = database.ref(`newAssignedPrograms/${selectedProgramKey}`);

    await assignedProgramRef.once('value', (snapshot) => {
      if (snapshot.exists()) {
        assignedProgramRef.update({
          status: 'complete',
          completedDate: new Date(),
        });
      }
    });

    const numPrograms = Object.keys(programs || {})?.length;
    if (numPrograms === 1) {
      await updateClientHasProgram(clientKey);
      history.push(CLIENTS.URL);
    }

    await getData();
    setCompleteModal(false);
  };

  const addWeekHandler = () => {
    if (!program) {
      return;
    }
    // create exercise objects for all days of the week.
    const newWeeks = {
      Days: Array.from({ length: 7 }).map(() => ({
        rest: false,
        workout: { finished: false },
      })),
    };

    // copy program and add new week
    const updatedProgram = {
      ...program,
      weeks: program.weeks ? [...program.weeks, newWeeks] : [newWeeks],
    };

    // update program
    setProgram(updatedProgram);
  };

  const duplicateWeekHandler = (Days: Day[]) => {
    if (!program) {
      return;
    }
    // remove client workout inputs from copied workout
    const formattedDays = Days.map((day) => {
      if (day.rest) {
        return { ...day };
      }

      let exercises: Exercise[][] = [];

      if (day.workout.exercises) {
        exercises = day.workout.exercises.map((exerciseGroup) => {
          return !exerciseGroup
            ? []
            : exerciseGroup.map((exercise) => {
                return {
                  ...exercise,
                  exerciseNotes: {
                    athlete: '',
                    coach: exercise.exerciseNotes?.coach || '',
                  },
                  sets: !exercise.sets
                    ? []
                    : exercise.sets.map((set) => {
                        const { completed, ...otherSets } = set;

                        const setKeys = Object.keys(otherSets) as Array<keyof typeof otherSets>;

                        return setKeys.reduce(
                          (acc, cur) => {
                            const currentType = otherSets[cur];
                            const value = typeof currentType === 'string' ? currentType : currentType?.programmed;

                            return {
                              ...acc,
                              [cur]: {
                                achieved: '',
                                programmed: value || '',
                              },
                            };
                          },
                          { completed: false },
                        );
                      }),
                };
              });
        });
      }

      if (!exercises.length) {
        return {
          ...day,
          workout: {
            ...day.workout,
            finished: false,
            workoutNotes: {
              athlete: '',
              coach: day.workout.workoutNotes?.coach || '',
            },
          },
        };
      }

      return {
        ...day,
        workout: {
          ...day.workout,
          exercises,
          finished: false,
          workoutNotes: {
            athlete: '',
            coach: day.workout.workoutNotes?.coach || '',
          },
        },
      };
    });

    // duplicate week
    const newWeek = { Days: formattedDays };

    // copy program and add new week
    const updatedProgram = {
      ...program,
      weeks: program.weeks ? [...program.weeks, newWeek] : [newWeek],
    };

    // update program
    setProgram(updatedProgram);
  };

  const deleteWeekHandler = (index: number) => {
    if (!program) {
      return;
    }
    // filter out selected week
    const weeks = program.weeks?.filter((_, i) => i !== index);

    // copy program and update weeks
    const updatedProgram = { ...program, weeks };

    // update program
    setProgram(updatedProgram);
  };

  const addWorkoutNotes = (workoutNotes: string, dayIndex: number, weekIndex: number) => {
    if (!program) {
      return;
    }
    // map over weeks
    const weeks = program.weeks?.map((week, i) => {
      // get selected week
      if (i !== weekIndex) {
        return week;
      }
      // map over days
      const days = week.Days.map(
        (day, index) => {
          // get selected day
          if (index !== dayIndex) {
            return day;
          }
          return {
            ...day,
            workout: {
              ...day.workout,
              workoutNotes: {
                athlete: day.workout?.workoutNotes?.athlete || '',
                coach: workoutNotes || '',
              },
            },
          };
        },

        // return day if not selected day
      );

      // update days
      return { Days: days };

      // return week if not selected week
    });

    // copy program and update weeks
    const updatedProgram = { ...program, weeks };

    // update program
    setProgram(updatedProgram);
  };

  const addExerciseNotes = (
    exerciseNotes: string,
    group: number,
    exerciseIndex: number,
    dayIndex: number,
    weekIndex: number,
  ) => {
    if (!program) {
      return;
    }
    // map over weeks
    const weeks = program.weeks?.map((week, i) => {
      // get selected week
      if (i !== weekIndex) {
        return week;
      }
      // map over days
      const days = week.Days.map((day, index) => {
        // get selected day
        if (index !== dayIndex) {
          return day;
        }
        const newExercises = day.workout.exercises?.map((exercise, i) => {
          if (i !== group) {
            return exercise;
          }
          return exercise.map((exerciseGroup, i) => {
            if (i !== exerciseIndex) {
              return exerciseGroup;
            }
            return {
              ...exerciseGroup,
              exerciseNotes: {
                athlete: exerciseGroup.exerciseNotes?.athlete || '',
                coach: exerciseNotes,
              },
            };
          });
        });

        return {
          ...day,
          workout: { ...day.workout, exercises: newExercises },
        };
      });

      // update days
      return { Days: days };
    });

    // copy program and update weeks
    const updatedProgram = { ...program, weeks };

    // update program
    setProgram(updatedProgram);
  };

  const addSetHandler = (group: number, exerciseIndex: number, dayIndex: number, weekIndex: number) => {
    if (!program) {
      return;
    }
    const newSet: Set = createDefaultSet(defaultSetParams);

    // map over weeks
    const weeks = program.weeks?.map((week, i) => {
      // get selected week
      if (i !== weekIndex) {
        return week;
      }
      // map over days
      const days = week.Days.map((day, index) => {
        // get selected day
        if (index !== dayIndex) {
          return day;
        }
        const newExercises = day.workout.exercises?.map((exercise, i) => {
          if (i !== group) {
            return exercise;
          }
          return exercise.map((exerciseGroup, i) => {
            if (i !== exerciseIndex) {
              return exerciseGroup;
            }
            const numberofSets = exerciseGroup.sets ? exerciseGroup.sets.length : 0;

            // if first set, initialize with empty values
            if (!numberofSets) {
              return {
                ...exerciseGroup,
                sets: [newSet],
              };
            } else {
              // initalize with last set values
              const { sets = [] } = exerciseGroup;
              const lastSet = {
                ...sets[numberofSets - 1],
                completed: false,
              };
              return {
                ...exerciseGroup,
                sets: [...sets, lastSet],
              };
            }
          });
        });

        return {
          ...day,
          workout: { ...day.workout, exercises: newExercises },
        };
      });

      // update days
      return { Days: days };
    });

    // copy program and update weeks
    const updatedProgram = { ...program, weeks };

    // update program
    setProgram(updatedProgram);
  };

  const removeSetHandler = (group: number, exerciseIndex: number, dayIndex: number, weekIndex: number) => {
    if (!program) {
      return;
    }
    // map over weeks
    const weeks = program.weeks?.map(
      (week, i) => {
        // get selected week
        if (i !== weekIndex) {
          return week;
        }
        // map over days
        const days = week.Days.map((day, index) => {
          // get selected day
          if (index !== dayIndex) {
            return day;
          }
          const newExercises = day.workout.exercises?.map((exercise, i) => {
            if (i !== group) {
              return exercise;
            }
            return exercise.map((exerciseGroup, i) => {
              if (i !== exerciseIndex) {
                return exerciseGroup;
              }
              const { sets: newSets = [] } = exerciseGroup;
              newSets.pop();

              return {
                ...exerciseGroup,
                sets: newSets,
              };
            });
          });

          return {
            ...day,
            workout: { ...day.workout, exercises: newExercises },
          };
        });

        // update days
        return { Days: days };
      },

      // return week if not selected week
    );

    // copy program and update weeks
    const updatedProgram = { ...program, weeks };

    // update program
    setProgram(updatedProgram);
  };

  const autoFillSetsHandler = (
    key: SetKeys,
    val: string,
    setIndex: number,
    group: number,
    exerciseIndex: number,
    dayIndex: number,
    weekIndex: number,
  ) => {
    if (!program) {
      return;
    }

    // map over weeks
    const weeks = program.weeks?.map((week, i) => {
      // get selected week
      if (i !== weekIndex) {
        return week;
      }

      // map over days
      const days: any = week.Days.map((day, index) => {
        // get selected day
        if (index !== dayIndex) {
          return day;
        }

        const newExercises = day.workout.exercises?.map((exercise, i) => {
          if (i !== group) {
            return exercise;
          }

          return exercise.map((exerciseGroup, i) => {
            if (i !== exerciseIndex) {
              return exerciseGroup;
            }

            const { sets = [] } = exerciseGroup;
            return {
              ...exerciseGroup,
              sets: sets.map((set, i) => {
                if (i < setIndex) {
                  return set;
                }

                if (typeof set[key] === 'string') {
                  return {
                    ...set,
                    [key]: {
                      index: i + 1,
                      programmed: val,
                      achieved: '',
                    },
                  };
                }

                return {
                  ...set,
                  [key]: {
                    programmed: val,
                    achieved: '',
                    index: (set?.[key] as any)?.index || i + 1,
                  },
                };
              }),
            };
          });
        });

        return {
          ...day,
          workout: { ...day.workout, exercises: newExercises },
        };
      });

      // update days
      return { Days: days };
    });

    // copy program and update weeks
    const updatedProgram = { ...program, weeks };

    // update program
    setProgram(updatedProgram);
  };

  const setsChangeHandler = (
    key: SetKeys,
    val: string,
    setIndex: number,
    group: number,
    exerciseIndex: number,
    dayIndex: number,
    weekIndex: number,
  ) => {
    if (!program) {
      return;
    }
    // map over weeks
    const weeks = program.weeks?.map((week, i) => {
      // return week if not selected week
      if (i !== weekIndex) {
        return week;
      }
      // map over days
      const days = week.Days.map((day, index) => {
        // return day if not selected day
        if (index !== dayIndex) {
          return day;
        }
        const newExercises = day.workout.exercises?.map((exercise, i) => {
          if (i !== group) {
            return exercise;
          }
          return exercise.map((exerciseGroup, i) => {
            if (i !== exerciseIndex) {
              return exerciseGroup;
            }
            const { sets = [] } = exerciseGroup;
            return {
              ...exerciseGroup,
              sets: sets.map((set, i) => {
                if (i !== setIndex) {
                  return set;
                }
                if (typeof set[key] === 'string') {
                  return {
                    ...set,
                    [key]: {
                      index: i + 1,
                      programmed: val,
                      achieved: '',
                    },
                  };
                }

                return {
                  ...set,
                  [key]: {
                    programmed: val,
                    achieved: '',
                    index: (set?.[key] as any)?.index || i + 1,
                  },
                };
              }),
            };
          });
        });

        return {
          ...day,
          workout: { ...day.workout, exercises: newExercises },
        };
      });

      // update days
      return { Days: days };
    });

    // copy program and update weeks
    const updatedProgram = { ...program, weeks };

    // update program
    setProgram(updatedProgram);
  };

  const setRestDayHandler = (weekIndex: number, dayIndex: number) => {
    if (!program) {
      return;
    }

    // map over weeks
    const weeks = program.weeks?.map((week, i) => {
      // get selected week
      if (i !== weekIndex) {
        return week;
      }

      // map over days
      const days = week.Days.map((day, index) => {
        // get selected day
        if (index !== dayIndex) {
          return day;
        }

        // update rest day
        return { ...day, rest: true };
      });

      // update days
      return { Days: days };
    });

    // copy program and update weeks
    const updatedProgram = { ...program, weeks };

    // update program
    setProgram(updatedProgram);
  };

  const removeRestDayHandler = (weekIndex: number, dayIndex: number) => {
    if (!program) {
      return;
    }
    // map over weeks
    const weeks = program.weeks?.map((week, i) => {
      // get selected week
      if (i !== weekIndex) {
        return week;
      }
      // map over days
      const days = week.Days.map((day, index) => {
        // get selected day
        if (index !== dayIndex) {
          return day;
        }
        // update rest day
        return { ...day, rest: false };
      });

      // update days
      return { Days: days };
    });

    // copy program and update weeks
    const updatedProgram = { ...program, weeks };

    // update program
    setProgram(updatedProgram);
  };

  const copyWorkoutHandler = (weekIndex: number, dayIndex: number) => {
    if (!program) {
      return;
    }

    const copied = program.weeks?.[weekIndex]?.Days?.[dayIndex];
    setCopiedWorkout(copied || null);
  };

  const pasteWorkoutHandler = (weekIndex: number, dayIndex: number) => {
    if (program && copiedWorkout) {
      // map over weeks
      const weeks = program.weeks?.map((week, i) => {
        // get selected week
        if (i !== weekIndex) {
          return week;
        }
        // map over days
        const days = week.Days.map((day, index) => {
          // get selected day
          if (index !== dayIndex) {
            return day;
          }
          // update rest day
          return copiedWorkout;
        });

        // update days
        return { Days: days };
      });

      // copy program and update weeks
      const updatedProgram = { ...program, weeks };

      // update program
      setProgram(updatedProgram);
    }
  };

  const handleProgramNameUpdate = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (!program) {
      return;
    }
    const { value } = target;

    // copy program and add new week
    const updatedProgram = {
      ...program,
      name: value,
    };

    // update program
    setProgram(updatedProgram);
  };

  const addWorkout = async (weekIndex: number, dayIndex: number) => {
    if (!program) {
      return;
    }
    // map over weeks
    const weeks = program.weeks?.map((week, i) => {
      // get selected week
      if (i !== weekIndex) {
        return week;
      }
      // map over days
      const days = week.Days.map((day, index) => {
        // get selected day
        if (index !== dayIndex) {
          return day;
        }
        const workoutTitle = getNestedValue(['workout', 'workoutTitle'], day) || '';

        const newWorkout = {
          rest: false,
          workout: {
            workoutTitle,
            finished: false,
          },
        };

        // add workout
        return newWorkout;
      });

      // update days
      return { Days: days };
    });

    // copy program and update weeks
    const updatedProgram = { ...program, weeks };

    // save program
    await database.ref(`newAssignedPrograms/${selectedProgramKey}`).update(updatedProgram);

    setChanged(false);
    setInitialProgram(null);

    //  navigate
    history.push({
      state: { programName: program.name, defaultSetParams },
      pathname: `${CLIENTS_WORKOUT_BUILDER.URL}/${selectedProgramKey}/${weekIndex}/${dayIndex}`,
    });
  };

  const goToWorkoutBuilder = async (weekIndex: number, dayIndex: number) => {
    if (!program) {
      return;
    }
    if (changed) {
      // save program
      await database.ref(`newAssignedPrograms/${selectedProgramKey}`).update(program);

      setChanged(false);
      setInitialProgram(null);
    }
    window.localStorage.setItem('selectedProgramKey', selectedProgramKey);
    //  navigate
    history.push({
      state: { programName: program.name, defaultSetParams },
      pathname: `${CLIENTS_WORKOUT_BUILDER.URL}/${selectedProgramKey}/${weekIndex}/${dayIndex}`,
    });
  };

  const handleNavPrompt = (nextLoc: Location<unknown>) => {
    if (!shouldNavOut) {
      setNextLocation((nextLoc as unknown) as string);
      showNavModal(true);

      return false;
    }

    return true;
  };

  const discardChanges = () => {
    setProgram(programs?.[trackProgramKey]);
    setSelectedProgramKey(trackProgramKey);
    setInitialProgram(programs?.[trackProgramKey]);
    setChanged(false);
    showNavModal(false);
    setShouldNavOut(true);
  };

  const onChangeProgramHandler = (e: any) => {
    if (changed) {
      setTrackProgramKey(e.target.value);
      showNavModal(true);
    } else {
      setProgram(programs?.[e.target.value] || null);
      setInitialProgram(programs?.[e.target.value] || null);
      setSelectedProgramKey(e.target.value);
    }
  };

  const updateProgramDefaultSetParams = (updatedParams: SetParam[]) => {
    if (!program) {
      return;
    }
    const updatedProgram = {
      ...program,
      settings: {
        ...program.settings,
        defaultSetParams: updatedParams,
      },
    };
    setProgram(updatedProgram);
  };

  const addDefaultSetParam = (param: SetParam) => {
    const updatedParams = [...defaultSetParams, param];
    setDefaultSetParams(updatedParams);
    updateProgramDefaultSetParams(updatedParams);
  };

  const updateDefaultSetParam = (newParam: SetParam, originalParam?: SetParam) => {
    if (!originalParam) {
      return;
    }
    const paramIndex = defaultSetParams.indexOf(originalParam);
    const updatedParams = [...defaultSetParams];
    updatedParams[paramIndex] = newParam;
    setDefaultSetParams(updatedParams);
    updateProgramDefaultSetParams(updatedParams);
  };

  const removeDefaultSetParam = (originalParam?: SetParam) => {
    if (!originalParam) {
      return;
    }
    const paramIndex = defaultSetParams.indexOf(originalParam);
    const updatedParams = [...defaultSetParams];
    if (paramIndex > -1) {
      updatedParams.splice(paramIndex, 1);
    }
    setDefaultSetParams(updatedParams);
    updateProgramDefaultSetParams(updatedParams);
  };

  if (loading) {
    return (
      <Layout loading={loading} heading="Loading...">
        <div>
          <Spinner />
        </div>
      </Layout>
    );
  }

  if (!program) {
    return <Redirect to="/404" />;
  }

  const weeks = program?.weeks;

  const viewWeeksList = program.weeks?.map((week, numWeek) => (
    <Week
      key={numWeek}
      days={week.Days}
      week={numWeek + 1}
      previousWeek={numWeek > 0 ? (weeks ? weeks[numWeek - 1] : week) : week}
      addWorkout={addWorkout}
      saveProgram={saveProgram}
      ref={elRefs.current[numWeek]}
      setRestDayHandler={setRestDayHandler}
      copyWorkoutHandler={copyWorkoutHandler}
      goToWorkoutBuilder={goToWorkoutBuilder}
      pasteWorkoutHandler={pasteWorkoutHandler}
      removeRestDayHandler={removeRestDayHandler}
      deleteWeekHandler={() => deleteWeekHandler(numWeek)}
      duplicateWeekHandler={() => duplicateWeekHandler(week.Days)}
      addExerciseNotes={(exerciseNotes, group, index, dayIndex) =>
        addExerciseNotes(exerciseNotes, group, index, dayIndex, numWeek)
      }
      addWorkoutNotes={addWorkoutNotes}
      addSetHandler={(group, index, dayIndex) => addSetHandler(group, index, dayIndex, numWeek)}
      removeSetHandler={(group, index, dayIndex) => removeSetHandler(group, index, dayIndex, numWeek)}
      setsChangeHandler={(key, val, setIndex, group, exerciseIndex, dayIndex) =>
        setsChangeHandler(key, val, setIndex, group, exerciseIndex, dayIndex, numWeek)
      }
      autoFillSetsHandler={(key, val, setIndex, group, exerciseIndex, dayIndex) =>
        autoFillSetsHandler(key, val, setIndex, group, exerciseIndex, dayIndex, numWeek)
      }
      setShowAutoFill={(setIdentifier: string) => setShowAutoFill(setIdentifier)}
      showAutoFill={showAutoFill}
      clientKey={clientKey}
    />
  ));

  let textInput: any = null;

  const nameFocusHandler = () => {
    textInput.focus();
  };

  const MAX_LENGTH = 23;
  const nameLength = program.name?.length || MAX_LENGTH - 5;

  const programName = (
    <div className={classes.TitleWrapper}>
      <input
        type="text"
        maxLength={MAX_LENGTH}
        placeholder="Edit"
        value={program.name}
        ref={(elem) => (textInput = elem)}
        onChange={handleProgramNameUpdate}
        className={classes.ProgramNameInput}
        style={{
          width: `${nameLength * 30}px`,
        }}
      />
      <img
        alt="edit"
        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iMjAiIGhlaWdodD0iMjAiCnZpZXdCb3g9IjAgMCAyMjYgMjI2IgpzdHlsZT0iIGZpbGw6IzAwMDAwMDsiPjxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0ibm9uemVybyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIHN0cm9rZS1saW5lY2FwPSJidXR0IiBzdHJva2UtbGluZWpvaW49Im1pdGVyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1kYXNoYXJyYXk9IiIgc3Ryb2tlLWRhc2hvZmZzZXQ9IjAiIGZvbnQtZmFtaWx5PSJub25lIiBmb250LXdlaWdodD0ibm9uZSIgZm9udC1zaXplPSJub25lIiB0ZXh0LWFuY2hvcj0ibm9uZSIgc3R5bGU9Im1peC1ibGVuZC1tb2RlOiBub3JtYWwiPjxwYXRoIGQ9Ik0wLDIyNnYtMjI2aDIyNnYyMjZ6IiBmaWxsPSJub25lIj48L3BhdGg+PGcgZmlsbD0iIzdlN2U3ZSI+PHBhdGggZD0iTTE3My4zOTkwOCwxOC44MzMzM2MtMi40MDk0OCwwIC00LjgyMTYzLDAuOTE3ODMgLTYuNjU3ODgsMi43NTg3OWwtMTYuMDc0NTQsMTYuMDc0NTRsMzcuNjY2NjcsMzcuNjY2NjdsMTYuMDc0NTQsLTE2LjA3NDU0YzMuNjgxOTIsLTMuNjgxOTIgMy42ODE5MiwtOS42NDMyNiAwLC0xMy4zMTU3NmwtMjQuMzUwOTEsLTI0LjM1MDkxYy0xLjg0MDk2LC0xLjg0MDk2IC00LjI0ODQsLTIuNzU4NzkgLTYuNjU3ODgsLTIuNzU4Nzl6TTEzNi41NDE2Nyw1MS43OTE2N2wtMTA4LjI5MTY3LDEwOC4yOTE2N3YzNy42NjY2N2gzNy42NjY2N2wxMDguMjkxNjcsLTEwOC4yOTE2N3oiPjwvcGF0aD48L2c+PC9nPjwvc3ZnPg=="
        onClick={nameFocusHandler}
        className={classes.EditImage}
      />
    </div>
  );

  return (
    <Layout heading="" loading={loading}>
      <Prompt when={changed} message={handleNavPrompt} />
      {programName}
      <div className={classes.btnWrapper}>
        {Object.keys(programs).length > 1 ? (
          <select onChange={onChangeProgramHandler} className={classes.ProgramsDropdown} value={selectedProgramKey}>
            {Object.entries(programs || {})?.map(([key, value]) => {
              const program = value;
              return (
                <option key={key} value={key}>
                  {program.name}
                </option>
              );
            })}
          </select>
        ) : (
          <div></div>
        )}

        <div className={classes.btnRightWrapper}>
          {changed && (
            <button className={classes.SaveProgram} onClick={() => setShowSaveProgram(true)}>
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAABjklEQVRoge2ZMU7DMBSG/8eQBWYkJLJzi0pcihtAuUJHuABigI0LMDLAjNpwjJ+hprJM7NiOE8eVv9GJ33ufrTynDVCpTA/JhuQ9yY7z8U7yPLXIekYBnY+xMmKIdAAuRq1GPF8ArkXkJ2ayKcIkJcUzSuaAY+ufSK5Inqapd2IsEje56wqmbyfUeFndrCfYSo2X1c16Ap2pcedORK2afx2fJMM6qa3AoWWbWMRL5iR1ESEELMYVgDeXTFaRQJwyJYkADpnSRACLTIkiwF7mWR9wvmuJiPSNm/zdF4oeV4/h2wn1OaXuyD+qyNJIIkLyMmaeaGix2phYqXZkEyujoyQ2MXOTdK1c1K61ZKrI0qgiBi8AWkkEgBbAa0gBqdpvKyLbkMRDqDPl23WP3n6zvv0OEZK3PiNLo4osjaMV6bJUkQBT5DFLFanh/vPBmuQu53+/MXm9DrKjPBDN39hzMZT3aLuWjUM3m/q5sNGTd6df9xV58EmSmjE12QptSN5RdbPMbEnekmyCRSqVcH4BoyWacwifNfYAAAAASUVORK5CYII="
                alt="save program - client"
              />
              SAVE
            </button>
          )}
          <button className={classes.RemoveProgram} onClick={() => setShowRemoveProgram(true)}>
            <img
              src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgdmlld0JveD0iMCAwIDkwMC41IDkwMC41IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA5MDAuNSA5MDAuNTsiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxnIHRyYW5zZm9ybT0ibWF0cml4KDAuODQyOTQzIDAgMCAwLjg0Mjk0MyA3MC43MTQ4IDcwLjcxNDgpIj48Zz4KCTxwYXRoIGQ9Ik0xNzYuNDE1LDg4MC41YzAsMTEuMDQ2LDguOTU0LDIwLDIwLDIwaDUwNy42N2MxMS4wNDYsMCwyMC04Ljk1NCwyMC0yMFYyMzIuNDg3aC01NDcuNjdWODgwLjVMMTc2LjQxNSw4ODAuNXogICAgTTU2Mi43NSwzNDIuNzY2aDc1djQzNi4wMjloLTc1VjM0Mi43NjZ6IE00MTIuNzUsMzQyLjc2Nmg3NXY0MzYuMDI5aC03NVYzNDIuNzY2eiBNMjYyLjc1LDM0Mi43NjZoNzV2NDM2LjAyOWgtNzVWMzQyLjc2NnoiIGRhdGEtb3JpZ2luYWw9IiMwMDAwMDAiIGNsYXNzPSJhY3RpdmUtcGF0aCIgZGF0YS1vbGRfY29sb3I9IiMwMDAwMDAiIHN0eWxlPSJmaWxsOiNGRkZGRkYiPjwvcGF0aD4KCTxwYXRoIGQ9Ik02MTguODI1LDkxLjkxMVYyMGMwLTExLjA0Ni04Ljk1NC0yMC0yMC0yMGgtMjk3LjE1Yy0xMS4wNDYsMC0yMCw4Ljk1NC0yMCwyMHY3MS45MTF2MTIuNXYxMi41SDE0MS44NzQgICBjLTExLjA0NiwwLTIwLDguOTU0LTIwLDIwdjUwLjU3NmMwLDExLjA0NSw4Ljk1NCwyMCwyMCwyMGgzNC41NDFoNTQ3LjY3aDM0LjU0MWMxMS4wNDYsMCwyMC04Ljk1NSwyMC0yMHYtNTAuNTc2ICAgYzAtMTEuMDQ2LTguOTU0LTIwLTIwLTIwSDYxOC44MjV2LTEyLjVWOTEuOTExeiBNNTQzLjgyNSwxMTIuNzk5aC0xODcuMTV2LTguMzg5di0xMi41Vjc1aDE4Ny4xNXYxNi45MTF2MTIuNVYxMTIuNzk5eiIgZGF0YS1vcmlnaW5hbD0iIzAwMDAwMCIgY2xhc3M9ImFjdGl2ZS1wYXRoIiBkYXRhLW9sZF9jb2xvcj0iIzAwMDAwMCIgc3R5bGU9ImZpbGw6I0ZGRkZGRiI+PC9wYXRoPgo8L2c+PC9nPiA8L3N2Zz4="
              alt="remove program - client"
            />
            REMOVE
          </button>
          <button className={classes.CompleteProgram} onClick={() => setCompleteModal(true)}>
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAABjUlEQVRoge2ZsU7DMBRF7wNWEEJsnZmoBB8D6kA/hrELH1IJdj6BkZ+AqUuzgnQY6kptid0mjp1U8pkavdTvnmenQyoVCoXCYAFugDegIj8VMAdGoYwWCi/pQ9JV14NpyELSvZl91RVPAl+cqf/wknQt6cVXDO1AJek8RaIWLM3ssq4QEmDrRjPvvSk4tH/oCB0FRaBvikDf9C4A3KZaeItEPR6BH2DWef/UAhvh18x26sMVACY74XHX48EL1Ewe4Bd46rR/CoFDww9SoEn4wQk0DZ9NgAN+w/E/sJPY/lECwLOb4jSwRuPJZxFw4TcD/ZOICZ9UABjXBNs6ErQ8NlkEXO3BM91p7OSzCLi6b8pRk88m4O6p24moyWcV2CPROnxWAY9EVPim/TtZYEMiOnyT/mexjdaY2Stw6j7Pu1p3b19fYde6vNhKxNELhJ6BStLF+qLVL0F3LH2F0A68JwjSFm+W0EM8kvSp1fv5PllIujOz77qidwfcPyJ3kuZaHafcVK63N3yhUCj0zx+23+8GaPKmIwAAAABJRU5ErkJggg=="
              alt="complete program - client"
            />
            COMPLETE PROGRAM
          </button>
        </div>
      </div>
      <div className={classes.ClientNameContainer}>
        <h3>{clientName || ''}&#39;s Training</h3>
      </div>
      <div className={classes.CalendarContent}>
        <div className={classes.Calendar}>
          <ProgramSettings
            defaultSetParams={defaultSetParams}
            updateDefaultSetParam={updateDefaultSetParam}
            removeDefaultSetParam={removeDefaultSetParam}
            addDefaultSetParam={addDefaultSetParam}
          />

          <div className={classes.Weeks}>{viewWeeksList}</div>

          <div className={classes.AddWeekButtonWrapper} onClick={addWeekHandler}>
            <div className={classes.AddWeekText}>Add Week</div>
            <button className={classes.AddWeekButton}>+</button>
          </div>
        </div>
      </div>
      <Modal isModalOpen={showSaveProgram} openModal={() => setShowSaveProgram(false)}>
        <div className={classes.modalContainer}>
          <div className={classes.ModalClose}>
            <img
              onClick={() => setShowSaveProgram(false)}
              src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGlkPSJDYXBhXzEiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDM4Ni42NjcgMzg2LjY2NyIgaGVpZ2h0PSI1MTIiIHZpZXdCb3g9IjAgMCAzODYuNjY3IDM4Ni42NjciIHdpZHRoPSI1MTIiIGNsYXNzPSJob3ZlcmVkLXBhdGhzIj48Zz48cGF0aCBkPSJtMzg2LjY2NyA0NS41NjQtNDUuNTY0LTQ1LjU2NC0xNDcuNzcgMTQ3Ljc2OS0xNDcuNzY5LTE0Ny43NjktNDUuNTY0IDQ1LjU2NCAxNDcuNzY5IDE0Ny43NjktMTQ3Ljc2OSAxNDcuNzcgNDUuNTY0IDQ1LjU2NCAxNDcuNzY5LTE0Ny43NjkgMTQ3Ljc2OSAxNDcuNzY5IDQ1LjU2NC00NS41NjQtMTQ3Ljc2OC0xNDcuNzd6IiBkYXRhLW9yaWdpbmFsPSIjMDAwMDAwIiBjbGFzcz0iaG92ZXJlZC1wYXRoIGFjdGl2ZS1wYXRoIiBzdHlsZT0iZmlsbDojRkZGRkZGIiBkYXRhLW9sZF9jb2xvcj0iIzAwMDAwMCI+PC9wYXRoPjwvZz4gPC9zdmc+"
              alt="close"
            />
          </div>
          <div className={classes.ModalSaveIcon}>
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAABjklEQVRoge2ZMU7DMBSG/8eQBWYkJLJzi0pcihtAuUJHuABigI0LMDLAjNpwjJ+hprJM7NiOE8eVv9GJ33ufrTynDVCpTA/JhuQ9yY7z8U7yPLXIekYBnY+xMmKIdAAuRq1GPF8ArkXkJ2ayKcIkJcUzSuaAY+ufSK5Inqapd2IsEje56wqmbyfUeFndrCfYSo2X1c16Ap2pcedORK2afx2fJMM6qa3AoWWbWMRL5iR1ESEELMYVgDeXTFaRQJwyJYkADpnSRACLTIkiwF7mWR9wvmuJiPSNm/zdF4oeV4/h2wn1OaXuyD+qyNJIIkLyMmaeaGix2phYqXZkEyujoyQ2MXOTdK1c1K61ZKrI0qgiBi8AWkkEgBbAa0gBqdpvKyLbkMRDqDPl23WP3n6zvv0OEZK3PiNLo4osjaMV6bJUkQBT5DFLFanh/vPBmuQu53+/MXm9DrKjPBDN39hzMZT3aLuWjUM3m/q5sNGTd6df9xV58EmSmjE12QptSN5RdbPMbEnekmyCRSqVcH4BoyWacwifNfYAAAAASUVORK5CYII="
              alt="save"
            />
          </div>
          <p className={classes.ModalLargeText}>Save Program</p>
          <p className={classes.ModalSmallText}>
            Do you want to save your program?
            <br />
            This will save any changes that were made.
          </p>
          <div className={classes.ModalButtons}>
            <button onClick={() => setShowSaveProgram(false)} className={classes.ModalCancel}>
              Cancel
            </button>
            <button className={classes.ModalSave} onClick={saveProgram}>
              Save
            </button>
          </div>
        </div>
      </Modal>
      <Modal isModalOpen={showRemoveProgram} openModal={() => setShowRemoveProgram(false)}>
        <div className={classes.modalContainer}>
          <div className={classes.ModalClose}>
            <img
              onClick={() => setShowRemoveProgram(false)}
              src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGlkPSJDYXBhXzEiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDM4Ni42NjcgMzg2LjY2NyIgaGVpZ2h0PSI1MTIiIHZpZXdCb3g9IjAgMCAzODYuNjY3IDM4Ni42NjciIHdpZHRoPSI1MTIiIGNsYXNzPSJob3ZlcmVkLXBhdGhzIj48Zz48cGF0aCBkPSJtMzg2LjY2NyA0NS41NjQtNDUuNTY0LTQ1LjU2NC0xNDcuNzcgMTQ3Ljc2OS0xNDcuNzY5LTE0Ny43NjktNDUuNTY0IDQ1LjU2NCAxNDcuNzY5IDE0Ny43NjktMTQ3Ljc2OSAxNDcuNzcgNDUuNTY0IDQ1LjU2NCAxNDcuNzY5LTE0Ny43NjkgMTQ3Ljc2OSAxNDcuNzY5IDQ1LjU2NC00NS41NjQtMTQ3Ljc2OC0xNDcuNzd6IiBkYXRhLW9yaWdpbmFsPSIjMDAwMDAwIiBjbGFzcz0iaG92ZXJlZC1wYXRoIGFjdGl2ZS1wYXRoIiBzdHlsZT0iZmlsbDojRkZGRkZGIiBkYXRhLW9sZF9jb2xvcj0iIzAwMDAwMCI+PC9wYXRoPjwvZz4gPC9zdmc+"
              alt="close"
            />
          </div>
          <div className={classes.ModalSaveIcon}>
            <img
              src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDUxMiA1MTIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMiA1MTI7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgY2xhc3M9IiI+PGc+PGNpcmNsZSBzdHlsZT0iZmlsbDojRDczQTI3IiBjeD0iMjU2IiBjeT0iMjU2IiByPSIyNTYiIGRhdGEtb3JpZ2luYWw9IiNFMjFCMUIiIGNsYXNzPSIiIGRhdGEtb2xkX2NvbG9yPSIjRTIxQjFCIj48L2NpcmNsZT48cGF0aCBzdHlsZT0iZmlsbDojQkYzMzIyIiBkPSJNNTEwLjI4LDI4NS4zMDRMMzY3LjkxMiwxNDIuOTM2TDE1MC4yNDgsMzY4LjYwOGwxNDAuOTI4LDE0MC45MjggIEM0MDYuMzUyLDQ5My42OTYsNDk3LjA1Niw0MDEuMjg4LDUxMC4yOCwyODUuMzA0eiIgZGF0YS1vcmlnaW5hbD0iI0M0MDYwNiIgY2xhc3M9ImFjdGl2ZS1wYXRoIiBkYXRhLW9sZF9jb2xvcj0iI0M0MDYwNiI+PC9wYXRoPjxnPgoJPHBhdGggc3R5bGU9ImZpbGw6I0ZGRkZGRjsiIGQ9Ik0zNTQuMzc2LDM3MS41MzZjLTUuMTIsMC0xMC4yMzItMS45NTItMTQuMTQ0LTUuODU2TDE0Ni40MDgsMTcxLjg0OCAgIGMtNy44MTYtNy44MTYtNy44MTYtMjAuNDcyLDAtMjguMjhzMjAuNDcyLTcuODE2LDI4LjI4LDBMMzY4LjUyLDMzNy40YzcuODE2LDcuODE2LDcuODE2LDIwLjQ3MiwwLDI4LjI4ICAgQzM2NC42MDgsMzY5LjU4NCwzNTkuNDk2LDM3MS41MzYsMzU0LjM3NiwzNzEuNTM2eiIgZGF0YS1vcmlnaW5hbD0iI0ZGRkZGRiIgY2xhc3M9IiI+PC9wYXRoPgoJPHBhdGggc3R5bGU9ImZpbGw6I0ZGRkZGRjsiIGQ9Ik0xNjAuNTQ0LDM3MS41MzZjLTUuMTIsMC0xMC4yMzItMS45NTItMTQuMTQ0LTUuODU2Yy03LjgxNi03LjgxNi03LjgxNi0yMC40NzIsMC0yOC4yOCAgIGwxOTMuODMyLTE5My44MzJjNy44MTYtNy44MTYsMjAuNDcyLTcuODE2LDI4LjI4LDBzNy44MTYsMjAuNDcyLDAsMjguMjhMMTc0LjY4OCwzNjUuNjggICBDMTcwLjc4NCwzNjkuNTg0LDE2NS42NjQsMzcxLjUzNiwxNjAuNTQ0LDM3MS41MzZ6IiBkYXRhLW9yaWdpbmFsPSIjRkZGRkZGIiBjbGFzcz0iIj48L3BhdGg+CjwvZz48L2c+IDwvc3ZnPg=="
              alt="delete"
            />
          </div>
          <p className={classes.ModalLargeText}>Remove Program</p>
          <p className={classes.ModalSmallText}>
            Do you want to remove this program from the client?
            <br />
            This cannot be undone.
          </p>
          <div className={classes.ModalButtons}>
            <button onClick={() => setShowRemoveProgram(false)} className={classes.ModalCancel}>
              Cancel
            </button>
            <button className={classes.ModalSave} onClick={removeProgram}>
              Remove
            </button>
          </div>
        </div>
      </Modal>
      <Modal isModalOpen={completeModal} openModal={() => setCompleteModal(false)}>
        <div className={classes.modalContainer}>
          <p className={classes.ModalLargeText}>
            Are you sure you want to mark <br />
            <strong>&quot;{program.name}&quot;</strong>
            <br />
            as complete?
          </p>
          <p className={classes.ModalSmallText}>
            This will mark this program as complete, meaning that your client has finished all their necessary workouts.
            This program will be archived and you will not have access to it.
          </p>
          <div className={classes.ModalButtons}>
            <button onClick={() => setCompleteModal(false)} className={classes.ModalCancel}>
              Cancel
            </button>
            <button onClick={completeProgram} className={classes.ModalDelete}>
              Continue
            </button>
          </div>
        </div>
      </Modal>
      <Modal isModalOpen={navModal} openModal={() => showNavModal(false)}>
        <div className={classes.modalContainer}>
          <p className={classes.ModalLargeText}>Did you forget to save?</p>
          <div className={classes.ModalButtons}>
            <button onClick={discardChanges} className={classes.ModalCancel}>
              Discard changes
            </button>
            <button onClick={() => showNavModal(false)} className={classes.ModalDelete}>
              Oops
            </button>
          </div>
        </div>
      </Modal>
    </Layout>
  );
};

export default connector(withAuth(ClientTraining));
