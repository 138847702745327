import React, { FC } from 'react';
import { Formik, FormikHelpers, Form, Field, ErrorMessage } from 'formik';
import cls from 'classnames';
import { object, string, array } from 'yup';
import Select from 'react-select';

import { Exercise, SetKeys } from 'interfaces/db';
import { customStyles, setKeysMap, emptyOption, emptySetObject, MAX_SETS_LENGTH } from 'utils/helpers';
import { FormProps, SelectCompProps, SelectOptions } from 'interfaces/utils';
import classes from './ExerciseLibrary.module.css';

type InitialValues = {
  type: string;
  videoURL?: string;
  sets: SetKeys[];
};

const validationSchema = object().shape({
  type: string().required('Title is a required field'),
  videoURL: string().trim(),
  sets: array(string()).min(1).max(3).required(),
});

const getValObject = (val: SetKeys): SelectOptions => {
  return setKeysMap.find((el) => el.value === val) as SelectOptions;
};

const SelectComp: FC<Omit<SelectCompProps, 'selected'> & { selected: SetKeys }> = ({
  options,
  selected,
  removeTypeChangeHandler,
  setTypeChangeHandler,
}) => {
  const value = getValObject(selected);

  return (
    <Select
      value={value}
      isSearchable={false}
      styles={customStyles}
      onChange={(val: any) => (val.value ? setTypeChangeHandler(val.value) : removeTypeChangeHandler())}
      options={[...options, emptyOption]}
    />
  );
};

const ExerciseLibForm: FC<FormProps<Exercise> & { initialValues: InitialValues }> = ({
  handleSubmit,
  initialValues,
}) => {
  const onSubmit = async (values: InitialValues, actions: FormikHelpers<InitialValues>) => {
    const { type, videoURL, sets: preSets } = values;

    const sets = preSets.reduce(
      (acc, cur, index) => ({
        ...acc,
        [cur]: { ...emptySetObject, index: index + 1 },
      }),
      { completed: false },
    );

    const value = {
      type,
      videoURL,
      sets: [sets],
    };

    handleSubmit(value);
    actions.setSubmitting(false);
  };

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
      {({ submitForm, values, setFieldValue }) => {
        const { sets } = values;

        const options = setKeysMap.filter((el) => !sets.includes(el.value));

        return (
          <Form className={classes.formWrapper}>
            <div className={classes.formControl}>
              <p className={classes.label}>Title</p>
              <Field name="type" className={classes.input} placeholder="Enter an exercise title" />
              <ErrorMessage name="type">{(errMsg) => <p className={classes.error}>{errMsg}</p>}</ErrorMessage>
            </div>

            <div className={classes.formControl}>
              <p className={classes.label}>Video URL &#40;Optional&#41;</p>
              <Field name="videoURL" className={classes.input} placeholder="Enter video URL" />
              <ErrorMessage name="videoURL">{(errMsg) => <p className={classes.error}>{errMsg}</p>}</ErrorMessage>
            </div>

            <div className={classes.formControl}>
              <p className={classes.label}>Parameters</p>

              <div className={classes.setsWrapper}>
                {sets.map((el, index) => (
                  <div key={el} className={classes.sets}>
                    <SelectComp
                      selected={sets[index]}
                      options={[getValObject(sets[index]), ...options]}
                      removeTypeChangeHandler={() => {
                        const updatedSets = sets.filter((_, i) => i !== index);
                        setFieldValue('sets', updatedSets);
                      }}
                      setTypeChangeHandler={(value: SetKeys) => {
                        // change set
                        const updatedSets = sets.map((el, i) => {
                          if (i === index) {
                            return value;
                          }
                          return el;
                        });

                        setFieldValue('sets', updatedSets);
                      }}
                    />
                  </div>
                ))}

                {sets.length !== MAX_SETS_LENGTH ? (
                  <div className={cls(classes.sets, classes.btnCentralize)}>
                    <button
                      type="button"
                      className={classes.addSetBtn}
                      onClick={() => {
                        const [newSet] = options;
                        setFieldValue('sets', [...sets, newSet.value]);
                      }}
                    >
                      <svg
                        className={classes.addSetIcon}
                        viewBox="0 -2.384 29.25 29.25"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g data-name="Icon ionic-md-add-circle-outline">
                          <path
                            data-name="Path 10586"
                            d="M22.148 16.102h-6.046v6.047h-2.954v-6.047H7.102v-2.954h6.047V7.102h2.953v6.047h6.047z"
                          />
                          <path
                            data-name="Path 10587"
                            d="M14.625 2.953A11.667 11.667 0 116.37 6.37a11.623 11.623 0 018.255-3.417m0-2.953A14.625 14.625 0 1029.25 14.625 14.623 14.623 0 0014.625 0z"
                          />
                        </g>
                      </svg>
                    </button>
                  </div>
                ) : null}
              </div>

              <ErrorMessage name="sets">{(errMsg) => <p className={classes.error}>{errMsg}</p>}</ErrorMessage>
            </div>

            <div className={classes.submitBtnWrapper}>
              <button type="button" onClick={submitForm} className={classes.submitBtn}>
                Save Exercise
              </button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ExerciseLibForm;
