import React, { FC } from 'react';
import BasicSmallSpinner from 'components/UI/Spinners';
import classes from './Billing.module.css';

type Props = {
  clientCount: number;
  hideSubscriptionCancelModal: () => void;
  handleSubscriptionCancel: () => void;
  loading: boolean;
};

const ConfirmPlanModal: FC<Props> = ({
  clientCount,
  hideSubscriptionCancelModal,
  handleSubscriptionCancel,
  loading,
}: Props) => {
  return (
    <div className={classes.modalContainer}>
      {!loading && (
        <div className={classes.modalClose}>
          <img
            alt="close"
            onClick={hideSubscriptionCancelModal}
            src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGlkPSJDYXBhXzEiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDM4Ni42NjcgMzg2LjY2NyIgaGVpZ2h0PSI1MTIiIHZpZXdCb3g9IjAgMCAzODYuNjY3IDM4Ni42NjciIHdpZHRoPSI1MTIiIGNsYXNzPSJob3ZlcmVkLXBhdGhzIj48Zz48cGF0aCBkPSJtMzg2LjY2NyA0NS41NjQtNDUuNTY0LTQ1LjU2NC0xNDcuNzcgMTQ3Ljc2OS0xNDcuNzY5LTE0Ny43NjktNDUuNTY0IDQ1LjU2NCAxNDcuNzY5IDE0Ny43NjktMTQ3Ljc2OSAxNDcuNzcgNDUuNTY0IDQ1LjU2NCAxNDcuNzY5LTE0Ny43NjkgMTQ3Ljc2OSAxNDcuNzY5IDQ1LjU2NC00NS41NjQtMTQ3Ljc2OC0xNDcuNzd6IiBkYXRhLW9yaWdpbmFsPSIjMDAwMDAwIiBjbGFzcz0iaG92ZXJlZC1wYXRoIGFjdGl2ZS1wYXRoIiBzdHlsZT0iZmlsbDojRkZGRkZGIiBkYXRhLW9sZF9jb2xvcj0iIzAwMDAwMCI+PC9wYXRoPjwvZz4gPC9zdmc+"
          />
        </div>
      )}
      <div className={classes.modalSaveIcon}>
        <img
          src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDUxMiA1MTIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMiA1MTI7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgY2xhc3M9IiI+PGc+PGNpcmNsZSBzdHlsZT0iZmlsbDojRDczQTI3IiBjeD0iMjU2IiBjeT0iMjU2IiByPSIyNTYiIGRhdGEtb3JpZ2luYWw9IiNFMjFCMUIiIGNsYXNzPSIiIGRhdGEtb2xkX2NvbG9yPSIjRTIxQjFCIj48L2NpcmNsZT48cGF0aCBzdHlsZT0iZmlsbDojQkYzMzIyIiBkPSJNNTEwLjI4LDI4NS4zMDRMMzY3LjkxMiwxNDIuOTM2TDE1MC4yNDgsMzY4LjYwOGwxNDAuOTI4LDE0MC45MjggIEM0MDYuMzUyLDQ5My42OTYsNDk3LjA1Niw0MDEuMjg4LDUxMC4yOCwyODUuMzA0eiIgZGF0YS1vcmlnaW5hbD0iI0M0MDYwNiIgY2xhc3M9ImFjdGl2ZS1wYXRoIiBkYXRhLW9sZF9jb2xvcj0iI0M0MDYwNiI+PC9wYXRoPjxnPgoJPHBhdGggc3R5bGU9ImZpbGw6I0ZGRkZGRjsiIGQ9Ik0zNTQuMzc2LDM3MS41MzZjLTUuMTIsMC0xMC4yMzItMS45NTItMTQuMTQ0LTUuODU2TDE0Ni40MDgsMTcxLjg0OCAgIGMtNy44MTYtNy44MTYtNy44MTYtMjAuNDcyLDAtMjguMjhzMjAuNDcyLTcuODE2LDI4LjI4LDBMMzY4LjUyLDMzNy40YzcuODE2LDcuODE2LDcuODE2LDIwLjQ3MiwwLDI4LjI4ICAgQzM2NC42MDgsMzY5LjU4NCwzNTkuNDk2LDM3MS41MzYsMzU0LjM3NiwzNzEuNTM2eiIgZGF0YS1vcmlnaW5hbD0iI0ZGRkZGRiIgY2xhc3M9IiI+PC9wYXRoPgoJPHBhdGggc3R5bGU9ImZpbGw6I0ZGRkZGRjsiIGQ9Ik0xNjAuNTQ0LDM3MS41MzZjLTUuMTIsMC0xMC4yMzItMS45NTItMTQuMTQ0LTUuODU2Yy03LjgxNi03LjgxNi03LjgxNi0yMC40NzIsMC0yOC4yOCAgIGwxOTMuODMyLTE5My44MzJjNy44MTYtNy44MTYsMjAuNDcyLTcuODE2LDI4LjI4LDBzNy44MTYsMjAuNDcyLDAsMjguMjhMMTc0LjY4OCwzNjUuNjggICBDMTcwLjc4NCwzNjkuNTg0LDE2NS42NjQsMzcxLjUzNiwxNjAuNTQ0LDM3MS41MzZ6IiBkYXRhLW9yaWdpbmFsPSIjRkZGRkZGIiBjbGFzcz0iIj48L3BhdGg+CjwvZz48L2c+IDwvc3ZnPg=="
          alt="delete"
        />
      </div>
      {clientCount > 5 ? (
        <p className={classes.modalLargeText}>
          You are unable to downgrade to the Free Plan because you have more than 5 clients.
          <br />
          If you still wish to downgrade, please remove {clientCount - 5} of your clients.
        </p>
      ) : (
        <>
          <p className={classes.modalLargeText}>Are you sure?</p>
          <p className={classes.modalSmallText}>
            Please confirm you would like to downgrade to the Free Plan &#40;up to 5 clients&#41;.
          </p>
        </>
      )}

      <div className={classes.modalButtons}>
        {loading ? (
          <BasicSmallSpinner />
        ) : clientCount <= 5 ? (
          <>
            <button
              className={[classes.button, classes.secondaryButton].join(' ')}
              onClick={hideSubscriptionCancelModal}
            >
              Cancel
            </button>
            <button className={[classes.button, classes.changeBtn].join(' ')} onClick={handleSubscriptionCancel}>
              Downgrade to Free Plan
            </button>
          </>
        ) : (
          <button className={[classes.button, classes.changeBtn].join(' ')} onClick={handleSubscriptionCancel}>
            Close Modal
          </button>
        )}
      </div>
    </div>
  );
};

export default ConfirmPlanModal;
