import {
  FunctionComponent,
  useRef,
  useEffect,
  useState,
} from 'react';
import { createPortal } from 'react-dom';

const Portal: FunctionComponent<{ selector: string }> = ({
  children,
  selector,
}) => {
  const ref = useRef<Element | null>(null);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    ref.current = document.querySelector(selector);
    setMounted(true);
  }, [selector]);

  return mounted && ref.current
    ? createPortal(children, ref.current)
    : null;
};

export default Portal;
