import React, { FC } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useToasts, AddToast } from 'react-toast-notifications';

import { LoginForm } from 'components';
import { RootState } from 'store';
import { REGISTER } from 'utils/routes';
import { login } from 'store/actions/auth';
import Logo from 'components/Logo/Logo';
import withoutAuth from 'utils/withoutAuth';
import classes from './Auth.module.css';

const mapStateToProps = ({ auth }: RootState) => {
  return {
    loading: auth.loading,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onLogin: (email: string, password: string, addToast: AddToast) =>
      dispatch(login(email, password, addToast)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type FormValues = {
  email: string;
  password: string;
};

const Auth: FC<PropsFromRedux> = ({ loading, onLogin }) => {
  const { addToast } = useToasts();

  const handleLogin = ({ email, password }: FormValues) => {
    onLogin(email, password, addToast);
  };

  return (
    <main>
      <div className={classes.Header}>
        <div className={classes.Logo}>
          <Logo
            link="https://www.coachelite.co/"
            auth={true}
            dashboard={false}
          />
        </div>

        <div className={classes.AuthButtons}>
          <p className={classes.signPreText}>Not a member? </p>
          <NavLink
            to={{ pathname: REGISTER.URL }}
            className={classes.SignupButton}
          >
            Sign up now
          </NavLink>
        </div>
      </div>

      <div className={classes.Auth}>
        <div className={classes.AuthWrapper}>
          <div className={classes.AuthContainer}>
            <h2 className={classes.AuthHeading}>Welcome Back</h2>
            <div className={classes.UserForm}>
              <LoginForm
                loading={loading}
                handleSubmit={handleLogin}
              />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default connector(withoutAuth(Auth));
