import React, { FC, useCallback } from 'react';
import cls from 'classnames';
import Select from 'react-select';

import SetComp from '../../Set/Set';
import classes from './Table.module.css';
import { customStyles, setKeysMap, MAX_SETS_LENGTH, emptyOption, emptySetObject } from 'utils/helpers';
import { Set, SetKeys, Exercise } from 'interfaces/db';
import { SelectCompProps } from 'interfaces/utils';
import { DEFAULT_SET } from 'utils/constants';

interface Props {
  finished: boolean;
  exercise: Exercise;
  addSetHandler: () => void;
  removeSetHandler: () => void;
  setsChangeHandler: (setType: SetKeys, value: string, setIndex: number) => void;
  autoFillSetsHandler: (setType: SetKeys, value: string, setIndex: number) => void;
  setTypeChangeHandler: (setType: SetKeys, setIndex: number) => void;
  removeTypeChangeHandler: (setKey: SetKeys) => void;
  addTypeChangehandler: (setType: SetKeys) => void;
  setShowAutoFill: (setIdentifier: string) => void;
  showAutoFill: string;
}

const newSet: Set = DEFAULT_SET;

const SelectComp: FC<SelectCompProps> = ({ options, selected, removeTypeChangeHandler, setTypeChangeHandler }) => {
  return (
    <Select
      value={selected}
      isSearchable={false}
      styles={customStyles}
      onChange={(val: any) => (val.value ? setTypeChangeHandler(val.value) : removeTypeChangeHandler())}
      options={[...options, emptyOption]}
    />
  );
};

const Table: FC<Props> = ({
  finished,
  exercise,
  addSetHandler,
  removeSetHandler,
  setsChangeHandler,
  autoFillSetsHandler,
  setTypeChangeHandler,
  addTypeChangehandler,
  removeTypeChangeHandler,
  setShowAutoFill,
  showAutoFill,
}) => {
  const { sets = [] } = exercise;
  const numSets = sets.length;

  const { completed, ...otherSets } = sets[0] || newSet;

  const sortedSetKeys = (Object.keys(otherSets) as Array<keyof typeof otherSets>).map((el) => {
    const setValue = otherSets?.[el];

    if (typeof setValue === 'string') {
      return { [el]: { ...emptySetObject } };
    }

    return { [el]: setValue };
  });

  const otherSetsKeys = sortedSetKeys.map((el) => {
    return Object.keys(el)[0];
  });

  const keysLength = otherSetsKeys.length;
  const titleClass = finished ? classes.ColumnHeaderEqual : classes.ColumnHeader;

  const options = useCallback(() => {
    return setKeysMap.filter((el) => !otherSetsKeys.includes(el.value));
  }, [otherSetsKeys]);

  return (
    <>
      <div className={classes.Table}>
        <ul>
          {!!numSets && (
            <li className={classes.TableRow}>
              <div className={classes.ColumnHeader}>sets</div>

              {otherSetsKeys.map((el, index) => {
                const selected = setKeysMap.find((key) => key.value === el);

                if (finished) {
                  return (
                    <div key={selected?.label} className={classes.ColumnHeader}>
                      {selected?.label}
                    </div>
                  );
                }

                if (selected) {
                  return (
                    <div key={index} className={titleClass}>
                      <SelectComp
                        selected={selected}
                        options={[selected, ...options()]}
                        removeTypeChangeHandler={() => removeTypeChangeHandler(selected.value)}
                        setTypeChangeHandler={(value: SetKeys) => setTypeChangeHandler(value, index)}
                      />
                    </div>
                  );
                }

                return undefined;
              })}

              {!finished && keysLength !== MAX_SETS_LENGTH ? (
                <div className={cls(titleClass, classes.btnCentralize)}>
                  <button
                    type="button"
                    className={classes.addSetBtn}
                    onClick={() => {
                      const [newSet] = options();
                      addTypeChangehandler(newSet.value);
                    }}
                  >
                    <svg
                      className={classes.addSetIcon}
                      viewBox="0 -2.384 29.25 29.25"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g data-name="Icon ionic-md-add-circle-outline">
                        <path
                          data-name="Path 10586"
                          d="M22.148 16.102h-6.046v6.047h-2.954v-6.047H7.102v-2.954h6.047V7.102h2.953v6.047h6.047z"
                        />
                        <path
                          data-name="Path 10587"
                          d="M14.625 2.953A11.667 11.667 0 116.37 6.37a11.623 11.623 0 018.255-3.417m0-2.953A14.625 14.625 0 1029.25 14.625 14.623 14.623 0 0014.625 0z"
                        />
                      </g>
                    </svg>
                  </button>
                </div>
              ) : null}

              {finished && (
                <div className={classes.ColumnHeaderTicked}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 31 31">
                    <g id="Group_16561" data-name="Group 16561" transform="translate(-4369 10482)">
                      <path
                        fill="#d3d3d3"
                        id="Icon_ionic-md-done-all"
                        data-name="Icon ionic-md-done-all"
                        d="M17.633,9.061l-1.4-1.4L9.868,14l1.4,1.4Zm4.258-1.4L11.271,18.195,7.113,14.052l-1.4,1.4,5.56,5.59L23.293,9.061ZM0,15.45l5.61,5.59,1.4-1.4-5.56-5.59Z"
                        transform="translate(4372.882 -10481.253)"
                      />
                      <g
                        id="Ellipse_34"
                        data-name="Ellipse 34"
                        transform="translate(4369 -10482)"
                        fill="none"
                        stroke="#d3d3d3"
                        strokeWidth="2"
                      >
                        <circle cx="15.5" cy="15.5" r="15.5" stroke="none" />
                        <circle cx="15.5" cy="15.5" r="14.5" fill="none" />
                      </g>
                    </g>
                  </svg>
                </div>
              )}
            </li>
          )}

          {sets.map((set, index) => (
            <SetComp
              set={set}
              key={index}
              setNum={index}
              finished={finished}
              ticked={set.completed}
              setsChangeHandler={(setType, value) => setsChangeHandler(setType, value, index)}
              autoFillSetsHandler={(setType, value) => autoFillSetsHandler(setType, value, index)}
              setShowAutoFill={(setIdentifier: string) => setShowAutoFill(setIdentifier)}
              showAutoFill={showAutoFill}
            />
          ))}
        </ul>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          paddingTop: 8,
          marginLeft: '5px',
        }}
      >
        {finished ? null : (
          <div className={classes.AddSet} onClick={addSetHandler}>
            <div className={classes.AddSetText}>+</div>
            ADD SET
          </div>
        )}
        {numSets === 0 || finished ? null : (
          <div className={classes.RemoveSet} onClick={removeSetHandler}>
            <div className={classes.RemoveSetText}>-</div>
            DELETE SET
          </div>
        )}
      </div>
    </>
  );
};

export default Table;
