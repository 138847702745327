import { AuthActionTypes } from 'interfaces/actions/auth';
import { CoachProfile, Codes } from 'interfaces/db';
import { FirebaseObject } from 'interfaces/utils';
import { AuthState } from 'interfaces/state';
import { updateObject } from '../utility';
import {
  AUTH_START,
  AUTH_SUCCESS,
  AUTH_FAIL,
  AUTH_LOGOUT,
  FETCH_COACH_PROFILE_START,
  FETCH_COACH_PROFILE_SUCCESS,
  FETCH_COACH_PROFILE_FAIL,
  SAVE_COACH_PROFILE_START,
  SAVE_COACH_PROFILE_SUCCESS,
  SAVE_COACH_PROFILE_FAIL,
  CREATE_COACH_PROFILE_START,
  CREATE_COACH_PROFILE_SUCCESS,
  CREATE_COACH_PROFILE_FAIL,
  SAVE_COACH_CODE_START,
  SAVE_COACH_CODE_SUCCESS,
  SAVE_COACH_CODE_FAIL,
  FETCH_COACH_CODES_START,
  FETCH_COACH_CODES_SUCCESS,
  FETCH_COACH_CODES_FAIL,
  SET_TOKEN,
} from '../actions/actionTypes';

const initialState: AuthState = {
  token: null,
  userId: null,
  error: null,
  loading: false,
  coachProfile: null,
  isRegistered: false,
  codes: {},
};

const authStart = (state: AuthState): AuthState => {
  return { ...state, ...{ error: null, loading: true } };
};

const authSuccess = (state: AuthState, action: { token: string; userId: string }): AuthState => {
  return updateObject(state, {
    token: action.token,
    userId: action.userId,
    error: null,
    loading: false,
  });
};

const authFail = (state: AuthState, action: { error: string }): AuthState => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const authLogout = (state: AuthState): AuthState => {
  return updateObject(state, { token: null, userId: null });
};

const fetchCoachProfileStart = (state: AuthState): AuthState => {
  return updateObject(state, { loading: true });
};

const fetchCoachProfileSuccess = (
  state: AuthState,
  action: {
    coachProfile: FirebaseObject<CoachProfile>;
    registered: boolean;
  },
): AuthState => {
  return updateObject(state, {
    loading: false,
    coachProfile: action.coachProfile,
    isRegistered: action.registered,
  });
};

const fetchCoachProfileFail = (state: AuthState): AuthState => {
  return updateObject(state, { loading: false });
};

const saveCoachProfileStart = (state: AuthState): AuthState => {
  return updateObject(state, { loading: true });
};

const saveCoachProfileSuccess = (
  state: AuthState,
  action: { coachProfile: FirebaseObject<CoachProfile> },
): AuthState => {
  return updateObject(state, {
    coachProfile: action.coachProfile,
    loading: false,
  });
};

const saveCoachProfileFail = (state: AuthState): AuthState => {
  return updateObject(state, { loading: false });
};

const createCoachProfileStart = (state: AuthState): AuthState => {
  return updateObject(state, { loading: true });
};

const createCoachProfileSuccess = (state: AuthState, action: { registered: true }): AuthState => {
  return updateObject(state, {
    loading: false,
    isRegistered: action.registered,
  });
};

const createCoachProfileFail = (state: AuthState): AuthState => {
  return updateObject(state, { loading: false });
};

const saveCoachCodeStart = (state: AuthState): AuthState => {
  return updateObject(state, { loading: true });
};

const saveCoachCodeSuccess = (state: AuthState): AuthState => {
  return updateObject(state, {
    loading: false,
  });
};

const saveCoachCodeFail = (state: AuthState): AuthState => {
  return updateObject(state, { loading: false });
};

const fetchCoachCodesStart = (state: AuthState): AuthState => {
  return updateObject(state, { loading: true });
};

const fetchCoachCodesSuccess = (state: AuthState, action: { codes: FirebaseObject<Codes> }): AuthState => {
  return updateObject(state, {
    loading: false,
    codes: action.codes,
  });
};

const fetchCoachCodesFail = (state: AuthState): AuthState => {
  return updateObject(state, { loading: false });
};

const setToken = (state: AuthState, action: { token: string }) => {
  return updateObject(state, { token: action.token });
};

const reducer = (state = initialState, action: AuthActionTypes): AuthState => {
  switch (action.type) {
    case AUTH_START:
      return authStart(state);
    case AUTH_SUCCESS:
      return authSuccess(state, action);
    case AUTH_FAIL:
      return authFail(state, action);
    case AUTH_LOGOUT:
      return authLogout(state);
    case FETCH_COACH_PROFILE_START:
      return fetchCoachProfileStart(state);
    case FETCH_COACH_PROFILE_SUCCESS:
      return fetchCoachProfileSuccess(state, action);
    case FETCH_COACH_PROFILE_FAIL:
      return fetchCoachProfileFail(state);

    case SAVE_COACH_PROFILE_START:
      return saveCoachProfileStart(state);
    case SAVE_COACH_PROFILE_SUCCESS:
      return saveCoachProfileSuccess(state, action);
    case SAVE_COACH_PROFILE_FAIL:
      return saveCoachProfileFail(state);

    case CREATE_COACH_PROFILE_START:
      return createCoachProfileStart(state);
    case CREATE_COACH_PROFILE_SUCCESS:
      return createCoachProfileSuccess(state, action);
    case CREATE_COACH_PROFILE_FAIL:
      return createCoachProfileFail(state);

    case SAVE_COACH_CODE_START:
      return saveCoachCodeStart(state);
    case SAVE_COACH_CODE_SUCCESS:
      return saveCoachCodeSuccess(state);
    case SAVE_COACH_CODE_FAIL:
      return saveCoachCodeFail(state);

    case FETCH_COACH_CODES_START:
      return fetchCoachCodesStart(state);
    case FETCH_COACH_CODES_SUCCESS:
      return fetchCoachCodesSuccess(state, action);
    case FETCH_COACH_CODES_FAIL:
      return fetchCoachCodesFail(state);

    case SET_TOKEN:
      return setToken(state, action);

    default:
      return state;
  }
};

export default reducer;
