import { AxiosPromise } from 'axios';
import axios from 'axios-base';
import { ApiBasicResponse, EmailRequest } from 'interfaces/api';
import { BASE_URL } from './helpers';
import { Programs, Workout } from 'interfaces/db';

const sendEmail = (
  toEmailAddress: string,
  templateName: string,
  emailPayload: { [key: string]: any },
): AxiosPromise<ApiBasicResponse> => {
  const url = `${BASE_URL}/v1-https-email-send`;

  const emailRequest: EmailRequest = {
    toEmailAddress,
    templateName,
    emailPayload,
  };

  return axios.post(url, emailRequest);
};

const fetchProgramById = async (programId: string): Promise<AxiosPromise<ApiBasicResponse>> => {
  const url = `${BASE_URL}/v1-https-programs-getProgramById?programId=${programId}`;

  return axios.get(url);
};

const createProgram = (token: string, program: Programs): AxiosPromise<ApiBasicResponse> => {
  const url = `${BASE_URL}/v1-https-programs-createProgram`;

  return axios.post(
    url,
    { program },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

const removeProgramById = (token: string, programId: string): AxiosPromise<ApiBasicResponse> => {
  const url = `${BASE_URL}/v1-https-programs-removeProgramById?programId=${programId}`;

  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const updateProgram = (token: string, programId: string, program: Programs): AxiosPromise<ApiBasicResponse> => {
  const url = `${BASE_URL}/v1-https-programs-updateProgram?programId=${programId}`;

  return axios.put(
    url,
    {
      programId,
      program,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

const getWorkoutById = (workoutId: string): AxiosPromise<ApiBasicResponse> => {
  const url = `${BASE_URL}/v1-https-workouts-getWorkoutById?workoutId=${workoutId}`;

  return axios.get(url);
};

const updateWorkout = (token: string, workoutId: string, workout: Workout): AxiosPromise<ApiBasicResponse> => {
  const url = `${BASE_URL}/v1-https-workouts-updateWorkout?workoutId=${workoutId}`;

  return axios.put(
    url,
    {
      workout,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

const createWorkout = (
  token: string,
  programId: string,
  workout: Workout,
  weekIndex: number,
  dayIndex: number,
  coachId: string | null,
): AxiosPromise<ApiBasicResponse> => {
  const url = `${BASE_URL}/v1-https-workouts-createWorkout`;

  return axios.post(
    url,
    {
      programId,
      weekIndex,
      dayIndex,
      workout,
      coachId,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

export {
  sendEmail,
  fetchProgramById,
  createProgram,
  removeProgramById,
  updateProgram,
  getWorkoutById,
  updateWorkout,
  createWorkout,
};
