import React, { FC } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import classes from './Dropdown.module.css';

interface Props {
  setShowDropDown: (value: boolean) => void;
  showDropDown: boolean;
  copyWorkoutHandler: () => void;
  pasteWorkoutHandler: () => void;
  setRestDayHandler: () => void;
}

const Dropdown: FC<Props> = ({
  setShowDropDown,
  showDropDown,
  copyWorkoutHandler,
  pasteWorkoutHandler,
  setRestDayHandler,
}) => {
  return (
    <OutsideClickHandler
      disabled={!showDropDown}
      onOutsideClick={() => setShowDropDown(false)}
    >
      <div
        className={classes.DropDownContainer}
        onClick={() => setShowDropDown(false)}
      >
        <div className={classes.DropDownBox}>
          <div
            className={classes.DropDownButtons}
            onClick={copyWorkoutHandler}
          >
            COPY
          </div>
          <div>|</div>
          <div
            className={classes.DropDownButtons}
            onClick={pasteWorkoutHandler}
          >
            PASTE
          </div>
          <div>|</div>
          <div
            className={classes.DropDownButtons}
            onClick={setRestDayHandler}
          >
            MAKE REST DAY
          </div>
        </div>
        <div className={classes.ArrowDown}></div>
      </div>
    </OutsideClickHandler>
  );
};

export default Dropdown;
