import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { eligibleCoach } from 'utils/helpers';
import classes from './ProgramCard.module.css';
import { CALENDAR } from 'utils/routes';
import OutsideAlerter from '../OutsideAlerter/OutsideAlerter';

const ProgramCard = ({
  title,
  program,
  programKey,
  programIndex,
  showAssignProgramModal,
  showRemoveProgramModal,
  duplicateProgramHandler,
  showMarkProgramLiveModal,
  showImageUploadModal,
  removeProgramFromMarketplace,
  userId,
}) => {
  const history = useHistory();
  const [showDropDown, setShowDropDown] = useState(false);

  const assignProgramHandler = () => {
    setShowDropDown(false);
    return showAssignProgramModal(programKey);
  };

  const handleDuplication = () => {
    setShowDropDown(false);
    return duplicateProgramHandler(programKey);
  };

  const handleRemoveFromMarketplace = (programKey, program) => {
    setShowDropDown(false);
    return removeProgramFromMarketplace(programKey, program);
  };

  const handleNav = () => {
    history.push({
      pathname: `${CALENDAR.URL}/${programKey}`,
    });
  };

  const setProgramIndex = () => {
    window.localStorage.setItem('programIndex', programIndex);
  };

  const dropdown = (
    <ul className={classes.DropDownContainer}>
      <li className={classes.DropDownButton} onClick={assignProgramHandler}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="#8F8E9B"
          className="bi bi-person-plus-fill"
          viewBox="0 0 16 16"
        >
          <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
          <path
            fillRule="evenodd"
            d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"
          />
        </svg>
        Assign
      </li>
      <li className={classes.LineRule}></li>
      <li className={classes.DropDownButton} onClick={handleDuplication}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="#8F8E9B"
          className="bi bi-subtract"
          viewBox="0 0 16 16"
        >
          <path d="M0 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v2h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-2H2a2 2 0 0 1-2-2V2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2z" />
        </svg>
        Duplicate
      </li>
      <li className={classes.LineRule}></li>
      <li className={classes.DropDownButton} onClick={() => showRemoveProgramModal(programKey)}>
        <img
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAC2ElEQVRoQ+1ZS2gTURQ9902s1mKw4AexIIigoq4UsS4EQaW7rBpcuApJN5GQmVlUd4GiVNrO1KKLNKmiK2l37oquCuKySJW6URA/oC5qpT9K865MsTBJa16cSYjgm+39nrm/d98j1OHL5XIiGu24KiCTAHUysEOhdg2EVwA/mp/fnc/l4qth3aCwCgYH83sigsYZdDGILgKmS2zEbDvxMYj8hkwoIPl8fufSkpgC43QYJwDMGpHWc5nMtZ9B9YQC4rqjt8F0M6jxMjkixzSTdlBdgYEMDDxuixgrXwCK+owvEnMfmF8QxPJWTnFERpjpLJhuAdjl41lo2b56IJ1OLwQBExiI44xdIchJv1EGEpaVeliLI65bTIB5zM9Lgruy2Z4ynbXo8ng2AXGcQpGAQzUoOAjgeAXfDAi1dSBGC4BTFfKzAD6rbDPwwbJSybKfUCnkOoXXAE6olDWZ/sa0Uic1kCZHwW9eHZHhoWI3C25XOU3Sm97cquL7Ozots8CKSoYkzWXt5ETV1FIp+VfpyvbrOIWYYD7WXADiR9ZO5qv5oATiuoVxMLqbCoTwzjRTRzQQ7w/oiNQzF3Vq+f5mldT6BOAtgP0VZ6ZFAC/hnaUIF8oCw5j6fRbrBNDmo80A+ArA644dm4LZ0Igw7pt26vr6ACUe9xlfn7ojIw/2ltZK3/xOGRFjXyaT+F55niOmuDfg3KHCPRDSGki1uvxjaumI6NRq0GTXqaVTS6fWelfW7VdPdn1Eqb446BrRNaJrRNeI3hD1qlulCoJfmep9RO8jeh/5T/YRwoQo4Q4TX2Kifl9DeS8k4tLgdjA9K2s0xJdFieakgHczeXiDRsw3iOm5NNC75VtMQ69M63nbrtJVHyDFfjD3qmw1lM6YNO1UV6gXq+HhsaMs5TSAOr/g1g6dmGNZu+dpKCCe8N2h0fOSaBDAGQDbanchFKdksPds0WdZPU9Umn4BuIU/b/ejCBUAAAAASUVORK5CYII="
          alt="delete program"
        />
        Delete
      </li>

      {eligibleCoach(userId) && (
        <>
          <li className={classes.LineRule}></li>
          <li
            className={classes.DropDownButton}
            onClick={() => {
              program?.onMarketplace
                ? handleRemoveFromMarketplace(programKey, program)
                : showMarkProgramLiveModal(program, programKey);
            }}
          >
            {program?.onMarketplace ? (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#8F8E9B"
                  className="bi bi-dash-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-7z" />
                </svg>
                Remove from Subscription App
              </>
            ) : (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#8F8E9B"
                  className="bi bi-plus-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                </svg>
                Add to Subscription App
              </>
            )}
          </li>
        </>
      )}
      <li className={classes.LineRule}></li>
      <li className={classes.DropDownButton} onClick={() => showImageUploadModal(programKey)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="#8F8E9B"
          className="bi bi-cloud-arrow-up-fill"
          viewBox="0 0 16 16"
        >
          <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 5.146a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2z" />
        </svg>
        Upload Image
      </li>
    </ul>
  );

  return (
    <div className={classes.cardContainer}>
      <OutsideAlerter close={() => setShowDropDown(false)} show={showDropDown}>
        <div className={classes.ProgramCard} onClick={setProgramIndex}>
          {showDropDown ? dropdown : null}

          <div className={classes.DotsContainer} onClick={() => setShowDropDown(!showDropDown)}>
            <div className={classes.dots}>
              <div className={classes.dot}></div>
            </div>
          </div>

          <div onClick={handleNav}>
            <div>
              <div className={classes.Title}>{title}</div>
              <div className={classes.Weeks}>
                {!program || !program.weeks
                  ? '0 WEEKS'
                  : program.weeks.length > 1
                  ? program.weeks.length + ' WEEKS'
                  : program.weeks.length + ' WEEK'}
              </div>
              {program?.onMarketplace && eligibleCoach(userId) && (
                <div className={classes.BlogCont}>
                  <div className={classes.blob}></div>
                  <span>On Marketplace</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </OutsideAlerter>
    </div>
  );
};

export default ProgramCard;
