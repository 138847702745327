import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { CLIENTS_TRAINING, CLIENTS_DOCUMENTS, CLIENTS_PROGRESS } from 'utils/routes';
import classes from './ClientButtons.module.css';

interface Props {
  clientKey: string;
  hasPrograms: boolean;
  showProgramTitle: () => void;
}

const ClientButtons: FC<Props> = ({ clientKey, hasPrograms, showProgramTitle }) => {
  const history = useHistory();

  const checkClientProgram = () => {
    if (!hasPrograms) {
      showProgramTitle();
    } else {
      history.push({
        pathname: `${CLIENTS_TRAINING.URL}/${clientKey}`,
      });
    }
  };

  const navigateToDocuments = () => {
    history.push({
      pathname: `${CLIENTS_DOCUMENTS.URL}/${clientKey}`,
    });
  };

  const navigateToProgress = () => {
    history.push({
      pathname: `${CLIENTS_PROGRESS.URL}/${clientKey}`,
    });
  };

  return (
    <div className={classes.ButtonGroup}>
      <div className={classes.ColumnOne}>
        <button className={classes.RedClientButton} onClick={checkClientProgram}>
          TRAINING
        </button>
        <div className={classes.VerticalDivider}></div>
        <button className={classes.RedClientButton} onClick={navigateToDocuments}>
          DOCUMENTS
        </button>
        <div className={classes.VerticalDivider}></div>
        <button className={classes.RedClientButton} onClick={navigateToProgress}>
          PROGRESS
        </button>
      </div>
    </div>
  );
};

export default ClientButtons;
