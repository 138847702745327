import React, { FC, useState, useEffect } from 'react';
import Logo from 'assets/images/logo.svg';
import GooglePlayBadge from 'assets/images/Google-play.svg';
import AppleStoreBadge from 'assets/images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg';
import AppGalleryBadge from 'assets/images/app-gallery.svg';
import InstagramIcon from 'assets/icons/icons8-instagram.svg';
import classes from './Footer.module.css';

const Footer: FC = () => {
  const [currentYear, setCurrentYear] = useState<number>();
  useEffect(() => {
    const date = new Date().getFullYear();
    setCurrentYear(date);
  }, []);
  return (
    <footer className={classes.footer}>
      <div className={classes.footerContainer}>
        <div>
          <img className={classes.logo} src={Logo} alt="logo" />
          <p>Download the athlete app today</p>
          <div className={classes.playStoreBadges}>
            <a
              href="https://play.google.com/store/apps/details?id=com.coachelite.coachelite"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={GooglePlayBadge} alt="Google play store badge" />
            </a>
            <a
              href="https://apps.apple.com/in/app/coachelite/id1512634037"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={AppleStoreBadge} alt="Apple-store download badge" />
            </a>
            <a
              href="https://appgallery.huawei.com/app/C104856625?sharePrepath=ag"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={AppGalleryBadge} alt="App Gallery download badge" />
            </a>
          </div>
        </div>
        <div className={classes.footerMiddle}>
          <p>HAVE QUESTIONS? SAY HELLO!</p>
          <a href="mailto:support@coachelite.co">
            support<span>@</span>coachelite.co
          </a>
        </div>
        <div className={classes.footerRHS}>
          <div className={classes.socialLinks}>
            <h3>Social</h3>
            <ul>
              <li>
                <a
                  href="https://www.instagram.com/coacheliteapp/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={InstagramIcon} alt="instagram" /> Instagram{' '}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className={classes.footerBottom}>
        <p>© {currentYear} CoachElite (Pty) Ltd.</p>
        <div>
          <a
            href="https://docs.google.com/document/d/16Rqu8_QSnGp_t1vUJaTndO8dHltGwAmyZKiSBj_xogY/edit"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy policy
          </a>
          <a
            href="https://docs.google.com/document/d/1CoVHks0TpuPR4-ltbiPuSgIyPyJf28K2P0kJdd3q4Do/edit"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms &amp; conditions
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
