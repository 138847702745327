import {
  FETCH_PROGRAMS_START,
  FETCH_PROGRAMS_SUCCESS,
  FETCH_PROGRAMS_FAIL,
  FETCH_PROGRAM_START,
  FETCH_PROGRAM_SUCCESS,
  FETCH_PROGRAM_FAIL,
  SET_PROGRAM_INDEX,
  INITIALISE_PROGRAM_INIT,
  INITIALISE_PROGRAM_START,
  INITIALISE_PROGRAM_SUCCESS,
  INITIALISE_PROGRAM_FAIL,
  REMOVE_PROGRAM_INIT,
  REMOVE_PROGRAM_START,
  REMOVE_PROGRAM_SUCCESS,
  REMOVE_PROGRAM_FAIL,
  DUPLICATE_WEEK,
  ADD_WEEK,
  REMOVE_WEEK,
  PUT_PROGRAM_INIT,
  PUT_PROGRAM_START,
  PUT_PROGRAM_SUCCESS,
  PUT_PROGRAM_FAIL,
  SET_CURRENT_DAY,
  SET_REST_DAY,
  REMOVE_REST_DAY,
  COPY_WORKOUT,
  PASTE_WORKOUT,
  ADD_EXERCISE,
  REMOVE_EXERCISE,
  ADD_SUPERSET,
  ADD_TABLE_SET,
  PROGRAM_TABLE_SET_CHANGE,
  ADD_EXERCISE_NOTES,
  ADD_EXERCISE_TYPE,
  ADD_WORKOUT_NOTES,
  SET_WORKOUT_TITLE,
  GO_BACK_PROGRAM,
  SAVE_WORKOUT_START,
  SAVE_WORKOUT_FAIL,
  SAVE_WORKOUT_SUCCESS,
} from '../actions/actionTypes';
import { updateObject } from '../utility';
import { ProgramsState } from 'interfaces/state';
import { FirebaseObject } from 'interfaces/utils';
import { Set, Exercise, Week, Programs, Workout } from 'interfaces/db';
import { ProgramsActionTypes } from 'interfaces/actions/programs';

const initialState: ProgramsState = {
  programs: null,
  loading: false,
  loadingSaving: false,
  programIndex: 0,
  dayIndex: null,
  weekIndex: null,
  copiedWorkout: null,
  changed: false,
};

const initialiseProgramInit = (state: ProgramsState): ProgramsState => {
  return state;
};

const initialiseProgramStart = (state: ProgramsState): ProgramsState => {
  return updateObject(state, { loading: true });
};

const initialiseProgramSuccess = (state: ProgramsState, action: { name: string; program: Programs }): ProgramsState => {
  const programsCopy = Object.assign({}, state.programs);
  programsCopy[action.name] = action.program;

  return updateObject(state, {
    loading: false,
    programs: programsCopy,
  });
};

const initialiseProgramFail = (state: ProgramsState): ProgramsState => {
  return updateObject(state, { loading: false });
};

const removeProgramInit = (state: ProgramsState): ProgramsState => {
  return state;
};

const removeProgramStart = (state: ProgramsState): ProgramsState => {
  return updateObject(state, { loading: true });
};

const removeProgramSuccess = (state: ProgramsState, action: { programKey: string }): ProgramsState => {
  const programsCopy = Object.assign({}, state.programs);
  delete programsCopy[action.programKey];

  return updateObject(state, {
    loading: false,
    // saved: true,
    programs: programsCopy,
  });
};

const removeProgramFail = (state: ProgramsState): ProgramsState => {
  return updateObject(state, { loading: false });
};

const setProgramIndex = (state: ProgramsState, action: { programIndex: number }): ProgramsState => {
  return updateObject(state, { programIndex: action.programIndex });
};

const fetchProgramsStart = (state: ProgramsState): ProgramsState => {
  return updateObject(state, { loading: true });
};

const fetchProgramsSuccess = (state: ProgramsState, action: { programs: FirebaseObject<Programs> }): ProgramsState => {
  const updatedPrograms: FirebaseObject<Programs> = {};

  Object.keys(action.programs).forEach((programId) => {
    const program = action.programs[programId];
    const updatedProgram = { ...program, isWorkoutDataPopulated: false };
    updatedPrograms[programId] = updatedProgram;
  });
  return updateObject(state, {
    programs: updatedPrograms,
    loading: false,
    changed: false,
  });
};

const fetchProgramsFail = (state: ProgramsState): ProgramsState => {
  return updateObject(state, { loading: false });
};

const fetchProgramStart = (state: ProgramsState): ProgramsState => {
  return updateObject(state, { loading: true, loadingSaving: false });
};

const fetchProgramSuccess = (state: ProgramsState, action: { programId: string; program: Programs }): ProgramsState => {
  const programsCopy: FirebaseObject<Programs> = Object.assign({}, state.programs);
  programsCopy[action.programId] = { ...action.program, isWorkoutDataPopulated: true };
  return updateObject(state, {
    programs: programsCopy,
    loading: false,
    changed: false,
  });
};

const fetchProgramFail = (state: ProgramsState): ProgramsState => {
  return updateObject(state, { loading: false });
};

const copyWorkout = (
  state: ProgramsState,
  action: {
    weekIndex: number;
    dayIndex: number;
    programIndex: number;
  },
): ProgramsState => {
  const programsCopy = Object.assign({}, state.programs);
  const key = Object.keys(programsCopy)[action.programIndex];

  const copiedWorkout = programsCopy[key]?.weeks?.[action.weekIndex].Days[action.dayIndex].workout;

  return updateObject(state, { copiedWorkout });
};

const pasteWorkout = (
  state: ProgramsState,
  action: {
    weekIndex: number;
    dayIndex: number;
    programIndex: number;
  },
): ProgramsState => {
  const programsCopy = Object.assign({}, state.programs);
  const key = Object.keys(programsCopy)[action.programIndex];

  const newCopy = JSON.parse(JSON.stringify(state.copiedWorkout));

  // destructure weeks
  const { weeks = [] } = programsCopy[key];
  weeks[action.weekIndex].Days[action.dayIndex].workout = newCopy;

  return updateObject(state, {
    programs: programsCopy,
    changed: true,
  });
};

const setRestDay = (
  state: ProgramsState,
  action: {
    weekIndex: number;
    dayIndex: number;
    programIndex: number;
  },
): ProgramsState => {
  const programsCopy = Object.assign({}, state.programs);
  const key = Object.keys(programsCopy)[action.programIndex];

  // destructure weeks
  const { weeks = [] } = programsCopy[key];
  weeks[action.weekIndex].Days[action.dayIndex].rest = true;
  return updateObject(state, {
    programs: programsCopy,
    changed: true,
  });
};

const removeRestDay = (
  state: ProgramsState,
  action: {
    weekIndex: number;
    dayIndex: number;
    programIndex: number;
  },
): ProgramsState => {
  const programsCopy = Object.assign({}, state.programs);
  const key = Object.keys(programsCopy)[action.programIndex];

  // destructure weeks
  const { weeks = [] } = programsCopy[key];
  weeks[action.weekIndex].Days[action.dayIndex].rest = false;
  return updateObject(state, {
    programs: programsCopy,
    changed: true,
  });
};

const putProgramInit = (state: ProgramsState): ProgramsState => {
  return updateObject(state, { loadingSaving: false });
};

const putProgramStart = (state: ProgramsState): ProgramsState => {
  return updateObject(state, { loadingSaving: true });
};

const putProgramSuccess = (state: ProgramsState, action: { programKey: string; program: Programs }): ProgramsState => {
  const programsCopy = Object.assign({}, state.programs);
  programsCopy[action.programKey] = action.program;

  // Modify each day in each week of the program, setting isDuplicated to false
  const weeks = programsCopy[action.programKey]?.weeks;
  if (weeks) {
    for (const week of weeks) {
      if (!week.Days) break;
      for (const day of week.Days) {
        day.isDuplicated = false;
      }
    }
  }

  return updateObject(state, {
    programs: programsCopy,
    loadingSaving: false,
    changed: false,
  });
};

const putProgramFail = (state: ProgramsState): ProgramsState => {
  return updateObject(state, { loadingSaving: false });
};

const setCurrentDay = (state: ProgramsState, action: { dayIndex: number; weekIndex: number }): ProgramsState => {
  return updateObject(state, {
    dayIndex: action.dayIndex,
    weekIndex: action.weekIndex,
  });
};

const removeWeek = (state: ProgramsState, action: { weekIndex: number }): ProgramsState => {
  const programsCopy = Object.assign({}, state.programs);
  const key = Object.keys(programsCopy)[state.programIndex];

  // destructure weeks
  const { weeks = [] } = programsCopy[key];
  weeks.splice(weeks.indexOf(weeks[action.weekIndex]), 1);

  return updateObject(state, {
    programs: programsCopy,
    changed: true,
  });
};

const addWeek = (state: ProgramsState, action: { week: Week; key: string }): ProgramsState => {
  const programsCopy = Object.assign({}, state.programs);

  // destructure weeks
  const { weeks = [] } = programsCopy[action.key];

  !programsCopy[action.key].weeks ? (programsCopy[action.key].weeks = [action.week]) : weeks.push(action.week);

  return updateObject(state, {
    programs: programsCopy,
    changed: true,
  });
};

const addExercise = (
  state: ProgramsState,
  action: { exercise: Exercise; weekIndex: number; dayIndex: number },
): ProgramsState => {
  const programsCopy = Object.assign({}, state.programs);
  const key = Object.keys(programsCopy)[state.programIndex];

  // destructure weeks
  const { weeks = [] } = programsCopy[key];

  if (weeks[action.weekIndex].Days[action.dayIndex].workout !== undefined) {
    if (weeks[action.weekIndex].Days[action.dayIndex].workout.exercises !== undefined) {
      (weeks[action.weekIndex].Days[action.dayIndex].workout.exercises as Exercise[][]).push([action.exercise]);
    } else {
      weeks[action.weekIndex].Days[action.dayIndex].workout = {
        ...weeks[action.weekIndex].Days[action.dayIndex].workout,
        exercises: [[action.exercise]],
      };
    }
  } else {
    weeks[action.weekIndex].Days[action.dayIndex].workout = {
      ...weeks[action.weekIndex].Days[action.dayIndex].workout,
      exercises: [[action.exercise]],
    };
  }
  // programsCopy[key].weeks[action.weekIndex].Days[action.dayIndex].workout.exercises.push([action.exercise])
  return updateObject(state, {
    programs: programsCopy,
    changed: true,
  });
};

const addSuperSet = (
  state: ProgramsState,
  action: {
    exercise: Exercise;
    index: number;
    weekIndex: number;
    dayIndex: number;
  },
): ProgramsState => {
  const programsCopy = Object.assign({}, state.programs);
  const key = Object.keys(programsCopy)[state.programIndex];

  // destructure weeks
  const { weeks = [] } = programsCopy[key];

  if (!weeks[action.weekIndex].Days[action.dayIndex].workout.exercises) {
    return state;
  }

  (weeks[action.weekIndex].Days[action.dayIndex].workout.exercises as Exercise[][])[action.index].push(action.exercise);
  return updateObject(state, {
    programs: programsCopy,
    changed: true,
  });
};

const addTableSets = (
  state: ProgramsState,
  action: {
    sets: Set;
    group: number;
    index: number;
    weekIndex: number;
    dayIndex: number;
  },
): ProgramsState => {
  const programsCopy = Object.assign({}, state.programs);
  const key = Object.keys(programsCopy)[state.programIndex];

  // destructure weeks
  const { weeks = [] } = programsCopy[key];

  if (!weeks[action.weekIndex].Days[action.dayIndex].workout.exercises) {
    return state;
  }

  const exercise = (weeks[action.weekIndex].Days[action.dayIndex].workout.exercises as Exercise[][])[action.group][
    action.index
  ];

  if (action.index === 0) {
    exercise.sets = [action.sets];
  } else if (action.index > 0) {
    const { sets = [] } = exercise;
    sets.push(action.sets);
  }
  return updateObject(state, { programs: programsCopy });
};

const addWorkoutNotes = (
  state: ProgramsState,
  action: { notes: string; weekIndex: number; dayIndex: number },
): ProgramsState => {
  const programsCopy = Object.assign({}, state.programs);
  const key = Object.keys(programsCopy)[state.programIndex];

  // destructure weeks
  const { weeks = [] } = programsCopy[key];

  if (weeks[action.weekIndex].Days[action.dayIndex].workout === undefined) {
    weeks[action.weekIndex].Days[action.dayIndex].workout = {
      exercises: [],
      finished: false,
      workoutTitle: '',
      workoutNotes: { coach: action.notes, athlete: '' },
      workoutDuration: '',
      dayIndex: action.dayIndex,
      weekIndex: action.weekIndex,
    };
  } else {
    weeks[action.weekIndex].Days[action.dayIndex].workout.workoutNotes = {
      coach: action.notes,
      athlete: '',
    };
  }
  return updateObject(state, {
    programs: programsCopy,
    changed: true,
  });
};

const setWorkoutTitle = (
  state: ProgramsState,
  action: {
    workoutTitle: string;
    weekIndex: number;
    dayIndex: number;
  },
): ProgramsState => {
  const programsCopy = Object.assign({}, state.programs);
  const key = Object.keys(programsCopy)[state.programIndex];

  // destructure weeks
  const { weeks = [] } = programsCopy[key];

  if (weeks[action.weekIndex].Days[action.dayIndex].workout === undefined) {
    weeks[action.weekIndex].Days[action.dayIndex].workout = {
      exercises: [],
      finished: false,
      workoutTitle: action.workoutTitle,
      workoutNotes: { coach: '', athlete: '' },
      workoutDuration: '',
      dayIndex: action.dayIndex,
      weekIndex: action.weekIndex,
    };
  } else {
    weeks[action.weekIndex].Days[action.dayIndex].workout.workoutTitle = action.workoutTitle;
  }
  return updateObject(state, {
    programs: programsCopy,
    changed: true,
  });
};

const addExerciseType = (
  state: ProgramsState,
  action: {
    exerciseType: string;
    group: number;
    index: number;
    weekIndex: number;
    dayIndex: number;
  },
): ProgramsState => {
  const programsCopy = Object.assign({}, state.programs);
  const key = Object.keys(programsCopy)[state.programIndex];

  // destructure weeks
  const { weeks = [] } = programsCopy[key];

  if (!weeks[action.weekIndex].Days[action.dayIndex].workout.exercises) {
    return state;
  }

  (weeks[action.weekIndex].Days[action.dayIndex].workout.exercises as Exercise[][])[action.group][action.index].type =
    action.exerciseType;

  return updateObject(state, {
    programs: programsCopy,
    changed: true,
  });
};

const addExerciseNotes = (
  state: ProgramsState,
  action: {
    exerciseNotes: string;
    group: number;
    index: number;
    weekIndex: number;
    dayIndex: number;
  },
): ProgramsState => {
  const programsCopy = Object.assign({}, state.programs);
  const key = Object.keys(programsCopy)[state.programIndex];

  // destructure weeks
  const { weeks = [] } = programsCopy[key];

  if (!weeks[action.weekIndex].Days[action.dayIndex].workout.exercises) {
    return state;
  }

  (weeks[action.weekIndex].Days[action.dayIndex].workout.exercises as Exercise[][])[action.group][
    action.index
  ].exerciseNotes = {
    coach: action.exerciseNotes,
    athlete: '',
  };

  return updateObject(state, {
    programs: programsCopy,
    changed: true,
  });
};

const removeExercise = (
  state: ProgramsState,
  action: {
    group: number;
    index: number;
    weekIndex: number;
    dayIndex: number;
  },
): ProgramsState => {
  const programsCopy = Object.assign({}, state.programs);
  const key = Object.keys(programsCopy)[state.programIndex];

  // destructure weeks
  const { weeks = [] } = programsCopy[key];

  const newGroup = (weeks[action.weekIndex].Days[action.dayIndex].workout.exercises as Exercise[][])[
    action.group
  ].filter((_, index) => index !== action.index);

  (weeks[action.weekIndex].Days[action.dayIndex].workout.exercises as Exercise[][])[action.group] = newGroup;

  return updateObject(state, {
    programs: programsCopy,
    changed: true,
  });
};

const duplicateWeek = (state: ProgramsState, action: { weekIndex: number }): ProgramsState => {
  const programsCopy = Object.assign({}, state.programs);

  const key = Object.keys(programsCopy)[state.programIndex];

  // destructure weeks
  const { weeks = [] } = programsCopy[key];

  const tempWeek = weeks[action.weekIndex];
  weeks.push(tempWeek);

  return updateObject(state, {
    programs: programsCopy,
    changed: true,
  });
};

const goBackProgram = (state: ProgramsState): ProgramsState => {
  return updateObject(state, { changed: false });
};

const programTableSetChange = (state: ProgramsState): ProgramsState => {
  return updateObject(state, { changed: true });
};

const saveWorkoutStart = (state: ProgramsState): ProgramsState => {
  return updateObject(state, {});
};

const saveWorkoutSuccess = (
  state: ProgramsState,
  action: {
    programKey: string;
    updatedWorkout: Workout;
    weekIndex: number;
    dayIndex: number;
  },
): ProgramsState => {
  const programsCopy = Object.assign({}, state.programs);
  const currentProgram = programsCopy[action.programKey];
  const day = currentProgram?.weeks?.[action.weekIndex]?.Days?.[action.dayIndex];

  if (day) {
    day.workout = action.updatedWorkout;
  }

  return updateObject(state, {
    programs: programsCopy,
    loadingSaving: false,
    changed: false,
  });
};

const saveWorkoutFail = (state: ProgramsState): ProgramsState => {
  return updateObject(state, { loadingSaving: false });
};

const reducer = (state = initialState, action: ProgramsActionTypes): ProgramsState => {
  switch (action.type) {
    // Fetching all programs
    case FETCH_PROGRAMS_START:
      return fetchProgramsStart(state);
    case FETCH_PROGRAMS_SUCCESS:
      return fetchProgramsSuccess(state, action);
    case FETCH_PROGRAMS_FAIL:
      return fetchProgramsFail(state);

    // Fetching individual program
    case FETCH_PROGRAM_START:
      return fetchProgramStart(state);
    case FETCH_PROGRAM_SUCCESS:
      return fetchProgramSuccess(state, action);
    case FETCH_PROGRAM_FAIL:
      return fetchProgramFail(state);

    case SET_PROGRAM_INDEX:
      return setProgramIndex(state, action);

    case INITIALISE_PROGRAM_INIT:
      return initialiseProgramInit(state);
    case INITIALISE_PROGRAM_START:
      return initialiseProgramStart(state);
    case INITIALISE_PROGRAM_SUCCESS:
      return initialiseProgramSuccess(state, action);
    case INITIALISE_PROGRAM_FAIL:
      return initialiseProgramFail(state);

    case REMOVE_PROGRAM_INIT:
      return removeProgramInit(state);
    case REMOVE_PROGRAM_START:
      return removeProgramStart(state);
    case REMOVE_PROGRAM_SUCCESS:
      return removeProgramSuccess(state, action);
    case REMOVE_PROGRAM_FAIL:
      return removeProgramFail(state);

    case DUPLICATE_WEEK:
      return duplicateWeek(state, action);
    case ADD_WEEK:
      return addWeek(state, action);
    case REMOVE_WEEK:
      return removeWeek(state, action);

    case PUT_PROGRAM_INIT:
      return putProgramInit(state);
    case PUT_PROGRAM_START:
      return putProgramStart(state);
    case PUT_PROGRAM_SUCCESS:
      return putProgramSuccess(state, action);
    case PUT_PROGRAM_FAIL:
      return putProgramFail(state);

    case SET_CURRENT_DAY:
      return setCurrentDay(state, action);
    case SET_REST_DAY:
      return setRestDay(state, action);
    case REMOVE_REST_DAY:
      return removeRestDay(state, action);

    case COPY_WORKOUT:
      return copyWorkout(state, action);
    case PASTE_WORKOUT:
      return pasteWorkout(state, action);

    case ADD_EXERCISE:
      return addExercise(state, action);
    case REMOVE_EXERCISE:
      return removeExercise(state, action);

    case ADD_SUPERSET:
      return addSuperSet(state, action);
    case ADD_TABLE_SET:
      return addTableSets(state, action);
    case PROGRAM_TABLE_SET_CHANGE:
      return programTableSetChange(state);
    case ADD_EXERCISE_NOTES:
      return addExerciseNotes(state, action);
    case ADD_EXERCISE_TYPE:
      return addExerciseType(state, action);
    case ADD_WORKOUT_NOTES:
      return addWorkoutNotes(state, action);

    case SET_WORKOUT_TITLE:
      return setWorkoutTitle(state, action);

    case GO_BACK_PROGRAM:
      return goBackProgram(state);

    case SAVE_WORKOUT_START:
      return saveWorkoutStart(state);
    case SAVE_WORKOUT_SUCCESS:
      return saveWorkoutSuccess(state, action);
    case SAVE_WORKOUT_FAIL:
      return saveWorkoutFail(state);

    default:
      return state;
  }
};

export default reducer;
