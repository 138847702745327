import React, { FC, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, NavLink } from 'react-router-dom';

import { RootState } from 'store';
import { getInitials } from 'utils/helpers';
import { DASHBOARD } from 'utils/routes';
import withAuth from 'utils/withAuth';
import Spinner from 'components/UI/Spinner/Spinner';
import { fetchClients } from 'store/actions/clients';
import Layout from '../Layout';
import classes from './Dashboard.module.css';

const mapStateToProps = ({ clients, auth }: RootState) => {
  return {
    token: auth.token,
    userId: auth.userId,
    clients: clients.clients,
    loading: clients.loading,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onFetchClients: (token: string, userId: string) => dispatch(fetchClients(token, userId)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & RouteComponentProps;

const Dashboard: FC<Props> = ({ token, userId, loading, clients, onFetchClients }) => {
  useEffect(() => {
    if (token && userId) {
      onFetchClients(token, userId);
    }
  }, [token, userId, onFetchClients]);

  if (loading) {
    return (
      <Layout loading={loading} heading={DASHBOARD.TITLE}>
        <div className={classes.wrapper}>
          <Spinner />
        </div>
      </Layout>
    );
  }
  window.localStorage.removeItem('selectedProgramKey');
  const clientsList = Object.keys(clients || {})
    .filter((clientKey: string) => {
      const user = clients?.[clientKey];
      const { hasPrograms = false } = user || {};

      return !hasPrograms;
    })
    .map((clientKey: string, index: number) => {
      const user = clients?.[clientKey];
      const { profile } = user || {};
      const { fullname } = profile || {};

      const initials = getInitials(fullname);

      return (
        <div key={index} className={classes.clientsWrapper}>
          <NavLink
            to={{
              pathname: `/programs`,
            }}
            exact
          >
            <div className={classes.Client}>
              <div className={classes.ImageDiv}>{initials}</div>

              <div className={classes.RightContainer}>
                <h3 className={classes.ClientName}>{fullname}</h3>

                <div className={classes.description}>Go Assign A Program</div>
              </div>
            </div>
          </NavLink>
        </div>
      );
    });

  return (
    <Layout loading={loading} heading={DASHBOARD.TITLE}>
      <div className={classes.wrapper}>
        <div className={classes.title}>NEEDS TRAINING</div>
        {!clientsList.length ? (
          <p className={classes.emptyList}>None of your clients need training right now.</p>
        ) : (
          <div className={classes.listWrapper}>{clientsList}</div>
        )}
      </div>
    </Layout>
  );
};

export default connector(withAuth(Dashboard));
