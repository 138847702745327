import React, { FC, useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { ClientDocumentParams } from 'interfaces/routes';
import { RootState } from 'store';
import withAuth from 'utils/withAuth';
import { storage } from 'utils/firebase';
import { Document as UploadForm } from 'components';
import DocumentCard from '../../components/DocumentCard/DocumentCard';
import TabNav from 'components/UI/TabNav/TabNav';
import { DOCUMENTS } from 'utils/routes';
import { CoachProfile, Profile, Documents as DocumentsType } from 'interfaces/db';
import { getToday } from 'utils/helpers';
import Modal from 'components/UI/Modal/Modal';
import { saveClientProfile } from 'store/actions/clients';
import { saveCoachProfile } from 'store/actions/auth';
import Layout from '../Layout';
import classes from './ClientDocuments.module.css';
import { addDocument, updateDocument, deleteDocument, fetchDocument } from 'store/actions/documents';
import { UploadTaskSnapshot, UploadTask } from '@firebase/storage-types';

const mapStateToProps = ({ auth, clients, documents }: RootState) => {
  const { userId, token, coachProfile } = auth;
  return {
    token,
    userId,
    loading: documents.loading,
    coachProfile,
    clients: clients.clients,
    documents: documents.documents,
    coachKey: Object.keys(coachProfile || {})[0],
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onSaveCoachProfile: (coachProfile: CoachProfile, coachProfileKey: string, token: string) =>
      dispatch(saveCoachProfile(coachProfile, coachProfileKey, token)),
    onSaveClientProfile: (clientProfile: Profile, clientKey: string, token: string) =>
      dispatch(saveClientProfile(clientProfile, clientKey, token)),
    onDeleteDocument: (url: string, token: string, userId: string, document: DocumentsType, documentId: string) =>
      dispatch(deleteDocument(url, token, userId, document, documentId)),
    onAddDocument: (token: string, document: DocumentsType, userId?: string) =>
      dispatch(addDocument(token, document, userId)),
    onUpdateDocument: (token: string, document: DocumentsType, documentId: string) =>
      dispatch(updateDocument(token, document, documentId)),
    onFetchDocument: (token: string, documentKey: string) => dispatch(fetchDocument(token, documentKey)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & RouteComponentProps<ClientDocumentParams>;

const ClientDocuments: FC<Props> = ({
  token,
  userId,
  clients,
  documents,
  loading,
  coachKey,
  coachProfile,
  onFetchDocument,
  onDeleteDocument,
  onSaveCoachProfile,
  onSaveClientProfile,
  onAddDocument,
  onUpdateDocument,
  match,
}) => {
  const { clientKey } = match.params;
  const [progress, setProgress] = useState(0);
  const [isModalOpen, setModalOpen] = useState(false);
  // const categories = Object.values( || ['Meal Plans', 'Ebooks']);

  const client = clients?.[clientKey || ''];
  const [categories, setCategories] = useState<string[]>([]);

  const [selectedTab, setSelectedTab] = useState<string>(categories[0]);

  useEffect(() => {
    for (const docKey of client?.profile?.documents || []) {
      onFetchDocument(token || '', docKey);
    }
  }, [client?.profile?.documents, token, userId, onFetchDocument]);

  useEffect(() => {
    const allClientCategories = Object.keys(documents || {})
      ?.map((docKey) => {
        if (client?.profile?.documents?.includes(docKey)) {
          return documents?.[docKey]?.category || 'null';
        }
        return 'null';
      })
      .filter((val) => val !== 'null');
    // get all unique values from mapped array
    const clientCategories = Array.from(new Set(allClientCategories));
    setCategories(clientCategories);
    setSelectedTab(clientCategories[0]);
  }, [documents]);

  const uploadProgress: (a: UploadTaskSnapshot) => any = (snapshot) => {
    setProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
  };

  const uploadError: (a: Error) => any = (error) => {
    console.log(error);
  };

  const uploadSuccess = ({
    uploadTask,
    title,
    fileName,
    fileSize,
    category,
  }: {
    uploadTask: UploadTask;
    title: string;
    fileName: string;
    fileSize: number;
    category: string;
  }) => {
    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL: string) => {
      if (coachProfile && coachProfile[coachKey] && token && documents && userId) {
        const { totalDocumentsSize = 0, documentCount, recentDocuments = [] } = coachProfile[coachKey];

        const newCoachProfile = {
          ...coachProfile[coachKey],
          totalDocumentsSize: totalDocumentsSize + fileSize,
          documentCount: documentCount && documentCount + 1,
          recentDocuments: recentDocuments,
        };

        const newDocument = {
          documentId: uuidv4(),
          clientList: [],
          downloadURL: downloadURL,
          fileName: fileName,
          title: title,
          userId: userId,
          fileSize: fileSize,
          uploadedAt: getToday(),
          category: category,
        };

        onAddDocument(token, newDocument, userId);
        onSaveCoachProfile(newCoachProfile, coachKey, token);
        setModalOpen(false);
        setProgress(0);
      }
    });
  };

  const handleSubmit = async ({ title, file, category }: { title: string; file: FileList; category: string }) => {
    const { name, size } = file[0];
    const storageRef = storage.ref(`${userId}/${uuidv4()}`);
    const uploadTask: any = storageRef.put(file[0]);

    uploadTask.on('state_changed', uploadProgress, uploadError, () =>
      uploadSuccess({
        uploadTask,
        title,
        fileName: name,
        fileSize: size,
        category,
      }),
    );
  };

  const filteredDocuments =
    client?.profile?.documents &&
    Object.fromEntries(
      Object.entries(documents || {})?.filter(
        ([key, value]) =>
          value?.category?.toLowerCase() === selectedTab?.toLowerCase() && client?.profile?.documents?.includes(key),
      ),
    );

  return (
    <Layout loading={loading} heading={DOCUMENTS.TITLE}>
      <div className={classes.Container}>
        <TabNav setSelectedTab={setSelectedTab} selectedTab={selectedTab} tabHeadings={categories} />
        <div className={classes.FilesWrapper}>
          {client?.profile?.documents &&
            Object.keys(filteredDocuments || {})?.map((key) => {
              const document = documents?.[key];
              if (!document) {
                return null;
              }
              return (
                <DocumentCard
                  key={key}
                  clientKey={clientKey}
                  documentKey={key}
                  document={document}
                  title={document?.title}
                  token={token}
                  userId={userId}
                  clients={clients}
                  fileName={document?.fileName}
                  downloadURL={document?.downloadURL}
                  onDeleteDocument={onDeleteDocument}
                  onSaveClientProfile={onSaveClientProfile}
                  onUpdateDocument={onUpdateDocument}
                />
              );
            })}
        </div>
      </div>

      {/* <button
        type="button"
        onClick={() => setModalOpen(true)}
        className={classes.AddProgramButtonWrapper}
      >
        <div className={classes.AddProgramText}>Upload Document</div>
        <div className={classes.AddProgramButton}>+</div>
      </button> */}

      <Modal isModalOpen={isModalOpen} openModal={() => setModalOpen(false)}>
        <div className={classes.modalContainer}>
          <div className={classes.ModalClose}>
            <img
              onClick={() => setModalOpen(false)}
              src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGlkPSJDYXBhXzEiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDM4Ni42NjcgMzg2LjY2NyIgaGVpZ2h0PSI1MTIiIHZpZXdCb3g9IjAgMCAzODYuNjY3IDM4Ni42NjciIHdpZHRoPSI1MTIiIGNsYXNzPSJob3ZlcmVkLXBhdGhzIj48Zz48cGF0aCBkPSJtMzg2LjY2NyA0NS41NjQtNDUuNTY0LTQ1LjU2NC0xNDcuNzcgMTQ3Ljc2OS0xNDcuNzY5LTE0Ny43NjktNDUuNTY0IDQ1LjU2NCAxNDcuNzY5IDE0Ny43NjktMTQ3Ljc2OSAxNDcuNzcgNDUuNTY0IDQ1LjU2NCAxNDcuNzY5LTE0Ny43NjkgMTQ3Ljc2OSAxNDcuNzY5IDQ1LjU2NC00NS41NjQtMTQ3Ljc2OC0xNDcuNzd6IiBkYXRhLW9yaWdpbmFsPSIjMDAwMDAwIiBjbGFzcz0iaG92ZXJlZC1wYXRoIGFjdGl2ZS1wYXRoIiBzdHlsZT0iZmlsbDojRkZGRkZGIiBkYXRhLW9sZF9jb2xvcj0iIzAwMDAwMCI+PC9wYXRoPjwvZz4gPC9zdmc+"
              alt="close"
            />
          </div>

          <p className={classes.ModalLargeText}>Upload Document</p>
          <UploadForm
            selectedTab={selectedTab}
            progress={progress}
            handleSubmit={handleSubmit}
            categories={categories}
          />
        </div>
      </Modal>
    </Layout>
  );
};

export default connector(withAuth(ClientDocuments));
