import React, { FC } from 'react';

import NavItem from './NavItem/NavItem';
import classes from './NavItems.module.css';

import {
  CLIENTS,
  PROGRAMS,
  DOCUMENTS,
  DASHBOARD,
  EXERCISE_LIBRARY,
  COMMUNITY,
  ANALYTICS,
} from 'utils/routes';
import dashboardIcon from 'assets/icons/dashboard.svg';
import programNavIcon from 'assets/icons/programs.svg';
import clientNavIcon from 'assets/icons/clients-icon-new.svg';
import exerciseLibIcon from 'assets/icons/exercise-library.svg';
import documentsIcon from 'assets/icons/documents.svg';
import communityIcon from 'assets/icons/chatbubbles.svg';
import analyticsIcon from 'assets/icons/analytics.svg';
import { eligibleCoach } from 'utils/helpers';

interface Props {
  userId?: string;
}

const NavItems: FC<Props> = ({ userId }) => (
  <ul className={classes.NavItems}>
    <NavItem link={DASHBOARD.URL} img={dashboardIcon}>
      {DASHBOARD.TITLE}
    </NavItem>

    <NavItem link={CLIENTS.URL} img={clientNavIcon}>
      {CLIENTS.TITLE}
    </NavItem>

    <NavItem link={PROGRAMS.URL} img={programNavIcon}>
      {PROGRAMS.TITLE}
    </NavItem>

    <NavItem link={DOCUMENTS.URL} img={documentsIcon}>
      {DOCUMENTS.TITLE}
    </NavItem>

    <NavItem link={EXERCISE_LIBRARY.URL} img={exerciseLibIcon}>
      {EXERCISE_LIBRARY.TITLE}
    </NavItem>

    <NavItem link={COMMUNITY.URL} img={communityIcon}>
      {COMMUNITY.TITLE}
    </NavItem>

    {eligibleCoach(userId || null, true) && (
      <NavItem link={ANALYTICS.URL} img={analyticsIcon}>
        {ANALYTICS.TITLE}
      </NavItem>
    )}
  </ul>
);

export default NavItems;
