import React, {
  FC,
  useRef,
  useState,
  ReactNode,
  useEffect,
  ChangeEvent,
  KeyboardEvent,
} from 'react';
import Fuse from 'fuse.js';
import cls from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';

import { Exercise } from 'interfaces/db';
import { getInitials } from 'utils/helpers';
import classes from './Autocomplete.module.css';

type Suggestions = Exercise & {
  author: 'coach' | 'admin';
};

const Autocomplete: FC<{
  fullname: string;
  footer: ReactNode;
  exerciseType: string;
  suggestions: Suggestions[];
  setExerciseFromLib: (exercise: Suggestions) => void;
  exerciseTypeOnChangeHandler: (value: string) => void;
}> = ({
  footer,
  fullname,
  suggestions,
  exerciseType,
  setExerciseFromLib,
  exerciseTypeOnChangeHandler,
}) => {
  const containerRef = useRef<HTMLUListElement>(null);
  const activeRef = useRef<HTMLLIElement>(null);

  const [showList, setShowList] = useState(false);
  const [activeSuggestion, setActiveSuggestion] = useState(0);
  const [filteredSuggestions, setFilteredSuggestions] = useState<Suggestions[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [userInput, setUserInput] = useState('');

  useEffect(() => {
    setUserInput(exerciseType);
  }, [exerciseType]);

  useEffect(() => {
    if (showSuggestions && userInput && filteredSuggestions.length) {
      setShowList(true);
    } else {
      setShowList(false);
    }
  }, [showSuggestions, userInput, filteredSuggestions]);

  // Event fired when the input value is changed
  const onChange = ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
    const { value } = currentTarget;
    exerciseTypeOnChangeHandler(value);

    const fuse = new Fuse(suggestions, {
      keys: ['type'],
      minMatchCharLength: 3,
    });

    // Filter our suggestions that don't contain the user's input
    const filteredSuggestions = fuse.search(value).map((el) => el.item);

    // Update the user input and filtered suggestions, reset the active
    // suggestion and make sure the suggestions are shown
    setActiveSuggestion(0);
    setFilteredSuggestions(filteredSuggestions);
    setShowSuggestions(true);
    setUserInput(value);
  };

  // Event fired when the user clicks on a suggestion
  const onClick = (index: number) => {
    const { type, sets, videoURL, author } = filteredSuggestions[index];

    // Update the user input and reset the rest of the state
    setActiveSuggestion(0);
    setFilteredSuggestions([]);
    setShowSuggestions(false);
    setUserInput(type);

    // set sets
    if (sets?.length) {
      setExerciseFromLib({ type, sets, videoURL, author });
    }
  };

  // Event fired when the user presses a key down
  const onKeyDown = ({ key }: KeyboardEvent<HTMLInputElement>) => {
    const li = activeRef.current;
    const container = containerRef.current;
    const liHeight = li?.offsetHeight || 0;

    // User pressed the enter key, update the input and close the
    // suggestions
    if (key === 'Enter') {
      const { type, sets, videoURL, author } = filteredSuggestions[activeSuggestion];
      setActiveSuggestion(0);
      setShowSuggestions(false);
      setUserInput(type);

      // set sets
      if (sets?.length) {
        setExerciseFromLib({ type, sets, videoURL, author });
      }
    }

    // User pressed the up arrow, decrement the index
    else if (key === 'ArrowUp') {
      if (activeSuggestion === 0) {
        return;
      }

      setActiveSuggestion(activeSuggestion - 1);
      const { type, sets, videoURL, author } = filteredSuggestions[activeSuggestion - 1] || {};

      // set sets
      if (type && sets?.length) {
        setUserInput(type);
        setExerciseFromLib({ type, sets, videoURL, author });
      }

      if (li && container) {
        container.scrollTo({
          behavior: 'smooth',
          top: li.offsetTop - liHeight,
        });
      }
    }

    // User pressed the down arrow, increment the index
    else if (key === 'ArrowDown') {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }

      setActiveSuggestion(activeSuggestion + 1);
      const { type, sets, videoURL, author } = filteredSuggestions[activeSuggestion + 1] || {};

      // set sets
      if (type && sets?.length) {
        setUserInput(type);
        setExerciseFromLib({ type, sets, videoURL, author });
      }

      if (li && container) {
        container.scrollTo({
          behavior: 'smooth',
          top: li.offsetTop,
        });
      }
    }
  };

  let suggestionsListComponent;

  if (showList) {
    suggestionsListComponent = (
      <OutsideClickHandler disabled={!showList} onOutsideClick={() => setShowList(false)}>
        <div className={classes.suggestionsOuterContainer}>
          <ul ref={containerRef} className={classes.suggestionsContainer}>
            {filteredSuggestions.map((suggestion, index) => {
              let className = classes.suggestion;
              const activeLI = index === activeSuggestion;

              // Flag the active suggestion with a class
              if (activeLI) {
                className = cls(classes.suggestion, classes.suggestionHighlighted);
              }

              return (
                <li
                  key={index}
                  className={className}
                  onClick={() => onClick(index)}
                  ref={activeLI ? activeRef : null}
                >
                  {suggestion.author === 'admin' ? (
                    <span>
                      <svg viewBox="0 0 31 31" className={classes.suggestionIcon}>
                        <defs>
                          <linearGradient
                            id="a"
                            x1="-.068"
                            x2="1"
                            y2="1"
                            gradientUnits="objectBoundingBox"
                          >
                            <stop offset="0" stopColor="#da0c1a" />
                            <stop offset="1" stopColor="#f8810a" />
                          </linearGradient>
                        </defs>
                        <g data-name="Group 16688" transform="translate(-138.5 -211.055)">
                          <rect
                            data-name="Rectangle 31"
                            width="31"
                            height="31"
                            rx="3.25"
                            transform="translate(138.5 211.055)"
                            fill="url(#a)"
                          />
                          <path
                            data-name="Path 12"
                            d="M161.909 218.114c-.034-.066-.073-.124-.111-.186l-.09-.128a2.918 2.918 0 00-2.5-.933h-7.074c-2.4 0-3.794 1.215-4.247 3.752l-2 11.81a3.977 3.977 0 00.214 2.542.862.862 0 00.1.181 2.845 2.845 0 002.626 1.087h7.082c2.378 0 3.764-1.215 4.191-3.753l.346-2.281.235-1.392h-.026l.06-.384h-5.021l-.428 3.006c-.081.389-.188.5-.56.5h-1.488l1.779-10.705h1.527c.372 0 .453.11.4.5l-.312 2.073-.073.438h.009l-.077.5h5.021l.612-4.057a4 4 0 00-.195-2.57zm-9.371 11.775h-1.449a.253.253 0 01-.243-.3l1.093-6.411a.249.249 0 01.243-.211h1.5z"
                            fill="#191c23"
                          />
                        </g>
                      </svg>
                    </span>
                  ) : (
                    <span className={classes.initials}>{getInitials(fullname)}</span>
                  )}

                  {suggestion.type}
                </li>
              );
            })}
          </ul>

          <div>{footer}</div>
        </div>
      </OutsideClickHandler>
    );
  } else {
    suggestionsListComponent = null;
  }

  return (
    <div>
      <input
        type="text"
        value={userInput}
        onChange={onChange}
        onKeyDown={onKeyDown}
        className={classes.exerciseType}
      />
      {suggestionsListComponent}
    </div>
  );
};

export default Autocomplete;
