import React, { FC } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from 'store';
import Logo from '../../Logo/Logo';
import NavItems from '../NavItems/NavItems';
import classes from './Sidebar.module.css';

const mapStateToProps = ({ auth }: RootState) => {
  return {
    userId: auth.userId,
  };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Sidebar: FC<PropsFromRedux> = ({ userId }) => {
  return (
    <React.Fragment>
      <div className={classes.Sidebar}>
        <Logo dashboard />
        <nav>
          <NavItems userId={userId || undefined} />
        </nav>
      </div>
    </React.Fragment>
  );
};

export default connector(Sidebar);
