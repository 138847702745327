import { Route } from 'interfaces/routes';

export const LOGOUT: Route = { TITLE: 'Log out', URL: '' };
export const LOGIN: Route = { TITLE: 'Login', URL: '/login' };
export const BILLING: Route = { TITLE: 'Billing', URL: '/billing' };
export const CLIENTS: Route = { TITLE: 'CLIENTS', URL: '/clients' };
export const SUPPORT: Route = { TITLE: 'Support', URL: '/support' };
export const NOT_FOUND: Route = { TITLE: '404 Not Found', URL: '' };
export const DASHBOARD: Route = {
  TITLE: 'DASHBOARD',
  URL: '/dashboard',
};
export const ACCOUNT: Route = {
  TITLE: 'Your Profile',
  URL: '/account',
};
export const CALENDAR: Route = {
  TITLE: 'Calendar',
  URL: '/programs',
};
export const PROGRAM_WORKOUT_BUILDER: Route = {
  TITLE: 'Workout Builder',
  URL: '/programs/workout-builder',
};
export const PROGRAMS: Route = {
  TITLE: 'PROGRAMS',
  URL: '/programs',
};
export const CLIENT_PROFILE: Route = {
  TITLE: 'Client Profile',
  URL: '/clients/profile',
};
export const COACH_TRAINING: Route = {
  TITLE: 'My Program',
  URL: '/my-program',
};
export const COACH_WORKOUT_BUILDER: Route = {
  TITLE: 'Workout Builder',
  URL: '/my-program/workout-builder',
};
export const CLIENTS_TRAINING: Route = {
  TITLE: 'Client Training',
  URL: '/clients/training',
};
export const CLIENTS_WORKOUT_BUILDER: Route = {
  TITLE: 'Workout Builder',
  URL: '/clients/training/workout-builder',
};
export const EXERCISE_LIBRARY: Route = {
  TITLE: 'EXERCISE LIBRARY',
  URL: '/exercise-library',
};
export const CLIENTS_DOCUMENTS: Route = {
  TITLE: 'Documents',
  URL: '/clients/documents',
};
export const DOCUMENTS: Route = {
  TITLE: 'DOCUMENTS',
  URL: '/documents',
};
export const REGISTER: Route = {
  TITLE: 'Register',
  URL: '/register',
};
export const RESET_PASSWORD: Route = {
  TITLE: 'Reset Password',
  URL: '/reset-password',
};
export const COMMUNITY: Route = {
  TITLE: 'COMMUNITY',
  URL: '/community',
};
export const CHECKOUT: Route = {
  TITLE: 'Checkout',
  URL: '/checkout',
};
export const POSTPAYMENT: Route = {
  TITLE: 'Post Payment',
  URL: '/post-payment',
};
export const CLIENTS_PROGRESS: Route = {
  TITLE: 'Client Progress',
  URL: '/progress',
};
export const ANALYTICS: Route = {
  TITLE: 'ANALYTICS',
  URL: '/analytics',
};
