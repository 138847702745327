import React, { FC } from 'react';

import saveIcon from 'assets/icons/save.svg';
import classes from './styles.module.css';

const CustomToast: FC = ({ children }) => {
  return (
    <div className={classes.container}>
      <img alt="save" src={saveIcon} className={classes.toastIcon} />
      <span className={classes.message}>{children}</span>
    </div>
  );
};

export default CustomToast;
