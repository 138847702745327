import { nanoid } from 'nanoid';
import React, { FC } from 'react';
import classes from './TabNav.module.css';
const MAX_CATEGORIES = 6;
interface Props {
  tabHeadings?: string[];
  setSelectedTab: (tab: string) => void;
  selectedTab: string;
  setCategoriesModalOpen?: (val: boolean) => void;
  setEditTabsModalOpen?: (val: boolean) => void;
}

const TabNav: FC<Props> = ({
  tabHeadings = [],
  setSelectedTab,
  selectedTab,
  setCategoriesModalOpen,
  setEditTabsModalOpen,
}) => {
  const tabs: any = tabHeadings.map((tab: string) => {
    return (
      <div
        key={nanoid()}
        onClick={() => setSelectedTab(tab)}
        className={selectedTab === tab ? [classes.Tab, classes.Selected].join(' ') : classes.Tab}
      >
        {tab}
      </div>
    );
  });

  return (
    <div className={classes.TabsContainer}>
      {tabs}
      {setCategoriesModalOpen && (tabHeadings?.length || 0) < MAX_CATEGORIES && (
        <div onClick={() => setCategoriesModalOpen(true)} className={classes.AddCategoryButton}>
          + Add Category
        </div>
      )}
      {setEditTabsModalOpen && (
        <button onClick={() => setEditTabsModalOpen(true)} className={classes.Options}>
          <img
            src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iMjAiIGhlaWdodD0iMjAiCnZpZXdCb3g9IjAgMCAyMjYgMjI2IgpzdHlsZT0iIGZpbGw6IzAwMDAwMDsiPjxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0ibm9uemVybyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIHN0cm9rZS1saW5lY2FwPSJidXR0IiBzdHJva2UtbGluZWpvaW49Im1pdGVyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1kYXNoYXJyYXk9IiIgc3Ryb2tlLWRhc2hvZmZzZXQ9IjAiIGZvbnQtZmFtaWx5PSJub25lIiBmb250LXdlaWdodD0ibm9uZSIgZm9udC1zaXplPSJub25lIiB0ZXh0LWFuY2hvcj0ibm9uZSIgc3R5bGU9Im1peC1ibGVuZC1tb2RlOiBub3JtYWwiPjxwYXRoIGQ9Ik0wLDIyNnYtMjI2aDIyNnYyMjZ6IiBmaWxsPSJub25lIj48L3BhdGg+PGcgZmlsbD0iIzdlN2U3ZSI+PHBhdGggZD0iTTE3My4zOTkwOCwxOC44MzMzM2MtMi40MDk0OCwwIC00LjgyMTYzLDAuOTE3ODMgLTYuNjU3ODgsMi43NTg3OWwtMTYuMDc0NTQsMTYuMDc0NTRsMzcuNjY2NjcsMzcuNjY2NjdsMTYuMDc0NTQsLTE2LjA3NDU0YzMuNjgxOTIsLTMuNjgxOTIgMy42ODE5MiwtOS42NDMyNiAwLC0xMy4zMTU3NmwtMjQuMzUwOTEsLTI0LjM1MDkxYy0xLjg0MDk2LC0xLjg0MDk2IC00LjI0ODQsLTIuNzU4NzkgLTYuNjU3ODgsLTIuNzU4Nzl6TTEzNi41NDE2Nyw1MS43OTE2N2wtMTA4LjI5MTY3LDEwOC4yOTE2N3YzNy42NjY2N2gzNy42NjY2N2wxMDguMjkxNjcsLTEwOC4yOTE2N3oiPjwvcGF0aD48L2c+PC9nPjwvc3ZnPg=="
            alt="edit icon"
            className={classes.EditIcon}
          />
          Edit Categories
        </button>
      )}
    </div>
  );
};

export default TabNav;
