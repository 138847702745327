import React, { FunctionComponent, useCallback } from 'react';
import Select from 'react-select';

import classes from './ProgramSettings.module.css';
import {
  customStyles,
  emptyOption,
  MAX_SETS_LENGTH,
  setKeysMap,
  setKeysObject,
} from 'utils/helpers';
import { SetParam } from 'interfaces/utils';
import { ReactComponent as AddSetSvg } from '../../assets/svgs/add-set.svg';

interface Props {
  defaultSetParams: SetParam[];
  updateDefaultSetParam: (newParam: SetParam, originalParam?: SetParam) => void;
  removeDefaultSetParam: (originalParam?: SetParam) => void;
  addDefaultSetParam: (param: SetParam) => void;
}

const ProgramSettings: FunctionComponent<Props> = ({
  defaultSetParams,
  updateDefaultSetParam,
  removeDefaultSetParam,
  addDefaultSetParam,
}) => {
  const options = useCallback(() => {
    return setKeysMap.filter((el) => !defaultSetParams.includes(el.value));
  }, [defaultSetParams]);

  return (
    <div className={classes.ProgrammingSettings}>
      <h3> Default Parameters: </h3>
      <div className={classes.SetParams}>
        {defaultSetParams.map((setKey, index) => {
          const selected = setKeysObject[setKey];
          return (
            <div key={index} className={classes.SetParam}>
              <Select
                value={selected}
                isSearchable={false}
                styles={customStyles}
                onChange={(val: any) =>
                  val.value
                    ? updateDefaultSetParam(val.value, selected?.value)
                    : removeDefaultSetParam(selected?.value)
                }
                options={[...options(), emptyOption]}
              />
            </div>
          );
        })}

        {defaultSetParams.length !== MAX_SETS_LENGTH && (
          <button
            type="button"
            className={classes.addSetBtn}
            onClick={() => {
              const [newSet] = options();
              addDefaultSetParam(newSet.value);
            }}
          >
            <AddSetSvg className={classes.addSetIcon} />
          </button>
        )}
      </div>
    </div>
  );
};

export default ProgramSettings;
