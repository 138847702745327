import React, { FC } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import { object, string } from 'yup';
import { NavLink } from 'react-router-dom';

import Button from '../../inputs/Button';
import { RESET_PASSWORD } from 'utils/routes';
import TextField from '../../inputs/TextField';
import { FormProps } from 'interfaces/utils';
import classes from './Login.module.css';

const initialValues = { email: '', password: '' };
const validationSchema = object().shape({
  email: string().email().required(),
  password: string().required(),
});

type FormValues = {
  email: string;
  password: string;
};

const EmailIcon: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16">
      <path
        id="Icon_material-email"
        data-name="Icon material-email"
        d="M21,6H5A2,2,0,0,0,3.01,8L3,20a2.006,2.006,0,0,0,2,2H21a2.006,2.006,0,0,0,2-2V8A2.006,2.006,0,0,0,21,6Zm0,4-8,5L5,10V8l8,5,8-5Z"
        transform="translate(-3 -6)"
        fill="#cacbd5"
        opacity="0.5"
      />
    </svg>
  );
};

const PasswordIcon: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.047"
      height="22.375"
      viewBox="0 0 17.047 22.375"
    >
      <path
        id="Icon_ionic-md-lock"
        data-name="Icon ionic-md-lock"
        d="M21.666,10.693H20.6V8.562a5.327,5.327,0,1,0-10.655,0v2.131H8.881A2.137,2.137,0,0,0,6.75,12.824V23.478a2.137,2.137,0,0,0,2.131,2.131H21.666A2.137,2.137,0,0,0,23.8,23.478V12.824A2.137,2.137,0,0,0,21.666,10.693Zm-6.393,9.7A2.131,2.131,0,1,1,17.4,18.257,2.137,2.137,0,0,1,15.274,20.388Zm3.3-9.7H11.971V8.562a3.3,3.3,0,1,1,6.606,0Z"
        transform="translate(-6.75 -3.234)"
        fill="#cacbd5"
        opacity="0.5"
      />
    </svg>
  );
};

const Login: FC<FormProps<FormValues> & { loading: boolean }> = ({ loading, handleSubmit }) => {
  const onSubmit = async (values: FormValues, actions: FormikHelpers<FormValues>) => {
    await handleSubmit(values);
    actions.setSubmitting(false);
  };

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
      {({ errors, touched, isSubmitting }) => (
        <Form className={classes.wrapper}>
          <div className={classes.inputWrapper}>
            <TextField
              name="email"
              errors={errors}
              touched={touched}
              placeholder="Email"
              icon={<EmailIcon />}
              auto={true}
            />
          </div>

          <TextField
            type="password"
            errors={errors}
            name="password"
            touched={touched}
            placeholder="Password"
            icon={<PasswordIcon />}
            auto={true}
          />

          <NavLink to={{ pathname: RESET_PASSWORD.URL }} className={classes.resetPassword}>
            Forgot your password?
          </NavLink>

          <div>
            <Button
              type="submit"
              loading={loading || isSubmitting}
              disabled={loading || isSubmitting}
            >
              SIGN IN
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Login;
