import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { RouteComponentProps } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { RootState } from 'store';
import firebase from 'firebase/app';
import { database, firestore, storage } from 'utils/firebase';
import { AddProgram, ProgramMetadataForm, ProgramImageUploadForm } from 'components';
import { Clients, Programs as ProgramsInterface } from 'interfaces/db';
import ProgramCard from 'components/ProgramCard/ProgramCard';
import Modal from 'components/UI/Modal/Modal';
import ClientAssignCard from 'components/ClientAssignCard/ClientAssignCard';
import { ReactComponent as SearchSvg } from '../../assets/svgs/search-icon.svg';
import {
  fetchPrograms,
  removeProgram,
  initialiseProgram,
  initialiseProgramStart,
  initialiseProgramSuccess,
  initialiseProgramFail,
  putProgramSuccess,
} from 'store/actions/programs';
import { CALENDAR, PROGRAMS } from 'utils/routes';
import Spinner from 'components/UI/Spinner/Spinner';
import withAuth from 'utils/withAuth';
import { getInitials, eligibleCoach } from 'utils/helpers';
import Layout from '../Layout';
import classes from './Programs.module.css';
import { UploadTaskSnapshot, UploadTask } from '@firebase/storage-types';
import { createProgram, fetchProgramById } from 'utils/api';

const mapStateToProps = ({ clients, programs, auth }: RootState) => {
  const { userId, coachProfile, token, loading } = auth;

  return {
    token,
    userId,
    authLoading: loading,
    clients,
    programs,
    coachProfile,
    coachKey: Object.keys(coachProfile || {})[0],
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onFetchPrograms: (userId: string) => dispatch(fetchPrograms(userId)),
    onRemoveProgram: (programKey: string, token: string) => dispatch(removeProgram(programKey, token)),
    onInitialiseProgram: (program: ProgramsInterface, token: string) => dispatch(initialiseProgram(program, token)),
    initialiseProgramSuccess: (program: ProgramsInterface, programKey: string) =>
      dispatch(initialiseProgramSuccess(program, programKey)),
    initialiseProgramStart: () => dispatch(initialiseProgramStart()),
    initialiseProgramFail: () => dispatch(initialiseProgramFail()),
    putProgramSuccess: (programKey: string, program: ProgramsInterface) =>
      dispatch(putProgramSuccess(programKey, program)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & RouteComponentProps;

type FormValues = {
  focusArea: string;
  sessionsPerWeek: number;
  experience: string;
  equipment: string;
  description: string;
  programName: string;
};
interface Payload extends FormValues {
  coachName: string;
  createdAt: firebase.firestore.FieldValue;
  duration: string;
  programKey: string;
  programName: string;
  program: ProgramsInterface;
}

const Programs: FC<Props> = ({
  token,
  userId,
  clients,
  history,
  programs,
  authLoading,
  coachKey,
  // coachName,
  coachProfile,
  onFetchPrograms,
  onRemoveProgram,
  onInitialiseProgram,
  initialiseProgramStart,
  initialiseProgramSuccess,
  initialiseProgramFail,
  putProgramSuccess,
}) => {
  const { addToast } = useToasts();

  const [programKey, setProgramKey] = useState('');
  const [selectedProgram, setSelectedProgram] = useState();
  const [programTitleModal, setProgramTitleModal] = useState(false);
  const [showRemoveProgram, setShowRemoveProgram] = useState(false);
  const [showAssignProgram, _setShowAssignProgramModal] = useState(false);
  const [clientsToAssign, setClientsToAssign] = useState<string[]>([]);
  const [clientsFiltered, setClientsFiltered] = useState<[string, Clients][]>([]);
  const [clientSearchValue, setClientSearchValue] = useState<string | null>(null);
  const [imageUploadModal, setImageUploadModal] = useState(false);
  const [progress, setProgress] = useState(0);
  const [programsFiltered, setProgramsFiltered] = useState<[string, ProgramsInterface][]>([]);
  const [programSearchValue, setProgramSearchValue] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (userId) {
      onFetchPrograms(userId);
    }
  }, [onFetchPrograms, userId]);

  const setShowAssignProgramModal = (value: boolean) => {
    _setShowAssignProgramModal(value);
    setClientSearchValue(null);
  };
  const [marketplaceLiveModal, setMarketplaceLiveModal] = useState(false);
  const coachName = coachProfile && coachProfile[coachKey] ? coachProfile[coachKey].fullname : '';

  const uploadProgress: (a: UploadTaskSnapshot) => any = (snapshot) => {
    setProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
  };

  const uploadError: (a: Error) => any = (error) => {
    console.log(error);
  };

  const uploadSuccess = ({
    uploadTask,
    programKey,
  }: {
    uploadTask: UploadTask;
    title: string;
    fileName: string;
    fileSize: number;
    programKey: string;
  }) => {
    uploadTask.snapshot.ref.getDownloadURL().then(async (downloadURL) => {
      if (token && userId && programKey) {
        const programRef = firestore.collection('programs').doc(programKey);
        const program = programs.programs?.[programKey];

        await programRef
          .update({
            image: downloadURL,
            coachName: coachName,
          })
          .then(async () => {
            if (eligibleCoach(userId)) {
              if (program?.onMarketplace) {
                const coachesRef = firestore?.collection('coaches');
                const coachDocument = coachesRef?.doc(userId || 'test');
                const funnelPlansRef = coachDocument?.collection('funnel-plans');
                const funnelDoc = await funnelPlansRef.limit(1).get();

                const programsRef = funnelPlansRef.doc(funnelDoc.docs[0]?.id)?.collection('programs');
                programsRef.doc(programKey).update({ image: downloadURL, coachName: coachName });
              }
            }
          })
          .catch((e) => {
            console.log(e);
          });

        setImageUploadModal(false);
        setProgress(0);
      }
    });
  };

  const duplicateProgramHandler = async (key: string) => {
    if (programs && programs.programs && programs.programs[key] && userId && token) {
      initialiseProgramStart();
      const response = await fetchProgramById(key);
      const program: ProgramsInterface = response.data.data?.program;

      const name = `${program.name} (copy)`;

      onInitialiseProgram(
        {
          name: name,
          image: '',
          weeks: program.weeks,
          userId: userId,
          coachId: userId,
        },
        token,
      );
    }
  };

  const setAssignedClient = (key: string) => {
    if (clientsToAssign.includes(key)) {
      const newClientsToAssign = clientsToAssign.filter((el) => el !== key);
      setClientsToAssign(newClientsToAssign);
      return;
    }

    setClientsToAssign([...clientsToAssign, key]);
  };

  const updateClientHasProgram = (clientKey: string): Promise<any> => {
    const clientRef = database.ref(`clients/${clientKey}`);

    return clientRef.once('value', (snapshot) => {
      if (snapshot.exists()) {
        clientRef.update({
          hasPrograms: true,
        });
      }
    });
  };

  const assignProgram = async () => {
    if (programs && programs.programs && programs.programs[programKey]) {
      try {
        setShowAssignProgramModal(false);

        setLoading(true);
        // program to be assigned
        const response = await fetchProgramById(programKey);
        const program: ProgramsInterface = response.data.data?.program;

        // promises array;
        const promises: Promise<any>[] = [];

        // map over clients and set programs
        clientsToAssign.map((clientKey) => {
          const updatedProgram = {
            ...program,
            clientKey: clientKey,
            assignedAt: new Date(),
          };
          const query = database.ref(`newAssignedPrograms/${uuidv4()}`).set(updatedProgram);

          const updateClient = updateClientHasProgram(clientKey);
          // push promise into promises array
          promises.push(query, updateClient);

          return undefined;
        });

        // resolve promises
        await Promise.all(promises);
        setLoading(false);
        setClientsToAssign([]);

        // show toast
        addToast('Program Assigned', { appearance: 'success' });
      } catch (err) {
        addToast('Program Could Not Be Assigned', {
          appearance: 'error',
        });
        console.log(err);
      }
    }
  };

  const handleSubmit = async ({ title }: { title: string }) => {
    try {
      if (userId && token) {
        initialiseProgramStart();
        const newProgram = {
          name: title,
          image: '',
          weeks: [],
          userId,
          coachId: userId,
        };

        setProgramTitleModal(false);

        const response = await createProgram(token, newProgram);

        const updatedProgram = response.data?.data as ProgramsInterface;
        if (updatedProgram.programId) {
          initialiseProgramSuccess(updatedProgram, updatedProgram.programId);
          history.push({ pathname: `${CALENDAR.URL}/${updatedProgram.programId}` });
        }
      }
    } catch {
      initialiseProgramFail();
    }
  };

  const handleImageUploadSubmit = async ({ title, file }: { title: string; file: FileList }) => {
    const { name, size } = file[0];
    const storageRef = storage.ref(`${userId}/programs/${programKey}`);
    const uploadTask: any = storageRef.put(file[0]);

    uploadTask.on('state_changed', uploadProgress, uploadError, () =>
      uploadSuccess({
        uploadTask,
        title,
        fileName: name,
        fileSize: size,
        programKey: programKey,
      }),
    );
  };

  const showRemoveProgramModal = (programKey: string) => {
    setProgramKey(programKey);
    setShowRemoveProgram(true);
  };

  const showImageUploadModal = (programKey: string) => {
    setProgramKey(programKey);
    setImageUploadModal(true);
  };

  const showMarkProgramLiveModal = (program: any, programKey: string) => {
    setSelectedProgram({ ...program, programKey: programKey });
    setMarketplaceLiveModal(true);
  };

  const removeProgramHandler = () => {
    if (token) {
      onRemoveProgram(programKey, token);
      setShowRemoveProgram(false);
    }
  };

  const removeProgramFromMarketplace = async (programKey: string, program: ProgramsInterface) => {
    // set loading to true
    const coachesRef = firestore?.collection('coaches');
    const coachDocument = coachesRef?.doc(userId || 'test');
    const funnelPlansRef = coachDocument?.collection('funnel-plans');
    const doc = await funnelPlansRef.limit(1).get();

    const updatedProgram: ProgramsInterface = { ...program, onMarketplace: false };
    putProgramSuccess(programKey, updatedProgram);

    funnelPlansRef
      .doc(doc.docs[0]?.id)
      .collection('programs')
      .doc(programKey)
      .delete()
      .then(async () => {
        // need to set loading to false as well

        const programRef = firestore.collection('programs').doc(programKey);

        await programRef.update({
          onMarketplace: false,
        });
      })
      .catch((err: any) => {
        console.log(err);
        // need to set loading to false as well
      });
  };

  const ProgramsList = () => {
    if (!programs?.programs) return null;

    let programsNew = Object.entries(programs.programs);
    if (programSearchValue) {
      programsNew = programsFiltered;
    }

    return programsNew.map(([programKey, program], index) => {
      return (
        <div key={programKey} className={classes.programsWrapper}>
          <ProgramCard
            programKey={programKey}
            showRemoveProgramModal={showRemoveProgramModal}
            programIndex={index}
            program={program}
            title={program.name || ''}
            showAssignProgramModal={(val: string) => {
              setProgramKey(val);
              setShowAssignProgramModal(true);
            }}
            showMarkProgramLiveModal={showMarkProgramLiveModal}
            duplicateProgramHandler={duplicateProgramHandler}
            showImageUploadModal={showImageUploadModal}
            removeProgramFromMarketplace={removeProgramFromMarketplace}
            userId={userId}
          />
        </div>
      );
    });
  };

  let clientList;

  if (clients.clients !== null) {
    let clientsNew = Object.entries(clients.clients);
    if (clientSearchValue) {
      clientsNew = clientsFiltered;
    }

    clientList = clients.loading ? (
      <Spinner />
    ) : (
      <>
        <ClientAssignCard
          name="You"
          initials={getInitials(coachName)}
          assigned={clientsToAssign.includes(coachKey)}
          setAssignedClient={() => setAssignedClient(coachKey)}
        />

        {clientsNew.map(([clientKey, client]) => {
          const clientName = client.profile.fullname || '';

          return (
            <ClientAssignCard
              key={clientKey}
              name={clientName}
              initials={getInitials(clientName)}
              assigned={clientsToAssign.includes(clientKey)}
              setAssignedClient={() => setAssignedClient(clientKey)}
            />
          );
        })}
      </>
    );
  }

  const programsList = programs.loading || loading ? <Spinner /> : ProgramsList();

  const onSearchClients = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const value = target?.value.toLowerCase();
    setClientSearchValue(value);

    const clientsFiltered = Object.entries(clients.clients || {})?.filter(([_, client]) => {
      return client?.profile?.fullname?.toLowerCase()?.includes(value);
    });

    setClientsFiltered(clientsFiltered);
  };

  const onSearchPrograms = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const value = target?.value.toLowerCase();
    setProgramSearchValue(value);

    const programsFiltered = Object.entries(programs.programs || {})?.filter(([_, program]) => {
      return program?.name?.toLowerCase()?.includes(value);
    });

    setProgramsFiltered(programsFiltered);
  };

  const createProgramOverview = async (payload: Payload) => {
    // set loading to true
    const coachesRef = firestore?.collection('coaches');
    const coachDocument = coachesRef?.doc(userId || 'test');
    const funnelPlansRef = coachDocument?.collection('funnel-plans');
    const doc = await funnelPlansRef.limit(1).get();

    funnelPlansRef
      .doc(doc.docs[0]?.id)
      .collection('programs')
      .doc(payload?.programKey)
      .set(payload)
      .then(() => {
        setMarketplaceLiveModal(false);
        // need to set loading to false as well
        const programRef = firestore.collection('programs').doc(payload?.programKey);
        programRef.update({
          onMarketplace: true,
        });

        const updatedProgram: ProgramsInterface = { ...payload.program, onMarketplace: true };

        putProgramSuccess(payload?.programKey, updatedProgram);
      })
      .catch((err: any) => {
        console.log(err);
        // need to set loading to false as well
      });
  };

  return (
    <Layout loading={authLoading} heading={PROGRAMS.TITLE}>
      <div className={classes.ProgramsContainer}>
        {programsList}

        <div className={classes.SearchPrograms}>
          <SearchSvg />
          <input onChange={onSearchPrograms} type="text" placeholder="Search programs..." />
        </div>

        {!programs.loading && (
          <div className={classes.AddProgramButtonWrapper} onClick={() => setProgramTitleModal(true)}>
            <div className={classes.AddProgramText}>Add New Program</div>
            <button onClick={() => setProgramTitleModal(true)} className={classes.AddProgramButton}>
              +
            </button>
          </div>
        )}

        <Modal openModal={() => setProgramTitleModal(false)} isModalOpen={programTitleModal}>
          <div className={classes.modalContainer}>
            <div className={classes.ModalClose}>
              <img
                onClick={() => setProgramTitleModal(false)}
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGlkPSJDYXBhXzEiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDM4Ni42NjcgMzg2LjY2NyIgaGVpZ2h0PSI1MTIiIHZpZXdCb3g9IjAgMCAzODYuNjY3IDM4Ni42NjciIHdpZHRoPSI1MTIiIGNsYXNzPSJob3ZlcmVkLXBhdGhzIj48Zz48cGF0aCBkPSJtMzg2LjY2NyA0NS41NjQtNDUuNTY0LTQ1LjU2NC0xNDcuNzcgMTQ3Ljc2OS0xNDcuNzY5LTE0Ny43NjktNDUuNTY0IDQ1LjU2NCAxNDcuNzY5IDE0Ny43NjktMTQ3Ljc2OSAxNDcuNzcgNDUuNTY0IDQ1LjU2NCAxNDcuNzY5LTE0Ny43NjkgMTQ3Ljc2OSAxNDcuNzY5IDQ1LjU2NC00NS41NjQtMTQ3Ljc2OC0xNDcuNzd6IiBkYXRhLW9yaWdpbmFsPSIjMDAwMDAwIiBjbGFzcz0iaG92ZXJlZC1wYXRoIGFjdGl2ZS1wYXRoIiBzdHlsZT0iZmlsbDojRkZGRkZGIiBkYXRhLW9sZF9jb2xvcj0iIzAwMDAwMCI+PC9wYXRoPjwvZz4gPC9zdmc+"
                alt="close"
              />
            </div>
            <div className={classes.ModalSaveIcon}>
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAABjklEQVRoge2ZMU7DMBSG/8eQBWYkJLJzi0pcihtAuUJHuABigI0LMDLAjNpwjJ+hprJM7NiOE8eVv9GJ33ufrTynDVCpTA/JhuQ9yY7z8U7yPLXIekYBnY+xMmKIdAAuRq1GPF8ArkXkJ2ayKcIkJcUzSuaAY+ufSK5Inqapd2IsEje56wqmbyfUeFndrCfYSo2X1c16Ap2pcedORK2afx2fJMM6qa3AoWWbWMRL5iR1ESEELMYVgDeXTFaRQJwyJYkADpnSRACLTIkiwF7mWR9wvmuJiPSNm/zdF4oeV4/h2wn1OaXuyD+qyNJIIkLyMmaeaGix2phYqXZkEyujoyQ2MXOTdK1c1K61ZKrI0qgiBi8AWkkEgBbAa0gBqdpvKyLbkMRDqDPl23WP3n6zvv0OEZK3PiNLo4osjaMV6bJUkQBT5DFLFanh/vPBmuQu53+/MXm9DrKjPBDN39hzMZT3aLuWjUM3m/q5sNGTd6df9xV58EmSmjE12QptSN5RdbPMbEnekmyCRSqVcH4BoyWacwifNfYAAAAASUVORK5CYII="
                alt="save"
              />
            </div>
            <AddProgram handleSubmit={handleSubmit} closeModal={() => setProgramTitleModal(false)} />
          </div>
        </Modal>

        <Modal isModalOpen={showAssignProgram} openModal={() => setShowAssignProgramModal(false)} isDynamicWidth={true}>
          <div className={classes.modalContainerAssignModal}>
            <div className={classes.ModalClose}>
              <img
                onClick={() => setShowAssignProgramModal(false)}
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGlkPSJDYXBhXzEiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDM4Ni42NjcgMzg2LjY2NyIgaGVpZ2h0PSI1MTIiIHZpZXdCb3g9IjAgMCAzODYuNjY3IDM4Ni42NjciIHdpZHRoPSI1MTIiIGNsYXNzPSJob3ZlcmVkLXBhdGhzIj48Zz48cGF0aCBkPSJtMzg2LjY2NyA0NS41NjQtNDUuNTY0LTQ1LjU2NC0xNDcuNzcgMTQ3Ljc2OS0xNDcuNzY5LTE0Ny43NjktNDUuNTY0IDQ1LjU2NCAxNDcuNzY5IDE0Ny43NjktMTQ3Ljc2OSAxNDcuNzcgNDUuNTY0IDQ1LjU2NCAxNDcuNzY5LTE0Ny43NjkgMTQ3Ljc2OSAxNDcuNzY5IDQ1LjU2NC00NS41NjQtMTQ3Ljc2OC0xNDcuNzd6IiBkYXRhLW9yaWdpbmFsPSIjMDAwMDAwIiBjbGFzcz0iaG92ZXJlZC1wYXRoIGFjdGl2ZS1wYXRoIiBzdHlsZT0iZmlsbDojRkZGRkZGIiBkYXRhLW9sZF9jb2xvcj0iIzAwMDAwMCI+PC9wYXRoPjwvZz4gPC9zdmc+"
                alt="close"
              />
            </div>

            <p className={classes.ModalLargeText}>Assign a program</p>
            <div className={classes.SearchClients}>
              <SearchSvg />
              <input onChange={onSearchClients} type="text" placeholder="Search clients..." />
            </div>

            <div className={classes.clientListWrapper}>
              <div className={classes.ClientListTrue}>{clientList}</div>
            </div>

            <div className={classes.ModalButtons}>
              <button onClick={() => setShowAssignProgramModal(false)} className={classes.ModalCancel}>
                Cancel
              </button>

              <button onClick={assignProgram} className={classes.ModalSave}>
                Assign
              </button>
            </div>
          </div>
        </Modal>

        <Modal isModalOpen={showRemoveProgram} openModal={() => setShowRemoveProgram(false)}>
          <div className={classes.modalContainer}>
            <div className={classes.ModalClose}>
              <img
                onClick={() => setShowRemoveProgram(false)}
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGlkPSJDYXBhXzEiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDM4Ni42NjcgMzg2LjY2NyIgaGVpZ2h0PSI1MTIiIHZpZXdCb3g9IjAgMCAzODYuNjY3IDM4Ni42NjciIHdpZHRoPSI1MTIiIGNsYXNzPSJob3ZlcmVkLXBhdGhzIj48Zz48cGF0aCBkPSJtMzg2LjY2NyA0NS41NjQtNDUuNTY0LTQ1LjU2NC0xNDcuNzcgMTQ3Ljc2OS0xNDcuNzY5LTE0Ny43NjktNDUuNTY0IDQ1LjU2NCAxNDcuNzY5IDE0Ny43NjktMTQ3Ljc2OSAxNDcuNzcgNDUuNTY0IDQ1LjU2NCAxNDcuNzY5LTE0Ny43NjkgMTQ3Ljc2OSAxNDcuNzY5IDQ1LjU2NC00NS41NjQtMTQ3Ljc2OC0xNDcuNzd6IiBkYXRhLW9yaWdpbmFsPSIjMDAwMDAwIiBjbGFzcz0iaG92ZXJlZC1wYXRoIGFjdGl2ZS1wYXRoIiBzdHlsZT0iZmlsbDojRkZGRkZGIiBkYXRhLW9sZF9jb2xvcj0iIzAwMDAwMCI+PC9wYXRoPjwvZz4gPC9zdmc+"
                alt="close"
              />
            </div>
            <div className={classes.ModalSaveIcon}>
              <img
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDUxMiA1MTIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMiA1MTI7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgY2xhc3M9IiI+PGc+PGNpcmNsZSBzdHlsZT0iZmlsbDojRDczQTI3IiBjeD0iMjU2IiBjeT0iMjU2IiByPSIyNTYiIGRhdGEtb3JpZ2luYWw9IiNFMjFCMUIiIGNsYXNzPSIiIGRhdGEtb2xkX2NvbG9yPSIjRTIxQjFCIj48L2NpcmNsZT48cGF0aCBzdHlsZT0iZmlsbDojQkYzMzIyIiBkPSJNNTEwLjI4LDI4NS4zMDRMMzY3LjkxMiwxNDIuOTM2TDE1MC4yNDgsMzY4LjYwOGwxNDAuOTI4LDE0MC45MjggIEM0MDYuMzUyLDQ5My42OTYsNDk3LjA1Niw0MDEuMjg4LDUxMC4yOCwyODUuMzA0eiIgZGF0YS1vcmlnaW5hbD0iI0M0MDYwNiIgY2xhc3M9ImFjdGl2ZS1wYXRoIiBkYXRhLW9sZF9jb2xvcj0iI0M0MDYwNiI+PC9wYXRoPjxnPgoJPHBhdGggc3R5bGU9ImZpbGw6I0ZGRkZGRjsiIGQ9Ik0zNTQuMzc2LDM3MS41MzZjLTUuMTIsMC0xMC4yMzItMS45NTItMTQuMTQ0LTUuODU2TDE0Ni40MDgsMTcxLjg0OCAgIGMtNy44MTYtNy44MTYtNy44MTYtMjAuNDcyLDAtMjguMjhzMjAuNDcyLTcuODE2LDI4LjI4LDBMMzY4LjUyLDMzNy40YzcuODE2LDcuODE2LDcuODE2LDIwLjQ3MiwwLDI4LjI4ICAgQzM2NC42MDgsMzY5LjU4NCwzNTkuNDk2LDM3MS41MzYsMzU0LjM3NiwzNzEuNTM2eiIgZGF0YS1vcmlnaW5hbD0iI0ZGRkZGRiIgY2xhc3M9IiI+PC9wYXRoPgoJPHBhdGggc3R5bGU9ImZpbGw6I0ZGRkZGRjsiIGQ9Ik0xNjAuNTQ0LDM3MS41MzZjLTUuMTIsMC0xMC4yMzItMS45NTItMTQuMTQ0LTUuODU2Yy03LjgxNi03LjgxNi03LjgxNi0yMC40NzIsMC0yOC4yOCAgIGwxOTMuODMyLTE5My44MzJjNy44MTYtNy44MTYsMjAuNDcyLTcuODE2LDI4LjI4LDBzNy44MTYsMjAuNDcyLDAsMjguMjhMMTc0LjY4OCwzNjUuNjggICBDMTcwLjc4NCwzNjkuNTg0LDE2NS42NjQsMzcxLjUzNiwxNjAuNTQ0LDM3MS41MzZ6IiBkYXRhLW9yaWdpbmFsPSIjRkZGRkZGIiBjbGFzcz0iIj48L3BhdGg+CjwvZz48L2c+IDwvc3ZnPg=="
                alt="delete"
              />
            </div>
            <p className={classes.ModalLargeText}>Delete Program</p>
            <p className={classes.ModalSmallText}>
              Do you want to delete this program?
              <br />
              This cannot be undone.
            </p>
            <div className={classes.ModalButtons}>
              <button onClick={() => setShowRemoveProgram(false)} className={classes.ModalCancel}>
                Cancel
              </button>
              <button className={classes.ModalSave} onClick={removeProgramHandler}>
                Delete
              </button>
            </div>
          </div>
        </Modal>

        <Modal isModalOpen={imageUploadModal} openModal={() => setImageUploadModal(false)}>
          <div className={classes.modalContainer}>
            <div className={classes.ModalClose}>
              <img
                onClick={() => setImageUploadModal(false)}
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGlkPSJDYXBhXzEiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDM4Ni42NjcgMzg2LjY2NyIgaGVpZ2h0PSI1MTIiIHZpZXdCb3g9IjAgMCAzODYuNjY3IDM4Ni42NjciIHdpZHRoPSI1MTIiIGNsYXNzPSJob3ZlcmVkLXBhdGhzIj48Zz48cGF0aCBkPSJtMzg2LjY2NyA0NS41NjQtNDUuNTY0LTQ1LjU2NC0xNDcuNzcgMTQ3Ljc2OS0xNDcuNzY5LTE0Ny43NjktNDUuNTY0IDQ1LjU2NCAxNDcuNzY5IDE0Ny43NjktMTQ3Ljc2OSAxNDcuNzcgNDUuNTY0IDQ1LjU2NCAxNDcuNzY5LTE0Ny43NjkgMTQ3Ljc2OSAxNDcuNzY5IDQ1LjU2NC00NS41NjQtMTQ3Ljc2OC0xNDcuNzd6IiBkYXRhLW9yaWdpbmFsPSIjMDAwMDAwIiBjbGFzcz0iaG92ZXJlZC1wYXRoIGFjdGl2ZS1wYXRoIiBzdHlsZT0iZmlsbDojRkZGRkZGIiBkYXRhLW9sZF9jb2xvcj0iIzAwMDAwMCI+PC9wYXRoPjwvZz4gPC9zdmc+"
                alt="close"
              />
            </div>

            <p className={classes.ModalLargeText}>Upload Program Image</p>
            <ProgramImageUploadForm progress={progress} handleSubmit={handleImageUploadSubmit} />
          </div>
        </Modal>

        <Modal isModalOpen={marketplaceLiveModal} openModal={() => setMarketplaceLiveModal(false)}>
          <div className={classes.modalContainer}>
            <div className={classes.ModalClose}>
              <img
                onClick={() => setMarketplaceLiveModal(false)}
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGlkPSJDYXBhXzEiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDM4Ni42NjcgMzg2LjY2NyIgaGVpZ2h0PSI1MTIiIHZpZXdCb3g9IjAgMCAzODYuNjY3IDM4Ni42NjciIHdpZHRoPSI1MTIiIGNsYXNzPSJob3ZlcmVkLXBhdGhzIj48Zz48cGF0aCBkPSJtMzg2LjY2NyA0NS41NjQtNDUuNTY0LTQ1LjU2NC0xNDcuNzcgMTQ3Ljc2OS0xNDcuNzY5LTE0Ny43NjktNDUuNTY0IDQ1LjU2NCAxNDcuNzY5IDE0Ny43NjktMTQ3Ljc2OSAxNDcuNzcgNDUuNTY0IDQ1LjU2NCAxNDcuNzY5LTE0Ny43NjkgMTQ3Ljc2OSAxNDcuNzY5IDQ1LjU2NC00NS41NjQtMTQ3Ljc2OC0xNDcuNzd6IiBkYXRhLW9yaWdpbmFsPSIjMDAwMDAwIiBjbGFzcz0iaG92ZXJlZC1wYXRoIGFjdGl2ZS1wYXRoIiBzdHlsZT0iZmlsbDojRkZGRkZGIiBkYXRhLW9sZF9jb2xvcj0iIzAwMDAwMCI+PC9wYXRoPjwvZz4gPC9zdmc+"
                alt="close"
              />
            </div>

            <p className={classes.ModalLargeText}>Mark as live</p>
            <div>
              <ProgramMetadataForm
                handleSubmit={createProgramOverview}
                loading={false}
                program={selectedProgram}
                coachName={coachName}
              />
            </div>
          </div>
        </Modal>
      </div>
    </Layout>
  );
};

export default connector(withAuth(Programs));
