import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';

import LOGO from 'assets/icons/Logo.svg';
import { DASHBOARD } from 'utils/routes';
import classes from './Logo.module.css';

interface Props {
  height?: number;
  link?: string;
  dashboard?: boolean;
  auth?: boolean;
}

const Logo: FC<Props> = ({
  // height = 50,
  // dashboard = true,
  link = DASHBOARD.URL,
  auth = false,
}) => {
  return (
    <div className={classes.Logo}>
      {auth ? (
        <a href={link}>
          <img src={LOGO} alt="CoachElite" />
        </a>
      ) : (
        <NavLink to={link}>
          <img src={LOGO} alt="CoachElite" />
        </NavLink>
      )}
    </div>
  );
};

export default Logo;
