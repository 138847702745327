/* eslint-disable camelcase */
import React, { FC, useState, useEffect, ChangeEvent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { ReactComponent as SearchSvg } from '../../assets/svgs/search-icon.svg';
import cls from 'classnames';
import { RootState } from 'store';
import Client from 'components/Client/Client';
import Modal from 'components/UI/Modal/Modal';
import { CLIENTS_TRAINING, CLIENTS } from 'utils/routes';
import Spinner from 'components/UI/Spinner/Spinner';
import { removeClient, fetchClients, updateClientStatus } from 'store/actions/clients';
import { database } from 'utils/firebase';
import withAuth from 'utils/withAuth';
import Layout from '../Layout';
import classes from './Clients.module.css';
import { ALL_CLIENTS, CLIENT_STATUS_TABS } from 'utils/constants';
import TabNavTotals from 'components/UI/TabNav/TabNavTotals';

const mapStateToProps = ({ clients, auth }: RootState) => {
  return {
    token: auth.token,
    clients: clients.clients,
    loading: clients.loading,
    userId: auth.userId,
    coachProfile: auth.coachProfile,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    deleteClient: (clientKey: string, token: string) => dispatch(removeClient(clientKey, token)),
    onFetchClients: (token: string, userId: string) => dispatch(fetchClients(token, userId)),
    onUpdateClientStatus: (clientKey: string, status: number) => dispatch(updateClientStatus(clientKey, status)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & RouteComponentProps;

type SelectedUser = {
  name: string;
  clientKey: string;
};

const EmptyClientsList: FC = () => {
  return (
    <div className={classes.emptyListWrapper}>
      <div>
        <svg viewBox="0 0 162.685 130.148" xmlns="http://www.w3.org/2000/svg" className={classes.emptyIcon}>
          <path
            data-name="Icon awesome-user-plus"
            d="M158.618 52.873h-16.269V36.6a4.079 4.079 0 00-4.067-4.067h-8.134a4.079 4.079 0 00-4.067 4.067v16.273h-16.269a4.079 4.079 0 00-4.067 4.067v8.134a4.079 4.079 0 004.067 4.067h16.268V85.41a4.079 4.079 0 004.067 4.067h8.134a4.079 4.079 0 004.067-4.067V69.141h16.268a4.079 4.079 0 004.067-4.067V56.94a4.079 4.079 0 00-4.065-4.067zM56.94 65.074A32.537 32.537 0 1024.4 32.537a32.534 32.534 0 0032.54 32.537zm22.776 8.134H75.47a44.249 44.249 0 01-37.062 0h-4.244A34.173 34.173 0 000 107.372v10.575a12.2 12.2 0 0012.2 12.2h89.477a12.2 12.2 0 0012.2-12.2v-10.575a34.173 34.173 0 00-34.161-34.164z"
            fill="#cacbd5"
          />
        </svg>
        <h1 className={classes.emptyListTitle}>ADDING A CLIENT</h1>
        <div className={classes.emptyListContentWrapper}>
          <p>1. Client downloads app on App Store</p>
          <p>2. Client registers using your unique coach code</p>
          <p>3. Once client has registered, refresh your page and they will appear here!</p>
          <p>4. Assign them their first program</p>
        </div>
      </div>
    </div>
  );
};

const EmptyClientStatusList: FC = () => {
  return (
    <div className={classes.emptyListWrapper}>
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className={classes.emptyIcon} fill="#cacbd5">
          <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
        </svg>
        {
          // <!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
        }
        <h1 className={classes.emptyListTitle}>NO CLIENTS</h1>
        <div className={classes.emptyListContentWrapper}>
          <p>No users fall under this status.</p>
        </div>
      </div>
    </div>
  );
};

const Clients: FC<Props> = ({
  token,
  userId,
  clients,
  loading,
  history,
  deleteClient,
  onFetchClients,
  onUpdateClientStatus,
}) => {
  const [programTitle, setProgramTitle] = useState('');
  const [removeModal, showRemoveModal] = useState(false);
  const [activeClientKey, setActiveClientKey] = useState('');
  const [programTitleModal, setProgramTitleModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<SelectedUser>({
    clientKey: '',
    name: '',
  });
  const [clientsFiltered, setClientsFiltered] = useState<any>([]);
  const [searchValue, setSearchValue] = useState<string | null>(null);
  const [pauseClientAccessModal, setPauseClientAccessModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState(ALL_CLIENTS);
  const [modalLoading, setModalLoading] = useState(false);
  const [statusTabs, setStatusTabs] = useState<Array<{ title: string; items?: any }>>(
    CLIENT_STATUS_TABS.map((tab) => ({ title: tab })),
  );

  useEffect(() => {
    if (token && userId) {
      onFetchClients(token, userId);
      window.localStorage.removeItem('selectedProgramKey');
    }
  }, [token, userId, onFetchClients]);

  const initialiseProgramCallback = async (err: Error | null, selectedProgramKey: string | null) => {
    if (!err && selectedProgramKey) {
      const clientRef = database.ref(`clients/${activeClientKey}`);

      await clientRef.once('value', (snapshot) => {
        if (snapshot.exists()) {
          clientRef.update({
            hasPrograms: true,
          });
        }
      });
      window.localStorage.setItem('selectedProgramKey', selectedProgramKey);
      // nav to clients training
      history.push({
        pathname: `${CLIENTS_TRAINING.URL}/${activeClientKey}`,
      });
    }
  };

  const initialiseProgram = async () => {
    // create program
    const assignedProgramsRef = database.ref(`newAssignedPrograms`);
    const newAssignedProgramRef = assignedProgramsRef.push();
    await newAssignedProgramRef
      .set({
        name: programTitle,
        image: '',
        weeks: [],
        userId: userId,
        clientKey: activeClientKey,
        createdAt: new Date(),
      })
      .then((data) => {
        initialiseProgramCallback(data, newAssignedProgramRef?.key);
      })
      .catch((err) => {
        initialiseProgramCallback(err, null);
      });
  };

  const preRemoveClient = (payload: SelectedUser) => {
    setSelectedUser(payload);
    showRemoveModal(true);
  };

  const handleRemoveClient = () => {
    if (token) {
      deleteClient(selectedUser.clientKey, token);
      showRemoveModal(false);
    }
  };

  const handleProgramTitle = (clientKey: string) => {
    setActiveClientKey(clientKey);
    setProgramTitleModal(true);
  };

  const showPauseClientAccessModal = (payload: SelectedUser) => {
    setSelectedUser(payload);
    setPauseClientAccessModal(true);
  };

  const setClientStatus = async () => {
    setModalLoading(true);
    const clientKey = selectedUser.clientKey;
    const status = clients?.[clientKey]?.status === 1 || !clients?.[clientKey]?.status ? 2 : 1;
    database
      .ref(`clients/${clientKey}`)
      .update({
        status: status,
      })
      .then(() => {
        onUpdateClientStatus(clientKey, status);
        setModalLoading(false);
        setPauseClientAccessModal(false);
      })
      .catch((e) => {
        setModalLoading(false);
        console.log(e);
      });
  };

  let clientsPerStatus = clients;
  if (clients) {
    if (selectedTab !== 'All') {
      clientsPerStatus = Object.fromEntries(
        Object.entries(clients || {})?.filter(([_, value]) => CLIENT_STATUS_TABS[value?.status || 1] === selectedTab),
      );
    }
  }

  useEffect(() => {
    if (clients) {
      const TABS_OBJECT = CLIENT_STATUS_TABS.map((tab) => {
        if (tab === 'All') {
          return { title: tab, items: Object.keys(clients).length };
        }
        // TODO could use this result to predetermine the clients that need to be shown in each tab instead of having to rerun it above (line 215)
        const result = Object.fromEntries(
          Object.entries(clients || {})?.filter(([_, value]) => CLIENT_STATUS_TABS[value?.status || 1] === tab),
        );
        return { title: tab, items: Object.keys(result || {}).length };
      });
      setStatusTabs(TABS_OBJECT);
    }
  }, [clients]);

  const ClientsList = () => {
    if (!clients) {
      return null;
    }

    let clientsNew = Object.entries(clientsPerStatus || {});
    if (searchValue) {
      clientsNew = clientsFiltered;
    }

    if (!clientsNew || clientsNew.length === 0) {
      if (selectedTab === 'All') {
        return <EmptyClientsList />;
      }
      return <EmptyClientStatusList />;
    }
    return clientsNew.map(([clientKey, client], index: number) => {
      const { profile, hasPrograms = false, status = 1 } = client;
      const { fullname } = profile;
      return (
        <Client
          key={index}
          name={fullname}
          clientKey={clientKey}
          hasPrograms={hasPrograms}
          showProgramTitle={() => handleProgramTitle(clientKey)}
          removeClient={() => preRemoveClient({ clientKey, name: fullname })}
          showPauseClientAccessModal={() => showPauseClientAccessModal({ clientKey, name: fullname })}
          status={status}
        />
      );
    });
  };

  const hasClients = !!Object.keys(clients || {}).length;

  if (loading) {
    return (
      <Layout loading={loading} heading={CLIENTS.TITLE}>
        <div className={classes.clients}>
          <Spinner />
        </div>
      </Layout>
    );
  }

  const onSearchClients = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const value = target?.value.toLowerCase();
    setSearchValue(value);

    const clientsFiltered = Object.entries(clientsPerStatus || {})?.filter(([_, client]) => {
      return client?.profile?.fullname?.toLowerCase()?.includes(value);
    });

    setClientsFiltered(clientsFiltered);
  };

  const onSetSelectedTab = (tab: string) => {
    setSelectedTab(tab);
    setSearchValue(null);
  };

  return (
    <Layout loading={loading} heading={CLIENTS.TITLE}>
      <div className={classes.Container}>
        <TabNavTotals setSelectedTab={onSetSelectedTab} selectedTab={selectedTab} tabHeadingsObject={statusTabs} />
        <div className={classes.SearchClients}>
          <SearchSvg />
          <input onChange={onSearchClients} type="text" placeholder="Search clients..." />
        </div>
        <div
          className={cls(classes.clients, {
            [classes.emptyClients]: !hasClients,
          })}
        >
          {hasClients ? ClientsList() : <EmptyClientsList />}
        </div>
      </div>

      <Modal isModalOpen={programTitleModal} openModal={() => setProgramTitleModal(false)}>
        <div className={classes.modalContainer}>
          <div className={classes.ModalClose}>
            <img
              onClick={() => setProgramTitleModal(false)}
              src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGlkPSJDYXBhXzEiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDM4Ni42NjcgMzg2LjY2NyIgaGVpZ2h0PSI1MTIiIHZpZXdCb3g9IjAgMCAzODYuNjY3IDM4Ni42NjciIHdpZHRoPSI1MTIiIGNsYXNzPSJob3ZlcmVkLXBhdGhzIj48Zz48cGF0aCBkPSJtMzg2LjY2NyA0NS41NjQtNDUuNTY0LTQ1LjU2NC0xNDcuNzcgMTQ3Ljc2OS0xNDcuNzY5LTE0Ny43NjktNDUuNTY0IDQ1LjU2NCAxNDcuNzY5IDE0Ny43NjktMTQ3Ljc2OSAxNDcuNzcgNDUuNTY0IDQ1LjU2NCAxNDcuNzY5LTE0Ny43NjkgMTQ3Ljc2OSAxNDcuNzY5IDQ1LjU2NC00NS41NjQtMTQ3Ljc2OC0xNDcuNzd6IiBkYXRhLW9yaWdpbmFsPSIjMDAwMDAwIiBjbGFzcz0iaG92ZXJlZC1wYXRoIGFjdGl2ZS1wYXRoIiBzdHlsZT0iZmlsbDojRkZGRkZGIiBkYXRhLW9sZF9jb2xvcj0iIzAwMDAwMCI+PC9wYXRoPjwvZz4gPC9zdmc+"
              alt="close"
            />
          </div>

          <div className={classes.ModalSaveIcon}>
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAABjklEQVRoge2ZMU7DMBSG/8eQBWYkJLJzi0pcihtAuUJHuABigI0LMDLAjNpwjJ+hprJM7NiOE8eVv9GJ33ufrTynDVCpTA/JhuQ9yY7z8U7yPLXIekYBnY+xMmKIdAAuRq1GPF8ArkXkJ2ayKcIkJcUzSuaAY+ufSK5Inqapd2IsEje56wqmbyfUeFndrCfYSo2X1c16Ap2pcedORK2afx2fJMM6qa3AoWWbWMRL5iR1ESEELMYVgDeXTFaRQJwyJYkADpnSRACLTIkiwF7mWR9wvmuJiPSNm/zdF4oeV4/h2wn1OaXuyD+qyNJIIkLyMmaeaGix2phYqXZkEyujoyQ2MXOTdK1c1K61ZKrI0qgiBi8AWkkEgBbAa0gBqdpvKyLbkMRDqDPl23WP3n6zvv0OEZK3PiNLo4osjaMV6bJUkQBT5DFLFanh/vPBmuQu53+/MXm9DrKjPBDN39hzMZT3aLuWjUM3m/q5sNGTd6df9xV58EmSmjE12QptSN5RdbPMbEnekmyCRSqVcH4BoyWacwifNfYAAAAASUVORK5CYII="
              alt="save"
            />
          </div>
          <p className={classes.ModalLargeText}>Create Program</p>
          <p className={classes.ModalSmallText}>
            Client has no assigned program.
            <br />
            Set program title
          </p>
          <div className={classes.ProgramTitleInput}>
            <form id="programTitle">
              <input
                style={{
                  height: '30px',
                  width: '300px',
                  outline: 'none',
                  fontSize: '15px',
                }}
                type="text"
                placeholder="Program title"
                onChange={(e) => setProgramTitle(e.target.value)}
              />
            </form>
          </div>

          <div className={classes.ModalButtons}>
            <button onClick={() => setProgramTitleModal(false)} className={classes.ModalCancel}>
              Cancel
            </button>

            <button className={classes.ModalSave} onClick={initialiseProgram}>
              Create Program
            </button>
          </div>
        </div>
      </Modal>

      <Modal isModalOpen={removeModal} openModal={() => showRemoveModal(false)}>
        <div className={classes.modalContainer}>
          <div className={classes.ModalClose}>
            <img
              alt="close"
              onClick={() => showRemoveModal(false)}
              src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGlkPSJDYXBhXzEiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDM4Ni42NjcgMzg2LjY2NyIgaGVpZ2h0PSI1MTIiIHZpZXdCb3g9IjAgMCAzODYuNjY3IDM4Ni42NjciIHdpZHRoPSI1MTIiIGNsYXNzPSJob3ZlcmVkLXBhdGhzIj48Zz48cGF0aCBkPSJtMzg2LjY2NyA0NS41NjQtNDUuNTY0LTQ1LjU2NC0xNDcuNzcgMTQ3Ljc2OS0xNDcuNzY5LTE0Ny43NjktNDUuNTY0IDQ1LjU2NCAxNDcuNzY5IDE0Ny43NjktMTQ3Ljc2OSAxNDcuNzcgNDUuNTY0IDQ1LjU2NCAxNDcuNzY5LTE0Ny43NjkgMTQ3Ljc2OSAxNDcuNzY5IDQ1LjU2NC00NS41NjQtMTQ3Ljc2OC0xNDcuNzd6IiBkYXRhLW9yaWdpbmFsPSIjMDAwMDAwIiBjbGFzcz0iaG92ZXJlZC1wYXRoIGFjdGl2ZS1wYXRoIiBzdHlsZT0iZmlsbDojRkZGRkZGIiBkYXRhLW9sZF9jb2xvcj0iIzAwMDAwMCI+PC9wYXRoPjwvZz4gPC9zdmc+"
            />
          </div>
          <div className={classes.ModalSaveIcon}>
            <img
              src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDUxMiA1MTIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMiA1MTI7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgY2xhc3M9IiI+PGc+PGNpcmNsZSBzdHlsZT0iZmlsbDojRDczQTI3IiBjeD0iMjU2IiBjeT0iMjU2IiByPSIyNTYiIGRhdGEtb3JpZ2luYWw9IiNFMjFCMUIiIGNsYXNzPSIiIGRhdGEtb2xkX2NvbG9yPSIjRTIxQjFCIj48L2NpcmNsZT48cGF0aCBzdHlsZT0iZmlsbDojQkYzMzIyIiBkPSJNNTEwLjI4LDI4NS4zMDRMMzY3LjkxMiwxNDIuOTM2TDE1MC4yNDgsMzY4LjYwOGwxNDAuOTI4LDE0MC45MjggIEM0MDYuMzUyLDQ5My42OTYsNDk3LjA1Niw0MDEuMjg4LDUxMC4yOCwyODUuMzA0eiIgZGF0YS1vcmlnaW5hbD0iI0M0MDYwNiIgY2xhc3M9ImFjdGl2ZS1wYXRoIiBkYXRhLW9sZF9jb2xvcj0iI0M0MDYwNiI+PC9wYXRoPjxnPgoJPHBhdGggc3R5bGU9ImZpbGw6I0ZGRkZGRjsiIGQ9Ik0zNTQuMzc2LDM3MS41MzZjLTUuMTIsMC0xMC4yMzItMS45NTItMTQuMTQ0LTUuODU2TDE0Ni40MDgsMTcxLjg0OCAgIGMtNy44MTYtNy44MTYtNy44MTYtMjAuNDcyLDAtMjguMjhzMjAuNDcyLTcuODE2LDI4LjI4LDBMMzY4LjUyLDMzNy40YzcuODE2LDcuODE2LDcuODE2LDIwLjQ3MiwwLDI4LjI4ICAgQzM2NC42MDgsMzY5LjU4NCwzNTkuNDk2LDM3MS41MzYsMzU0LjM3NiwzNzEuNTM2eiIgZGF0YS1vcmlnaW5hbD0iI0ZGRkZGRiIgY2xhc3M9IiI+PC9wYXRoPgoJPHBhdGggc3R5bGU9ImZpbGw6I0ZGRkZGRjsiIGQ9Ik0xNjAuNTQ0LDM3MS41MzZjLTUuMTIsMC0xMC4yMzItMS45NTItMTQuMTQ0LTUuODU2Yy03LjgxNi03LjgxNi03LjgxNi0yMC40NzIsMC0yOC4yOCAgIGwxOTMuODMyLTE5My44MzJjNy44MTYtNy44MTYsMjAuNDcyLTcuODE2LDI4LjI4LDBzNy44MTYsMjAuNDcyLDAsMjguMjhMMTc0LjY4OCwzNjUuNjggICBDMTcwLjc4NCwzNjkuNTg0LDE2NS42NjQsMzcxLjUzNiwxNjAuNTQ0LDM3MS41MzZ6IiBkYXRhLW9yaWdpbmFsPSIjRkZGRkZGIiBjbGFzcz0iIj48L3BhdGg+CjwvZz48L2c+IDwvc3ZnPg=="
              alt="delete"
            />
          </div>
          <p className={classes.ModalLargeText}>Are you sure?</p>
          <p className={classes.ModalSmallText}>Please confirm you want to remove {selectedUser.name}</p>

          <div className={classes.ModalButtons}>
            <button className={classes.ModalCancel} onClick={() => showRemoveModal(false)}>
              Cancel
            </button>
            <button className={classes.ModalDelete} onClick={handleRemoveClient}>
              Remove
            </button>
          </div>
        </div>
      </Modal>

      <Modal isModalOpen={pauseClientAccessModal} openModal={() => setPauseClientAccessModal(false)}>
        <div className={classes.modalContainer}>
          <div className={classes.ModalClose}>
            <img
              alt="close"
              onClick={() => setPauseClientAccessModal(false)}
              src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGlkPSJDYXBhXzEiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDM4Ni42NjcgMzg2LjY2NyIgaGVpZ2h0PSI1MTIiIHZpZXdCb3g9IjAgMCAzODYuNjY3IDM4Ni42NjciIHdpZHRoPSI1MTIiIGNsYXNzPSJob3ZlcmVkLXBhdGhzIj48Zz48cGF0aCBkPSJtMzg2LjY2NyA0NS41NjQtNDUuNTY0LTQ1LjU2NC0xNDcuNzcgMTQ3Ljc2OS0xNDcuNzY5LTE0Ny43NjktNDUuNTY0IDQ1LjU2NCAxNDcuNzY5IDE0Ny43NjktMTQ3Ljc2OSAxNDcuNzcgNDUuNTY0IDQ1LjU2NCAxNDcuNzY5LTE0Ny43NjkgMTQ3Ljc2OSAxNDcuNzY5IDQ1LjU2NC00NS41NjQtMTQ3Ljc2OC0xNDcuNzd6IiBkYXRhLW9yaWdpbmFsPSIjMDAwMDAwIiBjbGFzcz0iaG92ZXJlZC1wYXRoIGFjdGl2ZS1wYXRoIiBzdHlsZT0iZmlsbDojRkZGRkZGIiBkYXRhLW9sZF9jb2xvcj0iIzAwMDAwMCI+PC9wYXRoPjwvZz4gPC9zdmc+"
            />
          </div>
          <div className={classes.ModalSaveIcon}>
            <img
              src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDUxMiA1MTIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMiA1MTI7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgY2xhc3M9IiI+PGc+PGNpcmNsZSBzdHlsZT0iZmlsbDojRDczQTI3IiBjeD0iMjU2IiBjeT0iMjU2IiByPSIyNTYiIGRhdGEtb3JpZ2luYWw9IiNFMjFCMUIiIGNsYXNzPSIiIGRhdGEtb2xkX2NvbG9yPSIjRTIxQjFCIj48L2NpcmNsZT48cGF0aCBzdHlsZT0iZmlsbDojQkYzMzIyIiBkPSJNNTEwLjI4LDI4NS4zMDRMMzY3LjkxMiwxNDIuOTM2TDE1MC4yNDgsMzY4LjYwOGwxNDAuOTI4LDE0MC45MjggIEM0MDYuMzUyLDQ5My42OTYsNDk3LjA1Niw0MDEuMjg4LDUxMC4yOCwyODUuMzA0eiIgZGF0YS1vcmlnaW5hbD0iI0M0MDYwNiIgY2xhc3M9ImFjdGl2ZS1wYXRoIiBkYXRhLW9sZF9jb2xvcj0iI0M0MDYwNiI+PC9wYXRoPjxnPgoJPHBhdGggc3R5bGU9ImZpbGw6I0ZGRkZGRjsiIGQ9Ik0zNTQuMzc2LDM3MS41MzZjLTUuMTIsMC0xMC4yMzItMS45NTItMTQuMTQ0LTUuODU2TDE0Ni40MDgsMTcxLjg0OCAgIGMtNy44MTYtNy44MTYtNy44MTYtMjAuNDcyLDAtMjguMjhzMjAuNDcyLTcuODE2LDI4LjI4LDBMMzY4LjUyLDMzNy40YzcuODE2LDcuODE2LDcuODE2LDIwLjQ3MiwwLDI4LjI4ICAgQzM2NC42MDgsMzY5LjU4NCwzNTkuNDk2LDM3MS41MzYsMzU0LjM3NiwzNzEuNTM2eiIgZGF0YS1vcmlnaW5hbD0iI0ZGRkZGRiIgY2xhc3M9IiI+PC9wYXRoPgoJPHBhdGggc3R5bGU9ImZpbGw6I0ZGRkZGRjsiIGQ9Ik0xNjAuNTQ0LDM3MS41MzZjLTUuMTIsMC0xMC4yMzItMS45NTItMTQuMTQ0LTUuODU2Yy03LjgxNi03LjgxNi03LjgxNi0yMC40NzIsMC0yOC4yOCAgIGwxOTMuODMyLTE5My44MzJjNy44MTYtNy44MTYsMjAuNDcyLTcuODE2LDI4LjI4LDBzNy44MTYsMjAuNDcyLDAsMjguMjhMMTc0LjY4OCwzNjUuNjggICBDMTcwLjc4NCwzNjkuNTg0LDE2NS42NjQsMzcxLjUzNiwxNjAuNTQ0LDM3MS41MzZ6IiBkYXRhLW9yaWdpbmFsPSIjRkZGRkZGIiBjbGFzcz0iIj48L3BhdGg+CjwvZz48L2c+IDwvc3ZnPg=="
              alt="delete"
            />
          </div>
          <p className={classes.ModalLargeText}>Are you sure?</p>
          <p className={classes.ModalSmallText}>
            Please confirm you want to{' '}
            {clients?.[selectedUser.clientKey]?.status === 1 || !clients?.[selectedUser.clientKey]?.status
              ? 'Pause'
              : 'Unpause'}{' '}
            access for {selectedUser.name}
          </p>

          <div className={classes.ModalButtons}>
            <button className={classes.ModalCancel} onClick={() => setPauseClientAccessModal(false)}>
              Cancel
            </button>
            <button className={classes.ModalDelete} onClick={setClientStatus}>
              {modalLoading ? (
                <i className="fa fa-circle-o-notch fa-spin" />
              ) : clients?.[selectedUser.clientKey]?.status === 1 || !clients?.[selectedUser.clientKey]?.status ? (
                'Pause'
              ) : (
                'Unpause'
              )}
            </button>
          </div>
        </div>
      </Modal>
    </Layout>
  );
};

export default connector(withAuth(Clients));
