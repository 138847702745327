import React, { FC } from 'react';
import SectionHeader from 'components/SectionHeader';
import classes from './CoachDetails.module.css';
import mpClasses from '../SubscriptionFunnel.module.css';
// import ProgramTag from 'components/ProgramTag';
import { PlanImage } from 'interfaces/db';

interface Props {
  coachDetails: {
    title: string;
    coachName: string;
    descriptionTitle: string;
    description: string;
  };
  coachImg: PlanImage;
}

const CoachDetails: FC<Props> = ({
  coachImg,
  coachDetails: { title, coachName, descriptionTitle, description },
}) => {
  return (
    <section className={mpClasses.section} id="coach-overview">
      <SectionHeader>
        <span>Get to know {coachName}</span>
      </SectionHeader>
      <p className={classes.subtitle}>{title}</p>

      <div className={classes.coachPicContainer}>
        <img src={coachImg.downloadURL} alt={coachImg.fileName} />
      </div>
      <div className={classes.detailsContainer}>
        {/* <div>
          <p className={classes.heading}>Qualification</p>
          <p className={classes.text}>
            BA Sports Science, University of KZN
          </p>
          <p className={classes.heading}>LOREM IPSUM</p>
          <p className={classes.text}>Lorem ipsum dolor sit amet</p>
        </div> */}
        <div>
          <p className={classes.heading}>{descriptionTitle}</p>
          <p className={`${classes.text} ${classes.description}`}>{description}</p>
        </div>
      </div>
      {/* <div className={classes.ratingContainer}>
        <div>
          <div className={classes.ratingValue}>37</div>
          <div className={classes.ratingText}>Happy customers</div>
        </div>
        <div>
          <div className={classes.ratingValue}>2</div>
          <div className={classes.ratingText}>National medals</div>
        </div>
        <div>
          <div className={classes.ratingValue}>98%</div>
          <div className={classes.ratingText}>
            client satisfaction
          </div>
        </div>
        <div>
          <div className={classes.ratingValue}>4</div>
          <div className={classes.ratingText}>SA champs trained</div>
        </div>
      </div> */}
    </section>
  );
};

export default CoachDetails;
