import React, { FC } from 'react';

import classes from './ClientAssignCard.module.css';

interface Props {
  name: string;
  initials: string;
  assigned: boolean;
  setAssignedClient: () => void;
}

const ClientAssignCard: FC<Props> = ({
  name,
  initials,
  assigned = false,
  setAssignedClient,
}) => {
  return (
    <div className={classes.Client}>
      <div
        className={
          assigned ? classes.ImageDivHighlight : classes.ImageDiv
        }
        onClick={setAssignedClient}
      >
        {initials.toUpperCase()}
      </div>

      <div className={classes.ClientName}>{name}</div>
    </div>
  );
};

export default ClientAssignCard;
