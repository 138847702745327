import { AnalyticsProgramData } from 'interfaces/api';
import React, { FC, useEffect, useState } from 'react';

import classes from './ProgramPerformanceList.module.css';

import Spinner from 'components/UI/Spinner/Spinner';

// import positiveGrowthSymbol from 'assets/icons/positiveGrowthSymbol.svg';
// import negativeGrowthSymbol from 'assets/icons/negativeGrowthSymbol.svg';
// import flatlineGrowthSymbol from 'assets/icons/flatlineGrowthSymbol.svg';

interface Props {
  loading: boolean;
  programAnalyticsData: AnalyticsProgramData[];
}

type DropdownOption = 'allTimeSubscribers' | 'activeSubscribers';

const ProgramPerformanceList: FC<Props> = ({ programAnalyticsData, loading }) => {
  const [selectedOption, setSelectedOption] = useState<DropdownOption>('allTimeSubscribers');
  const [loadingMessage, setLoadingMessage] = useState<string>('Loading Program Analytics...');

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value as DropdownOption);
  };

  const loader = (
    <div className={classes.Loading}>
      <Spinner />
      {loadingMessage}
    </div>
  );

  useEffect(() => {
    let count = 0;
    const interval = setInterval(() => {
      count === 0 && setLoadingMessage('Still Loading...');
      count === 2 && setLoadingMessage('Calculating analytics for all programs...');
      count++;
    }, 7000);

    if (loading === false || count === 1) {
      clearInterval(interval);
    }
  }, [loading]);

  return (
    <div className={classes.Wrapper}>
      <div className={classes.TopLayer}>
        <p className={classes.Title}>Top Performers</p>
        <select className={classes.Dropdown} onChange={handleSelectChange} value={selectedOption}>
          <option value="allTimeSubscribers"> All Time Subscribers </option>
          <option value="activeSubscribers"> Active Subscribers </option>
        </select>
      </div>

      {loading ? (
        loader
      ) : (
        <table className={classes.TableWrapper}>
          <thead className={classes.TableHeader}>
            <tr>
              <th className={classes.ColumnHeader}>Pos</th>
              <th className={classes.ColumnHeader}></th>
              <th className={classes.ColumnHeader}>Program</th>
              <th className={classes.ColumnHeader}>Subscribers Count</th>
            </tr>
          </thead>
          <div style={{ paddingTop: 10 }}></div>
          <tbody style={{ width: '100%' }}>
            {programAnalyticsData
              .sort((a, b) => {
                return b[selectedOption] - a[selectedOption];
              })
              .map((program, index) => (
                <tr key={program.programName}>
                  <td className={classes.Item} style={{ paddingRight: 20, paddingLeft: 5 }}>
                    {index + 1}
                  </td>
                  <td className={classes.Item} style={{ paddingRight: 25 }}>
                    <img
                      className={classes.Thumbnail}
                      src={program.image || program.programImage}
                      alt={program.programName}
                    />
                  </td>
                  <td className={classes.Item} style={{ paddingRight: 50 }}>
                    {program.programName}
                  </td>
                  <td className={classes.Item}>{program[selectedOption]}</td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default ProgramPerformanceList;

// <div className={classes.ListWrapper}>
// {programAnalyticsData?.map((program, index) => (
//   <div className={classes.ListItem} key={index}>
//     <div className={classes.OverviewWrapper}>
//       <div className={classes.Position}>{index + 1 + '.'}</div>
//       <div className={classes.Thumbnail}>{program.image}</div>
//       <div className={classes.ProgramTitle}>{program.programName}</div>
//     </div>
//     <div className={classes.GrowthWrapper}>
//       <div className={classes.GrowthCount}>
//         {/* {program.growth > 0 ? <img
//         src={positiveGrowthSymbol}
//         alt="positiveGrowthSymbol"
//         className={classes.GrowthIcon}
//       /> : program.growth == 0 ? <img
//         src={flatlineGrowthSymbol}
//         alt="flatlineGrowthSymbol"
//         className={classes.GrowthIcon}
//       /> : <img
//             src={negativeGrowthSymbol}
//             alt="negativeGrowthSymbol"
//             className={classes.GrowthIcon}
//           />} */}
//         {/* {(program.growth + "").replace(/[0-]/g, '')} */}
//       </div>
//       <div className={classes.SubCount}>{program.totalSubscribers}</div>
//     </div>
//   </div>
// ))}
// </div>
