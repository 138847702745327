import {
  FETCH_EXERCISES_START,
  FETCH_EXERCISES_SUCCESS,
  FETCH_EXERCISES_FAIL,
} from '../actions/actionTypes';
import { updateObject } from '../utility';
import { FirebaseObject } from 'interfaces/utils';
import { Exercise } from 'interfaces/db';
import { ExerciseLibraryState } from 'interfaces/state';
import { ExerciseLibraryActionTypes } from 'interfaces/actions/exerciseLibrary';

const initialState: ExerciseLibraryState = {
  exercises: null,
  loading: false,
  finishedPull: false
};

const fetchExercisesStart = (
  state: ExerciseLibraryState,
): ExerciseLibraryState => {
  return updateObject(state, { loading: true });
};

const fetchExercisesSuccess = (
  state: ExerciseLibraryState,
  action: { exercises: FirebaseObject<Exercise> },
): ExerciseLibraryState => {
  return updateObject(state, {
    exercises: action.exercises,
    loading: false,
    finishedPull: true
  });
};

const fetchExercisesFail = (
  state: ExerciseLibraryState,
): ExerciseLibraryState => {
  return updateObject(state, { loading: false, finishedPull: true });
};

const reducer = (
  state = initialState,
  action: ExerciseLibraryActionTypes,
): ExerciseLibraryState => {
  switch (action.type) {
    case FETCH_EXERCISES_START:
      return fetchExercisesStart(state);

    case FETCH_EXERCISES_SUCCESS:
      return fetchExercisesSuccess(state, action);

    case FETCH_EXERCISES_FAIL:
      return fetchExercisesFail(state);

    default:
      return state;
  }
};

export default reducer;
