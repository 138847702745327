import React, { FC } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

import {
  Login,
  Clients,
  Support,
  Account,
  Programs,
  Calendar,
  Register,
  Settings,
  NotFound,
  Documents,
  Dashboard,
  CoachWorkout,
  ClientProfile,
  ClientTraining,
  WorkoutBuilder,
  ForgotPassword,
  ExerciseLibrary,
  ClientDocument,
  Community,
  Checkout,
  SubscriptionFunnel,
  PostPayment,
  Progress,
  Analytics,
} from './containers';
import * as ROUTES from './utils/routes';
import './App.css';

const App: FC = () => {
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to={ROUTES.LOGIN.URL} />
      </Route>
      <Route path={`${ROUTES.CLIENTS_PROGRESS.URL}/:clientKey`} component={Progress} />
      <Route path={ROUTES.POSTPAYMENT.URL} component={PostPayment} />
      <Route path={ROUTES.CHECKOUT.URL} component={Checkout} />
      <Route path={ROUTES.LOGIN.URL} component={Login} exact />
      <Route path={ROUTES.ACCOUNT.URL} component={Account} />
      <Route path={ROUTES.LOGIN.URL} component={Login} exact />
      <Route path={ROUTES.DASHBOARD.URL} component={Dashboard} />
      <Route path={ROUTES.REGISTER.URL} component={Register} exact />
      <Route path={ROUTES.BILLING.URL} component={Settings} />
      <Route path={ROUTES.CLIENTS.URL} component={Clients} exact />
      <Route path={ROUTES.SUPPORT.URL} component={Support} exact />
      <Route path={ROUTES.DOCUMENTS.URL} component={Documents} exact />
      <Route path={ROUTES.ANALYTICS.URL} component={Analytics} exact />
      <Route path={`${ROUTES.CLIENTS_DOCUMENTS.URL}/:clientKey`} component={ClientDocument} exact />
      <Route path={ROUTES.RESET_PASSWORD.URL} component={ForgotPassword} exact />
      <Route path={ROUTES.EXERCISE_LIBRARY.URL} component={ExerciseLibrary} exact />
      <Route
        path={`${ROUTES.CLIENTS_WORKOUT_BUILDER.URL}/:assignedProgramKey/:weekIndex/:dayIndex`}
        component={WorkoutBuilder}
        exact
      />
      <Route path={`${ROUTES.CLIENTS_TRAINING.URL}/:clientKey`} component={ClientTraining} />
      <Route
        component={WorkoutBuilder}
        path={`${ROUTES.COACH_WORKOUT_BUILDER.URL}/:assignedProgramKey/:weekIndex/:dayIndex`}
        exact
      />
      <Route path={ROUTES.COACH_TRAINING.URL} component={CoachWorkout} />
      <Route path={`${ROUTES.CLIENT_PROFILE.URL}/:clientKey`} component={ClientProfile} />
      <Route path={ROUTES.PROGRAMS.URL} component={Programs} exact />
      <Route
        component={WorkoutBuilder}
        path={`${ROUTES.PROGRAM_WORKOUT_BUILDER.URL}/:programKey/:weekIndex/:dayIndex`}
        exact
      />
      <Route path={`${ROUTES.CALENDAR.URL}/:programKey`} component={Calendar} />
      <Route path={ROUTES.COMMUNITY.URL} component={Community} />
      <Route path={`/:funnelId${ROUTES.CHECKOUT.URL}`} component={Checkout} exact />
      <Route path={`/:funnelId`} component={SubscriptionFunnel} exact />

      <Route component={NotFound} />
    </Switch>
  );
};

export default withRouter(App);
