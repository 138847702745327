import React, { FC, useState, useEffect, ChangeEvent } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { connect, ConnectedProps } from 'react-redux';

import { RootState } from 'store';
import withAuth from 'utils/withAuth';
import { storage } from 'utils/firebase';
import { Document as UploadForm, AddCategoryForm } from 'components';
import DocumentCard from '../../components/DocumentCard/DocumentCard';
import TabNav from 'components/UI/TabNav/TabNav';
import { DOCUMENTS } from 'utils/routes';
import { CoachProfile, Profile, Documents as DocumentsType } from 'interfaces/db';
import { getToday, eligibleCoach } from 'utils/helpers';
import Modal from 'components/UI/Modal/Modal';
import { saveClientProfile } from 'store/actions/clients';
import { saveCoachProfile } from 'store/actions/auth';
import Layout from '../Layout';
import { ReactComponent as SearchSvg } from '../../assets/svgs/search-icon.svg';

import classes from './Documents.module.css';
import { fetchDocuments, addDocument, updateDocument, deleteDocument } from 'store/actions/documents';
import { UploadTaskSnapshot, UploadTask } from '@firebase/storage-types';

const mapStateToProps = ({ auth, clients, documents }: RootState) => {
  const { userId, token, coachProfile, loading } = auth;
  return {
    token,
    userId,
    loadingCoach: loading,
    loading: documents.loading,
    coachProfile,
    clients: clients.clients,
    documents: documents.documents,
    coachKey: Object.keys(coachProfile || {})[0],
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onFetchDocuments: (token: string, userId: string) => dispatch(fetchDocuments(token, userId)),
    onSaveCoachProfile: (coachProfile: CoachProfile, coachProfileKey: string, token: string) =>
      dispatch(saveCoachProfile(coachProfile, coachProfileKey, token)),
    onSaveClientProfile: (clientProfile: Profile, clientKey: string, token: string) =>
      dispatch(saveClientProfile(clientProfile, clientKey, token)),
    onDeleteDocument: (url: string, token: string, userId: string, document: DocumentsType, documentId: string) =>
      dispatch(deleteDocument(url, token, userId, document, documentId)),
    onAddDocument: (token: string, document: DocumentsType, userId?: string) =>
      dispatch(addDocument(token, document, userId)),
    onUpdateDocument: (token: string, document: DocumentsType, documentId: string) =>
      dispatch(updateDocument(token, document, documentId)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Documents: FC<PropsFromRedux> = ({
  token,
  userId,
  clients,
  documents,
  loading,
  coachKey,
  coachProfile,
  onFetchDocuments,
  onDeleteDocument,
  onSaveCoachProfile,
  onSaveClientProfile,
  onAddDocument,
  onUpdateDocument,
  loadingCoach,
}) => {
  const [progress, setProgress] = useState(0);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isEditTabsModalOpen, setEditTabsModalOpen] = useState(false);
  const [isCategoriesModalOpen, setCategoriesModalOpen] = useState(false);
  const [filteredDocuments, setFilteredDocuments] = useState<any>([]);
  const [searchValue, setSearchValue] = useState<string | null>(null);

  const defaultCategories = Object.values(
    coachProfile?.[coachKey]?.categories ||
      ['Meal Plans', 'Ebooks'].concat(eligibleCoach(userId) ? ['Subscription App'] : []),
  );

  const [categories, setCategories] = useState(defaultCategories);

  const [selectedTab, setSelectedTab] = useState(categories[0]);
  const [categoriesEdit, setCategoriesEdit] = useState<any>(categories.reduce((o, key) => ({ ...o, [key]: key }), {}));

  const createDocumentsFromMealPlans = (
    mealPlans: {
      downloadURL: string;
      fileName: string;
      title: string;
    }[],
  ) => {
    if (userId && token) {
      mealPlans.forEach((mealPlan) => {
        const newDocument: DocumentsType = {
          documentId: 'string',
          downloadURL: mealPlan.downloadURL,
          fileName: mealPlan.fileName,
          title: mealPlan.title,
          userId: userId,
          fileSize: 0,
          uploadedAt: getToday(),
          category: 'Meal plans',
        };
        onAddDocument(token, newDocument);
      });
    }
  };

  useEffect(() => {
    if (coachKey && coachProfile && coachProfile[coachKey].mealPlans && token && userId) {
      const mealPlans = coachProfile[coachKey].mealPlans;
      if (mealPlans && mealPlans?.length > 0) {
        let newCoachProfile = Object.assign({}, coachProfile?.[coachKey]);
        newCoachProfile = {
          ...newCoachProfile,
          categories: defaultCategories,
          mealPlans: [],
        };

        onSaveCoachProfile(newCoachProfile, coachKey, token);

        createDocumentsFromMealPlans(mealPlans || []);
      }
    }
  }, [coachProfile, coachKey]);

  useEffect(() => {
    const categoriesNew = JSON.parse(JSON.stringify(defaultCategories));
    if (categoriesNew.indexOf('Subscription App') === -1 && eligibleCoach(userId)) {
      categoriesNew.push('Subscription App');
    }
    setCategories(categoriesNew);
    setCategoriesEdit(categoriesNew.reduce((o: any, key: any) => ({ ...o, [key]: key }), {}));
    setSelectedTab(categoriesNew[0]);
  }, [coachProfile?.[coachKey]?.categories]);

  useEffect(() => {
    if (token && userId) {
      onFetchDocuments(token, userId);
    }
  }, [token, userId, onFetchDocuments]);

  const uploadProgress: (a: UploadTaskSnapshot) => any = (snapshot) => {
    setProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
  };

  const uploadError: (a: Error) => any = (error) => {
    console.log(error);
  };

  const uploadSuccess = ({
    uploadTask,
    title,
    fileName,
    fileSize,
    category,
  }: {
    uploadTask: UploadTask;
    title: string;
    fileName: string;
    fileSize: number;
    category: string;
  }) => {
    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
      if (coachProfile && coachProfile[coachKey] && token && documents && userId) {
        const { totalDocumentsSize = 0, documentCount, recentDocuments = [] } = coachProfile[coachKey];

        const newCoachProfile = {
          ...coachProfile[coachKey],
          totalDocumentsSize: totalDocumentsSize + fileSize,
          documentCount: documentCount && documentCount + 1,
          recentDocuments: recentDocuments,
        };

        const newDocument = {
          documentId: uuidv4(),
          clientList: [],
          downloadURL: downloadURL,
          fileName: fileName,
          title: title,
          userId: userId,
          fileSize: fileSize,
          uploadedAt: getToday(),
          category: category,
        };

        onAddDocument(token, newDocument, userId);

        onSaveCoachProfile(newCoachProfile, coachKey, token);
        setModalOpen(false);
        setProgress(0);
      }
    });
  };

  const handleSubmit = async ({ title, file, category }: { title: string; file: FileList; category: string }) => {
    const { name, size } = file[0];
    const storageRefString =
      category !== 'Subscription App'
        ? `${userId}/documents/${uuidv4()}`
        : `${userId}/documents/subscription-app/${uuidv4()}`;
    const storageRef = storage.ref(storageRefString);
    const uploadTask: any = storageRef.put(file[0]);

    uploadTask.on('state_changed', uploadProgress, uploadError, () =>
      uploadSuccess({
        uploadTask,
        title,
        fileName: name,
        fileSize: size,
        category,
      }),
    );
  };

  const addCategory = async (category: string) => {
    const add = async () => {
      if (coachProfile && coachKey && token) {
        const coachCategories: string[] = coachProfile[coachKey].categories
          ? Array.from(new Set([...coachProfile[coachKey].categories, ...categories]))
          : categories;

        console.log([...coachCategories, category]);
        const updatedCoachProfile = {
          ...coachProfile[coachKey],
          categories: [...coachCategories, category],
        };
        await onSaveCoachProfile(updatedCoachProfile, coachKey, token);
      }
    };
    add().then(() => {
      setCategoriesModalOpen(false);
    });
  };

  const documentsForCategory =
    documents &&
    Object.fromEntries(
      Object.entries(documents || {})?.filter(
        ([_, value]) => value?.category?.toLowerCase() === selectedTab?.toLowerCase(),
      ),
    );

  const removeCategory = (category: string) => {
    if (coachProfile && coachKey && token) {
      const coachProfileCopy = { ...coachProfile?.[coachKey] };
      const newCoachProfile = {
        ...coachProfile?.[coachKey],
        categories: coachProfileCopy?.categories?.filter((cat) => cat.toLowerCase() !== category.toLowerCase()),
      };

      if (newCoachProfile) {
        onSaveCoachProfile(newCoachProfile, coachKey, token);
      }
    }
  };

  const categoryOnChangeHandler = (e: any) => {
    if (e) {
      const categoriesEditCopy = { ...categoriesEdit };
      const id: string = e.target.id;
      categoriesEditCopy[id] = e.target.value;
      setCategoriesEdit(categoriesEditCopy);
    }
  };

  const onSaveCategoriesHandler = () => {
    const saveCoachProfile = new Promise((resolve, reject) => {
      if (coachProfile && coachKey && token) {
        const updatedCoachProfile = {
          ...coachProfile[coachKey],
          categories: Object.keys(categoriesEdit)?.map((key) => categoriesEdit[key]),
        };
        onSaveCoachProfile(updatedCoachProfile, coachKey, token).then(() => {
          resolve(null);
        });
      } else {
        reject(new Error("One of the following don't exist: coach-profile && coach-key && token"));
      }
    });

    const saveDocuments = new Promise((resolve, reject) => {
      if (token && documents && categoriesEdit) {
        for (const docKey in documents) {
          for (const catKey in categoriesEdit) {
            if (
              documents[docKey] &&
              documents[docKey]?.category?.toLowerCase() === catKey?.toLowerCase() &&
              documents[docKey]?.category?.toLowerCase() !== categoriesEdit[catKey]?.toLowerCase()
            ) {
              const updatedDocument = {
                ...documents[docKey],
                category: categoriesEdit[catKey],
              };
              onUpdateDocument(token, updatedDocument, docKey);
              break;
            }
          }
        }
        resolve(null);
      } else {
        reject(new Error("One of the following don't exist: token && documents && categories"));
      }
    });

    Promise.all([saveCoachProfile, saveDocuments])
      .then(() => {
        setEditTabsModalOpen(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSearchDocuments = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const value = target?.value.toLowerCase();
    setSearchValue(value);

    const documentsFilteredBySearch = Object.entries(documentsForCategory || {})?.filter(([_, document]) => {
      return document?.title?.toLowerCase()?.includes(value);
    });

    setFilteredDocuments(documentsFilteredBySearch);
  };

  const DocumentsList = () => {
    if (!documents) {
      return null;
    }

    let documentsNew = Object.entries(documentsForCategory || {});
    if (searchValue) documentsNew = filteredDocuments;

    return documentsNew.map(([key, document], index: number) => {
      return (
        <DocumentCard
          key={index}
          documentKey={key}
          document={document}
          title={document?.title}
          token={token}
          userId={userId}
          clients={clients}
          fileName={document?.fileName}
          downloadURL={document?.downloadURL}
          onDeleteDocument={onDeleteDocument}
          onSaveClientProfile={onSaveClientProfile}
          onUpdateDocument={onUpdateDocument}
        />
      );
    });
  };

  const onSetSelectedTab = (tab: string) => {
    setSelectedTab(tab);
    setSearchValue(null);
  };

  return (
    <Layout loading={loading} heading={DOCUMENTS.TITLE}>
      <div className={classes.Container}>
        <TabNav
          setSelectedTab={onSetSelectedTab}
          selectedTab={selectedTab}
          tabHeadings={categories}
          setCategoriesModalOpen={setCategoriesModalOpen}
          setEditTabsModalOpen={setEditTabsModalOpen}
        />

        <div className={classes.SearchDocuments}>
          <SearchSvg />
          <input onChange={onSearchDocuments} value={searchValue || ''} type="text" placeholder="Search documents..." />
        </div>

        <div className={classes.FilesWrapper}>{DocumentsList()}</div>
      </div>

      <button type="button" onClick={() => setModalOpen(true)} className={classes.AddProgramButtonWrapper}>
        <div className={classes.AddProgramText}>Upload Document</div>
        <div className={classes.AddProgramButton}>+</div>
      </button>

      <Modal isModalOpen={isModalOpen} openModal={() => setModalOpen(false)}>
        <div className={classes.modalContainer}>
          <div className={classes.ModalClose}>
            <img
              onClick={() => setModalOpen(false)}
              src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGlkPSJDYXBhXzEiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDM4Ni42NjcgMzg2LjY2NyIgaGVpZ2h0PSI1MTIiIHZpZXdCb3g9IjAgMCAzODYuNjY3IDM4Ni42NjciIHdpZHRoPSI1MTIiIGNsYXNzPSJob3ZlcmVkLXBhdGhzIj48Zz48cGF0aCBkPSJtMzg2LjY2NyA0NS41NjQtNDUuNTY0LTQ1LjU2NC0xNDcuNzcgMTQ3Ljc2OS0xNDcuNzY5LTE0Ny43NjktNDUuNTY0IDQ1LjU2NCAxNDcuNzY5IDE0Ny43NjktMTQ3Ljc2OSAxNDcuNzcgNDUuNTY0IDQ1LjU2NCAxNDcuNzY5LTE0Ny43NjkgMTQ3Ljc2OSAxNDcuNzY5IDQ1LjU2NC00NS41NjQtMTQ3Ljc2OC0xNDcuNzd6IiBkYXRhLW9yaWdpbmFsPSIjMDAwMDAwIiBjbGFzcz0iaG92ZXJlZC1wYXRoIGFjdGl2ZS1wYXRoIiBzdHlsZT0iZmlsbDojRkZGRkZGIiBkYXRhLW9sZF9jb2xvcj0iIzAwMDAwMCI+PC9wYXRoPjwvZz4gPC9zdmc+"
              alt="close"
            />
          </div>

          <p className={classes.ModalLargeText}>Upload Document</p>
          <UploadForm
            progress={progress}
            selectedTab={selectedTab}
            handleSubmit={handleSubmit}
            categories={categories}
          />
        </div>
      </Modal>

      <Modal isModalOpen={isCategoriesModalOpen} openModal={() => setCategoriesModalOpen(false)}>
        <div className={classes.modalContainer}>
          <div className={classes.ModalClose}>
            <img
              onClick={() => setCategoriesModalOpen(false)}
              src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGlkPSJDYXBhXzEiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDM4Ni42NjcgMzg2LjY2NyIgaGVpZ2h0PSI1MTIiIHZpZXdCb3g9IjAgMCAzODYuNjY3IDM4Ni42NjciIHdpZHRoPSI1MTIiIGNsYXNzPSJob3ZlcmVkLXBhdGhzIj48Zz48cGF0aCBkPSJtMzg2LjY2NyA0NS41NjQtNDUuNTY0LTQ1LjU2NC0xNDcuNzcgMTQ3Ljc2OS0xNDcuNzY5LTE0Ny43NjktNDUuNTY0IDQ1LjU2NCAxNDcuNzY5IDE0Ny43NjktMTQ3Ljc2OSAxNDcuNzcgNDUuNTY0IDQ1LjU2NCAxNDcuNzY5LTE0Ny43NjkgMTQ3Ljc2OSAxNDcuNzY5IDQ1LjU2NC00NS41NjQtMTQ3Ljc2OC0xNDcuNzd6IiBkYXRhLW9yaWdpbmFsPSIjMDAwMDAwIiBjbGFzcz0iaG92ZXJlZC1wYXRoIGFjdGl2ZS1wYXRoIiBzdHlsZT0iZmlsbDojRkZGRkZGIiBkYXRhLW9sZF9jb2xvcj0iIzAwMDAwMCI+PC9wYXRoPjwvZz4gPC9zdmc+"
              alt="close"
            />
          </div>

          <p className={classes.ModalLargeText}>Add Category</p>
          <AddCategoryForm
            handleSubmit={addCategory}
            existingCategories={categories?.map((category) => category?.toLowerCase())}
            loading={loading || loadingCoach}
          />
        </div>
      </Modal>

      <Modal isModalOpen={isEditTabsModalOpen} openModal={() => setEditTabsModalOpen(false)}>
        <div className={classes.modalContainer}>
          <div className={classes.ModalClose}>
            <img
              onClick={() => setEditTabsModalOpen(false)}
              src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGlkPSJDYXBhXzEiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDM4Ni42NjcgMzg2LjY2NyIgaGVpZ2h0PSI1MTIiIHZpZXdCb3g9IjAgMCAzODYuNjY3IDM4Ni42NjciIHdpZHRoPSI1MTIiIGNsYXNzPSJob3ZlcmVkLXBhdGhzIj48Zz48cGF0aCBkPSJtMzg2LjY2NyA0NS41NjQtNDUuNTY0LTQ1LjU2NC0xNDcuNzcgMTQ3Ljc2OS0xNDcuNzY5LTE0Ny43NjktNDUuNTY0IDQ1LjU2NCAxNDcuNzY5IDE0Ny43NjktMTQ3Ljc2OSAxNDcuNzcgNDUuNTY0IDQ1LjU2NCAxNDcuNzY5LTE0Ny43NjkgMTQ3Ljc2OSAxNDcuNzY5IDQ1LjU2NC00NS41NjQtMTQ3Ljc2OC0xNDcuNzd6IiBkYXRhLW9yaWdpbmFsPSIjMDAwMDAwIiBjbGFzcz0iaG92ZXJlZC1wYXRoIGFjdGl2ZS1wYXRoIiBzdHlsZT0iZmlsbDojRkZGRkZGIiBkYXRhLW9sZF9jb2xvcj0iIzAwMDAwMCI+PC9wYXRoPjwvZz4gPC9zdmc+"
              alt="close"
            />
          </div>

          <p className={classes.ModalLargeText}>Edit Categories</p>
          <div className={classes.CategoriesList}>
            {Object.keys(categoriesEdit || {})?.map((catKey) => (
              <div key={catKey} className={classes.CategoryWrapInput}>
                <input
                  className={classes.CategoryInput}
                  value={categoriesEdit[catKey]}
                  id={catKey}
                  onChange={(e) => categoryOnChangeHandler(e)}
                  readOnly={catKey?.toLowerCase() === 'subscription app' && eligibleCoach(userId)}
                />
                {Object.values(documents || {})?.find(
                  (doc) => doc?.category?.toLowerCase() === catKey?.toLowerCase(),
                ) ||
                (catKey?.toLowerCase() === 'subscription app' && eligibleCoach(userId)) ? null : (
                  <button onClick={() => removeCategory(catKey)} className={classes.DeleteButton}>
                    <img
                      className={classes.DeleteIcon}
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAC2ElEQVRoQ+1ZS2gTURQ9902s1mKw4AexIIigoq4UsS4EQaW7rBpcuApJN5GQmVlUd4GiVNrO1KKLNKmiK2l37oquCuKySJW6URA/oC5qpT9K865MsTBJa16cSYjgm+39nrm/d98j1OHL5XIiGu24KiCTAHUysEOhdg2EVwA/mp/fnc/l4qth3aCwCgYH83sigsYZdDGILgKmS2zEbDvxMYj8hkwoIPl8fufSkpgC43QYJwDMGpHWc5nMtZ9B9YQC4rqjt8F0M6jxMjkixzSTdlBdgYEMDDxuixgrXwCK+owvEnMfmF8QxPJWTnFERpjpLJhuAdjl41lo2b56IJ1OLwQBExiI44xdIchJv1EGEpaVeliLI65bTIB5zM9Lgruy2Z4ynbXo8ng2AXGcQpGAQzUoOAjgeAXfDAi1dSBGC4BTFfKzAD6rbDPwwbJSybKfUCnkOoXXAE6olDWZ/sa0Uic1kCZHwW9eHZHhoWI3C25XOU3Sm97cquL7Ozots8CKSoYkzWXt5ETV1FIp+VfpyvbrOIWYYD7WXADiR9ZO5qv5oATiuoVxMLqbCoTwzjRTRzQQ7w/oiNQzF3Vq+f5mldT6BOAtgP0VZ6ZFAC/hnaUIF8oCw5j6fRbrBNDmo80A+ArA644dm4LZ0Igw7pt26vr6ACUe9xlfn7ojIw/2ltZK3/xOGRFjXyaT+F55niOmuDfg3KHCPRDSGki1uvxjaumI6NRq0GTXqaVTS6fWelfW7VdPdn1Eqb446BrRNaJrRNeI3hD1qlulCoJfmep9RO8jeh/5T/YRwoQo4Q4TX2Kifl9DeS8k4tLgdjA9K2s0xJdFieakgHczeXiDRsw3iOm5NNC75VtMQ69M63nbrtJVHyDFfjD3qmw1lM6YNO1UV6gXq+HhsaMs5TSAOr/g1g6dmGNZu+dpKCCe8N2h0fOSaBDAGQDbanchFKdksPds0WdZPU9Umn4BuIU/b/ejCBUAAAAASUVORK5CYII="
                      alt="delete category"
                    />
                  </button>
                )}
              </div>
            ))}
          </div>
          <div className={classes.ModalButtons}>
            <button type="button" onClick={onSaveCategoriesHandler} className={classes.ModalSave}>
              {loading || loadingCoach ? <i className="fa fa-circle-o-notch fa-spin" /> : 'Save Changes'}
            </button>
          </div>
        </div>
      </Modal>
    </Layout>
  );
};

export default connector(withAuth(Documents));
