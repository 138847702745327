export const ADD_CLIENT = 'ADD_CLIENT';
export const REMOVE_CLIENT = 'REMOVE_CLIENT';

export const UPDATE_CLIENT_STATUS = 'UPDATE_CLIENT_STATUS';

export const FETCH_CLIENTS_START = 'FETCH_CLIENTS_START';
export const FETCH_CLIENTS_SUCCESS = 'FETCH_CLIENTS_SUCCESS';
export const FETCH_CLIENTS_FAIL = 'FETCH_CLIENTS_FAIL';

export const FETCH_PROGRAMS_START = 'FETCH_PROGRAMS_START';
export const FETCH_PROGRAMS_SUCCESS = 'FETCH_PROGRAMS_SUCCESS';
export const FETCH_PROGRAMS_FAIL = 'FETCH_PROGRAMS_FAIL';

export const FETCH_PROGRAM_START = 'FETCH_PROGRAM_START';
export const FETCH_PROGRAM_SUCCESS = 'FETCH_PROGRAM_SUCCESS';
export const FETCH_PROGRAM_FAIL = 'FETCH_PROGRAM_FAIL';

export const FETCH_COACH_PROFILE_START = 'FETCH_COACH_PROFILE_START';
export const FETCH_COACH_PROFILE_SUCCESS = 'FETCH_COACH_PROFILE_SUCCESS';
export const FETCH_COACH_PROFILE_FAIL = 'FETCH_COACH_PROFILE_FAIL';

export const PUT_PROGRAM_START = 'PUT_PROGRAM_START';
export const PUT_PROGRAM_SUCCESS = 'PUT_PROGRAM_SUCCESS';
export const PUT_PROGRAM_FAIL = 'PUT_PROGRAM_FAIL';
export const PUT_PROGRAM_INIT = 'PUT_PROGRAM_INIT';

export const INITIALISE_PROGRAM_START = 'INITIALISE_PROGRAM_START';
export const INITIALISE_PROGRAM_SUCCESS = 'INITIALISE_PROGRAM_SUCCESS';
export const INITIALISE_PROGRAM_FAIL = 'INITIALISE_PROGRAM_FAIL';
export const INITIALISE_PROGRAM_INIT = 'INITIALISE_PROGRAM_INIT';

export const REMOVE_PROGRAM_START = 'REMOVE_PROGRAM_START';
export const REMOVE_PROGRAM_SUCCESS = 'REMOVE_PROGRAM_SUCCESS';
export const REMOVE_PROGRAM_FAIL = 'REMOVE_PROGRAM_FAIL';
export const REMOVE_PROGRAM_INIT = 'REMOVE_PROGRAM_INIT';

export const REMOVE_CLIENT_START = 'REMOVE_CLIENT_START ';
export const REMOVE_CLIENT_SUCCESS = 'REMOVE_CLIENT_SUCCESS';
export const REMOVE_CLIENT_FAIL = 'REMOVE_CLIENT_FAIL';
export const REMOVE_CLIENT_INIT = 'REMOVE_CLIENT_INIT';

export const SET_REST_DAY = 'SET_REST_DAY';
export const REMOVE_REST_DAY = 'REMOVE_REST_DAY';

export const ADD_WEEK = 'ADD_WEEK';
export const REMOVE_WEEK = 'REMOVE_WEEK';

// export const INCREMENT_WEEK_KEY = 'INCREMENT_WEEK_KEY';
export const INCREMENT_EXERCISE_KEY = 'INCREMENT_EXERCISE_KEY';
// export const ADD_WORKOUT_TO_DAY = 'ADD_WORKOUT_TO_DAY';

export const ADD_EXERCISE = 'ADD_EXERCISE';
export const REMOVE_EXERCISE = 'REMOVE_EXERCISE';
export const ADD_SUPERSET = 'ADD_SUPERSET';
export const ADD_TABLE_SET = 'ADD_TABLE_SET';
export const PROGRAM_TABLE_SET_CHANGE = 'PROGRAM_TABLE_SET_CHANGE';
export const ADD_EXERCISE_TYPE = 'ADD_EXERCISE_TYPE';
export const ADD_EXERCISE_NOTES = 'ADD_EXERCISE_NOTES';
export const ADD_WORKOUT_NOTES = 'ADD_WORKOUT_NOTES';
export const SET_WORKOUT_TITLE = 'SET_WORKOUT_TITLE';

export const FETCH_WORKOUTS_START = 'FETCH_WORKOUTS_START';
export const FETCH_WORKOUTS_SUCCESS = 'FETCH_WORKOUTS_SUCCESS';
export const FETCH_WORKOUTS_FAIL = 'FETCH_WORKOUTS_FAIL';

export const SAVE_COACH_PROFILE_START = 'SAVE_COACH_PROFILE_START';
export const SAVE_COACH_PROFILE_SUCCESS = 'SAVE_COACH_PROFILE_SUCCESS';
export const SAVE_COACH_PROFILE_FAIL = 'SAVE_COACH_PROFILE_FAIL';

export const SAVE_CLIENT_PROFILE_START = 'SAVE_CLIENT_PROFILE_START';
export const SAVE_CLIENT_PROFILE_SUCCESS = 'SAVE_CLIENT_PROFILE_SUCCESS';
export const SAVE_CLIENT_PROFILE_FAIL = 'SAVE_CLIENT_PROFILE_FAIL';

export const CHANGE_HEADER_TITLE = 'CHANGE_HEADER_TITLE';
export const SET_CURRENT_DAY = 'SET_CURRENT_DAY';

export const UPDATE_INDEX = 'UPDATE_INDEX';
// export const DISCARD_PROGRAM_STATE = 'DISCARD_PROGRAM_STATE';

export const SET_PROGRAM_INDEX = 'SET_PROGRAM_INDEX';

export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const SET_CURRENT_PROGRAM_INDEX = 'SET_CURRENT_PROGRAM_INDEX';

export const DUPLICATE_WEEK = 'DUPLICATE_WEEK';

export const COPY_WORKOUT = 'COPY_WORKOUT';
export const PASTE_WORKOUT = 'PASTE_WORKOUT';

export const CREATE_COACH_PROFILE_START = 'CREATE_COACH_PROFILE_START';
export const CREATE_COACH_PROFILE_SUCCESS = 'CREATE_COACH_PROFILE_SUCCESS';
export const CREATE_COACH_PROFILE_FAIL = 'CREATE_COACH_PROFILE_FAIL';

export const SAVE_COACH_CODE_START = 'SAVE_COACH_CODE_START';
export const SAVE_COACH_CODE_SUCCESS = 'SAVE_COACH_CODE_SUCCESS';
export const SAVE_COACH_CODE_FAIL = 'SAVE_COACH_CODE_FAIL';

export const FETCH_COACH_CODES_START = 'FETCH_COACH_CODES_START';
export const FETCH_COACH_CODES_SUCCESS = 'FETCH_COACH_CODES_SUCCESS';
export const FETCH_COACH_CODES_FAIL = 'FETCH_COACH_CODES_FAIL';

export const GO_BACK_PROGRAM = 'GO_BACK_PROGRAM';

// Plans
export const FETCH_PLAN_START = 'FETCH_PLAN_START';
export const FETCH_PLAN_SUCCESS = 'FETCH_PLAN_SUCCESS';
export const FETCH_PLAN_FAIL = 'FETCH_PLAN_FAIL';

export const SET_TOKEN = 'SET_TOKEN';

// Exercise Library
export const FETCH_EXERCISES_START = 'FETCH_EXERCISES_START';
export const FETCH_EXERCISES_SUCCESS = 'FETCH_EXERCISES_SUCCESS';
export const FETCH_EXERCISES_FAIL = 'FETCH_EXERCISES_FAIL';

// Documents
export const FETCH_DOCUMENTS_START = 'FETCH_DOCUMENTS_START';
export const FETCH_DOCUMENTS_SUCCESS = 'FETCH_DOCUMENTS_SUCCESS';
export const FETCH_DOCUMENTS_FAIL = 'FETCH_DOCUMENTS_FAIL';

export const FETCH_DOCUMENT_START = 'FETCH_DOCUMENT_START';
export const FETCH_DOCUMENT_SUCCESS = 'FETCH_DOCUMENT_SUCCESS';
export const FETCH_DOCUMENT_FAIL = 'FETCH_DOCUMENT_FAIL';

export const ADD_DOCUMENT_START = 'ADD_DOCUMENT_START';
export const ADD_DOCUMENT_SUCCESS = 'ADD_DOCUMENT_SUCCESS';
export const ADD_DOCUMENT_FAIL = 'ADD_DOCUMENT_FAIL';

export const UPDATE_DOCUMENT_START = 'UPDATE_DOCUMENT_START';
export const UPDATE_DOCUMENT_SUCCESS = 'UPDATE_DOCUMENT_SUCCESS';
export const UPDATE_DOCUMENT_FAIL = 'UPDATE_DOCUMENT_FAIL';

export const SAVE_WORKOUT_START = 'SAVE_WORKOUT_START';
export const SAVE_WORKOUT_SUCCESS = 'SAVE_WORKOUT_SUCCESS';
export const SAVE_WORKOUT_FAIL = 'SAVE_WORKOUT_FAIL';
