import React, { FC } from 'react';
import Sidebar from 'components/Navigation/Sidebar/Sidebar';
import Header from 'components/UI/Header/Header';
import classes from './Layout.module.css';

type Props = {
  heading: string;
  loading: boolean;
};

const Layout: FC<Props> = ({ heading, loading, children }) => {
  return (
    <div className={classes.container}>
      <div className={classes.lhsWrapper}>
        <Sidebar />
      </div>

      <div className={classes.rhsWrapper}>
        <div className={classes.innerRhsWrapper}>
          <Header heading={heading} loading={loading} />

          <main>
            <div>{children}</div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default Layout;
