import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';

import { DASHBOARD } from 'utils/routes';

const NotFound: FC = () => {
  return (
    <div
      style={{
        color: '#fff',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      404: Not found.
      <NavLink
        to={{ pathname: DASHBOARD.URL }}
        style={{ marginLeft: '0.25rem', color: '#e94811' }}
      >
        Go home
      </NavLink>
    </div>
  );
};

export default NotFound;
