import React, { FC } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import { object, string } from 'yup';
import { NavLink } from 'react-router-dom';

import Button from '../../inputs/Button';
import TextField from '../../inputs/TextField';
import { FormProps } from 'interfaces/utils';
import { LOGIN } from 'utils/routes';
import classes from './ForgotPassword.module.css';

const initialValues = { email: '' };
const validationSchema = object().shape({
  email: string().email().required(),
});

type FormValues = {
  email: string;
};

const EmailIcon: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="16"
      viewBox="0 0 20 16"
    >
      <path
        id="Icon_material-email"
        data-name="Icon material-email"
        d="M21,6H5A2,2,0,0,0,3.01,8L3,20a2.006,2.006,0,0,0,2,2H21a2.006,2.006,0,0,0,2-2V8A2.006,2.006,0,0,0,21,6Zm0,4-8,5L5,10V8l8,5,8-5Z"
        transform="translate(-3 -6)"
        fill="#cacbd5"
        opacity="0.5"
      />
    </svg>
  );
};

const ForgotPassword: FC<FormProps<FormValues>> = ({
  handleSubmit,
}) => {
  const onSubmit = async (
    values: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    await handleSubmit(values);
    actions.setSubmitting(false);
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ errors, touched, isSubmitting }) => (
        <Form className={classes.wrapper}>
          <div className={classes.inputWrapper}>
            <TextField
              name="email"
              errors={errors}
              touched={touched}
              placeholder="Email"
              icon={<EmailIcon />}
            />

            <NavLink
              to={{ pathname: LOGIN.URL }}
              className={classes.loginLink}
            >
              Go back to login
            </NavLink>

            <div>
              <Button
                type="submit"
                loading={isSubmitting}
                disabled={isSubmitting}
              >
                Send Password Reset Email
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ForgotPassword;
