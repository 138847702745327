import React, { FC, useState } from 'react';

import Exercise from 'components/Exercise/Exercise';
import Dropdown from '../../../../components/UI/Dropdown/Dropdown';
import QuickEditModal from '../../../../components/UI/QuickEditModal/QuickEditModal';

import { SetKeys, Day as DayType } from 'interfaces/db';
import classes from './Day.module.css';
interface Props {
  day: DayType;
  dayNumber: number;
  weekNumber: number;
  saveProgram: () => void;
  setRestDay: (weekIndex: number, dayIndex: number) => void;
  removeRestDay: (weekIndex: number, dayIndex: number) => void;
  addWorkout: () => Promise<void>;
  copyWorkoutHandler: () => void;
  pasteWorkoutHandler: () => void;
  goToWorkoutBuilder: () => Promise<void>;
  addExerciseNotes: (
    exerciseNotes: string,
    group: number,
    index: number,
  ) => void;
  addWorkoutNotes: (
    workoutNotes: string,
    dayIndex: number,
    weekIndex: number,
  ) => void;
  addSetHandler: (group: number, index: number) => void;
  removeSetHandler: (group: number, index: number) => void;
  setsChangeHandler: (
    key: SetKeys,
    val: string,
    setIndex: number,
    group: number,
    index: number,
  ) => void;
  autoFillSetsHandler: (
    key: SetKeys,
    val: string,
    setIndex: number,
    group: number,
    index: number,
  ) => void;
  setShowAutoFill: (setIdentifier: string) => void;
  showAutoFill: string;
  previousDay?: DayType;
  coachId?: string | null;
}

const Day: FC<Props> = ({
  day,
  dayNumber,
  setRestDay,
  weekNumber,
  addWorkout,
  saveProgram,
  addSetHandler,
  removeRestDay,
  addExerciseNotes,
  addWorkoutNotes,
  removeSetHandler,
  setsChangeHandler,
  copyWorkoutHandler,
  goToWorkoutBuilder,
  pasteWorkoutHandler,
  autoFillSetsHandler,
  setShowAutoFill,
  showAutoFill,
  previousDay,
  coachId,
}) => {
  const [isQuickEdit, setIsQuickEdit] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);

  const removeRestDayHandler = () => {
    removeRestDay(weekNumber, dayNumber);
  };

  const setRestDayHandler = () => {
    setShowDropDown(false);
    setRestDay(weekNumber, dayNumber);
  };

  let exercises = null;

  if (day.rest === true) {
    exercises = (
      <div className={classes.RestDay}>
        <p className={classes.RestDayHeading}>REST DAY</p>

        <button
          className={classes.Button}
          onClick={removeRestDayHandler}
        >
          Remove rest day
        </button>
      </div>
    );
  } else {
    exercises =
      day.workout &&
      day.workout.exercises &&
      Object.keys(day.workout.exercises).map((_, index) => {
        if (day.workout.exercises?.[index]) {
          return (
            <Exercise
              key={index}
              exerciseBlock={String.fromCharCode(65 + index)}
              exercise={day.workout.exercises[index]}
            />
          );
        }

        return null;
      }); // group might need to be index
  }
  const finished = day.workout && day.workout.finished;

  return (
    <>
      <QuickEditModal
        showModal={isQuickEdit}
        closeModal={() => setIsQuickEdit(false)}
        day={day}
        dayNumber={dayNumber}
        weekNumber={weekNumber}
        saveProgram={saveProgram}
        addSetHandler={addSetHandler}
        setIsQuickEdit={setIsQuickEdit}
        removeSetHandler={removeSetHandler}
        addExerciseNotes={addExerciseNotes}
        addWorkoutNotes={addWorkoutNotes}
        setsChangeHandler={setsChangeHandler}
        autoFillSetsHandler={autoFillSetsHandler}
        setShowAutoFill={setShowAutoFill}
        showAutoFill={showAutoFill}
        previousDay={previousDay}
        coachId={coachId}
      />
      <div className={classes.wrapper}>
        {showDropDown ? (
          <Dropdown
            setShowDropDown={setShowDropDown}
            showDropDown={showDropDown}
            copyWorkoutHandler={copyWorkoutHandler}
            pasteWorkoutHandler={pasteWorkoutHandler}
            setRestDayHandler={setRestDayHandler}
          />
        ) : null}
        <div className={classes.Day}>
          {finished ? (
            <div className={classes.DayFinished}>
              <div className={classes.DayFinishedDay}>
                DAY {dayNumber + 1}
              </div>
              <div className={classes.DayFinishedTitle}>
                {day.workout && day.workout.workoutTitle}
              </div>
              <div className={classes.GreenText}>
                WORKOUT COMPLETE
              </div>

              <div
                onClick={goToWorkoutBuilder}
                className={classes.FinishedDayButton}
              >
                View Feedback
              </div>
            </div>
          ) : (
            <div>
              <div className={classes.Header}>
                <div className={classes.headings}>
                  DAY {dayNumber + 1}
                  <div className={classes.workoutName}>
                    {day.workout && day.workout.workoutTitle}
                  </div>
                </div>
                <div
                  className={classes.DotsContainer}
                  onClick={() => setShowDropDown(!showDropDown)}
                >
                  <div className={classes.dots}>
                    <div className={classes.dot}></div>
                  </div>
                </div>
              </div>

              <div className={classes.DayContent}>
                <div className={classes.Workouts}>{exercises}</div>

                {!finished &&
                !day.rest &&
                (!day.workout.exercises ||
                  !day.workout.exercises.length) ? (
                  <>
                    <div
                      className={classes.AddButtonWrapper}
                      onClick={addWorkout}
                    >
                      <button className={classes.AddButton}>+</button>
                      <div className={classes.AddText}>
                        Add Workout
                      </div>
                    </div>
                    <div
                      className={classes.AddButtonWrapper}
                      onClick={setRestDayHandler}
                    >
                      <button className={classes.AddButton}>+</button>
                      <div className={classes.AddText}>
                        Make rest day
                      </div>
                    </div>
                  </>
                ) : day.rest || finished ? null : (
                  <div className={classes.DayBtnWrapper}>
                    <div
                      className={classes.DayButton}
                      // style={styleButton}
                      onClick={() => setIsQuickEdit(true)}
                    >
                      <img
                        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iMjAiIGhlaWdodD0iMjAiCnZpZXdCb3g9IjAgMCAyMjYgMjI2IgpzdHlsZT0iIGZpbGw6IzAwMDAwMDsiPjxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0ibm9uemVybyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIHN0cm9rZS1saW5lY2FwPSJidXR0IiBzdHJva2UtbGluZWpvaW49Im1pdGVyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1kYXNoYXJyYXk9IiIgc3Ryb2tlLWRhc2hvZmZzZXQ9IjAiIGZvbnQtZmFtaWx5PSJub25lIiBmb250LXdlaWdodD0ibm9uZSIgZm9udC1zaXplPSJub25lIiB0ZXh0LWFuY2hvcj0ibm9uZSIgc3R5bGU9Im1peC1ibGVuZC1tb2RlOiBub3JtYWwiPjxwYXRoIGQ9Ik0wLDIyNnYtMjI2aDIyNnYyMjZ6IiBmaWxsPSJub25lIj48L3BhdGg+PGcgZmlsbD0iIzdlN2U3ZSI+PHBhdGggZD0iTTE3My4zOTkwOCwxOC44MzMzM2MtMi40MDk0OCwwIC00LjgyMTYzLDAuOTE3ODMgLTYuNjU3ODgsMi43NTg3OWwtMTYuMDc0NTQsMTYuMDc0NTRsMzcuNjY2NjcsMzcuNjY2NjdsMTYuMDc0NTQsLTE2LjA3NDU0YzMuNjgxOTIsLTMuNjgxOTIgMy42ODE5MiwtOS42NDMyNiAwLC0xMy4zMTU3NmwtMjQuMzUwOTEsLTI0LjM1MDkxYy0xLjg0MDk2LC0xLjg0MDk2IC00LjI0ODQsLTIuNzU4NzkgLTYuNjU3ODgsLTIuNzU4Nzl6TTEzNi41NDE2Nyw1MS43OTE2N2wtMTA4LjI5MTY3LDEwOC4yOTE2N3YzNy42NjY2N2gzNy42NjY2N2wxMDguMjkxNjcsLTEwOC4yOTE2N3oiPjwvcGF0aD48L2c+PC9nPjwvc3ZnPg=="
                        alt="edit icon"
                        className={classes.EditIcon}
                      />
                      Quick Edit
                    </div>

                    <div
                      className={classes.DayButton}
                      // style={styleButton}
                      onClick={goToWorkoutBuilder}
                    >
                      <svg
                        className={classes.EditIcon}
                        viewBox="0 0 24.999 18.562"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g
                          fill="#707070"
                          data-name="Group 16743"
                          transform="translate(-244 -906.438)"
                        >
                          <path
                            d="M18.221 12.656a5.277 5.277 0 00.043-.844c0-.3-.043-.548-.043-.844l1.812-1.392a.387.387 0 00.086-.548l-1.726-2.911a.42.42 0 00-.518-.169l-2.158.844a6.28 6.28 0 00-1.467-.844l-.3-2.236a.462.462 0 00-.432-.337h-3.453a.462.462 0 00-.431.337l-.346 2.236a7.307 7.307 0 00-1.467.844l-2.158-.844a.4.4 0 00-.518.169L3.42 9.028a.51.51 0 00.086.548l1.856 1.392c0 .3-.043.548-.043.844s.043.548.043.844l-1.813 1.393a.388.388 0 00-.086.548l1.726 2.911a.42.42 0 00.518.169l2.158-.844a6.28 6.28 0 001.467.844l.345 2.236a.418.418 0 00.431.337h3.452a.462.462 0 00.432-.337l.346-2.236a7.3 7.3 0 001.467-.844l2.158.844a.4.4 0 00.518-.169l1.724-2.908a.509.509 0 00-.086-.548zm-6.43 2.109a2.954 2.954 0 113.021-2.953 2.969 2.969 0 01-3.021 2.954z"
                            data-name="Icon ionic-md-settings"
                            transform="translate(240.625 903.5)"
                          ></path>
                          <g data-name="Icon material-edit">
                            <path
                              d="M8.157 21.558H4v-4.157l.146-.146L14.322 7.08l.353-.354.354.354 3.45 3.45.353.353-.353.354L8.304 21.412l-.147.146zM19.11 10.606l-.353-.354-3.45-3.45-.354-.353.354-.354 1.684-1.683a1.408 1.408 0 011.002-.416c.378 0 .734.148 1.002.416l2.153 2.153a1.419 1.419 0 010 2.004l-1.684 1.683-.354.354z"
                              transform="translate(246.938 902.941)"
                            ></path>
                            <path
                              fill="#171a1c"
                              d="M7.95 21.058l10.175-10.175-3.45-3.45L4.5 17.608v3.45h3.45m11.16-11.16l1.683-1.683a.916.916 0 000-1.297L18.64 4.765a.914.914 0 00-1.297 0L15.66 6.45l3.45 3.45M8.363 22.059H3.5v-4.865l.293-.293L13.968 6.726l.707-.707.277-.277 1.684-1.684a1.905 1.905 0 011.356-.562c.512 0 .993.2 1.355.562L21.5 6.211a1.92 1.92 0 010 2.711l-1.683 1.684-.493.492-.492.492L8.657 21.766l-.293.292z"
                              transform="translate(246.938 902.941)"
                            ></path>
                          </g>
                        </g>
                      </svg>
                      Edit Workout
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Day;
