import React, { FunctionComponent } from 'react';
import cls from 'classnames';

import SetsColum from './SetsColum';
import classes from './Set.module.css';
import { emptySetObject } from 'utils/helpers';
import { SetObject, Set as SetType, SetKeys } from 'interfaces/db';

const MAX_SETS_LENGTH = 3;

interface Props {
  set: SetType;
  setNum: number;
  finished: boolean;
  ticked: boolean;
  setsChangeHandler: (setType: SetKeys, value: string) => void;
  autoFillSetsHandler: (setType: SetKeys, value: string) => void;
  setShowAutoFill: (setIdentifier: string) => void;
  showAutoFill: string;
}

const Set: FunctionComponent<Props> = ({
  set,
  setNum,
  ticked,
  finished,
  setsChangeHandler,
  autoFillSetsHandler,
  setShowAutoFill,
  showAutoFill,
}) => {
  const { completed, ...otherSets } = set;

  const setsArray = (Object.keys(otherSets) as Array<
    keyof typeof otherSets
  >)
    .map((el) => {
      const setValue = otherSets[el];

      if (typeof setValue === 'string') {
        return { [el]: { ...emptySetObject } };
      }

      // if (!(setValue as SetObject)?.index) {
      //   return { [el]: { ...setValue, index: 1 } };
      // }

      return { [el]: setValue };
    })
    // .sort((elA, elB) => {
    //   const [valA]: any = Object.values(elA);
    //   const [valB]: any = Object.values(elB);

    //   return valA.index - valB.index;
    // })
    .map((el, index) => {
      const [setKey] = Object.keys(el) as SetKeys[];
      return (
        <SetsColum
          key={index}
          setNum={setNum}
          setKey={setKey}
          finished={finished}
          setsChangeHandler={setsChangeHandler}
          autoFillSetsHandler={autoFillSetsHandler}
          set={otherSets[setKey] as string | SetObject}
          setShowAutoFill={setShowAutoFill}
          showAutoFill={showAutoFill}
        />
      );
    });

  return (
    <li key={setNum} className={classes.TableRow}>
      <div
        className={
          !finished
            ? classes.TableInputSet
            : classes.TableInputSetFinished
        }
      >
        {setNum + 1}
      </div>

      {setsArray}

      {setsArray.length !== MAX_SETS_LENGTH ? (
        <div
          className={cls(
            classes.InputContainer,
            classes.TableInputEmpty,
          )}
        />
      ) : null}

      {finished ? (
        ticked ? (
          <div
            style={{
              width: 27,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <svg
              // style={{ marginLeft: 8 }}
              xmlns="http://www.w3.org/2000/svg"
              width="18.1"
              height="14.1"
              viewBox="0 0 26.4 20.1"
            >
              <path
                id="Icon_material-done"
                data-name="Icon material-done"
                d="M13.5,24.3,7.2,18,5.1,20.1l8.4,8.4,18-18L29.4,8.4Z"
                transform="translate(-5.1 -8.4)"
                fill="#58cb2c"
              />
            </svg>
          </div>
        ) : (
          <div
            style={{
              width: 27,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <svg
              // style={{ marginLeft: 8 }}
              xmlns="http://www.w3.org/2000/svg"
              width="18.1"
              height="18.1"
              viewBox="0 0 21 21"
            >
              <path
                id="Icon_material-close"
                data-name="Icon material-close"
                d="M28.5,9.615,26.385,7.5,18,15.885,9.615,7.5,7.5,9.615,15.885,18,7.5,26.385,9.615,28.5,18,20.115,26.385,28.5,28.5,26.385,20.115,18Z"
                transform="translate(-7.5 -7.5)"
                fill="#ee1818"
              />
            </svg>
          </div>
        )
      ) : null}
    </li>
  );
};

export default Set;
