import React, { useEffect, useState } from 'react';

import { DASHBOARD } from './routes';
import { auth } from './firebase';

type Status = 'LOADING' | 'SIGNED_IN';

const withoutAuth = (Component: any) =>
  function HOC(props: any) {
    const [status, setStatus] = useState<Status>('LOADING');

    useEffect(() => {
      auth.onAuthStateChanged(async (user) => {
        if (user) {
          props.history.push(DASHBOARD.URL);
        } else {
          setStatus('SIGNED_IN');
        }
      });
    });

    if (status === 'LOADING') {
      return null;
    } else {
      return <Component {...props} />;
    }
  };

export default withoutAuth;
