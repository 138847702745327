import React, { FC, useState, useEffect } from 'react';
import classes from './ExerciseHistoryBox.module.css';
import ExerciseItem from '../ExerciseItem/ExerciseItem';
import { Line } from 'react-chartjs-2';

import { SetObject } from '../../interfaces/db';
import { DataSet, IndexedExercise } from '../../interfaces/utils';
import Spinner from 'components/UI/Spinner/Spinner';
import { firestore } from 'utils/firebase';
import { DocumentData, QueryDocumentSnapshot } from '@firebase/firestore-types';
interface Props {
  closeAnalytics: (item: IndexedExercise) => void;
  userId?: string | null;
  indexedExercise: IndexedExercise;
}
interface Set {
  [key: string]: SetObject | string | boolean;
}

const ExerciseHistoryBox: FC<Props> = ({ closeAnalytics, userId, indexedExercise }) => {
  const [pastInstances, setPastInstances] = useState<QueryDocumentSnapshot<DocumentData>[]>();
  const [PRs, setPRs] = useState<QueryDocumentSnapshot<DocumentData>[]>();

  const [graphData, setGraphData] = useState<any>({
    datasets: [],
  });

  const [calculated, setCalculated] = useState<boolean>(false);

  useEffect(() => {
    if (indexedExercise.exercise.type && userId) {
      firestore
        .collection(`clients`)
        .doc(userId)
        .collection(`pastExercises`)
        .doc(indexedExercise.exercise.type)
        .collection(`instances`)
        .limitToLast(30)
        .orderBy(`date`, 'asc')
        .get()
        .then((query) => {
          const tempPastInstances: QueryDocumentSnapshot<DocumentData>[] = [];
          query.forEach((doc) => {
            tempPastInstances.push(doc);
          });
          if (tempPastInstances.length > 0) setPastInstances(tempPastInstances);
          setCalculated(true);
        });

      firestore
        .collection(`clients`)
        .doc(userId)
        .collection(`pastExercises`)
        .doc(indexedExercise.exercise.type)
        .collection(`prs`)
        .get()
        .then((query) => {
          const tempPRs: QueryDocumentSnapshot<DocumentData>[] = [];
          query.forEach(function (doc) {
            tempPRs.push(doc);
          });
          setPRs(tempPRs);
        });
    } else {
      setCalculated(true);
    }
  }, [indexedExercise.exercise.type, userId]);

  const extractRemainder = (set: Set) => {
    if (set && typeof set.completed === 'boolean') {
      const { ...remainder } = set;
      return remainder;
    } else {
      return set;
    }
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const loader = (
    <div className={classes.Loading}>
      <Spinner />
      Calculating...
    </div>
  );

  const selectGraph = (key: string) => {
    const tempGraphData = graphData.datasets;

    if (key === 'weight') {
      tempGraphData[0].hidden = !tempGraphData[0].hidden;
    }

    if (key === 'volume') {
      tempGraphData[1].hidden = !tempGraphData[1].hidden;
    }

    setGraphData((prevState: any) => {
      return {
        ...prevState,
        datasets: tempGraphData,
      };
    });
  };

  useEffect(() => {
    const bestWeight = (sets: Set[]) => {
      // calculate relevant numbers
      const max = { weight: 0, numSets: 0 };
      sets.forEach((set) => {
        Object.keys(extractRemainder(set)).forEach((title) => {
          const weightValue = Number(
            (set?.[title] as SetObject).achieved || (set?.[title] as SetObject).programmed,
          );
          if (title.includes(`weight`)) {
            if (weightValue > max.weight) {
              max.weight = weightValue;
              max.numSets = 0;
            } else if (weightValue === max.weight) {
              max.numSets = max.numSets + 1;
            }
          }
        });
      });
      return max;
    };

    const totalVolume = (sets: Set[]) => {
      // calculate relevant numbers
      const total = { weight: 0, numSets: 0 };
      sets.forEach((set) => {
        Object.keys(extractRemainder(set)).forEach((title) => {
          const value = Number(
            (set?.[title] as SetObject).achieved || (set?.[title] as SetObject).programmed,
          );
          if (title.includes(`weight`)) {
            total.weight += value;
            total.numSets = total.numSets + 1;
          }
        });
      });
      return total;
    };

    const weightDataSet: DataSet = {
      label: 'Weight',
      fill: true,
      backgroundColor: '#6D7172',
      hidden: false,
    };

    const volumeDataSet: DataSet = {
      label: 'Volume',
      fill: true,
      backgroundColor: '#C74F26',
      hidden: true,
    };

    // compute data points for graph
    if (pastInstances) {
      const weightData: number[] = [];
      const volumeData: number[] = [];
      const labels: string[] = [];
      pastInstances.forEach((instance) => {
        const data = instance.data();
        labels.push(`${new Date(data?.date).getDate()}/${new Date(data?.date).getMonth() + 1}`);
        weightData.push(bestWeight(data?.sets).weight);
        volumeData.push(totalVolume(data?.sets).weight);
      });
      const datasets: DataSet[] = [
        {
          ...weightDataSet,
          data: weightData,
        },
        {
          ...volumeDataSet,
          data: volumeData,
        },
      ];
      setGraphData({
        labels,
        datasets,
      });
    }
  }, [pastInstances]);

  return (
    <div className={classes.Container}>
      <button className={classes.CloseBoxButton} onClick={() => closeAnalytics(indexedExercise)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="#707070"
          className="bi bi-x-lg"
          viewBox="0 0 16 16"
        >
          <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z" />
        </svg>
      </button>
      {!calculated ? (
        loader
      ) : !pastInstances ? (
        <div className={classes.NoData}>
          <label> No past instances recorded </label>
        </div>
      ) : (
        <React.Fragment>
          <div className={classes.Graph}>
            <div className={classes.GraphSelectorContainer}>
              {graphData?.datasets?.map((item: any, index: any) => {
                const color = item?.label === 'Weight' ? '#6D7172' : '#C74F26';
                // const border = item.hidden
                //   ? 'hidden'
                //   : '1px solid #f1f2f2';
                return (
                  <div
                    key={index}
                    className={classes.GraphSelector}
                    style={{ backgroundColor: color }}
                    onClick={() => selectGraph(item.label.toLowerCase())}
                  >
                    {item.label}
                    {item.hidden ? (
                      <svg
                        className={classes.Icon}
                        xmlns="http://www.w3.org/2000/svg"
                        width="13"
                        height="13"
                        fill="currentColor"
                        viewBox="0 0 16 16"
                      >
                        <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                      </svg>
                    ) : (
                      <svg
                        className={classes.Icon}
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3 14.5A1.5 1.5 0 0 1 1.5 13V3A1.5 1.5 0 0 1 3 1.5h8a.5.5 0 0 1 0 1H3a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V8a.5.5 0 0 1 1 0v5a1.5 1.5 0 0 1-1.5 1.5H3z" />
                        <path d="m8.354 10.354 7-7a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z" />
                      </svg>
                    )}
                  </div>
                );
              })}
            </div>
            <Line data={graphData} options={options} />
          </div>
          <hr className={classes.Divider} />
          <div className={classes.InnerContainer}>
            <div className={classes.PRContainer}>
              <label> PR&#39;s </label>
              {PRs?.map((item, i) => {
                const data = item.data();
                return (
                  <div key={i} className={classes.PRList}>
                    {`${item.id} PR: `}
                    {data.value}
                    {` ${data.unit}`}
                  </div>
                );
              })}
            </div>

            <div className={classes.HistoryContainer}>
              <label> History</label>
              {pastInstances?.map((item, index) => {
                const data = item.data();
                return (
                  <div className={classes.HistoryList} key={index}>
                    {new Date(data.date).toDateString()}
                    <ExerciseItem sets={data.sets} />
                  </div>
                );
              })}
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default ExerciseHistoryBox;
