import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios, { AxiosResponse } from 'axios';
import tick from '../../assets/icons/tick.svg';
import classes from './Checkout.module.css';
import { CheckoutForm } from 'components';
import { BASE_URL, encodeData } from 'utils/helpers';
import Modal from 'components/UI/Modal/Modal';
import Button from 'components/inputs/Button';
import Logo from 'assets/images/logo.svg';
import Footer from 'containers/Footer';
import mixpanel from 'mixpanel-browser';
import { firestore } from 'utils/firebase';

type FormValues = {
  email: string;
  firstName: string;
  lastName: string;
};

type PaymentInfo = {
  paymentType: string;
  coachName: string;
  numPrograms: number;
  planDescription: string;
  planImageDesktop: string;
  planImageMobile: string;
  planCode: string;
  isTrialEnabled?: boolean;
  funnelIdentifier: string;
  coachUserId: string;
  price: number;
  renewalPrice: number;
  currency: string;
  isReactivateSubscription: boolean;
};

const PaymentPage: FC = () => {
  const { state } = useLocation();
  const [paymentInfo, setPaymentInfo] = useState<PaymentInfo>();
  const [isModalOpen, setModalState] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (state) {
      const objectString = state + '';
      const encodedDataBuffer = Buffer.from(objectString, 'base64');
      const decodedData = encodedDataBuffer.toString('utf8');
      const paymentInfo: PaymentInfo = JSON.parse(decodedData);

      setPaymentInfo(paymentInfo);
    } else {
      window.location.href = 'https://www.coachelite.co/';
    }
  }, []);

  useEffect(() => {
    if (paymentInfo && !paymentInfo?.planCode) {
      window.location.href = 'https://www.coachelite.co/';
    }
  }, [paymentInfo]);

  const handleSubmit = async ({ email, firstName, lastName }: FormValues) => {
    mixpanel.track('Checkout Button Click', {
      CoachIdentifier: paymentInfo?.funnelIdentifier,
    });
    setLoading(true);
    const data = encodeData(
      JSON.stringify({
        email: email,
        planCode: paymentInfo?.planCode,
        fullName: `${firstName} ${lastName}`,
        coachUserId: paymentInfo?.coachUserId,
        ...paymentInfo,
      }),
    );

    // TODO allow user to select price they want
    const funnelPricesRef = firestore
      .collection('coaches')
      .doc(paymentInfo?.coachUserId)
      .collection('funnel-plans')
      .doc(paymentInfo?.planCode)
      .collection('prices');

    const priceRef = await funnelPricesRef.limit(1).get();
    const priceDoc = priceRef.docs[0];

    axios({
      method: 'POST',
      url: `${BASE_URL}/v1-https-funnels-createCheckoutSession`,
      data: {
        email: email,
        firstName: firstName,
        lastName: lastName,
        coachUserId: paymentInfo?.coachUserId,
        planCode: paymentInfo?.planCode,
        priceId: priceDoc.id,
        identifier: paymentInfo?.funnelIdentifier,
        isReactivateSubscription: paymentInfo?.isReactivateSubscription,
        data: data,
      },
    })
      .then((response: AxiosResponse) => {
        setLoading(false);
        window.location.href = response?.data?.data?.sessionUrl;
      })
      .catch((error) => {
        setLoading(false);
        const message = error?.response?.data?.message;
        if (message && message === 'auth/user-exists') {
          setModalState(true); // warn user they are already a client and can't subscribe
          setMessage('Account already exists.');
        } else {
          setMessage('Something went wrong.');
          mixpanel.track('Checkout Error', {
            CoachIdentifier: paymentInfo?.funnelIdentifier,
          });
        }
      });
  };

  const currencySymbol = paymentInfo?.currency === 'USD' ? '$' : paymentInfo?.currency === 'ZAR' ? 'R' : '';

  const pricing = paymentInfo?.isTrialEnabled
    ? `FIRST MONTH IS ${currencySymbol}1`
    : currencySymbol + paymentInfo?.price + '/month';

  return (
    <>
      <Modal openModal={() => setModalState(false)} isModalOpen={isModalOpen}>
        <div className={classes.modalContainer}>
          <div className={classes.ModalClose}>
            <img
              onClick={() => setModalState(false)}
              src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGlkPSJDYXBhXzEiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDM4Ni42NjcgMzg2LjY2NyIgaGVpZ2h0PSI1MTIiIHZpZXdCb3g9IjAgMCAzODYuNjY3IDM4Ni42NjciIHdpZHRoPSI1MTIiIGNsYXNzPSJob3ZlcmVkLXBhdGhzIj48Zz48cGF0aCBkPSJtMzg2LjY2NyA0NS41NjQtNDUuNTY0LTQ1LjU2NC0xNDcuNzcgMTQ3Ljc2OS0xNDcuNzY5LTE0Ny43NjktNDUuNTY0IDQ1LjU2NCAxNDcuNzY5IDE0Ny43NjktMTQ3Ljc2OSAxNDcuNzcgNDUuNTY0IDQ1LjU2NCAxNDcuNzY5LTE0Ny43NjkgMTQ3Ljc2OSAxNDcuNzY5IDQ1LjU2NC00NS41NjQtMTQ3Ljc2OC0xNDcuNzd6IiBkYXRhLW9yaWdpbmFsPSIjMDAwMDAwIiBjbGFzcz0iaG92ZXJlZC1wYXRoIGFjdGl2ZS1wYXRoIiBzdHlsZT0iZmlsbDojRkZGRkZGIiBkYXRhLW9sZF9jb2xvcj0iIzAwMDAwMCI+PC9wYXRoPjwvZz4gPC9zdmc+"
              alt="close"
            />
          </div>
          <p className={classes.ModalLargeText}>{message || 'Something went wrong.'}</p>
          <div className={classes.ModalButtons}>
            <Button onClick={() => setModalState(false)}>Ok</Button>
          </div>
        </div>
      </Modal>
      <div className={classes.Page}>
        <div className={classes.Header}>
          <div className={classes.Logo}>
            <a href={`https://app.coachelite.co/${paymentInfo?.funnelIdentifier}`}>
              <img src={Logo} alt="logo" />
            </a>
          </div>

          <div className={classes.AuthButtons}></div>
        </div>
        <h1>SUBSCRIBE NOW!</h1>
        <ul>
          <li>
            <div className={[classes.Liststyle, classes.ListstyleComplete].join(' ')}>
              <img src={tick} alt="tick" />
            </div>
            SUBSCRIPTION SELECTED
          </li>
          <div className={classes.CardContainer}>
            <div className={classes.ImageContainer}>
              <img src={paymentInfo?.planImageDesktop} alt="coach program" />
            </div>

            <div className={classes.CardContent}>
              <h3>{pricing}</h3>
              {paymentInfo?.isTrialEnabled && (
                <h5>{`${currencySymbol + paymentInfo?.renewalPrice} (${
                  paymentInfo?.currency
                }) every month after. Cancel anytime.`}</h5>
              )}
              {paymentInfo?.isTrialEnabled && (
                <h6>
                  {`After completion of 1 month of your subscription, your renewal will be automatically charged at ${
                    currencySymbol + paymentInfo?.renewalPrice
                  }, with the flexibility to cancel at anytime.`}
                </h6>
              )}

              {!paymentInfo?.isTrialEnabled && (
                <h4 className={classes.Title}>
                  {paymentInfo?.coachName}{' '}
                  <span>
                    &#40;{paymentInfo?.numPrograms} program
                    {paymentInfo?.numPrograms && paymentInfo?.numPrograms > 1 && 's'}
                    &#41;
                  </span>
                </h4>
              )}
              {!paymentInfo?.isTrialEnabled && (
                <span className={classes.NumPrograms}>
                  &#40;{paymentInfo?.numPrograms} program
                  {paymentInfo?.numPrograms && paymentInfo?.numPrograms > 1 && 's'}
                  &#41;
                </span>
              )}
              {!paymentInfo?.isTrialEnabled && <p className={classes.Description}>{paymentInfo?.planDescription}</p>}
            </div>
          </div>
          <li>
            <div className={classes.Liststyle}>2</div>
            YOUR ACCOUNT
          </li>
          <CheckoutForm handleSubmit={handleSubmit} loading={loading} />
          <span className={classes.Disclaimer}>
            By continuing you accept our{' '}
            <a
              href="https://docs.google.com/document/d/16Rqu8_QSnGp_t1vUJaTndO8dHltGwAmyZKiSBj_xogY"
              rel="noopener noreferrer"
              target="_blank"
            >
              privacy policy
            </a>
            ,{' '}
            <a
              href="https://docs.google.com/document/d/1CoVHks0TpuPR4-ltbiPuSgIyPyJf28K2P0kJdd3q4Do"
              rel="noopener noreferrer"
              target="_blank"
            >
              terms of service
            </a>{' '}
            and{' '}
            <a href="" target="_blank" rel="noopener noreferrer">
              billing terms
            </a>
            .
          </span>
        </ul>
      </div>
      <Footer />
    </>
  );
};

export default PaymentPage;
