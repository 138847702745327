import React, { FC } from 'react';
import { Formik, Form, Field, FormikHelpers, FieldInputProps, FormikProps, FieldMetaProps } from 'formik';
// import { object, string, number } from 'yup';
import firebase from 'firebase/app';
// import { FormProps } from 'interfaces/utils';
import classes from './ProgramMetadata.module.css';
import Textarea from 'react-textarea-autosize';
import { Programs } from 'interfaces/db';

const experienceLevels = ['Beginner', 'Intermediate', 'Advanced'];

interface FieldProps<V = any> {
  field: FieldInputProps<V>;
  form: FormikProps<V>;
  meta: FieldMetaProps<V>;
}

type InitialValues = {
  focusArea: string;
  sessionsPerWeek: number;
  experience: string;
  equipment: string;
  description: string;
  programName: string;
};
interface Payload extends InitialValues {
  coachName: string;
  createdAt: firebase.firestore.FieldValue;
  duration: string;
  programKey: string;
  programName: string;
  programImage: any;
  program: Programs;
}

const ProgramMetadata: FC<{
  handleSubmit: (payload: Payload) => Promise<void>;
  loading: boolean;
  program: any;
  coachName: string;
}> = ({ handleSubmit, loading, program, coachName }) => {
  // const validationSchema = object().shape({
  //   focusArea: string().max(30).required(),
  //   sessionsPerWeek: number().required(),
  //   experience: string().required(),
  //   equipment: string().max(30).required(),
  //   description: string().max(255).required(),
  //   programName: string().max(255).required(),
  // });

  const initialValues = {
    focusArea: '',
    sessionsPerWeek: 1,
    experience: 'Beginner',
    equipment: 'Full Equipment',
    description: '',
    programName: program?.name,
  };

  const onSubmit = async (values: InitialValues, actions: FormikHelpers<InitialValues>) => {
    const payload: Payload = {
      ...values,
      coachName: coachName,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      duration: program?.weeks?.length || 1,
      programKey: program?.programKey,
      programName: program?.name,
      programImage: program?.image,
      program: program,
    };
    handleSubmit(payload);
    actions.setSubmitting(false);
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      // validationSchema={validationSchema}
    >
      {({ submitForm }) => (
        <Form>
          <div className={classes.CategoryModal}>
            <div className={classes.FieldsContainer}>
              <p>Metadata for the overview of your program on your subscription funnel profile</p>
              <Field id="programName" name="programName" placeholder="Program Name" className={classes.Category} />
              <Field id="focusArea" name="focusArea" placeholder="Focus Area" className={classes.Category} />
              <Field
                id="sessionsPerWeek"
                name="sessionsPerWeek"
                type="number"
                placeholder="Session Per Week"
                className={classes.Category}
              />
              <Field as="select" id="experience" name="experience" className={classes.planInput}>
                {experienceLevels.map((experience) => (
                  <option value={experience} key={experience}>
                    {experience}
                  </option>
                ))}
              </Field>
              <Field id="equipment" name="equipment" placeholder="Equipment" className={classes.Category} />
              <Field name="description">
                {({ field }: FieldProps) => (
                  // <div className={MessageInputClass}>
                  <Textarea id="description" placeholder="Description" {...field} />
                )}
                {/* </div> */}
              </Field>
            </div>

            <div className={classes.ModalButtons}>
              <button type="button" onClick={submitForm} className={classes.ModalSave}>
                {loading ? <i className="fa fa-circle-o-notch fa-spin" /> : 'Make Program Live'}
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ProgramMetadata;
