import React, { FC } from 'react';
import classes from './ExerciseGroup.module.css';

interface Props {
  group: number;
  index: number;
}

const ExerciseGroup: FC<Props> = ({ group, index }) => {
  const char = String.fromCharCode(65 + group);

  return <div className={classes.ExerciseGroup}>{char + index}</div>;
};

export default ExerciseGroup;
