import React, { FC, useState, useEffect, useRef } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import TabNav from 'components/UI/TabNav/TabNav';
import { UpdateProfileForm, FunnelSettingsForm } from 'components';
import { saveCoachProfile } from 'store/actions/auth';
import Modal from 'components/UI/Modal/Modal';
import Spinner from 'components/UI/Spinner/Spinner';
import withAuth from 'utils/withAuth';
import { RootState } from 'store';
import Layout from '../Layout';
import { ACCOUNT } from 'utils/routes';
import { CoachProfile as ICoachProfile } from 'interfaces/db';
import classes from './Account.module.css';
import { firestore, storage, getUser } from 'utils/firebase';
import { UploadTask, UploadTaskSnapshot } from '@firebase/storage-types';
import { v4 as uuidv4 } from 'uuid';
import { createFunnelSubscriptionPlan } from './functions';
import { eligibleCoach } from 'utils/helpers';
import { useToasts } from 'react-toast-notifications';

const mapStateToProps = ({ auth }: RootState) => {
  return {
    token: auth.token,
    userId: auth.userId,
    coachProfile: auth.coachProfile,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onSaveCoachProfile: (coachProfile: ICoachProfile, coachProfileKey: string, token: string) =>
      dispatch(saveCoachProfile(coachProfile, coachProfileKey, token)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type FormValues = Pick<ICoachProfile, 'fullname' | 'birthday' | 'email'>;

interface FunnelSettings {
  coachOverview: {
    coachName: string;
    title: string;
    descriptionTitle: string;
    description: string;
  };
  planOverview: {
    identifier: string;
    price: number;
    // displayPrice: number;
    description: string;
    currency: string;
    organisation: boolean;
    isTrialEnabled: boolean;
  };
  testimonials: any[];
  faqs: any[];
  otherImages: {
    heroImageDesktop: any;
    heroImageMobile: any;
    getToKnowImage: any;
  };
}

const CoachProfile: FC<PropsFromRedux> = ({ token, onSaveCoachProfile, coachProfile, userId }) => {
  const TABS = eligibleCoach(userId) ? ['Edit Profile', 'Subscription Funnel Settings'] : ['Edit Profile'];
  const [loading, setLoading] = useState(false);
  const [saveProfileLoading, setSaveProfileLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [showSaveProfile, setShowSaveProfile] = useState(false);
  const [newFormVals, setNewFormVals] = useState<FormValues>({
    fullname: '',
    birthday: '',
    email: '',
  });
  const [selectedTab, setSelectedTab] = useState(TABS[0]);
  const [funnelSettings, _setFunnelSettings] = useState<FunnelSettings>({
    coachOverview: {
      coachName: '',
      title: '',
      descriptionTitle: '',
      description: '',
    },
    planOverview: {
      identifier: '',
      price: 0,
      description: '',
      // displayPrice: 0,
      currency: 'ZAR',
      organisation: false,
      isTrialEnabled: false,
    },
    testimonials: [],
    faqs: [],
    otherImages: {
      heroImageDesktop: '',
      heroImageMobile: '',
      getToKnowImage: '',
    },
  });
  const [planCode, setPlanCode] = useState<string | null>('');

  const funnelSettingsRef = useRef(funnelSettings);

  const { addToast } = useToasts();

  const setFunnelSettings = (info: any) => {
    funnelSettingsRef.current = info;
    _setFunnelSettings(info);
  };

  // ! we will eventually also fetch the prices collections to show the different pricing options
  useEffect(() => {
    const fetchPlanDoc = () =>
      new Promise((resolve) => {
        setLoading(true);
        const coachesRef = firestore?.collection('coaches');
        const coachDocument = coachesRef?.doc(userId || 'test');
        // const marketplacePlansRef = coachDocument?.collection('marketplace-plans');
        const funnelPlansRef = coachDocument?.collection('funnel-plans');
        funnelPlansRef
          .limit(1)
          .get()
          .then((funnelPlansRef) => {
            const doc = funnelPlansRef.docs[0];
            if (!doc?.exists) {
              return resolve({});
            }
            setPlanCode(doc.id);
            return resolve(doc.data());
          });
      });
    fetchPlanDoc()
      .then((result) => {
        setLoading(false);
        const funnelSettings = result as FunnelSettings;
        setFunnelSettings(funnelSettings);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const showSaveProfileModal = ({ fullname, birthday, email }: FormValues) => {
    setNewFormVals({ fullname, birthday, email });
    setShowSaveProfile(true);
  };

  const handleUpdateProfileError = (error: any) => {
    console.error('Error saving coach profile', error);
    if (error?.code && error?.code?.includes('auth')) {
      // Firebase auth error
      addToast(`Error updating account: ${error.message}`, { appearance: 'error' });
    } else {
      addToast(`An error has occurred updating Account`, { appearance: 'error' });
    }
    setSaveProfileLoading(false);
    setShowSaveProfile(false);
  };

  const handleSaveProfile = async () => {
    if (coachProfile && token) {
      setSaveProfileLoading(true);
      const key = Object.keys(coachProfile)[0];
      const newCoachProfile = {
        ...coachProfile[key],
        ...newFormVals,
      };

      try {
        if (getCoachValues().email !== newFormVals.email) {
          // Email updated
          try {
            await getUser()?.updateEmail(newFormVals.email);
          } catch (err: any) {
            handleUpdateProfileError(err);
            return;
          }
        }
        await onSaveCoachProfile(newCoachProfile, key, token);
        addToast('Account updated successfully', { appearance: 'success' });
        setSaveProfileLoading(false);
        setShowSaveProfile(false);
      } catch (err: any) {
        handleUpdateProfileError(err);
      }
    }
  };

  const uploadProgress: (a: UploadTaskSnapshot) => any = (snapshot) => {
    setProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
  };

  console.log(progress);
  const uploadError: (a: Error) => any = (error) => {
    console.log(error);
  };

  const uploadSuccess = async ({
    uploadTask,
    fileName,
    fileSize,
    testimonialId,
    key,
    resolve,
  }: {
    uploadTask: UploadTask;
    fileName: string;
    fileSize: number;
    testimonialId?: string;
    key: number | string;
    resolve: any;
  }) => {
    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
      if (userId) {
        let image = {
          downloadURL: downloadURL,
          fileName: fileName || 'No file name',
          userId: userId,
          fileSize: fileSize || 50,
        };
        if (testimonialId) {
          image = {
            ...image,
            testimonialId: testimonialId,
          } as any;

          testimonialObjectUpdate(testimonialId, key, image, resolve);
        } else {
          otherImageObjectUpdate(key, image, resolve);
        }
      }
    });
  };

  const otherImageObjectUpdate = async (key: number | string, image: any, resolve: any) => {
    const funnelSettingsCopy = Object.assign({}, funnelSettingsRef.current);
    funnelSettingsCopy.otherImages = {
      ...funnelSettingsCopy.otherImages,
      [key]: image,
    };
    await setFunnelSettings(funnelSettingsCopy);
    resolve(funnelSettingsRef.current);
  };

  const testimonialObjectUpdate = async (testimonialId: string, key: number | string, image: any, resolve: any) => {
    const funnelSettingsCopy = Object.assign({}, funnelSettingsRef.current);
    funnelSettingsCopy.testimonials = funnelSettingsCopy?.testimonials.map((testimonial) => {
      if (testimonial.id === testimonialId) {
        const imageType = key ? 'afterImage' : 'beforeImage';
        return { ...testimonial, [imageType]: image };
      }
      return testimonial;
    });
    await setFunnelSettings(funnelSettingsCopy);
    resolve(funnelSettingsRef.current);
  };

  const testimonialUploadTasks = async (image: File, key: number, testimonialId: string) =>
    new Promise((resolve) => {
      const { name, size } = image;
      const storageRef = storage.ref(`coaches/${userId}/marketplace/testimonials/${testimonialId}/${uuidv4()}`);
      const uploadTask: any = storageRef.put(image);

      uploadTask.on('state_changed', uploadProgress, uploadError, () =>
        uploadSuccess({
          uploadTask: uploadTask,
          fileName: name,
          fileSize: size,
          testimonialId: testimonialId,
          key: key,
          resolve,
        }),
      );
    });

  const handleUploadTestimonialImages = async (testimonials: any[], resolve: any) => {
    await Promise.all(
      testimonials.map(async (testimonial) => {
        return new Promise((resolve) => {
          const promises: Promise<any>[] = [];
          try {
            const beforeImage = testimonial.beforeImage;
            const afterImage = testimonial.afterImage;
            if (afterImage instanceof File && !(beforeImage instanceof File)) {
              promises.push(testimonialUploadTasks(afterImage, 1, testimonial.id));
            } else if (beforeImage instanceof File && !(afterImage instanceof File)) {
              promises.push(testimonialUploadTasks(beforeImage, 0, testimonial.id));
            } else if (!(beforeImage instanceof File) && !(afterImage instanceof File)) {
              resolve(null);
              return;
            } else {
              promises.push(testimonialUploadTasks(beforeImage, 0, testimonial.id));
              promises.push(testimonialUploadTasks(afterImage, 1, testimonial.id));
            }

            Promise.all(promises).then(() => {
              resolve(funnelSettingsRef.current);
            });
          } catch (e) {
            console.log(e);
          }
        });
      }),
    ).then(() => {
      resolve(funnelSettingsRef.current);
    });

    setProgress(0);
  };

  const handleUploadOtherImages = async (otherImages: any, resolve: any) => {
    await Promise.all(
      Object.keys(otherImages).map(async (key) => {
        return new Promise((resolve) => {
          try {
            const file = otherImages[key];
            if ((typeof file === 'string' && file.length === 0) || !(file instanceof File)) {
              resolve(null);
              return;
            }
            const { name, size } = file;
            const storageRef = storage.ref(`coaches/${userId}/marketplace/otherImages/${key}/${uuidv4()}`);

            const uploadTask: any = storageRef.put(file);
            uploadTask.on('state_changed', uploadProgress, uploadError, () =>
              uploadSuccess({
                uploadTask,
                fileName: name,
                fileSize: size,
                key: key,
                resolve,
              }),
            );
          } catch (e) {
            console.log(e);
          }
        }).then(() => {
          console.log('One image uploaded successfully');
        });
      }),
    ).then(() => {
      resolve(funnelSettingsRef.current);
    });

    setProgress(0);
  };

  const handleSaveFunnel = async (payload: FunnelSettings) => {
    setFunnelSettings(payload);
    const promiseTestimonial = new Promise((resolve) => handleUploadTestimonialImages(payload?.testimonials, resolve));
    const promiseOtherImages = new Promise((resolve) => handleUploadOtherImages(payload?.otherImages, resolve));

    Promise.all([promiseOtherImages, promiseTestimonial]).then(async () => {
      try {
        const planOverview = funnelSettingsRef.current?.planOverview;
        if (coachProfile && token && userId) {
          const identifiersRef = firestore?.collection('funnel-identifiers');
          const doc = await identifiersRef.doc(planOverview?.identifier)?.get();

          if (!doc?.exists) {
            // New subscription funnel
            try {
              const response: any = await createFunnelSubscriptionPlan(planOverview, userId);
              const planCode = response.data?.data?.productId;

              setPlanCode(planCode);

              // ? should we instead pass all setttings data through and update on the backend?
              const coachesRef = firestore?.collection('coaches');
              const coachDocument = coachesRef?.doc(userId || 'test');
              const funnelPlansRef = coachDocument?.collection('funnel-plans');
              funnelPlansRef
                .doc(planCode)
                .update(funnelSettingsRef.current)
                .then(() => {
                  console.log('Completed creating subscription funnel');
                  // need to set loading to false as well
                })
                .catch((e) => {
                  console.log(e);
                });
              // });
            } catch (error) {
              console.log(error);
            }
          } else {
            // Exisiting subscription funnel
            if (doc.data()?.coachUserId === userId && planCode) {
              const coachesRef = firestore?.collection('coaches');
              const coachDocument = coachesRef?.doc(userId || 'test');
              const funnelPlansRef = coachDocument?.collection('funnel-plans');
              funnelPlansRef
                .doc(planCode)
                .update({ ...funnelSettingsRef.current })
                .then(() => {
                  console.log('Completed updating subscription funnel');
                  // need to set loading to false
                })
                .catch((e) => {
                  console.log(e);
                });
            } else {
              console.log('Identifier already exists');
            }
          }
        }
      } catch (e) {
        console.log('Error Occurred While Updating Funnel Settings:' + e);
      }
    });
  };

  const loadingCoachProfile = !Object.keys(coachProfile || {}).length;

  if (loadingCoachProfile) {
    return (
      <Layout loading={loadingCoachProfile} heading="Your Profile">
        <Spinner />
      </Layout>
    );
  }

  const getCoachValues = () => {
    const { email, code, fullname, birthday } = Object.values(coachProfile || {})[0];

    return {
      code,
      email,
      birthday,
      fullname,
    };
  };

  const BodyContent = () => {
    switch (selectedTab) {
      case 'Edit Profile':
        return (
          <div className={classes.TrainerProfile}>
            <UpdateProfileForm initialValues={getCoachValues()} handleSubmit={showSaveProfileModal} />
          </div>
        );
      case 'Subscription Funnel Settings':
        return (
          <div className={classes.TrainerProfile}>
            <FunnelSettingsForm
              handleSubmit={handleSaveFunnel}
              funnelValues={funnelSettings}
              loading={loading}
              coachValues={Object.values(coachProfile || {})[0]}
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Layout loading={loadingCoachProfile} heading={ACCOUNT.TITLE}>
      <div className={classes.Container}>
        <TabNav setSelectedTab={setSelectedTab} selectedTab={selectedTab} tabHeadings={TABS} />
        {BodyContent()}
        <Modal isModalOpen={showSaveProfile} openModal={() => setShowSaveProfile(false)}>
          <div className={classes.modalContainer}>
            <div className={classes.ModalClose}>
              <img
                alt="close"
                onClick={() => setShowSaveProfile(false)}
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGlkPSJDYXBhXzEiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDM4Ni42NjcgMzg2LjY2NyIgaGVpZ2h0PSI1MTIiIHZpZXdCb3g9IjAgMCAzODYuNjY3IDM4Ni42NjciIHdpZHRoPSI1MTIiIGNsYXNzPSJob3ZlcmVkLXBhdGhzIj48Zz48cGF0aCBkPSJtMzg2LjY2NyA0NS41NjQtNDUuNTY0LTQ1LjU2NC0xNDcuNzcgMTQ3Ljc2OS0xNDcuNzY5LTE0Ny43NjktNDUuNTY0IDQ1LjU2NCAxNDcuNzY5IDE0Ny43NjktMTQ3Ljc2OSAxNDcuNzcgNDUuNTY0IDQ1LjU2NCAxNDcuNzY5LTE0Ny43NjkgMTQ3Ljc2OSAxNDcuNzY5IDQ1LjU2NC00NS41NjQtMTQ3Ljc2OC0xNDcuNzd6IiBkYXRhLW9yaWdpbmFsPSIjMDAwMDAwIiBjbGFzcz0iaG92ZXJlZC1wYXRoIGFjdGl2ZS1wYXRoIiBzdHlsZT0iZmlsbDojRkZGRkZGIiBkYXRhLW9sZF9jb2xvcj0iIzAwMDAwMCI+PC9wYXRoPjwvZz4gPC9zdmc+"
              />
            </div>

            <div className={classes.ModalSaveIcon}>
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAABjklEQVRoge2ZMU7DMBSG/8eQBWYkJLJzi0pcihtAuUJHuABigI0LMDLAjNpwjJ+hprJM7NiOE8eVv9GJ33ufrTynDVCpTA/JhuQ9yY7z8U7yPLXIekYBnY+xMmKIdAAuRq1GPF8ArkXkJ2ayKcIkJcUzSuaAY+ufSK5Inqapd2IsEje56wqmbyfUeFndrCfYSo2X1c16Ap2pcedORK2afx2fJMM6qa3AoWWbWMRL5iR1ESEELMYVgDeXTFaRQJwyJYkADpnSRACLTIkiwF7mWR9wvmuJiPSNm/zdF4oeV4/h2wn1OaXuyD+qyNJIIkLyMmaeaGix2phYqXZkEyujoyQ2MXOTdK1c1K61ZKrI0qgiBi8AWkkEgBbAa0gBqdpvKyLbkMRDqDPl23WP3n6zvv0OEZK3PiNLo4osjaMV6bJUkQBT5DFLFanh/vPBmuQu53+/MXm9DrKjPBDN39hzMZT3aLuWjUM3m/q5sNGTd6df9xV58EmSmjE12QptSN5RdbPMbEnekmyCRSqVcH4BoyWacwifNfYAAAAASUVORK5CYII="
                alt="save"
              />
            </div>
            <p className={classes.ModalLargeText}>Are you sure?</p>
            <p className={classes.ModalSmallText}>
              Do you want to save your profile?
              <br />
              This will save any changes that were made.
              <br />
              Changing your email address will update your login details.
            </p>
            <div className={classes.ModalButtons}>
              <button className={classes.ModalCancel} onClick={() => setShowSaveProfile(false)}>
                Cancel
              </button>
              <button className={classes.ModalSave} onClick={handleSaveProfile}>
                {saveProfileLoading ? <i className="fa fa-circle-o-notch fa-spin" /> : 'Save'}
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </Layout>
  );
};

export default connector(withAuth(CoachProfile));
